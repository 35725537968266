import { ThemeTypeEnum } from "@/ts/SuperEnum";
export interface ThemesVO {
    theme_obj: any,
    css: { [_v: string]: string }
}

export interface ThemesType {
    [_key: number]: ThemesVO
};
export const Themes: ThemesType = {
    [ThemeTypeEnum.black]: {
        theme_obj: {
            //#region NewDigitalCollectionManagement相关
            bian_zu: { //编组图片
                big_img: require('@/assets/image/suoxiao_b.png'),
                small_img: require('@/assets/image/suxiao_1_b.png')
            },
            real_name: {
                positive: require('@/assets/image/realname/shangchuanzheng_b.png'),
                other: require('@/assets/image/realname/shangchuanfan_b.png')
            },
            news: {
                more: require('@/assets/image/xiaoxi/tianjia_b.png'),
                jiantou: require('@/assets/image/jiantou_b.png'),
                mail: require('@/assets/image/xiaoxi/tongxunlu_b.png'),
                upimg:require('@/assets/image/xiaoxi/upimg_b.png')
            },
            my:{
                cangpin:require('@/assets/image/my/cangpin_b.png'),
                baimingdan:require('@/assets/image/my/baimingdan_b.png'),
                shenhe:require('@/assets/image/my/shenhe_b.png'),
                chaoguan:require('@/assets/image/my/chaoji_b.png'),
                siyao:require('@/assets/image/my/miyao_b.png'),
                guanyu:require('@/assets/image/my/guanyu_b.png'),
                kefu:require('@/assets/image/my/kefu_b.png'),
                shezhi: require('@/assets/image/my/shezhi_b.png'),
                qiandao:require('@/assets/image/qian_dao_btn.png')
            },
            chatwin:{
                zhaopian:require('@/assets/image/xiaoxi/zhaopian_b.png'),
                shipin:require('@/assets/image/xiaoxi/video_b.png'),
                zhangxiang:require('@/assets/image/xiaoxi/zhaoxiang.png'),
                wenjian:require('@/assets/image/xiaoxi/wenjian_b.png'),
                zhuangzhang:require('@/assets/image/xiaoxi/zhuangzhang_b.png'),
                nft:require('@/assets/image/xiaoxi/ntf.png'),
                mingpian:require('@/assets/image/xiaoxi/mingpian_b.png')
            },
            hecheng: {
                button: require('@/assets/image/ui/asset_compose/new_yijian_btn.png'),
            },
            zhuzi:{
                default:"display:flex;",
                di_img:require('@/assets/image/ui/zhuzi/tV.png'),
                di_img_css:['width: 300px !important;','margin-top: -30px;'],
                title_content:"margin-top: 100px",
                bg_img:require('@/assets/image/ui/zhuzi/1K.png')
            },
             //返回图片
            allback:{
                back_img:require('@/assets/image/jiantou_b.png')
            }
            //#endregion
        },
        css: {
            home_bgc_class:'#202023',
            bottom_class:'#191f23',
            //公共信息背景色
            Announcement :'#303538',
            //置换块
            zhihuan_bgc:'#1b1c1e',
            zhihuan_input_bgc:'#303538',
            // 通讯录快
            tongxunlu_bgc:'#232323',
            //input框颜色
            input_bgc:'#303539',
            box_bgc:'#303539',
            //钱包框颜色
            qianbao_bgc: '#2A2E2F',
            //提现方式border
            tixian_border:'#06090c',
            //合成背景图
            hecheng_bgc: '#191F24',
            hecheng_tanchu_bgc:'#252933',
            //合成记录
            hechengjilu_border:'#303339',
            //玲珑塔
            linglongta_bgc:'rgba(0,0,0,.5)',
            //支付密码背景图
            zhifumima_bgc:'#202023',
            //提示框
            tishikuang_bgc:'#333',
            //消息背景色
            new_bg:'#070d0d',
            new_box_bg:'#191F24',
            bgc_class: `#000000`,
            line: `#303339`,
            border: `#6D7278`,
            ffffff_324460: `#ffffff`,
            c313940_F6F6F6:`#313940`,
            c303538_ffffff: `#303538`,
            c303339_ffffff: `#303339`,
            c2A2E2F_ffffff: `#2A2E2F`,
            c191F24_ffffff: `#191F24`,
            fffffe_324460: `#fffffe`,
            c848484_324460: `#848484`,
            c02cdb0_ffffff: `#02cdb0`,
            ffffff_c02cdb0: `#ffffff`,
            ffffff_c6D7278: `#ffffff`,
            c6D7278_A8ADB4: `#6D7278`,
            C2C2C3_848484: `#C2C2C3`,
            ffffff_303339: `#ffffff`,
            c24619387_FC5925: `rgb(246, 193, 87)`,
            c212323_ffffff: `#212323`,
            f6fafe_434343: `#f6fafe`,
            c181818_f6fafe: `#181818`,
            c2A2E2F_F6FAFE: `#2A2E2F`,
            c191F24_1BCDAF: `#191F24`,
            ffffff_1BCDAF: `#ffffff`,
            c000000_FFFFFF: `#000000`,
            c000000_F1F1F1: `#000000`,
            c000000_4B4C4D: `#000000`,
            c02CDB0_324460: `#02CDB0`,
            ffffff_F6FAFE: `ffffff`,
            FEFFFF_324460: `#FEFFFF`,
            c191f24_FEFFFF: `#191f24`,
            c2A2E2F_FEFFFF: `#2A2E2F`,
            RealNameAuthentication_body: `#191F24`,
            RealNameAuthentication_font_color: `#ffffff`,
            RealNameAuthentication_input_box: `#28292C`,
            RealNameAuthentication_input_box_span: `#ffffff`,
            c3B3F40_E9EEF5: `#3B3F40`,
            ffffff_0091FF: `#ffffff`,
            c000000_ffffff: `#202023`,
            c191F24_F6FAFE: `#191F24`,
            c2B2B2B_F2F2F2:`#2B2B2B`,
            // tabcomponent_tab_name_box:`#2A2E2F`,
            // DigitalCollectionManagement_top_menu_box: `#2A2E2F`,
            box_bg: `#2A2E2F`,
            c000000_ACB5BB: `#000000`,
            c303339_F5F6F7: `#303339`,
            c181818_ffffff:`#181818`,
            c242424_E6E6E6:`#242424`,
            c191F24_E1E2E6: `#191F24`,
            C30353A_FFFFFF: `#30353A`,
            c1B1C1E_FFFFFF: `#1B1C1E`,
            c2B2C2E_D4D5D6: `#2B2C2E`,
            C333333_ffffff: `#333333`,
            c8A8B8C_324460: `#324460`,
            FFFFFF_c040506: `#FFFFFF`,
            c1B1C1E_F0F1F5: `#1B1C1E`,
            c000000_F0F1F5: `#202023`,
            c2B2C2E_E1E1E2: `#2B2C2E`,
            c292929_ffffff: `#262627`,
            c2b2c2e_f2f2f2: `#2b2c2e`,
            c3F3E41_3F3E43:`#3F3E41`,
            nft_bgc:`#1b1b1b`
        }
    },
    [ThemeTypeEnum.white]: {
        theme_obj: {
            //#region NewDigitalCollectionManagement相关
            bian_zu: { //编组图片
                big_img: require('@/assets/image/suoxiao.png'),
                small_img: require('@/assets/image/suoxiao_s.png')
            },
            real_name: {
                positive: require('@/assets/image/realname/shangchuanzheng_w.png'),
                other: require('@/assets/image/realname/shangchuanfan_w.png')
            },
            news: {
                more: require('@/assets/image/xiaoxi/tianjia.png'),
                jiantou: require('@/assets/image/jiantou_w.png'),
                mail: require('@/assets/image/xiaoxi/tongxunlu.png'),
                upimg:require('@/assets/image/xiaoxi/upimg_w.png')
            },
            my:{
                cangpin:require('@/assets/image/my/cangpin_w.png'),
                baimingdan:require('@/assets/image/my/baimingdan_w.png'),
                shenhe:require('@/assets/image/my/shenhe_w.png'),
                chaoguan:require('@/assets/image/my/chaoji_w.png'),
                siyao:require('@/assets/image/my/miyao_w.png'),
                guanyu:require('@/assets/image/my/guanyu_w.png'),
                kefu:require('@/assets/image/my/kefu_w.png'),
                shezhi: require('@/assets/image/my/shezhi_w.png'),
                qiandao:require('@/assets/image/qian_dao_btn_w.png')
            },
            hecheng: {
                button: require('@/assets/image/ui/asset_compose/new_yijian_btn_b.png'),
            },
            chatwin:{
                zhaopian:require('@/assets/image/xiaoxi/zhaopian_w.png'),
                shipin:require('@/assets/image/xiaoxi/shipin_w.png'),
                zhangxiang:require('@/assets/image/xiaoxi/xiangji_w.png'),
                wenjian:require('@/assets/image/xiaoxi/wenjian_w.png'),
                zhuangzhang:require('@/assets/image/xiaoxi/zhuanzhang_w.png'),
                nft:require('@/assets/image/xiaoxi/nft_w.png'),
                mingpian:require('@/assets/image/xiaoxi/mingpian_w.png')
            },
            zhuzi:{
                default:"display:flex; justify-content: center;",
                di_img:require('@/assets/image/ui/zhuzi/tvs.png'),
                title_content:"margin-top: 70px",
                bg_img:require('@/assets/image/ui/zhuzi/kk.png'),
            },
            //返回图片
            allback:{
                back_img:require('@/assets/image/jiantou_w.png')
            }
            //#endregion
        },
        css: {
            nft_bgc:'#FFFFFF',
            home_bgc_class:'#F0F1F5',
            bottom_class:'#FFFFFF',
            Announcement :'#ffffff',
           zhihuan_bgc:'#FFFFFF',
           zhihuan_input_bgc:'#FFFFFF',
           tongxunlu_bgc:'#FFFFFF',
           input_bgc:'#ffffff',
           box_bgc:'#f2f2f2',
           qianbao_bgc: '#ffffff',
           tixian_border:'#ffffff',
           hecheng_bgc: '#fffffff',
           hecheng_tanchu_bgc:'#ffffff',
           hechengjilu_border:'#ffffff',
           linglongta_bgc:'#ffffff',
           zhifumima_bgc:'#F0F1F5',
           tishikuang_bgc:'#ffffff',
           new_bg:'#F0F1F5',
           new_box_bg:'#ffffff',
           c181818_ffffff:`#ffffff`,
           c2B2B2B_F2F2F2:`#F2F2F2`,




            bgc_class: `#F0F1F5`,
            line: `#F6FAFE`,
            border: `#F6FAFE`,
            ffffff_324460: `#324460`,
            c303538_ffffff: `#FFFFFF`,
            c303339_ffffff: `#ffffff`,
            c2A2E2F_ffffff: `#ffffff`,
            c191F24_ffffff: `#ffffff`,
            fffffe_324460: `#324460`,
            c848484_324460: `#324460`,
            c02cdb0_ffffff: `#ffffff`,
            ffffff_c02cdb0: `#02cdb0`,
            ffffff_c6D7278: `#6D7278`,
            c6D7278_A8ADB4: `#A8ADB4`,
            C2C2C3_848484: `#848484`,
            ffffff_303339: `#303339`,
            c24619387_FC5925: `#FC5925`,
            c212323_ffffff: `#ffffff`,
            f6fafe_434343: `#434343`,
            c181818_f6fafe: `#f6fafe`,
            c2A2E2F_F6FAFE: `#F6FAFE`,
            c191F24_1BCDAF: `#1BCDAF`,
            ffffff_1BCDAF: `#1BCDAF`,
            c000000_FFFFFF: `#FFFFFF`,
            c000000_F1F1F1: `#F1F1F1`,
            c000000_4B4C4D: `#4B4C4D`,
            c02CDB0_324460: `#324460`,
            ffffff_F6FAFE: `#F6FAFE`,
            c242424_E6E6E6:`#E6E6E6`,
            FEFFFF_324460: `#324460`,
            c191f24_FEFFFF: `#FEFFFF`,
            c2A2E2F_FEFFFF: `#FEFFFF`,
            ffffff_0091FF: `#0091FF`,
            c313940_F6F6F6:`#F6F6F6`,
            c191F24_F6FAFE: `#F6FAFE`,
            RealNameAuthentication_body: `#F6FAFE`,
            RealNameAuthentication_font_color: `#000000`,
            RealNameAuthentication_input_box: `#ffffff`,
            RealNameAuthentication_input_box_span: `#434343`,
            c3B3F40_E9EEF5: `#E9EEF5`,
            // DigitalCollectionManagement_top_menu_box: `#ffffff`,
            box_bg: `#ffffff`,
            c000000_ACB5BB: `#ACB5BB`,
            c303339_F5F6F7: `#F5F6F7`,
            c000000_ffffff: `#ffffff`,
            // tabcomponent_tab_name_box:`#F6FAFE`,
            c191F24_E1E2E6: `#E1E2E6`,
            C30353A_FFFFFF: `#FFFFFF`,
            c1B1C1E_FFFFFF: `#FFFFFF`,     
            c2B2C2E_D4D5D6: `#D4D5D6`,
            C333333_ffffff: `#dfdfdf`,
            FFFFFF_c040506: `#040506`,
            c1B1C1E_F0F1F5: `#F0F1F5`,
            c000000_F0F1F5: `#F0F1F5`,
            c2B2C2E_E1E1E2: `#E1E1E2`,
            c292929_ffffff: `#ffffff`,
            c2b2c2e_f2f2f2: `#f2f2f2`,
            c3F3E41_3F3E43:`#3F3E43`,
        }
    },
};