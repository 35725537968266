/**
 * 小程序SDK
 */
export default abstract class LookBaoAppletSDK {
    /**
     * 启动参数
     */
    private static start_parameter?: AppletStartParameterVO;
    /**
    * 显示小程序事件
    */
    abstract OnShow(v: any): void;
    /**
     * 隐藏小程序事件
     * @param v 
     */
    abstract OnHide(v: any): void;
    /**
     * 引擎加载完毕
     */
    abstract OnLoaded(): void;

    /**
     * 拉起LookBao登录授权
     */
    static PullUpAuthorization(appid: string, callback: (d: any) => void) {
        window.LookBaoSDK.Global.app?.PullUpAuthorization(appid, callback);
    }

    /**
     * 运行游戏
     */
    static ToRunGame(start_parameter: AppletStartParameterVO): void {
        this.start_parameter = start_parameter;
        window.LookBaoAppletSDK.GameHall?.ToRunGame();
    }

    /**
     * 关闭小程序
     */
    static CloseApplet() {

        this.start_parameter?.AppletWindowVUE?.CloseMe();
    }

    /**
     * 刷新小程序
     */
    static RefreshApplet(): void {
        window.LookBaoSDK.Tools.AlertError("未实现此接口!");
    };

    /**
     * 转发分享小程序
     * @param title 
     * @param image_url 
     * @param param 
     */
    static async ToShareAsync(title: string, image_url: string, param: any) {
        window.LookBaoSDK.Tools.AlertError("未实现此接口!");
    }

    /**
     * 获取应用配置
     */
    static GetAppConfiguration() {

    }

    /**
     * 支付
     */
    static ToPayment() {

    }

    /**
     * H5复制剪切板
     */
    static CopyContentToClipboard() {

    }
}