import Tools from "./Tools";
import AccountManagement from "./blockchain/AccountManagement";
import { CoinTypeEnum } from "./blockchain/BaseWallet";
import CoinTypeManager from "./blockchain/CoinTypeManager";
import CoinTypeVO from "./blockchain/vo/CoinTypeVO";
import CurrencySystemVO from "./blockchain/vo/CurrencySystemVO";
import Config from "./Config";
import CordovaPlugin, { Camera } from "./CordovaPlugin";
import FaceConfig from "./FaceConfig";
import IndexedDB from "./IndexedDB";
import { I_Server } from "./I_Server";
import { AppletTypeEnum, ChatTypeEnum, CurrencySystemIdEnum, InputBoxTypeEnum, PropTypeEnum, QRTypeEnum, RedPacketToolsModelEnum, SlideVerificationTypeEnum, ThemeTypeEnum, TransferTypeEnum } from "./SuperEnum";

import BackDataVO from "./vo/BackDataVO";
import IM_Group from "./vo/IM_Group";
import IM_User from "./vo/IM_User";
import * as ImageConversion from 'image-conversion';
import { Themes } from "@/assets/theme/theme";
import AppletStartParameterVO from "./vo/AppletStartParameterVO";
import { IM_PropGoods } from "./vo/IM_PropGoods";
import { ThemesType, ThemesVO } from '@/assets/theme/theme';
import SYS_Platform from "./vo/SysPlatform";
import { reactive } from "vue";
import SYS_Premission from "./vo/SYS_Premission";

export type TABLES = "temporary_message";

export default class Global {
    public static is_start = false;
    public static app: any = null;
    public static home: any = null;
    public static head: any = null;
    public static menu_vo: any = null;
    public static open_chat_window: any = null;
    public static server: I_Server;

    public static premission_list: SYS_Premission[] = reactive([]);
    /**
     * 平台列表
     */
    public static platform_vo_list: SYS_Platform[] = [];

    private static _host_platform_vo?: SYS_Platform | null = reactive({});
    public static get host_platform_vo(): SYS_Platform {
        return this._host_platform_vo as SYS_Platform;
    }


    public static set host_platform_vo(v: SYS_Platform | any) {

        for (const key in v) {
            if (Object.prototype.hasOwnProperty.call(v, key)) {
                const d = v[key];
                (this._host_platform_vo as any)[key] = d;
            }
        }
        //初始化平台
        Global.SetUsePlatformGuid = (v as SYS_Platform).guid as string;
    }



    private static _indexed_db: IndexedDB | null = null;

    /**
     * 是否为长按
     */
    public static LongTouch: boolean = false;

    /**
     * 录音与播放对象
     */
    public static media_rec: Media | null = null;

    /**
     * 登录UserVO
     */
    private static _login_user_vo: IM_User | null;


    public static test_home_data: any = null;

    /**
     * 音视频通讯TOKEN
     */
    public static IM_TOKEN: string = '';

    /**
     * 最后的参数
     */
    public static params_data_dic: { [url: string]: any } = {};

    /**
     * 币种体系列表
     */
    public static currency_system_list: CurrencySystemVO[] = [];

    /**
     * 音频播放进度
     */
    public static play_voice_run_dic: {
        [url: string]: {
            time: number
        }
    } = {};


    /**
     * 图像压缩对象
     */
    public static get $ImageConversion() {
        return ImageConversion;
    }


    /**
     * 上传文件进度队列
     */
    public static upload_file_progress_dic: {
        [guid: string]: {
            time: number,
            progress: number,
            progress_call_fun: (v: number) => void,
            finish_call_fun: (flag: boolean) => void
        }
    } = {};
    /**
     * 角标数
     */
    private static _badge_number: number = 0;


    public static get badge_number(): number {
        return this._badge_number;
    }

    public static set badge_number(v: number) {
        this._badge_number = v;
        CordovaPlugin.SetBadgeNum(this._badge_number);
    }
    /**
     * 显示超级消息框
     * @param obj 
     */
    public static ShowSuperMessageBox(obj: {
        title: string,
        content: string,
        ok_text: string,
        cancel_text: string,
        show_ok_btn: boolean,
        show_cancel_btn: boolean,
        ok_fun: () => {},
        cancel_fun?: () => {}
    }) {
        Global.app && Global.app.ShowSuperMessageBox(obj);
    }
    /**
     * 隐藏超级消息框
     */
    public static HideSuperMessageBox() {
        Global.app && Global.app.HideSuperMessageBox();
    }

    /**
     * 显示隐私政策
     * @param callback 
     */
    public static ShowYSZCWindow(callback: () => void) {
        Global.app && Global.app.ShowYSZCWindow(callback);
    }


    /**
     * 获取系统币种
     */
    public static get GetSystemCoinVO(): CoinTypeVO | null {
        let v_item = CoinTypeManager.singleton.GetAllCoinTypeList().Where(x => x.is_system_integral).GetValueOfIndex(0);
        if (v_item.flag)
            return v_item.data;
        return null;
    }

    /**
     * 显示玲珑塔
     * @param llt_message_chapter_id 玲珑塔章节id
     * @param llt_message_floor_num 玲珑塔层数
     * @param llt_message_show_top 是否显示顶部导航
     */
    public static ShowLLTMessageBox(llt_message_chapter_id: number, llt_message_floor_num: number, llt_message_show_top: boolean = false) {
        Global.app && Global.app?.ShowLLTMessageBox(llt_message_chapter_id, llt_message_floor_num, llt_message_show_top);
    }

    /**
     * 关闭玲珑塔
     */
    public static CloseLLTMessageBox() {
        Global.app && Global.app?.CloseLLTMessageBox();
    }

    /**
     * 打开背包
     */
    public static OpenKnapsack() {
        Global.app && Global.app.OpenKnapsack();
    }

    /**
     * 关闭背包
     */
    public static CloseKnapsack() {
        Global.app && Global.app.CloseKnapsack();
    }

    /**
     * 显示助记词WINDOW窗口
     * @param login_vue 
     */
    public static ShowMnemonicWindow(login_vue: any = null) {
        Global.app && Global.app.ShowMnemonicWindow(login_vue);
    }

    /**
     * 显示提现
     */
    public static ShowWithdrawal() {
        Global.app && Global.app.ShowWithdrawal();
    }
    //显示分账体现
    public static ShowWithdrawalfenzhang() {
        Global.app && Global.app.ShowWithdrawalfenzhang();
    }

    /**
     * 查看实物兑换记录
     */
    public static ShowExchangeRecord() {
        Global.app && Global.app.ShowExchangeRecord();
    }

    /**
     * 关闭助记词WINDOW窗口
     */
    public static CloseMnemonicWindow() {
        Global.app && Global.app.CloseMnemonicWindow();
    }

    /**
     * 显示我的NFT邀请好友列表
     */
    public static ShowMyNftInviteFriendList() {
        Global.app && Global.app.ShowMyNftInviteFriendList();
    }

    /**
     * 隐藏我的NFT邀请好友列表
     */
    public static HideMyNftInviteFriendList() {
        Global.app && Global.app.HideMyNftInviteFriendList();
    }

    /**
     * 获取打开小程序历史
     */
    public static GetOpenAppletHistory(): {
        app_name: string,
        icon: string,
        app_id: number
    }[] {
        try {
            //获取列表
            let v = localStorage.getItem(`open_applet_history_${Global.login_user_vo?.uid}`);
            if (v == null) {
                if (Tools.IsNull(v as any)) {
                    v = "[]";
                }
            }
            let arr = JSON.parse(v as string);
            return arr;
        }
        catch (e) {
            return [];
        }
    }

    /**
     * 显示桶管理 
     */
    public static ShowBucketBox() {
        Global.app && Global.app.ShowBucketBox();
    }

    /**
     * 关闭桶管理
     */
    public static CloseBucketBox() {
        Global.app && Global.app.CloseBucketBox();
    }

    /**
     * 显示道具详情
     * @param select_prop_details 
     */
    public static ShowPropDetails(select_prop_details: any, close_fun: () => void) {
        Global.app && Global.app.ShowPropDetails(select_prop_details, close_fun);
    }

    /**
     * 关闭选择的道具详情
     */
    public static ClosePropDetails() {
        Global.app && Global.app.ClosePropDetails();
    }

    /**
     * 显示获取物品窗口
     */
    public static ShowPrizePromptWindow(title: string, img_url: string, describe: string): void {
        Global.app && Global.app.ShowPrizePromptWindow(title, img_url, describe);
    }

    /**
     * 关闭获取物品窗口
     */
    public static ClosePrizePromptWindow() {
        Global.app && Global.app.ClosePrizePromptWindow();
    }

    /**
     * 使用道具
     * @param prop_back_pack_id 背包ID 
     * @param new_extended_data 
     */
    public static async ToApplyPropAsync(prop_back_pack_id: number, new_extended_data: any): Promise<{
        flag: boolean,
        msg: string,
        prop_type: PropTypeEnum,
        d: any
    }> {
        let res = await Tools.GetHtmlAsync("ApplyProp", {
            id: prop_back_pack_id,
            extended_data: JSON.stringify(new_extended_data),
        });
        if (Tools.CheckHtmlIsOK(res)) {
            let d = res.data;
            let prop_type = d.prop_type;
            switch (d.prop_type) {
                case PropTypeEnum.盲盒道具: //盲盒
                    let asset_type = d.asset_type;
                    let title = d.title;
                    let img_url = d.img_url;
                    return {
                        flag: true,
                        msg: `恭喜获得:${title}`,
                        prop_type: prop_type,
                        d: {
                            asset_type,
                            title,
                            img_url
                        }
                    };
                case PropTypeEnum.红包道具: //红包
                    let red_package_amount = d.red_package_amount;
                    let red_package_prop_type = d.red_package_prop_type;

                    return {
                        flag: true,
                        msg: `打开红包获得 ${red_package_prop_type == 1 ? Global.host_platform_vo?.main_integral_alias : '￥'}${red_package_amount}`,
                        prop_type: prop_type,
                        d: {
                            red_package_amount
                        }
                    }
                case PropTypeEnum.兑换实物道具:
                    Tools.AlertSuccess("已成功使用道具!");
                    return {
                        flag: true,
                        msg: "已成功使用道具!",
                        prop_type: prop_type,
                        d: {

                        }
                    }
                default:
                    Tools.AlertSuccess("已成功使用道具!");
                    return {
                        flag: true,
                        msg: "已成功使用道具!",
                        prop_type: prop_type,
                        d: {

                        }
                    }
            }
        }
        return {
            flag: false,
            msg: "道具使用失败!",
            prop_type: 0,
            d: null
        }
    }

    /**
     * 添加到打开小程序历史
     * @param applet_vo 
     */
    public static AddOpenAppletHistory(applet_vo: {
        app_name: string,
        icon: string,
        id: number
    }) {

        let arr = this.GetOpenAppletHistory();
        for (let i = 0; i < arr.length; i++) {
            const v = arr[i];
            if (v.app_id == applet_vo.id) {
                arr.splice(i, 1);
                break;
            }
        }
        arr.unshift({
            app_name: applet_vo.app_name,
            icon: applet_vo.icon,
            app_id: applet_vo.id
        });
        arr = arr.splice(0, 8); //留8个
        //进行存储
        localStorage.setItem(`open_applet_history_${Global.login_user_vo?.uid}`, JSON.stringify(arr));
    }

    /**
     * 是否静音属性
     */
    public static get Mute(): boolean {
        let is_mute = Tools.GetLocalStorage(`${Global.login_user_vo?.uid}_is_mute`, "false");
        if (Tools.IsNull(is_mute))
            return false;
        switch (is_mute) {
            case "false":
                return false;
            case "true":
                return true;
        }
        return false;;
    }

    /**
     * 显示分站切换
     */
    public static ShowPlatformSwitch() {
        Global.app && (Global.app.show_platform_switch = true);
    }

    /**
     * 关闭分站切换
     */
    public static ClosePlatformSwitch() {
        Global.app && (Global.app.show_platform_switch = false);
    }

    /**
     * 是否静音属性
     */
    public static set Mute(v: boolean) {
        Tools.SetLocalStorage(`${Global.login_user_vo?.uid}_is_mute`, v ? 'true' : 'false');
    }

    /**
     * 获取密码
     */
    public static get PayPassword(): string {
        if (!Global.login_user_vo) {
            return Tools.GUID;
        }
        let pwd = Tools.GetLocalStorage(`pay_password_${Global.login_user_vo?.uid}`, "") ?? "";
        if (Tools.IsNotNull(pwd)) {
            if (pwd.length != 6) {
                this.PayPassword = "";
                return "";
            }
        }
        return pwd;
    }

    public static get IsRealName(): boolean {
        let u = Global.login_user_vo;
        if (u?.is_real_name == 1)
            return true;
        Global.MessageBox("系统提示!", "请先进行实名认证!", () => {
            Global.ShowRealNameAuthentication();
        });
        return false;
    }

    /**
     * 获取实名状态
     */
    public static get IsRealNameStatus(): boolean {
        let u = Global.login_user_vo;
        if (u?.is_real_name == 1)
            return true;
        return false;
    }

    /**
     * 获取版本
     */
    public static get GetNODE_ENV(): "production" | "development" {
        return process.env.NODE_ENV as "production" | "development";
    }
    /**
     * VUE版本标签
     */
    public static get GetVueAppTitle(): string | undefined {
        return process.env.VUE_APP_TITLE;
    }

    /**
     * 检测是否是web版
     */
    public static get IsWeb(): boolean {
        return Global.GetVueAppTitle == "webbuild" ? true : false;
    }

    /**
     * 设置密码
     */
    public static set PayPassword(v: string) {
        Tools.SetLocalStorage(
            `pay_password_${Global.login_user_vo?.uid}`
            , v);
        Tools.AlertSuccess("密码已变更!");
    }

    private static get new_message_dian_dic(): { [key: string]: boolean } {
        let obj = JSON.parse(Tools.GetLocalStorage("hong_dian_dic", "{}"));
        if (obj == null)
            obj = {};
        return obj;
    }

    /**
     * 清空登录记录
     */
    public static ClearLoginData() {

        Global.login_user_vo = null;
        Global.SelectUID = "";
    }

    /**
     * 当前选择的UID
     */
    public static get SelectUID(): string {
        let uid = Tools.GetLocalStorage("select_uid", "");
        if (Global.login_user_vo) {
            if (Tools.IsNotNull(Global.login_user_vo?.uid)) {
                if (Global.login_user_vo?.uid != uid) {
                    this.SelectUID = Global.login_user_vo?.uid ?? "";
                    uid = uid;
                }
            }
        }
        return uid;
    }

    /**
     * 当前选择的UID
     */
    public static set SelectUID(v: string) {
        let now_select_uid = Tools.GetLocalStorage("select_uid", "");
        if (now_select_uid == "" || now_select_uid != v) {
            Tools.SetLocalStorage("select_uid", v);
            Global.ClearLoginUserVO();
        }
    }

    /**
     * 当前选择的密码
     */
    public static get SelectPWD(): string {
        return Tools.GetLocalStorage("select_pwd", "");
    }

    /**
     * 当前选择的密码
     */
    public static set SelectPWD(v: string) {
        Tools.SetLocalStorage("select_pwd", v);
    }

    /**
     * 添加红点
     * @param key 
     */
    public static AddNewMessageDian(key: string) {
        let v = this.new_message_dian_dic;
        v[key] = true;
        //进行存储
        Tools.SetLocalStorage("hong_dian_dic", JSON.stringify(v));
    }

    public static RemoveNewMessageDian(key: string) {
        let v = this.new_message_dian_dic;
        if (v)
            delete v[key];
        Tools.SetLocalStorage("hong_dian_dic", JSON.stringify(v));
    }
    /**
     * 执行扫码逻辑
     * @param d 
     */
    public static RunLookBaoQR(d: any) {
        switch (d.type) {
            case QRTypeEnum.好友名片: //加好友
                let uid = d.data.uid;
                let nick_name = d.data.nick_name;
                let head_icon = d.data.head_icon;
                Global.OpenUserInformation(uid);
                break;
            case QRTypeEnum.群名片: //加群
                let group_id = d.data.uid;
                let group_nick_name = d.data.nick_name;
                let group_head_icon = d.data.head_icon;
                Global.OpenGroupChatInformation(group_id);
                break;
            case QRTypeEnum.开放平台登录:
                let pre_login_token = d.data.pre_login_token;
                if (Tools.IsNotNull(pre_login_token)) {
                    Tools.GoTo("/open_platform_authorization", {}, {
                        pre_login_token: pre_login_token
                    });
                }
                break;
            default:
                Tools.AlertError(`请扫描${Config.APP_NAME}专用二维码!`);
                break;
        }
    }

    /**
     * 检测是否有红点
     * @param key 
     * @returns 
     */
    public static CheckHaveHongDian(key: string): boolean {
        return this.new_message_dian_dic[key] != null;
    }
    /**
     * 检测是否存在红点
     * @returns 
     */
    public static HaveHongDian(): boolean {

        let count = 0;
        for (const key in this.new_message_dian_dic) {
            if (Object.prototype.hasOwnProperty.call(this.new_message_dian_dic, key)) {
                // const element = this.new_message_dian_dic[key];
                count++;
            }
        }
        return count > 0;
    }

    /**
     * 获取小红点列表
     * @returns 
     */
    public static GetNewMessageDianDic() {

        return this.new_message_dian_dic;
    }


    public static get login_user_vo(): IM_User | null {
        if (this._login_user_vo == null) {

            let v = localStorage.getItem("login_user_vo");
            if (v) {
                this._login_user_vo = JSON.parse(v);
                Object.setPrototypeOf(this._login_user_vo, IM_User.prototype);
            }
            else {
                return null;
            }
        }
        return this._login_user_vo;
    }

    public static set login_user_vo(v: IM_User | null) {
        this._login_user_vo = v;
        if (v) {
            Global.ToUpdatePremissionIdentificationAsync();
            localStorage.setItem("login_user_vo", JSON.stringify(v));
            //连接到音频服务
            Global.ConnectIMVideoServerAsync();
        }
    }

    /**
     * 更新权限标识
     */
    private static async ToUpdatePremissionIdentificationAsync() {
        if (Global.login_user_vo) {
            if (Global.login_user_vo.api_authorization_permission_id > 0) {
                if (Global.premission_list.length == 0) {
                    //进行更新操作
                    await this.GetMyPremissionIdentificationListAsync();
                }
            }
        }
    }
    private static async GetMyPremissionIdentificationListAsync() {
        let res = await Tools.GetHtmlAsync(
            "GetMyPremissionIdentificationList",
            {}
        );
        if (Tools.CheckHtmlIsOK(res, false)) {
            // this.premission_list = res.data;
            Tools.SetArray(this.premission_list, res.data);
        }
    }

    public static ClearLoginUserVO() {
        localStorage.removeItem("login_user_vo");
        this._login_user_vo = null;
    }

    /**
     * 执行统一支付
     * @param pay_transfer_type 支付类型
     * @param pay_address 支付地址
     * @param pay_coin_type 货币类型
     * @param pay_amount 支付金额
     * @param pay_message 支付描述
     * @param pay_extend_data 支付附加数据
     */
    public static ToUnifiedPayment(
        pay_transfer_type: TransferTypeEnum,
        pay_address: string,
        pay_coin_type: CoinTypeEnum,
        pay_amount: number,
        pay_message: string,
        pay_extend_data: any
    ) {
        Tools.AlertError("同意支付入口暂不开放!");
        return;
        Global.app && Global.app.ShowUnifiedPayment(pay_transfer_type, pay_address, pay_coin_type, pay_amount, pay_message, pay_extend_data);
    }

    /**
     * 修复转账交易标识
     */
    public static TransferInitialization() {
        try {
            let remove_keys = [];
            for (var i = 0; i < localStorage.length; i++) {
                var key = localStorage.key(i); //获取本地存储的Key
                if (key) {
                    let v = localStorage.getItem(key);
                    if (key.endsWith("_nonce") && key.startsWith("0x")) {
                        remove_keys.push(key);
                    }
                }
            }
            //进行删除
            remove_keys.forEach((key) => {
                localStorage.removeItem(key);
            });
            if (remove_keys.length == 0) {
                // Tools.AlertError(`没有找到可修复的交易标识!`);
            } else {
                // Tools.AlertSuccess(`成功初始化 ${remove_keys.length} 个交易标识!`);
            }
        }
        catch (ex) {

        }
    }

    /**
     * 数据库对象
     */
    public static async GetDBAsync(): Promise<IndexedDB> {
        if (this._indexed_db == null) {
            let db_name: string = `db_${this.login_user_vo && this.login_user_vo.uid}`;
            this._indexed_db = new IndexedDB(db_name, 3);
            await this._indexed_db.OpenAsync("temporary_message");
        }
        return this._indexed_db;
    }

    public static SetTitle(title: string): void {
        Global.app?.SetTitle(title);
    }

    /**
     * 显示lookbao键盘
     */
    public static ShowLookBaoKeyboard(
        default_keyboard_number: number | string,
        keyboard_finish_call: (number: number) => void,
        show_keyboard_title: boolean = true
    ) {
        Global.app && Global.app.ShowLookBaoKeyboard(default_keyboard_number, show_keyboard_title, keyboard_finish_call);
    }

    /**
     * 设置在线状态
     * @param online 
     */
    public static SetTitleOnLine(online: boolean): void {
        Global.app?.SetTitleOnLine(online);
    }

    /**
     * 显示修改个人信息面板
     */
    public static ShowUpdateAccount(show_base: boolean = false) {
        Global.app?.ShowUpdateAccount(show_base);
    }
    //    /**
    //      * 显示聊天页
    //      */
    //     public static ShowChatGroup(show_base: boolean = true) {
    //         Global.app?.ShowChatGroup(show_base);
    //     }
    //     // 关闭聊天页
    //     public static CloswChatGroup(show_base: boolean = false) {
    //         Global.app?.CloswChatGroup(show_base);
    //     }

    /**
     * 显示弹出框
     * @param message 
     */
    public static AlertSuccess(message: string): void {
        Global.app?.ShowAlert(message, 1);
    }

    public static AlertError(message: string): void {
        console.error(message);

        Global.app?.ShowAlert(message, 2);
    }

    /**
     * 弹出消息框
     * @param title 
     * @param content 
     * @param success_fun 
     * @param cancel_fun 
     * @param open_no_ti_shi 是否显示不在提示控件
     * @param other_tishi_data 参与运算提示框特殊内容(只影响是否显示提示框参加MD5加密)
     */
    public static MessageBox(title: string, content: string, success_fun: () => void, cancel_fun: (() => void) | null = null, open_no_ti_shi: boolean = false, other_tishi_data: string = "") {
        Global.app?.MessageBox(title, content, success_fun, cancel_fun, open_no_ti_shi, other_tishi_data);
    }


    /**
    * 删除使用的平台GUID
    */
    public static ClearUsePlatformGuid() {
        localStorage.removeItem("use_platform_guid");
    }

    /**
     * 设置选择的平台GUID
     */
    public static set SetUsePlatformGuid(guid: string) {
        localStorage.setItem("use_platform_guid", guid);
    }

    /**
     * 获取使用的平台GUID
     */
    public static get GetUsePlatformGuid() {
        return localStorage.getItem("use_platform_guid") as string;
    }

    /**
     * 设置TOKEN
     */
    public static set SetLoginToken(v: string) {
        localStorage.setItem("login_token", v);
        CordovaPlugin.UpdateLoginToken();
    }



    /**
     * 登录TOKEN
     */
    public static get GetLoginToken(): string {
        return localStorage.getItem("login_token") as string;
    }


    public static SetButtonList(button_list: {
        name: string,
        css?: string,
        style?: string,
        click: () => {}
    }[]) {
        this.head && this.head.SetButtonList(button_list);
    }

    /**
     * 设置返回的回调事件
     */
    public static SetOnGoBackFunction(fun: () => void) {
        let path = Global?.app?.$route?.path;
        if (this.head) {
            if (!this.head.OnGoBack)
                this.head.OnGoBack = {};
            this.head.OnGoBack[path] = fun;
        }
    }
    /**
     * 获取当前按钮列表
     * @returns 
     */
    public static GetButtonList(): {
        name: string,
        css?: string,
        style?: string,
        click: () => {}
    }[] {
        return this.head && this.head.GetButtonList();
    }

    /**
     * 显示菜单
     */
    public static ShowMenu(top: string, left: string) {
        this.app && this.app.ShowMenu(top, left);
    }

    /**
     * 关闭菜单
     */
    public static CloseMenu() {
        this.app && this.app.CloseMenu();
    }

    /**
     * 设置菜单内容
     * @param menu_list 
     */
    public static SetMenuList(menu_list: {
        name: string,
        icon: string,
        click: () => void
    }) {
        Global.app && Global.app.SetMenuList(menu_list);
    }

    /**
     * 显示输入框
     */
    public static ShowInputBox(title: string, content: string, input_box_type: InputBoxTypeEnum = InputBoxTypeEnum.TextArea, call_fun: ((txt: string) => void) | null = null) {
        Global.app.ShowInputBox(title, content, input_box_type, call_fun);
    }

    /**
     * 选择图片
     */
    public static async SelectPhotosAsync(): Promise<{
        file_url: string,
        flag: boolean,
        error_message: string
    }> {
        let res = await CordovaPlugin.OpenCameraAsync(
            50,
            Camera.PictureSourceType.SAVEDPHOTOALBUM,
            Camera.DestinationType.DATA_URL
        );
        if (res.flag) {
            let img_url = res.img_url;
            let mime_type = res.mime_type;
            console.log(`选择文件为:${img_url}`);
            let v = await CordovaPlugin.UploadFileAsync(img_url, mime_type);
            if (v.flag) {
                let file_arr = v.data;
                if (file_arr != null) {
                    if (file_arr.length > 0) {
                        let d = file_arr[0];
                        let FileName = d.FileName;
                        let FileURL = d.FileURL;
                        let OriginalFileName = d.OriginalFileName;
                        let OriginalFileNameNoExtension = d.OriginalFileNameNoExtension;
                        let MimeType = d.MimeType;
                        return {
                            flag: true,
                            file_url: FileURL,
                            error_message: ""
                        };
                    } else {
                        return {
                            flag: false,
                            file_url: "",
                            error_message: "选择图片失败"
                        };
                    }
                }
            } else {
                return {
                    flag: false,
                    file_url: "",
                    error_message: "上传图片失败!"
                };
            }
        }
        return {
            flag: false,
            file_url: "",
            error_message: "打开相册失败!"
        };
    }

    /**
     * 显示输入框
     * @param title 
     * @param content 
     * @returns 
     */
    public static ShowInputBoxAsync(title: string, content: string, input_box_type: InputBoxTypeEnum = InputBoxTypeEnum.TextArea, max_length: number = 0): Promise<string> {
        return new Promise<string>((r) => {
            Global.app.ShowInputBox(title, content, input_box_type, (txt: string) => {
                r(txt);
            }, max_length);
        });
    }
    /**
     * 获取登录TOKEN
     * @returns 
     */
    public static async GetIMTokenAsync(): Promise<boolean> {
        let res = await Global.SendAsync(this, "GetIMToken", {});
        if (Tools.CheckDataIsOK(res, false)) {
            let d = res?.data_vo?.DATA;
            if (d != null) {
                if (Tools.IsJson(d)) {
                    let jobj = JSON.parse(d);
                    Global.IM_TOKEN = jobj.token;
                }
            }
            return true;
        }
        Global.IM_TOKEN = "";
        return false;
    }

    /**
     * 连接到音视频服务
     */
    public static async ConnectIMVideoServerAsync() {
        if (await Global.GetIMTokenAsync()) {
            if (await CordovaPlugin.ConnectAsync()) {
                console.log("成功连接到音视频服务!");
                return true;
            } else {
                console.log("音视频服务连接失败!");
                // Tools.AlertError("音视频服务连接失败!");
                return false;
            }
        }
    }


    /**
     * 发送数据
     * @param action 
     * @param data 
     */
    public static Send(action: string, data: any): void {
        Global.server.Send(action, data);
    }
    /**
     * 同步请求接口
     * @param action 请求键
     * @param data 请求数据
     * @param wait_time 默认时间3秒
     */
    public static SendAsync(bind_this: any, action: string, data: any, wait_time: number = 3000): Promise<{
        flag: boolean,
        _this: any,
        err_msg: string,
        data_vo: BackDataVO | null
    } | null> {
        return Global.server.SendAsync(bind_this, action, data, wait_time);
    }


    /**
     * 发送消息
     * @param chat_type 
     * @param to_user_vo 
     * @param send_data 
     * @returns 
     */
    public static async ToSayAsync(chat_type: ChatTypeEnum, to_user_vo: IM_User, group_vo: IM_Group, send_data: any, forward: boolean = false) {
        if (typeof send_data == "string") {
            let vvv = send_data.replace(/\ +/g, "");
            vvv = vvv.replace(/[\r\n]/g, "");
            if (Tools.IsNull(vvv)) {
                Tools.AlertError("内容不能为空!");
                return true;
            }
        }

        if (Global.login_user_vo != null) {
            let v = {
                type: ChatTypeEnum[chat_type],
                is_me: true,
                forward: forward,
                id: 0,
                acc_vo: {
                    uid: Global.login_user_vo.uid,
                    user_name: Global.login_user_vo.nick_name,
                    head_img: Global.login_user_vo.head_pic,
                    msg: send_data, //this.send_message,
                },
                time: Tools.GetNowTime,
            };

            Global.open_chat_window && (Global.open_chat_window.send_message = "");

            let ret_vo = await Global.SendAsync(this, "ToSay", {
                forward: forward,
                send_uid: to_user_vo && to_user_vo.uid,
                message: typeof send_data == "string" ? send_data : JSON.stringify(send_data),
                message_type: chat_type,
                group_id: group_vo?.id ?? 0 //Global.open_chat_window.group_id,
            });
            if (ret_vo?.flag) {
                if (ret_vo.data_vo?.STATUS == 0) {
                    Tools.AlertError(ret_vo.data_vo.ERROR_MESSAGE);
                    return ret_vo;
                }
            }
            v.id = ret_vo?.data_vo?.DATA.id;
            // Global.open_chat_window.AddMessage(v);

            //添加到本地发送记录
            await Global.AddTopMessageAsync({
                type: chat_type,
                time: v.time,
                id: v.id,
                group_vo: group_vo,
                group_id: group_vo?.id ?? 0,
                from_user: Global.login_user_vo,
                to_user: to_user_vo,
                data: send_data
            }, false);

            return ret_vo;
        }
        return true;
    }
    /**
     * 显示通用菜单
     * @param menu_list 
     */
    public static ShowGeneralMenu(menu_list: {
        menu_name: string,
        menu_click: () => void
    }[]) {
        Global.app && Global.app.ShowGeneralMenu(menu_list);
    }

    /**
     * 关闭通用菜单
     */
    public static CloseGeneralMenu() {
        Global.app && Global.app.CloseGeneralMenu();
    }
    /**
     * 打开用户资料面板
     * @param uid 
     */
    public static OpenUserInformation(uid: string) {
        Tools.GoTo("/user_information", {
            uid: uid,
        });
    }

    /**
     * 打开群用户信息面板
     * @param uid 
     * @param group_id 
     */
    public static OpenGroupUserInformation(uid: string, group_id: number) {
        Tools.GoTo("/group_information_user_data", {}, {
            uid: uid,
            group_id: group_id
        });
    }

    /**
     * 打开群资料
     * @param group_id 
     */
    public static OpenGroupChatInformation(group_id: number) {
        Tools.GoTo("/group_chat_information", {}, {
            group_id: group_id
        });
    }

    /**
     * 添加到消息库
     * @param v 
     * @param flush_list 是否刷新列表
     */
    public static async AddTopMessageAsync(v: {
        type: ChatTypeEnum,
        time: number,
        id: number,
        group_vo?: IM_Group,
        group_id: number,
        from_user?: IM_User,
        to_user?: IM_User,
        data: string
    }, flush_list: boolean = true) {
        let temporary_message = (await Global.GetDBAsync()).GetStore(
            "temporary_message"
        );
        if (temporary_message) {

            let group_icon = "image/group.png";
            let group_vo = v.group_vo;
            if (group_vo) {
                group_icon = group_vo.icon ? group_vo.icon : "image/group.png";
                group_vo = JSON.parse(JSON.stringify(group_vo));
            }


            let group_id = v.group_id;
            let from_user = v.from_user;
            let to_user = v.to_user;
            if (to_user != null) {
                to_user = JSON.parse(JSON.stringify(to_user));
            }

            let show_msg = Global.GetMessageStr(v);
            if (Tools.IsNotNull(show_msg)) {
                if (group_id > 0) {
                    if (Tools.IsNull(group_vo && group_vo.icon)) {
                        group_vo && (group_vo.icon = group_icon);
                    }

                    if (from_user) {
                        if (from_user.uid != Global.login_user_vo?.uid) {
                            //判断时间点
                            let item = await temporary_message.GetValueAsync(`group_${group_id}`);
                            if (item != null) {

                                if (item.flag) {

                                    let to_group_vo = item.data;

                                    if (to_group_vo != null) {
                                        let now_time = to_group_vo.v.time;
                                        let over_time = Tools.UnixTimestampToDateTime(now_time);
                                        let last_time = Tools.UnixTimestampToDateTime(v.time);
                                        if (over_time == last_time) {
                                            return;
                                        }
                                    }
                                }
                            }
                            this.AddNewMessageDian(`group_${group_id}`);
                        }
                    }

                    temporary_message.AddOrUpdateAsync(`group_${group_id}`, {
                        group_vo: group_vo,
                        msg: show_msg,
                        time: v.time,
                    });
                } else {
                    if (Global.login_user_vo != null) {
                        if (from_user && from_user.uid == Global.login_user_vo.uid) {
                            if (from_user.uid != Global.login_user_vo?.uid)
                                this.AddNewMessageDian(`${to_user && to_user.uid}`);
                            //获取原来的数据
                            let get_vo = await temporary_message.GetValueAsync(`${to_user && to_user.uid}`);

                            if (get_vo.flag) {
                                get_vo.data.v.user_vo = to_user;
                                get_vo.data.v.msg = show_msg;
                                get_vo.data.v.time = v.time;
                                temporary_message.AddOrUpdateAsync(`${to_user && to_user.uid}`, get_vo.data.v);
                            }
                            else {
                                temporary_message.AddOrUpdateAsync(
                                    `${to_user && to_user.uid}`,
                                    {
                                        user_vo: to_user,
                                        msg: show_msg,
                                        time: v.time,
                                        online: true
                                    }
                                );
                            }
                        }
                        else {
                            this.AddNewMessageDian(`${from_user && from_user.uid}`);
                            let _get_vo = await temporary_message.GetValueAsync(`${from_user && from_user.uid}`);

                            if (_get_vo.flag) {
                                _get_vo.data.v.user_vo = from_user;
                                _get_vo.data.v.msg = show_msg;
                                _get_vo.data.v.time = v.time;
                                temporary_message.AddOrUpdateAsync(
                                    `${from_user && from_user.uid}`, _get_vo.data.v
                                );
                            }
                            else {
                                temporary_message.AddOrUpdateAsync(
                                    `${from_user && from_user.uid}`,
                                    {
                                        user_vo: from_user,
                                        msg: show_msg,
                                        time: v.time,
                                        online: true
                                    }
                                );
                            }
                        }
                    }


                }
            }

            if (flush_list)
                await Global.home && Global.home.GetMessageListAsync();
        }
    }


    /**
     * 显示支付窗口
     * @param payment_order_number 
     * @param payment_action_guid 
     */
    public static ShowPayment(payment_order_number: string, payment_action_guid: string) {
        Global.app && Global.app.ShowPayment(payment_order_number, payment_action_guid);
    }
    /**
     * 设置选择的币种
     * @param coin_type 
     */
    public static SetSelectCoinType(coin_type: CoinTypeEnum) {
        Tools.SetSessionStorage("SELECT_COIN_TYPE", coin_type.toString());
    }

    /**
     * 获取最后选择的币种类型
     */
    public static GetSelectCoinType(): CoinTypeEnum {
        let coin_type = Tools.GetSessionStorage("SELECT_COIN_TYPE");
        if (coin_type == null) {
            return CoinTypeEnum.Default;
        }
        else {
            return parseInt(coin_type);
        }
    }

    /**
     * 设置选择的币种体系
     * @param currency_system_id 
     */
    public static SetSelectCurrencySystemID(currency_system_id: CurrencySystemIdEnum) {
        Tools.SetSessionStorage("SELECT_Currency_System_ID", currency_system_id.toString());
    }

    /**
     * 获取选择的币种体系
     * @returns 
     */
    public static GetSelectCurrencySystemID(): CurrencySystemIdEnum {
        let currency_system_id = Tools.GetSessionStorage("SELECT_Currency_System_ID");
        if (currency_system_id == null)
            return CurrencySystemIdEnum.LookBao;
        return parseInt(currency_system_id);
    }

    /**
     * 获取图标
     * @param url 
     */
    public static GetCoinIconURL(url: string, pic_mode: "none" | "800" | "300" | "160" | "f" | "f160" = "none") {
        let pic_mode_url = "";
        if (Tools.IsNotNull(url)) {
            if (typeof url == "string") {
                if (!url.startsWith("data:"))
                    url = this.guolv_url(url);
                if (url.startsWith("file:"))
                    return url;
            }
        }
        switch (pic_mode) {
            case "800":
                pic_mode_url = "_800";
                break;
            case "300":
                pic_mode_url = "_300";
                break;
            case "160":
                pic_mode_url = "_160";
                break;
            case "f":
                pic_mode_url = "_f";
                break;
            case "f160":
                pic_mode_url = "_f_160";
                break;
        }

        if (Tools.IsNotNull(url)) {
            if (url.startsWith("data:"))
                return url;
            if (url.startsWith("image/") || url.startsWith("/image/"))
                return url;
            if (url.startsWith("http://") || url.startsWith("https://")) {
                if (url.endsWith("_lookbao")) {
                    //检测是否存在原资源地址没有改成CDN的
                    url = url.replace(Config.RES_HOST, Config.CDN_RES_HOST);
                    return `${url}${pic_mode_url}`;
                }
                else {
                    return url;
                }
            }
            return this.guolv_url(`${Config.CDN_RES_URL}/${url}${url.endsWith("_lookbao") ? pic_mode_url : ''}`);
        }
        else {
            return "";
        }
    }

    private static guolv_url(url: string): string {
        if (Tools.StrIsNotNull(url)) {
            if (typeof url == "string") {
                if (url.replaceAll != null && url.replace != null) {
                    url = url.replaceAll('//', '/');
                    url = url.replace("http:/", "http://");
                    url = url.replace("https:/", "https://");
                }
            }
        }
        return url;
    }

    /**
     * 显示设置支付密码
     * @param set_transfer_password_call_fun 
     */
    public static ShowSetTransferPassword(set_transfer_password_call_fun: () => void) {
        Global.app && Global.app.ShowSetTransferPassword(set_transfer_password_call_fun);
    }

    /**
     * 初始化钱包
     */
    public static async InitWalletAsync(): Promise<boolean> {

        let ret_state = false;
        let uid: string = Global.SelectUID;
        let is_new = false;

        if (Tools.IsNotNull(uid)) {

            if (AccountManagement.LoadAccountWalletData(uid)) {
                let account_wallet_list = AccountManagement.GetAllAccountWalletList(uid);

                if (account_wallet_list.length == 0) {
                    //创建钱包
                    await AccountManagement.AddNewWalletAsync(uid, "未命名", "", CoinTypeEnum.IM);
                    is_new = true;
                }
                ret_state = true;
            }
            else {
                if (!AccountManagement.CheckHaveAccountWallet(uid)) {
                    //创建钱包
                    await AccountManagement.AddNewWalletAsync(uid, "未命名", "", CoinTypeEnum.IM);
                    is_new = true;
                }
                ret_state = true;
            }

            //进行激活
            let account_wallet_vo = AccountManagement.GetNowSelectAccountWalletVO(uid);
            await account_wallet_vo?.InitAsync();

            account_wallet_vo?.ToActivated()

            //进行保存

            AccountManagement.Save(uid);
            //存储助记词
            // await Global.ToSaveMnemonicAsync(uid);

            if (!is_new) {
                //连接到节点
                await account_wallet_vo?.InitAsync();
            }

            //更新钱包地址
            // await Tools.GetHtmlAsync("UpdateWalletAddress", {
            //     wallet_address: AccountManagement.GetNowSelectAccountWalletVO(uid)?.address_vo?.address ?? ""
            // })
        }
        return ret_state;
    }
    /**
     * 获取聊天数据以及表情
     * @param v 
     * @returns 
     */
    public static GetMakeTextAndFaceMesssage(v: string) {
        if (typeof v != "string")
            return v;
        let message_list = [];
        //合成消息列表
        let msg = "";
        let find_face = false;
        for (let i = 0; i < v.length; i++) {
            const m = v[i];
            //找表情
            if (m == "$" && v[i + 1] == ":") {
                let start_index = i + 1;
                let find_face_id = "";
                let find_end = false;
                let run_count = 0;
                while (true && Tools.IsNotNull(v[start_index])) {
                    let make_v = v[start_index];
                    start_index++;
                    run_count++;
                    if (make_v == ":" && run_count > 1) {
                        find_end = true;
                        break;

                    } else if (!isNaN(make_v as any)) {
                        find_face_id += make_v;
                    } else {
                        if (run_count == 1) continue;
                        break;
                    }
                }

                if (
                    Tools.IsNotNull(find_face_id) &&
                    !isNaN(find_face_id as any) &&
                    find_end
                ) {
                    if (Tools.IsNotNull(msg)) {
                        message_list.push({
                            type: 0,
                            msg: msg,
                        });
                        msg = "";
                    }
                    //确实是个表情
                    //获取结构体
                    let face_vo = FaceConfig.GetConfigVOFromFileName(find_face_id);

                    message_list.push({
                        type: 1,
                        path: `image/face/${find_face_id}.png`,
                        config: face_vo,
                    });
                    i += run_count;
                } else {
                    if (Tools.IsNotNull(msg)) {
                        message_list.push({
                            type: 0,
                            msg: msg,
                        });
                        msg = "";
                    }
                }
            } else {
                msg += v[i];
            }
        }
        if (Tools.IsNotNull(msg)) {
            message_list.push({
                type: 0,
                msg: msg,
            });
            msg = "";
        }

        return message_list;
    }
    /**
     * 备份助记词
     */
    public static async ToSaveMnemonicAsync(uid: string): Promise<boolean> {
        console.log("正在保存助记词!");

        let wallet_vo = AccountManagement.GetNowSelectAccountWalletVO(uid);
        if (wallet_vo != null) {
            console.log("成功获取钱包对象!");
            let csa_vo = wallet_vo.currency_system_address_list[CurrencySystemIdEnum.LookBao];
            let file_name = `mnemonic_${csa_vo.address.address}`;
            console.log("检查文件是否存在!");
            //检测文件是否存在
            if (!await CordovaPlugin.CheckFileIsExistsAsync('lookbao_mnemonic', file_name)) {
                console.log("文件不存在进行转储!");
                let mnemonic = wallet_vo.mnemonic;
                if (Tools.IsNotNull(mnemonic)) {
                    if (
                        await CordovaPlugin.WriteTxtFileAsync(
                            mnemonic,
                            "lookbao_mnemonic",
                            file_name
                        )
                    ) {
                        console.log(`助记词已存储`);
                        return true;
                    } else {
                        console.log(`助记词保存失败`);
                        return false;
                    }
                } else {
                    console.log(`助记词是空的`);
                    return false;
                }
            }
            else {
                console.log("助记词文件已存在");
                return true;
            }
        }
        console.log("存储助记词失败");
        return false;
    }

    public static GetMessageStr(d: {
        data: any,
        type: ChatTypeEnum
    }) {
        let v = d.data;
        switch (d.type) {
            case ChatTypeEnum.TIME:
                break;
            case ChatTypeEnum.NEW_FRIEND:
                return "[新朋友]";
            case ChatTypeEnum.NEW_GROUP:
                return "[新成员加入]";
            case ChatTypeEnum.CREATE_GROUP:
                return "[新群创建]";
            case ChatTypeEnum.INVITATION_GROUP:
                return "[新增群成员]";
            case ChatTypeEnum.FACE:
            case ChatTypeEnum.TEXT:
                return v;
            case ChatTypeEnum.NFTTransfer:
                return "[NFT资产]";
            case ChatTypeEnum.IMAGE:
                return "[图片]";
            case ChatTypeEnum.FILE:
                return "[文件]";
            case ChatTypeEnum.VOICE:
                return "[语音通话]";
            case ChatTypeEnum.VIDEO:
                return "[视频通话]";
            case ChatTypeEnum.APPLET_SHARE:
                return "[小程序]";
            case ChatTypeEnum.SPEECH:
                return "[语音]";
            case ChatTypeEnum.POSITION:
                return "[位置]";
            case ChatTypeEnum.REDPACKAGE:
                return "[红包]";
            case ChatTypeEnum.TRANSACTION:
                return "[转账]";
            case ChatTypeEnum.LegalCurrencyTransfer:
                return "[零钱转账]";
            case ChatTypeEnum.BUSINESSCARD:
                return "[名片]";
            default:
                return "";
        }
    }

    /**
     * 打开聊天框
     * @param group_id 
     * @param uid 
     */
    public static async OpenChatWindowAsync(group_id: number, uid: string) {
        if (group_id > 0) {
            Tools.GoTo("/chat_window", {
                group_id: group_id,
            });
        }
        else {
            if (Tools.IsNotNull(uid)) {
                //获取UID
                let res = await Tools.GetHtmlAsync("GetUserBasicDataFromUID", {
                    uid: uid
                });
                if (Tools.CheckHtmlIsOK(res, false)) {
                    let user_vo = res.data.user_vo;
                    //跳转
                    Tools.GoTo(
                        "/chat_window",
                        {
                            uid: uid,
                        },
                        {
                            user_vo: user_vo,
                        }
                    );
                }
            }
        }
    }

    /**
     * 处理通知消息
     * @param d 消息内容
     */
    public static async ProcessingNotificationMessagesAsync(d: {
        display_type: "notification",
        extra: any,
        body: {
            after_open: string,
            img: string,
            ticker: string,
            title: string,
            play_sound: string,
            text: string
        }
    }) {
        switch (d.display_type) {
            case "notification":
                let extra = d.extra;
                if (extra) {
                    if (extra.notify_type) {
                        switch (extra.notify_type) {
                            case "open_group":
                                let group_id = extra.group_id;
                                //跳转
                                Tools.GoTo("/chat_window", {
                                    group_id: group_id,
                                });
                                break;
                            case "open_friend":
                                let uid = extra.uid;
                                //获取UID
                                let res = await Tools.GetHtmlAsync("GetUserBasicDataFromUID", {
                                    uid: uid
                                });
                                if (Tools.CheckHtmlIsOK(res, false)) {
                                    let user_vo = res.data.user_vo;
                                    //跳转
                                    Tools.GoTo(
                                        "/chat_window",
                                        {
                                            uid: uid,
                                        },
                                        {
                                            user_vo: user_vo,
                                        }
                                    );
                                }
                                break;
                        }
                    }
                }
                break;
        }
    }

    /**
     * 设置守卫Data
     * @param vm 
     * @param data 
     */
    public static SetBeforRouteData(vm: any, data: any, fun_white_list: string[] = []) {
        //初始化DATA
        Object.assign(vm.$data, vm.$options.data());
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const v = data[key];
                switch (typeof v) {
                    case "function":
                        if (fun_white_list.length > 0) {
                            if (fun_white_list.Where(x => x == key).length > 0) {
                                vm[key] = v;
                                delete data[key];
                            }
                        }

                        break;
                    default:
                        vm[key] = v;
                        delete data[key];
                        break;
                }
            }
        }
    }
    /**
     * 重新加载页面
     */
    public static Reload() {
        Global.app && Global.app.Reload();
    }

    /**
     * 显示通知
     * @param nick_name 
     * @param content 
     * @param icon 
     */
    public static async ShowMessageNotificationAsync(type: ChatTypeEnum, title: string, nick_name: string, content: string, icon: string, group_id: number, user_vo: IM_User | null) {
        /**
         * 判断是否在顶层显示
         */

        let show_v = Global.GetMessageStr({
            type: type,
            data: content,
        });
        if (type == ChatTypeEnum.FACE) {
            show_v = Global.GetMakeTextAndFaceMesssage(content);
        }

        if (CordovaPlugin.IsLookBaoApp()) {
            if (await CordovaPlugin.IsTopActivityAsync()) {
                Global.app && Global.app.ShowMessageNotification(type, title, nick_name, show_v, icon, group_id, user_vo);
            }
            else {
                Global.badge_number++;
                if (type == ChatTypeEnum.FACE) {
                    show_v = "[表情]";
                }
                await CordovaPlugin.ShowMessageNotificationAsync(type, title, nick_name, show_v, icon, group_id, user_vo);
            }
        }
        else {
            Global.app && Global.app.ShowMessageNotification(type, title, nick_name, show_v, icon, group_id, user_vo);
        }
    }

    /**
     * 隐藏通知
     */
    public static HideMessageNotification() {
        Global.app && Global.app.HideMessageNotification();
    }

    /**
     * 显示上链任务窗口
     */
    public static OpenUplinkStatusWindow() {
        Global.app && (Global.app.show_uplink_status_window = true);
    }

    /**
     * 隐藏Loading窗口
     */
    public static HideGlobalLoading() {
        Global.app && Global.app.HideGlobalLoading();
    }

    /**
     * 显示全局Loading
     * @param msg 
     * @param auto_close 是否自动关闭
     */
    public static ShowGlobalLoading<T>(msg: string, call_fun: (m: {
        SetLoadingMsg: (msg: string) => void
    }) => T, auto_close: boolean = true): T {

        Global.app && Global.app.ShowGlobalLoading(msg);
        let _m = {
            SetLoadingMsg: (v: string) => {
                Global.app && Global.app.ShowGlobalLoading(v);
            }
        };
        let v = null;
        try {
            v = call_fun(_m);
        }
        catch (e) {

        }
        finally {
            if (auto_close)
                Global.app && Global.app.HideGlobalLoading();
        }
        return v as T;
    }

    /**
     * 显示全局Loading
     * @param msg 
     * @param call_fun_async 
     * @param no_close_loading 完成后不关闭loading窗口
     */
    public static async ShowGlobalLoadingAsync<T>(msg: string, call_fun_async: (m: {
        SetLoadingMsg: (msg: string) => void
    }) => Promise<T>, no_close_loading: boolean = false): Promise<T> {
        Global.app && Global.app.ShowGlobalLoading(msg);
        let _m = {
            SetLoadingMsg: (v: string) => {
                Global.app.ShowGlobalLoading(v);
            }
        };
        let ret = null;
        try {
            ret = await call_fun_async(_m);
        }
        catch (e: any) {
            Tools.AlertError(e);
        }

        if (!no_close_loading)
            Global.app && Global.app.HideGlobalLoading();
        return ret as T;
    }

    /**
     * 更新我的登录数据
     */
    public static async UpdateMyLoginUserAsync() {
        Global.app && (await Global.app?.UpdateMyLoginUserAsync());
    }


    /**
     * 获取系统钱包地址
     */
    public static async GetSystemWalletAddressAsync(): Promise<{
        flag: boolean,
        wallet_address: string
    }> {
        let res = await Tools.GetHtmlAsync("GetSystemWalletAddress", {});
        if (Tools.CheckHtmlIsOK(res, false)) {
            let wallet_address = res.data.sys_address;
            return {
                flag: true,
                wallet_address: wallet_address
            };
        }
        return {
            flag: false,
            wallet_address: ""
        };
    }

    /**
     * 格式化地址
     * @param address 
     */
    public static FormatAddress(address: string) {
        try {
            let start = address.substring(0, 6);
            let end = address.substring(address.length - 4, address.length);
            return `${start}...${end}`;
        }
        catch (ex) {
            return address;
        }
    }

    /**
     * 显示实名认证窗口
     */
    public static ShowRealNameAuthentication() {
        Global.app && (Global.app.show_real_name_authentication = true);
    }

    /**
     * 显示升级账户系统
     */
    public static ShowAccountSystemUpgrade() {
        Global.app && Global.app.ShowAccountSystemUpgrade();
    }

    /**
     * 显示用户详情数据
     * @param user_vo 
     */
    public static ShowUserInfromationViewing(user_vo: IM_User) {
        Global.app && Global.app.ShowUserInfromationViewing(user_vo);
    }

    /**
     * 获取是否存在文件读写权限
     * @returns 
     */
    public static async GetHaveFilePromissionAsync(): Promise<boolean> {
        if (CordovaPlugin.IsLookBaoApp()) {
            let have_file_promission = await CordovaPlugin.GetHaveFilePromissionAsync();
            if (!have_file_promission) {
                CordovaPlugin.SetHaveFilePromission();
                Tools.AlertError("您没有文件读写权限,请授权!")
                return false;
            }
        }
        return true;
    }

    /**
     * 显示系统公告
     */
    public static ShowSystemNotice() {

        Global.app && Global.app.ShowSystemNotice();
    }
    /**
     * 显示资产选择器
     * @param SelectAssetSelectorCallBackFun 
     * @param is_back_end 是否是后端使用(是否大屏显示)
     * @param use_sub_assets 是否使用子资产选择器
     * @param asset_selector_filter_type 过滤类型 0未设置(忽略筛选) 1按主藏品 2按道具 3按子藏品
     * @param asset_selector_filter_value 过滤内容
     * @param asset_selector_hide_tab 是否隐藏头部标签
     * @param asset_selector_title 资产选择题标题
     * @param is_any_mode 任意模式
     * @param is_me 只限自己
     * @param asset_selector_redpacket_prop_obj 是否显示红包打包对象
     * @param asset_selector_filter_is_use_count 已使用量
     * @param show_only_join_substitution 仅显示可置换列表true是 false不是
     */
    public static ShowAssetSelectorBox(SelectAssetSelectorCallBackFun: (filter_type: 1 | 2, d: any) => void, is_back_end: boolean, use_sub_assets: boolean = false, asset_selector_filter_type: 0 | 1 | 2 | 3 = 0, asset_selector_filter_value: string = "", asset_selector_hide_tab: boolean = false, asset_selector_title: string = "", is_any_mode: boolean = false, is_me: boolean = false, asset_selector_redpacket_prop_obj: {
        show: boolean,
        temp_id: number,
        red_packet_model: RedPacketToolsModelEnum
    } = {
            show: false,
            temp_id: 0,
            red_packet_model: RedPacketToolsModelEnum.CreateTemplateAndBuildRedPack
        }, asset_selector_filter_is_use_count: number = 0, show_only_join_substitution: boolean = false, substitution_input_or_output: number = 0) {
        Global.app && Global.app.ShowAssetSelectorBox(SelectAssetSelectorCallBackFun, use_sub_assets, asset_selector_filter_type, asset_selector_filter_value, asset_selector_hide_tab, asset_selector_title, is_back_end, is_any_mode, is_me, asset_selector_redpacket_prop_obj, asset_selector_filter_is_use_count, show_only_join_substitution, substitution_input_or_output);
    }

    /**
     * 隐藏资产选择器
     */
    public static HideAssetSelectorBox() {
        Global.app && Global.app?.HideAssetSelectorBox();
    }

    /**
        * 显示更新组件
        */
    public static ShowToUpdate() {
        Global.app && Global.app?.ShowToUpdate();
    }

    /**
     * 这是更新进度条
     * @param num 
     */
    public static SetProgress(num: number) {
        Global.app && Global.app?.SetProgress(num);
    }

    /**
     * 打开小程序
     * @param applet_bind_vue 绑定的VUE
     * @param appid 小程序APPID 
     * @param start_parameter 启动参数
     */
    public static async OpenAppletAsync(applet_bind_vue: any, appid: string, start_parameter: any) {
        Global.CloseMinizeAppletState();
        let res = await Tools.GetHTMLDAppAsync("GetDAppVOFromAppID", { app_id: appid });
        if (Tools.CheckHtmlIsOK(res)) {
            let d = res.data;
            //显示小程序
            let app_start_param_vo = new AppletStartParameterVO();
            app_start_param_vo.AppID = appid;
            app_start_param_vo.AppStartType = d.is_game ? AppletTypeEnum.Game : AppletTypeEnum.APP;
            app_start_param_vo.Parameter = start_parameter;
            app_start_param_vo.AppBaseData = d; //APP基础数据
            Global.AddOpenAppletHistory(d);
            Global.app && Global.app.OpenAppletAsync(applet_bind_vue, app_start_param_vo);
        }
    }

    /**
     * 隐藏游戏窗口
     */
    public static HideAppletBox() {
        Global.app && Global.app?.HideAppletBox();
    }

    /**
     * 初始化主题
     */
    public static InitTheme() {
        let v: string = Tools.GetLocalStorage("theme", ThemeTypeEnum.black.valueOf().toString());
        if (Tools.StrIsNotNull(v)) {
            let theme_v = parseInt(v);
            Global.SetTheme(theme_v);
        }
        else {
            Global.SetTheme(ThemeTypeEnum.black);
        }
    }

    /**
     * 设置主题
     * @param themeName 
     */
    public static SetTheme(themeName: ThemeTypeEnum = ThemeTypeEnum.white) {
        Global.app && (Global.app.$store.state.text = themeName == ThemeTypeEnum.white);
        Global.app && (Global.app.$store.state.colorbtn = themeName == ThemeTypeEnum.black);
        //进行存储
        Tools.SetLocalStorage("theme", themeName.valueOf().toString());
        const theme_data: ThemesVO = Themes[themeName];
        Global.app && (Global.app.SetThemeObj(theme_data.theme_obj));
        for (let key in theme_data.css) {
            document.querySelector("body")?.style.setProperty(`--${key}`, theme_data.css[key]);
        }
    };

    /**
     * 打开H5扫一扫
     */
    public static OpenH5Scan(callback: (d: string) => void) {
        Global.app && Global.app.OpenH5Scan(callback);
    }

    /**
     * 打开验证码
     * @param slide_phone_num 
     * @param slide_verification_type 
     * @param slide_call_back 
     */
    public static OpenSlideVerification(
        slide_phone_num: string,
        slide_verification_type: SlideVerificationTypeEnum,
        slide_success_callback: (d: any) => void,
        slide_error_callback: (msg: string) => void,
        slide_cancel_callback: () => void,
    ) {
        if (Tools.IsNull(slide_phone_num)) {
            Tools.AlertError("手机号不能为空!");
            slide_cancel_callback && slide_cancel_callback();
            return;
        }
        Global.app && Global.app.OpenSlideVerification(slide_phone_num, slide_verification_type, slide_success_callback, slide_error_callback, slide_cancel_callback);
    }

    /**
     * 关闭验证码
     */
    public static CloseSlideVerification() {
        Global.app && Global.app.CloseSlideVerification();
    }

    /**
     * 版本检测
     */
    public static CheckVersion() {

        let next_try_check_version_time_str: string = Tools.GetSessionStorage("NextTryCheckVersionTime") as string;
        if (Tools.StrIsNotNull(next_try_check_version_time_str)) {
            let p = parseInt(next_try_check_version_time_str);
            if (!isNaN(p)) {
                if (p >= Tools.GetNowTime) {
                    Tools.ShowLog("检查更新", "无需检查!");
                    return;
                }
            }
        }
        /**
         * 设置下一次检查的时间点
         */
        Tools.SetSessionStorage("NextTryCheckVersionTime", (Tools.GetNowTime + (600 * 1000)).toString());

        Tools.GetHtml("GetNowCurrentVersion", {}, (res => {

            let have_new_version = false; //是否存在新版本
            if (Tools.CheckHtmlIsOK(res, false)) {
                let server_current_version: string = res.data;
                Tools.ShowLog("版本检查", `发现服务器版本为:${server_current_version}`);
                if (Tools.IsNull(server_current_version)) {
                    return;
                }
                else {
                    //检测版本是否是最新的
                    //切割数据
                    let app_version = Config.Version;
                    //点分割
                    let app_version_arr = app_version.split('.');
                    let server_current_version_arr = server_current_version.split('.');
                    //检测第一位
                    for (let i = 0; i < server_current_version_arr.length; i++) {
                        const server_v = server_current_version_arr.GetValueOfIndex(i);
                        const app_v = app_version_arr.GetValueOfIndex(i);
                        if (!app_v.flag) {
                            have_new_version = true;
                            break;
                        }
                        let server_num_str = server_v.data.replace("V", "").replace("v", "");
                        let app_num_str = app_v.data.replace("V", "").replace("v", "");
                        let server_num = parseInt(server_num_str);
                        let app_num = parseInt(app_num_str);
                        if (isNaN(server_num))
                            break;

                        if (isNaN(app_num))
                            break;
                        if (server_num > app_num) {
                            have_new_version = true;
                            break;
                        }
                        else if (server_num < app_num) {
                            break;
                        }
                    }

                    if (have_new_version) {
                        Global.app && Global.app?.ShowUpdateAppBox(server_current_version);
                    }
                }
            }
        }));
    }


    /**
     * 打开红包生成工具
     * @param red_packet_model 红包模式 仅创建模板,根据模板ID打包钱包,创建模板并生成红包道具
     * @param red_packet_callback 回调
     * @param allow_use_old_prop 是否允许老资产
     * @param allow_use_old_now_count 允许老资产传入的已使用数量 
     * @param red_packet_use_temp_id 使用模板ID
     */
    public static OpenRedpackTools(red_packet_model: RedPacketToolsModelEnum, red_packet_callback: (red_packet_model: RedPacketToolsModelEnum, d: IM_PropGoods | {
        prop_goods_vo: IM_PropGoods,
        prop_back_pack_id: Number
    }) => {}, allow_use_old_prop: boolean, allow_use_old_now_count: Number, red_packet_use_temp_id?: number) {
        Global.app && Global.app.OpenRedpackTools(red_packet_model, red_packet_callback, allow_use_old_prop, allow_use_old_now_count, red_packet_use_temp_id);
    }
    /**
     * 关闭红包生成工具
     */
    public static CloseRedpackTools() {
        Global.app && Global.app.CloseRedpackTools();
    }


    /**
     * 小时最小化小程序窗口
     */
    public static ShowMinizeAppletState(app_id: number) {
        Global.app && Global.app.ShowMinizeAppletState(app_id);
    }
    /**
     * 关闭最小化小程序窗口
     */
    public static CloseMinizeAppletState() {
        Global.app && Global.app.CloseMinizeAppletState();
    }


    /**
     * 获取平台数据
     */
    public static async GetAllPlatfromListAsync() {
        let res = await Tools.GetHtmlAsync("GetPlatformList", {
            page: 1,
            num: 50
        });
        if (Tools.CheckHtmlIsOK(res)) {
            let list: SYS_Platform[] = res.data.data;
            this.platform_vo_list = list;
        }
    }

    /**
     * 根据HOST获取平台数据
     */
    public static async GetPlatformFromHostAsync() {
        // Tools.ShowLog("平台", "平台正在初始化!");
        let res = await Tools.GetHtmlAsync("GetPlatformFromHost", {
            host: window.location.origin
        });
        if (Tools.CheckHtmlIsOK(res, false)) {
            Global.host_platform_vo = res.data;
            Tools.ShowLog("平台", `检测到分站:${Global.host_platform_vo.platform_name}!`);
        }
    }

    /**
     * 刷新平台数据
     */
    public static async RefreshPlatformDataAsync() {
        let guid = Global.GetUsePlatformGuid;
        let res = await Tools.GetHtmlAsync("GetPlatformFromGUID", {
            guid: guid
        });
        if (Tools.CheckHtmlIsOK(res, false)) {
            Global.host_platform_vo = res.data;
            // Tools.ShowLog("平台", `检测到分站:${Global.host_platform_vo.platform_name}!`);
        }
    }
}