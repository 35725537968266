/**
 * 群组表
 */
export default class IM_Group {
    id?: number;
    random_number?: number;
    group_name?: string;
    uid?: string;
    create_time?: string;
    icon?: string;
}