<template>
  <div class="page_scroll_div" @scroll="scrollBottom($event)">
    <slot></slot>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
export default {
  props: {
    pageNum: Number, //每页数量
    action: String, //执行指令
    /**
     * 完成数据函数
     */
    CompleteDataFunAsync: function (d) {},
  },
  data() {
    return {
      loading: false,
      loading_height: 0, //当前loading初始高度
      state: 0, //状态
      page: 0,
      pageCount: 0,
      allCount: 0,
      pageParameter: {}, //参数
    };
  },
  methods: {
    async scrollBottom(e) {
      // 滚动到页面底部时
      const el = e.target;
      const allHeight = el.scrollHeight;
      const scrollTop = el.scrollTop;
      const offsetHeight = el.offsetHeight;
      const contentHeight = el.clientHeight;
      const toBottom = contentHeight - allHeight - scrollTop;
      const jisuan = parseInt(scrollTop) + parseInt(offsetHeight);
      console.log(`allHeight=${allHeight} jisuan:${jisuan}`);
      if (jisuan >= allHeight - 10) {
        console.log(
          `正在加载...分页 this.state=${this.state}  this.loading=${this.loading}!`
        );
        if (this.state != 1 && !this.loading) {
          this.state = 1; //代表到底
          let to_load = false;
          if (this.pageCount == 0) {
            this.page++;
            to_load = true;
          } else if (this.pageCount > 0) {
            if (this.page < this.pageCount) {
              this.page++;
              to_load = true;
            }
          }
          if (to_load) await this.LoadDownAsync();
        }
      } else if (scrollTop <= 10) {
        // if (this.state != -1 && !this.loading) {
        //   this.state = -1;
        //   await this.LoadTopAsync();
        // }
      } else {
        this.state = 0;
      }
    },
    async LoadTopAsync() {
      this.loading = true;
      try {
        console.log("加载顶部数据!");
        let res = await Tools.GetHtmlAsync(this.action, {
          ...this.pageParameter,
          page: this.page,
          num: this.pageNum,
        });
        if (Tools.CheckHtmlIsOK(res, false)) {
          this.pageCount = res.data.pagecount;
          this.allCount = res.data.allcount;
          this.CompleteDataFunAsync &&
            this.CompleteDataFunAsync(
              res.data,
              this.page,
              this.pageCount,
              this.allCount
            );
        }
      } catch(ex) {
      } finally {
        this.loading = false;
      }
    },
    async LoadDownAsync() {
      this.loading = true;
      try {
        console.log("加载底部数据");
        let res = await Tools.GetHtmlAsync(this.action, {
          ...this.pageParameter,
          page: this.page,
          num: this.pageNum,
        });
        if (Tools.CheckHtmlIsOK(res, false)) {
          this.pageCount = res.data.pagecount;
          this.allCount = res.data.allcount;
          this.CompleteDataFunAsync &&
            this.CompleteDataFunAsync(
              res.data,
              this.page,
              this.pageCount,
              this.allCount
            );
        }
      } catch(ex) {
      } finally {
        this.loading = false;
      }
    },
    /**
     * 开始启动初始化
     */
    Start(pageParameter) {
      this.pageParameter = pageParameter;
      this.page = 1;
      this.LoadDownAsync();
    },
  },
  mounted() {
    // this.page = 1;
    // this.LoadDownAsync();
  },
};
</script>

<style>
.page_scroll_div {
  overflow-y: auto !important;
  height: 100% !important;
}
.page_scroll_div::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>