<template>
  <div class="update_user_body">
    <div class="update_phone_body" v-show="show_update_phone">
      <my-image
        v-if="show_update_phone"
        src="image/back.png"
        class="close_btn"
        @click="CloseMe"
      />
      <div class="update_phone_content">
        <div class="pop_notification">
          <div>手机号变更</div>
          <img
            @click="show_update_phone = false"
            :src="
              !$store.state.colorbtn
                ? require('@/assets/image/xiaoxi/blackx.png')
                : require('@/assets/image/xiaoxi/x.png')
            "
            alt=""
          />
        </div>
        <div class="phone_update_box">
          <input
            class="phone_yzm_input"
            type="text"
            maxlength="11"
            v-model="phone"
            placeholder="请输入手机号"
          />
          <button
            :class="[
              'warning_btn send_code_btn',
              send_code_ing ? 'to_gray' : '',
            ]"
            @click="SendCodeAsync"
          >
            {{ send_code_ing ? `${wait_time}s后再试` : "发送验证码" }}
          </button>
        </div>
        <input
          class="phone_yzm_input input_two"
          placeholder="请输入验证码"
          type="text"
          maxlength="4"
          v-model="phone_code"
        />
        <button class="update_phone_btn" @click="ToUpdatePhoneAsync">
          确认修改
        </button>
      </div>
    </div>
    <div
      :class="
        show_base
          ? 'update_account_content update_account_content_base'
          : 'update_account_content'
      "
    >
      <my-image
        v-if="!show_base"
        :src="theme_obj?.allback?.back_img"
        class="close_btn"
        @click="CloseMe"
      />
      <div class="lie lie_center">
        <span class="title">个人信息</span>

        <div class="item">
          <span>头像</span>
          <div class="right_box">
            <my-image
              class="head_img"
              v-if="head_pic"
              :src="ShowImageFromResource(head_pic)"
            />
            <my-image class="you_icon" src="image/you.png" />

            <input
              type="file"
              placeholder="上传头像"
              accept="image/*"
              @change="UploadFileAsync($event)"
            />
          </div>
        </div>

        <div class="item" @click="UpdateKey('nick_name', '修改昵称')">
          <span>昵称</span>
          <div class="right_box">
            <span>{{ nick_name }}</span>
            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div>

        <div class="item" v-if="!show_base">
          <span>UID</span>
          <div class="right_box">
            <span class="uid_title">{{ uid }}</span>
          </div>
        </div>

        <div class="item" @click="ShowUpdatePhoneAsync" v-if="!show_base">
          <span>手机号</span>
          <div class="right_box">
            <span>{{ user_vo.phone ? user_vo.phone : "未设置" }}</span>
            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div>
        <div class="item" @click="OpenWalletAddress">
          <span>区块链地址</span>
          <span class="right_box">
            <span v-show="wallet_address">{{
              FormatAddress(wallet_address)
            }}</span>
          </span>
          <my-image class="you_icon" src="image/you.png" />
        </div>
        <div class="item" @click="ShowRealNameAuthentication">
          <span>实名认证</span>
          <div class="right_box">
            <span>{{ ShowRealMessage }}</span>
            <my-image
              v-show="real_vo == null || real_vo?.state == 3"
              class="you_icon"
              src="image/you.png"
            />
          </div>
        </div>

        <div class="item" @click="UpdateAuthorIntroductionAsync">
          <span>作者介绍</span>
          <div class="right_box">
            <span>{{ user_vo.author_introduction }}</span>
            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div>

        <!-- <div class="item" @click="ShowMyQR">
          <span>我的二维码</span>
          <div class="right_box">
            <span></span>
            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div> -->

        <!-- <div class="item" v-if="!show_base">
          <span>性别</span>
          <div class="right_box">
            <select v-model="sex" class="sex_select_input">
              <option value="0">选择性别</option>
              <option value="1">男</option>
              <option value="2">女</option>
            </select>
          </div>
        </div> -->

        <!-- <div
          class="item"
          @click="UpdateKey('birthady', '请输入生日', 'time')"
          v-if="!show_base"
        >
          <span>生日</span>
          <div class="right_box">
            <span>{{ birthady ? ShowDate(birthady) : "未设置" }}</span>
            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div> -->

        <!-- <div class="item" @click="UpdateKey('phone', '请输入手机号')">
          <span>地区</span>
          <div class="right_box">
            <span>{{ phone ? phone : "未设置" }}</span>
            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div> -->

        <!-- <div
          class="item"
          @click="UpdateKey('personal_label', '请输入个人标签')"
          v-if="!show_base"
        >
          <span>个人标签</span>
          <div class="right_box">
            <span>{{ personal_label ? personal_label : "未设置" }}</span>
            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div>

        <div
          class="item"
          @click="UpdateKey('personal_signature', '请输入个人签名')"
          v-if="!show_base"
        >
          <span>个人签名</span>
          <div class="right_box">
            <span>{{
              personal_signature ? personal_signature : "未设置"
            }}</span>
            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div>

         -->

        <!-- <div class="item" @click="UpdateKey('phone', '请输入手机号')">
          <span>我的二维码</span>
          <div class="right_box">
            <span>{{ phone ? phone : "未设置" }}</span>
            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div> -->

        <button class="success_btn" @click="ToSaveAsync">保存设置</button>
      </div>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
import { InputBoxTypeEnum, SlideVerificationTypeEnum } from "@/ts/SuperEnum";
import AccountManagement from "@/ts/blockchain/AccountManagement";
import SuperTimer from "@/ts/SuperTimer";
import { inject } from "@vue/runtime-core";
import CordovaPlugin from "@/ts/CordovaPlugin";
export default {
  data() {
    return {
      head_pic: "",
      nick_name: "",
      last_nick_name: "", //最后昵称
      sex: 0,
      phone: "",
      uid: "",
      birthady: "",
      personal_label: "",
      personal_signature: "",
      real_vo: null,
      wait_time: 120,
      send_code_ing: false,
      phone_code: "", //手机验证码
      show_update_phone: false, //是否显示修改手机密码
      wallet_address: "",
    };
  },
  props: {
    show_base: Boolean,
  },
  computed: {
    ShowRealMessage() {
      if (this.real_vo == null) return "未认证";
      switch (this.real_vo.state) {
        case 0:
          return "审核中";
        case 1:
          return "已通过";
        case 2:
          return "未通过";
        default:
          return "未知状态";
      }
    },
  },
  methods: {
    /**
     * 确认修改手机号码
     */
    async ToUpdatePhoneAsync() {
      let f = await this.UpdateAccountDataFromKeyAsync(
        "phone",
        this.phone,
        this.phone_code
      );
      this.phone_code = "";
      if (f) {
        this.show_update_phone = false;
      }
    },
    //区块链
    FormatAddress(address) {
      return Tools.FormatAddress(address);
    },
    //获取区块链
    async activated() {
      //获取钱包地址
      let wallet_vo = AccountManagement.GetNowSelectAccountWalletVO(
        Global.SelectUID
      );
      if (wallet_vo == null) {
        await Global.InitWalletAsync();
        wallet_vo = AccountManagement.GetNowSelectAccountWalletVO(
          Global.SelectUID
        );
      }
      this.wallet_address = wallet_vo?.address_vo?.address;
      Global.ShowSystemNotice();
    },
    //区块链跳转
    OpenWalletAddress() {
      this.$parent.show_update_account = false;
      Tools.GoTo("/wallet_address_alert");
    },
    /**
     * 显示修改手机号
     */
    async ShowUpdatePhoneAsync() {
      this.show_update_phone = true;
    },
    async SendCodeAsync() {
      if (this.send_code_ing) {
        return;
      }
      this.send_code_ing = true;
      this.wait_time = 120;
      let flag = await this.SendMobilePhoneVerificationAsync(this.wait_time);
      if (flag) {
        SuperTimer.Start(
          "send_code_ing",
          () => {
            this.wait_time--;
            if (this.wait_time <= 0) {
              this.wait_time = 0;
              this.send_code_ing = false;
              return false;
            }
            return true;
          },
          1000
        );
      } else {
        this.send_code_ing = false;
      }
    },
    /**
     * 发送手机验证请求
     */
    async SendMobilePhoneVerificationAsync(wait_time) {
      // let res = await Tools.GetHtmlAsync("SendMobilePhoneVerification", {
      //   phone: this.phone,
      //   wait_time: wait_time,
      // });
      // if (Tools.CheckHtmlIsOK(res)) {
      //   return true;
      // }
      // return false;
      return await new Promise((r) => {
        try {
          Global.OpenSlideVerification(
            this.phone,
            SlideVerificationTypeEnum.VerificationPhone,
            (f) => {
              r(true);
            },
            (msg) => {
              Tools.AlertError(msg);
            },
            () => {
              // Tools.AlertError("已取消");
              r(false);
            }
          );
        } catch (ex) {
          r(false);
        }
      });
    },

    ShowImageFromResource(img) {
      return Global.GetCoinIconURL(img, "f160");
    },
    ShowMyQR(app_down) {
      Tools.GoTo(
        "/qr",
        {},
        {
          app_down: app_down,
          uid: this.user_vo.uid,
        }
      );
      this.CloseMe();
    },
    async UpdateAccountDataFromKeyAsync(key, value, code) {
      let res = await Tools.GetHtmlAsync("UpdateAccountDataFromKey", {
        key: key,
        value: value,
        code: code,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        let d = res.data;
        this.user_vo = d.user_vo;
        Tools.AlertSuccess("修改成功!");
        return true;
      }
      return false;
    },
    async UpdateAuthorIntroductionAsync() {
      let content = await Global.ShowInputBoxAsync(
        "作者介绍",
        this.user_vo.author_introduction,
        InputBoxTypeEnum.TextArea,
        200
      );
      if (Tools.IsNotNull(content)) {
        await this.UpdateAccountDataFromKeyAsync(
          "author_introduction",
          content
        );
      }
    },
    /**
     * 获取实名认证信息
     */
    async GetMyRealNameAuthenticationVOAsync() {
      let res = await Tools.GetHtmlAsync("GetMyRealNameAuthenticationVO", {});
      if (Tools.CheckHtmlIsOK(res)) {
        if (res.data.have) {
          this.real_vo = res.data.real_vo;
        } else {
          this.real_vo = null;
        }
      }
    },
    /**
     * 显示实名认证窗口
     */
    async ShowRealNameAuthentication() {
      //
      if (this.real_vo == null || this.real_vo?.state == 3) {
        if (!(await Global.GetHaveFilePromissionAsync())) {
          return;
        }
        // console.log('1111')
        Global.ShowRealNameAuthentication();
        this.CloseMe();
      } else if (this.real_vo?.state == 2) {
        let reason = this.real_vo.reason;
        // Global.MessageBox("拒绝原因", reason, () => {});
        Tools.AlertError(reason);
      }
    },

    ShowDate(v) {
      return Tools.ShowDate(v);
    },
    CloseMe() {
      this.$parent.show_update_account = false;
    },
    UpdateKey(key, title, type) {
      var type_enum = InputBoxTypeEnum.TEXT;
      if (type == "time") type_enum = InputBoxTypeEnum.Date;
      Global.ShowInputBox(title, this[key], type_enum, async (t) => {
        if (Tools.IsNotNull(t)) {
          this[key] = t;
          //进行保存
          await this.ToSaveAsync(true);
        }
      });
    },
    async UploadFileAsync(event) {
      var files = event.target.files;
      if (files.length > 0) {
        let res = await Tools.UploadFileAsync("UploadFile", {}, files);
        if (Tools.CheckHtmlIsOK(res)) {
          let v = res.data[0];
          this.head_pic = v.FileURL;
        }
      }
    },
    RefData() {
      this.uid = this.user_vo.uid;
      // this.phone = this.user_vo.phone;
      this.sex = this.user_vo.sex;
      this.head_pic = this.user_vo.head_pic;
      this.nick_name = this.user_vo.nick_name;
      this.last_nick_name = this.user_vo.nick_name;

      this.birthady = this.user_vo.birthady;
      this.personal_label = this.user_vo.personal_label;
      this.personal_signature = this.user_vo.personal_signature;
    },
    async ToSaveAsync(no_close) {
      if (Tools.IsNull(this.nick_name)) {
        Tools.AlertError("昵称不能为空!");
        return;
      }
      if (this.nick_name == "未命名") {
        Tools.AlertError("昵称必须设置!");
        return;
      }
      if (Tools.IsNull(this.head_pic)) {
        Tools.AlertError("头像不能为空!");
        return;
      }
      if (Tools.IsNull(this.sex)) {
        // Tools.AlertError("性别不能为空!");
        // return;
        this.sex = 1;
      }

      let res = await Tools.GetHtmlAsync("UpdateAccountData", {
        nick_name: this.nick_name,
        head_pic: this.head_pic,
        sex: this.sex,
        phone: this.phone,
        birthady: Tools.IsNull(this.birthady) ? "" : this.birthady,
        personal_label: this.personal_label,
        personal_signature: this.personal_signature,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        Tools.AlertSuccess("修改成功!");
        this.last_nick_name = this.nick_name;
        Global.Send("GetNowLoginAccountData", {});
        if (no_close != true) this.CloseMe();
      } else {
        this.nick_name = this.last_nick_name;
      }
    },
  },
  setup() {
    const user_vo = inject("user_vo");
    const theme_obj = inject("theme_obj");
    return {
      user_vo,
      theme_obj
    };
  },
  watch: {
    user_vo: {
      deep: true,
      handler() {
        this.RefData();
      },
    },
  },
  mounted() {
    this.activated();
    //更新
    Global.UpdateMyLoginUserAsync();
    this.RefData();
    this.GetMyRealNameAuthenticationVOAsync();
  },
};
</script>
<style scoped lang='less'>
.pop_notification {
  margin-bottom: 20px;
  img {
    width: 14px;
    float: right;
    margin-top: -26px;
    margin-right: -10px;
  }
}
.lie {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.lie_center {
  align-items: center;
}

.update_user_body {
  color: @FFFFFF_c040506;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update_account_content {
  background-color: @home_bgc_class;
  padding: 10px 14px;
  width: 100%;
  height: 100%;
  position: relative;
  /* border-radius: 10px; */
}

.update_account_content_base {
  height: auto !important;
}

.close_btn {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  color: @ffffff_324460;
}

.item {
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 13.5px;
  border: @c1B1C1E_FFFFFF 1px solid;
  background-color: @c1B1C1E_FFFFFF;
  padding: 15px 10px;
}

.item > span:nth-child(1) {
  min-width: 50px;
  text-align: left;
  color: @ffffff_324460;
}

input[type="text"],
input[type="number"],
select {
  height: 30px;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.success_btn {
  height: 35px;
  width: 80%;
  border-radius: 30px;
  margin-top: auto;
  background-color: #02cdb0 !important;
  color: #ffffff;
}

.head_img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.you_icon {
  width: 16px;
  height: 16px;
}

.right_box {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.right_box > span {
  color: #848484;
  font-size: 14px;
}

input[type="file"] {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-width: 100%;
  top: 0px;
  left: 0px;
  display: block;
  opacity: 0;
}

.sex_select_input {
  width: 50px;
  font-size: 12px;
}

.uid_title {
  font-size: 18px !important;
  color: #f7b500 !important;
}

/* 修改手机号窗口 */
.update_phone_body {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
}
.update_phone_content {
  display: flex;
  flex-direction: column;
  background-color: @c191F24_ffffff;
  padding: 15px 20px 20px 20px;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  height: 230px;
}

.update_phone_content input {
  height: 40px;
  border: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.close_phone_window {
  top: 10px !important;
  right: 10px !important;
  left: auto !important;
}
.update_phone_btn {
  width: 50%;
  margin-top: 10px;
  background-color: #02cdb0;
  border-radius: 5px;
  border: #02cdb0 1px;
  height: 30px;
  color: #ffffff;
  margin: auto;
}

.send_code_btn {
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #ffffff !important;
}
/* 修改手机号窗口 */

.phone_update_box {
  margin-bottom: 10px;
}

.phone_update_box > input {
}
.phone_yzm_input {
  border: 0px;
  background: @home_bgc_class !important;
  color: @ffffff_324460 !important;
  border-radius: 10px 0px 0px 10px;
}
.input_two {
  border-radius: 10px;
}
</style>