<template>
  <div :class="['body', show_alert_state == 1 ? 'success' : 'error']">
    <span>{{ message }}</span>
  </div>
</template>
<script>
export default {
  props: {
    message: String,
    show_alert_state: Number,
  },
};
</script>
<style scoped>
.body {
  position: fixed;
  width: 100%;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  overflow: hidden;
  z-index: 5000;
}
.success {
  background-color: #5fb878;
}
.error {
  background-color: #ff5722;
}
</style>