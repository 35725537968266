<template>
  <!-- 资产选择器 -->
  <div class="asset_selector_body">
    <div
      :class="[
        'asset_selector_content',
        asset_selector_is_back_end || asset_selector_is_any_mode
          ? 'is_back_end'
          : '',
      ]"
    >
      <div class="asset_selector_head">
        <span>{{ title != null && title != "" ? title : "资产选择器" }}</span>
        <img
          :src="
            !$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')
          "
          @click="CloseMe"
        />
      </div>
      <div class="asset_selector_center">
        <Tab
          ref="tab1"
          :tab_list="['藏品', '道具']"
          :hide_tab="hide_tab"
          :OnTabSelected="OnTabSelected"
        >
          <template #0>
            <Page
              ref="GetAssetSelectorDataList1"
              action="GetAssetSelectorDataList"
              :CompleteDataFunAsync="GetAssetSelectorDataList1"
              :pageNum="20"
              class="asset_selector_item_list"
            >
              <template v-if="asset_list.length > 0">
                <div
                  @click="SelectItem(v)"
                  :class="['asset_selector_item', CheckIsSelect(v) ? '' : '']"
                  :key="i"
                  v-for="(v, i) in asset_list"
                >
                  <img
                    v-if="CheckIsSelect(v)"
                    class="gou_xuan"
                    src="image/gou_xuan.png"
                  />

                  <div class="asset_selector_img">
                    <img
                      v-if="v.filter_type == 1"
                      :src="
                        GetResourceIMG(v?.asset_type_hash_vo?.assets_thumbnails)
                      "
                    />
                    <img
                      v-else-if="v.filter_type == 3"
                      :src="
                        GetResourceIMG(v?.asset_type_hash_vo?.assets_thumbnails)
                      "
                    />
                  </div>
                  <div class="asset_selector_title">
                    <span v-if="v?.filter_type == 1">{{
                      v?.asset_type_hash_vo?.assets_title
                    }}</span>
                    <span v-else-if="v?.filter_type == 3">{{
                      v?.asset_type_hash_vo?.assets_title
                    }}</span>
                  </div>

                  <span v-if="v?.filter_type == 3" class="asset_index_item">
                    {{ ShowAssetTab(v).msg }}
                  </span>
                </div>
              </template>
              <template v-else>
                <div class="no_data">
                  <span v-if="find_asset_type_hash_vo">
                    暂无 "{{ find_asset_type_hash_vo?.assets_title }}" 藏品
                  </span>
                  <span v-else> 暂无数据 </span>
                </div>
              </template>
            </Page>
          </template>
          <template #1>
            <Page
              ref="GetAssetSelectorDataList2"
              action="GetAssetSelectorDataList"
              :CompleteDataFunAsync="GetAssetSelectorDataList2"
              :pageNum="20"
              class="asset_selector_item_list"
            >
              <div
                class="asset_selector_item"
                v-if="
                  CanRedPacket &&
                  ((asset_selector_redpacket_prop_obj?.show ?? false) ||
                    (asset_selector_redpacket_prop_obj.temp_id > 0 &&
                      this.asset_selector_redpacket_prop_obj?.data))
                "
                @click="ShowRedPackTools()"
              >
                <div class="asset_selector_img">
                  <img
                    v-if="asset_selector_redpacket_prop_obj.temp_id > 0"
                    :src="
                      this.asset_selector_redpacket_prop_obj?.data
                        ?.prop_goods_vo?.prop_img
                    "
                  />
                  <img
                    v-else
                    src="@/assets/image/ui/red_packet_tools/auto.png"
                  />
                </div>
                <div class="asset_selector_title">
                  <span>{{
                    asset_selector_redpacket_prop_obj.temp_id > 0
                      ? this.asset_selector_redpacket_prop_obj?.data
                          ?.prop_goods_vo?.prop_name
                      : "自定义红包"
                  }}</span>
                </div>
              </div>

              <template v-if="prop_goods_list.length > 0">
                <div
                  @click="SelectItem(v)"
                  :class="['asset_selector_item', CheckIsSelect(v) ? '' : '']"
                  :key="i"
                  v-for="(v, i) in prop_goods_list"
                >
                  <div v-if="v.count > 0" class="prop_goods_count_tab">
                    {{ v.count }}
                  </div>
                  <img
                    v-if="CheckIsSelect(v)"
                    class="gou_xuan"
                    src="image/gou_xuan.png"
                  />

                  <div class="asset_selector_img">
                    <img :src="v?.prop_img" />
                  </div>
                  <div class="asset_selector_title">
                    <span>{{ v?.prop_name }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="no_data">
                  <span v-if="find_prop_goods_vo">
                    暂无 "{{ find_prop_goods_vo.prop_name }}" 道具
                  </span>
                  <span v-else>
                    {{
                      asset_selector_redpacket_prop_obj?.show ? "" : "暂无数据"
                    }}
                  </span>
                </div>
              </template>
            </Page>
          </template>
        </Tab>
      </div>
      <div
        class="asset_selector_bottom"
        v-if="prop_goods_list.length > 0 || asset_list.length > 0"
      >
        <button
          :class="['success_btn', select_d == null ? 'to_gray' : '']"
          @click="QueDing()"
        >
          选择
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Tab from "@/components/TabComponent.vue";
import Tools from "@/ts/Tools";
import Page from "@/components/PageComponent.vue";
import Global from "@/ts/Global";
import { RedPacketToolsModelEnum } from "@/ts/SuperEnum";
import {inject} from "vue";
export default {
  setup() {
    const user_vo = inject("user_vo");
    const host_platform_vo = inject("host_platform_vo");
    return {
      user_vo,
      host_platform_vo,
    };
  },
  data() {
    return {
      select_asset_type_hash_vo: null,
      select_d: null,
      asset_list: [],
      sub_asset_list: [], //子资产数据列表
      prop_goods_list: [],
      find_prop_goods_vo: null, //找到的道具数据
      find_asset_type_hash_vo: null, //找到的藏品数据
      substation_config_vo: null, //平台配置数据
    };
  },
  computed: {
    /**
     * 检查是否可以打包红包
     */
    CanRedPacket() {
      if (this.substation_config_vo) {
        if (
          this.substation_config_vo.open_change_package == 1 ||
          this.substation_config_vo.open_miu_packget == 1
        )
          return true;
      }
      return false;
    },
  },
  props: {
    title: String, //标题
    hide_tab: Boolean, //是否隐藏头部标签
    is_use_count: 0, //已使用数量(一般道具使用)
    /**
     * 要过滤的具体值
     */
    asset_selector_filter_value: String,
    /**
     * 过滤类型 1主藏品 2道具 3子藏品
     */
    asset_selector_filter_type: Number,
    /**
     * 使用子资产模式
     */
    use_sub_assets: Boolean, //子程序
    asset_selector_is_back_end: Boolean, //是否是后端使用
    asset_selector_is_any_mode: Boolean, //是否使用任意模式
    show_only_join_substitution: Boolean, //仅显示可置换列表 true 启用 false未启用
    substitution_input_or_output: Number, // 1为input 2为置换output
    asset_selector_is_me: Boolean, //仅限自己
    /**
     * 是否显示红包道具入口
     */
    asset_selector_redpacket_prop_obj: {
      show: Boolean, //是否显示红包窗口
      temp_id: Number, //模板ID
      red_packet_model: RedPacketToolsModelEnum.CreateTemplateAndBuildRedPack,
    },
    /**
     * 回调函数
     */
    SelectAssetSelectorCallBackFun: function (
      filter_type,
      d,
      asset_selector_filter_type,
      asset_selector_filter_value
    ) {},
  },
  components: {
    Tab,
    Page,
  },
  methods: {
    GetResourceIMG(url) {
      return Global.GetCoinIconURL(url);
    },
    /**
     * 红包打包器
     */
    ShowRedPackTools() {
      if (this.asset_selector_redpacket_prop_obj.show) {
        if (this.asset_selector_redpacket_prop_obj.temp_id > 0) {
          //存在模板ID点击后直接打包
          Global.OpenRedpackTools(
            this.asset_selector_redpacket_prop_obj.red_packet_model,
            (r, _d) => {
              this.select_d = {
                filter_type: 2,
                d: _d.prop_goods_vo,
              };
              this.QueDing();
            },
            true,
            this.is_use_count,
            this.asset_selector_redpacket_prop_obj.temp_id
          );
        } else {
          //不存在模板ID选择打包模式
          Global.OpenRedpackTools(
            this.asset_selector_redpacket_prop_obj.red_packet_model,
            (r, _d) => {
              this.select_d = {
                filter_type: 2,
                d: _d?.prop_goods_vo ?? _d,
              };
              this.QueDing();
            },
            false,
            this.is_use_count
          );
        }
      }
    },

    /**
     * 显示资产
     */
    ShowAssetTab(d) {
      if (d?.asset_vo == null) {
        return {
          flag: true,
          msg: "",
        };
      }

      let flag = true;
      let v = d.asset_vo;
      let asset_type_hash_vo = d.asset_type_hash_vo;
      let msg = `#${v?.index}`;
      if (v.state != 2) {
        msg = "未上链";
        flag = false;
      }
      if (v.asset_lock == 1) {
        msg = "已锁定";
        flag = false;
      }
      if (Tools.IsNotNull(v.unlocking_time)) {
        let unlocking_time = Tools.TimeToLong(v.unlocking_time);
        if (unlocking_time > Tools.GetNowTime) {
          msg = "已锁仓";
          flag = false;
        }
      }
      if (v.is_destroy == 1) {
        msg = "已销毁";
        flag = false;
      }
      if (v.is_trusteeship != 1) {
        msg = "非托管";
        flag = false;
      }
      if (v.is_blind_box == 1) {
        msg = "已锁定到盲盒";
        flag = false;
      }
      flag = true;
      return {
        flag: flag,
        msg: msg,
      };
    },

    /**
     * 确定选择
     */
    QueDing() {
      if (this.select_d == null) {
        return;
      }
      let v = this.select_d;
      let filter_type = v.filter_type;
      let d = v.d;
      if (!d) {
        Tools.AlertError("您还没有选择!");
        return;
      }
      this.SelectAssetSelectorCallBackFun(
        filter_type,
        d,
        this.asset_selector_filter_type,
        this.asset_selector_filter_value
      );
      this.CloseMe();
    },
    SelectItem(d) {
      let filter_type = d.filter_type;
      if (!(this.use_sub_assets && filter_type == 1))
        this.select_d = {
          filter_type: filter_type,
          d: d.asset_type_hash_vo,
        };
      if (filter_type == 1 && this.use_sub_assets) {
        this.select_d = {
          filter_type: 3,
          d: null,
        };
        this.GetSubAssetDataListAsync(d.asset_type_hash_vo.type_hash);
      } else if (filter_type == 2) {
        this.select_d = {
          filter_type: filter_type,
          d: d,
        };
      } else if (filter_type == 3) {
        this.select_d = {
          filter_type: filter_type,
          d: d,
        };
      }
    },
    CheckIsSelect(v) {
      if (!this.ShowAssetTab(v).flag) return;
      let filter_type = v.filter_type;
      if (this.select_d != null) {
        if (this.select_d.filter_type == filter_type) {
          switch (filter_type) {
            case 1:
              if (this.select_d?.d?.id == v?.asset_type_hash_vo?.id)
                return true;
              break;
            case 2:
              if (this.select_d?.d?.id == v.id) return true;
              break;
            case 3:
              if (this.select_d?.d?.asset_vo?.id == v?.asset_vo?.id)
                return true;
              break;
          }
        }
      }
      return false;
    },
    /**
     * 关闭自己
     */
    CloseMe() {
      this.RecoveryData();
      Global.HideAssetSelectorBox();
    },
    RecoveryData() {
      this.select_asset_type_hash_vo = null;
      this.select_d = null;
    },
    /**
     * 获取资产选择器数据列表
     */
    async GetAssetSelectorDataListAsync(filter_type) {
      debugger;
      // let res = await Tools.GetHtmlAsync("GetAssetSelectorDataList", {
      //   filter_type: filter_type,
      // });
      // if (Tools.CheckHtmlIsOK(res)) {
      //   let filter_type = res.data.filter_type;
      //   switch (filter_type) {
      //     case 1: //筛选藏品
      //       this.asset_list = res.data.data;
      //       break;
      //     case 2: //筛选的道具
      //       this.prop_goods_list = res.data.data;
      //       break;
      //   }
      // }
      if (this.show_only_join_substitution) {
        //如果为置换模式
        switch (filter_type) {
          case 2: //道具
            if (this.substitution_input_or_output == 1) {
              //如果是input
              if (this.host_platform_vo.allow_input_prop != 1) return;
            } else if (this.substitution_input_or_output == 2) {
              //如果是output
              if (this.host_platform_vo.allow_output_prop != 1) return;
            }
            break;
          default:
            //其它(藏品)
            if (this.substitution_input_or_output == 1) {
              //如果是input
              if (this.host_platform_vo.allow_input_asset != 1) return;
            } else if (this.substitution_input_or_output == 2) {
              //如果是output
              if (this.host_platform_vo.allow_output_asset != 1) return;
            }
            break;
        }
      }

      this.$refs[`GetAssetSelectorDataList${filter_type}`]?.Start({
        filter_type: filter_type,
        asset_selector_filter_type: this.asset_selector_filter_type,
        asset_selector_filter_value: this.asset_selector_filter_value,
        asset_selector_is_me: this.asset_selector_is_me,
        is_any_mode_filter: this.asset_selector_is_any_mode,
        show_only_join_substitution: this.show_only_join_substitution,
      });
    },

    async GetSubAssetDataListAsync(type_hash) {
      this.$refs[`GetAssetSelectorDataList1`]?.Start({
        filter_type: 3,
        type_hash: type_hash,
        asset_selector_filter_type: 3,
        asset_selector_filter_value: this.asset_selector_filter_value,
        asset_selector_is_me: this.asset_selector_is_me,
        is_any_mode_filter: this.asset_selector_is_any_mode,
        show_only_join_substitution: this.show_only_join_substitution,
      });
    },
    GetAssetSelectorDataList1(d, page) {
      if (d.filter_type == 3) {
        this.select_asset_type_hash_vo = d.asset_type_hash_vo;
      } else {
        this.select_asset_type_hash_vo = null;
      }
      d.data.forEach((v) => {
        v.filter_type = d.filter_type;
      });

      this.find_asset_type_hash_vo = d.find_asset_type_hash_vo;

      if (page == 1) {
        this.asset_list = d.data;
      } else {
        this.asset_list.PushAll(d.data);
      }
    },
    GetAssetSelectorDataList2(d, page) {
      this.select_asset_type_hash_vo = null;
      d.data.forEach((v) => {
        v.filter_type = d.filter_type;
      });

      this.find_prop_goods_vo = d.find_prop_goods_vo;

      if (page == 1) {
        this.prop_goods_list = d.data;
      } else {
        this.prop_goods_list.PushAll(d.data);
      }
    },

    OnTabSelected(index) {
      this.RecoveryData();
      if (index == 0) {
        if (this.asset_selector_filter_type == 3) {
          this.GetSubAssetDataListAsync(this.asset_selector_filter_value);
        } else if (this.asset_selector_filter_type == 0) {
          this.GetAssetSelectorDataListAsync(1);
        }
      } else {
        if (
          this.asset_selector_filter_type == 2 ||
          this.asset_selector_filter_type == 0
        )
          this.GetAssetSelectorDataListAsync(2);
      }
    },
    /**
     * 用户获取分站配置数据
     */
    async UserGetSubstationConfigurationAsync() {
      let res = await Tools.GetHtmlAsync("UserGetSubstationConfiguration", {});
      if (Tools.CheckHtmlIsOK(res)) {
        this.substation_config_vo = res.data;
      }
    },
  },
  async mounted() {
    debugger;
    await this.UserGetSubstationConfigurationAsync();
    this.select_d = null;
    if (this.asset_selector_redpacket_prop_obj) {
      if (this.asset_selector_redpacket_prop_obj.show) {
        let temp_id = this.asset_selector_redpacket_prop_obj.temp_id;
        if (temp_id > 0) {
          let r = await Tools.GetHtmlAsync("RedPacketPropGoodFromTempID", {
            temp_id: temp_id,
          });
          if (Tools.CheckHtmlIsOK(r, false)) {
            this.asset_selector_redpacket_prop_obj.data = r.data;
          }
        }
      }
    }

    if (this.asset_selector_filter_type == 3) {
      this.GetSubAssetDataListAsync(this.asset_selector_filter_value);
    } else if (this.asset_selector_filter_type == 0) {
      this.GetAssetSelectorDataListAsync(1);
    }
    if (
      this.asset_selector_filter_type == 2 ||
      this.asset_selector_filter_type == 0
    ) {
      this.GetAssetSelectorDataListAsync(2);
    }
    if (this.asset_selector_filter_type == 2) {
      this.$refs["tab1"].select_index = 1;
    } else {
      this.$refs["tab1"].select_index = 0;
    }
  },
};
</script>
<style scoped lang="less">
.asset_selector_body {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .is_back_end {
    height: 80% !important;
  }

  .asset_selector_content {
    background-color: @c191F24_ffffff;
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    .asset_selector_head {
      padding: 8px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      font-size: 14px;
      img {
        position: absolute;
        right: 10px;
      }
    }

    .asset_selector_center {
      display: flex;
      height: 100%;
      overflow: hidden;
      .asset_selector_item_list {
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: start;

        .asset_selector_item {
          width: 30%;
          height: 30vw;
          display: inline-block;
          float: left;
          margin-left: 2.66%;
          margin-bottom: 10px;
          background-color: #303539;
          border-radius: 10px;
          overflow: hidden;

          position: relative;

          .prop_goods_count_tab {
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: blueviolet;
            padding: 2px 10px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          .asset_index_item {
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: blueviolet;
            padding: 2px 5px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            font-size: 12px;
            color: white;
          }

          .gou_xuan {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
          }

          .asset_selector_img {
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .asset_selector_title {
            width: 100%;
            display: block;
            font-size: 12px;
            position: absolute;
            bottom: 0px;
            background-color: rgba(0, 0, 0, 0.8);
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            color: white;
          }
        }
      }
    }

    .asset_selector_bottom {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: auto;
      height: 45px;
      button {
        height: 100%;
        width: 100%;
        letter-spacing: 2px;
        font-size: 20px;
        font-weight: bold;
        z-index: 100;
      }
    }
  }
}

.no_data {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}
</style>