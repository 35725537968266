<template>
  <div class="body">
    <div class="content">
      <span class="title">{{host_platform_vo?.platform_name}}服务协议和隐私政策</span>
      <span class="miaoshu">
        在您使用我的各项服务之前，请务必审慎阅读、充分理解<span
          class="tab"
          @click="OpenXieYi(1)"
          >《用户协议》</span
        >、 <span class="tab" @click="OpenXieYi(2)">《隐私政策》</span>的条款。
        <br />
        <span class="tab_b">
          并且我们会申请获取您的设备信息，以向您提供安全风控服务。
        </span>
        <br />
        <span>
          同时您应特别注意前述协议中免除或者限制我们责任的条款、对您权利进行限制的条款。如您已详细阅读Look
          Bao 用户协议、隐私政策， 请点击【同意并继续】开始使用我们的服务。
        </span>
      </span>
      <button class="infomation_btn" @click="TongYiBtn">同意并继续</button>
      <span class="no_btn" @click="NoBtn">不同意并退出APP</span>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import CordovaPlugin from "@/ts/CordovaPlugin";
import { inject} from "vue";
export default {
  setup() {
    const host_platform_vo = inject("host_platform_vo");
    return {
      host_platform_vo,
    };
  },
  data() {
    return {
      APP_NAME: "",
    };
  },
  props: {
    show_yszc_window_call_back: function () {},
  },
  methods: {
    TongYiBtn() {
      this.show_yszc_window_call_back && this.show_yszc_window_call_back();
      Tools.SetLocalStorage("show_yszc_window", "true");
      // Tools.SetLocalStorage("ty_xy", "TRUE");
      this.$parent.show_yszc_window = false;
    },
    NoBtn() {
      CordovaPlugin.CloseApp();
    },
    OpenXieYi(index) {
      switch (index) {
        case 1:
          Tools.OpenURLToWebView(
            `${this.APP_NAME}平台用户服务协议`,
            this.host_platform_vo?.user_service_agreement_url,
            true
          );
          break;
        case 2:
          Tools.OpenURLToWebView(
            `${this.APP_NAME}平台用户隐私政策`,
            this.host_platform_vo?.user_privacy_policy_url,
            true
          );
          break;
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.body {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
  padding: 0px 5%;
}

.content {
  flex-direction: column;
  padding: 20px;
  display: flex;
  background-color: #191f23;
  border-radius: 20px;
}

.title {
  font-size: 18px;
}
.miaoshu {
  font-size: 12px;
  margin: 10px 0px;
  text-align: left;
}
.infomation_btn {
  height: 30px;
  border-radius: 10px;
  margin-top: 10px;
}

.no_btn {
  font-size: 12px;
  margin-top: 10px;
}

.tab {
  color: rgb(20, 136, 244);
}
.tab_b {
  font-weight: bold;
  margin: 10px 0px;
  display: block;
}
</style>