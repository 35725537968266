import { TABLES } from "./Global";

/**
 * 获取IndexedDB数据
 */
IDBObjectStore.prototype.GetValueAsync = function (key) {
    return IndexedDB.GetValueAsync(this, key);
}

/**
 * 添加数据
 */
IDBObjectStore.prototype.AddAsync = function (key, d) {
    return IndexedDB.AddAsync(this, key, d);
};

/**
 * 修改数据
 */
IDBObjectStore.prototype.AddOrUpdateAsync = function (key, data) {
    return IndexedDB.AddOrUpdateAsync(this, key, data);
}

/**
 * 删除数据
 */
IDBObjectStore.prototype.DeleteAsync = function (key) {
    return IndexedDB.DeleteAsync(this, key);
}

/**
 * 表结构
 */
class TableVO {
    public key: IDBValidKey | IDBKeyRange = "";
    public v: any = null;
    constructor(key: IDBValidKey | IDBKeyRange, v: any) {
        this.key = key,
            this.v = v;
    }
}

/**
 * 返回值结构体
 */
class RetVO {
    /**
     * 返回状态
     */
    public flag: boolean = false;
    /**
     * 具体的数据
     */
    public data: any = null;
}

/**
 * 游标的读取方向枚举
 */
enum OpenCursorEnum {
    /**
     * 游标中的数据按主键值升序排列，主键值相等的数据都被读取
     */
    next = "next",
    /**
     * 游标中的数据按主键值升序排列，主键值相等只读取第一条数据
     */
    nextunique = "nextunique",
    /**
     * 游标中的数据按主键值降序排列，主键值相等的数据都被读取
     */
    prev = "prev",
    /**
     * 游标中的数据按主键值降序排列，主键值相等只读取第一条数据
     */
    prevunique = "prevunique"
}

export default class IndexedDB {
    private db: IDBDatabase | null = null;
    private db_name: string = "";
    private version: number = 3;

    /**
     * 
     * @param db_name 数据库名称
     * @param version 版本号
     */
    constructor(db_name: string, version: number = 3) {
        this.db_name = db_name;
        this.version = version;
    }

    /**
     * 获取表内全部数据
     * @param table_name 
     * @returns 
     */
    public GetAllListAsync(table_name: TABLES): Promise<any[]> {
        return new Promise((r) => {
            let v = this.GetStore(table_name)?.getAll();
            if (v) {
                v.onsuccess = (ev) => {
                    let result = (ev.target as any).result;
                    r(result);
                };
                v.onerror = (ev) => {
                    r([] as any[]);
                }
            }
        });
    }

    /**
     * 打开数据库
     */
    public async OpenAsync(...tables: TABLES[]): Promise<boolean> {
        return new Promise((succ_fun, err_fun) => {
            //设置打开数据库超时时间
            let time_out = setTimeout(() => {
                succ_fun(false);
            }, 3000);
            try {
                let request = window.indexedDB.open(this.db_name, this.version);
                request.onsuccess = async (ev) => {
                    this.db = request.result;
                    console.log("数据库打开成功!");
                    clearTimeout(time_out);

                    tables.forEach(store_name => {
                        try {
                            if (!this.db?.objectStoreNames.contains(store_name)) {
                                return this.db?.createObjectStore(store_name, { keyPath: "key", autoIncrement: false }) as IDBObjectStore; //自动生成主键
                            }
                        }
                        catch (e) {

                        }
                    });
                    succ_fun(true);
                };
                request.onblocked = (ev) => { //这是个啥不知道
                    console.log("blocked====");
                };

                request.onupgradeneeded = async (ev) => {
                    console.log("出现数据库升级事件!");
                    this.db = (ev.target as any).result;
                    clearTimeout(time_out);

                    tables.forEach(store_name => {
                        if (!this.db?.objectStoreNames.contains(store_name)) {
                            return this.db?.createObjectStore(store_name, { keyPath: "key", autoIncrement: false }) as IDBObjectStore; //自动生成主键
                        }
                    });
                    succ_fun(true);
                };
                request.onerror = (ev) => {
                    console.log("打开数据库出现了错误!")
                    clearTimeout(time_out);
                    succ_fun(false);
                };
            }
            catch (ex) {
                console.log("打开数据库出现异常!");
                clearTimeout(time_out);
                succ_fun(false);
            }
        });
    }
    /**
     * 获取一个仓库
     * @param store_name 仓库名
     * @param mode readonly 只读 readwrite 读写
     * @returns 
     */
    public GetStore(store_name: TABLES, mode: "readwrite" | "readonly" = "readwrite"): IDBObjectStore | null {
        let tx = this.GetTransaction(store_name, mode);
        if (tx == null)
            return tx;
        return tx.objectStore(store_name);
    }
    /**
     * 获取一个事务
     * @param store_name 仓库名
     * @param mode readonly 只读 readwrite 读写
     * @returns 
     */
    private GetTransaction(store_name: TABLES, mode: "readwrite" | "readonly" = "readwrite") {

        if (this.db) {
            let tx = this.db.transaction(store_name, mode);
            tx.oncomplete = (ev) => {
                console.log("事物结束了!");
            };
            tx.onerror = (ev) => {
                console.log("事物报错了!");
            };
            tx.onabort = (ev) => {
                console.log("事物被终止");
            };
            return tx;
        }
        return null;
    }

    /**
     * 删除数据库
     * @param db_name 
     * @returns 
     */
    public DeleteDB(db_name: string): IDBOpenDBRequest {
        return window.indexedDB.deleteDatabase(db_name);
    }

    /**
     * 删除仓库
     * @param store_name 
     */
    public DeleteStore(store_name: string): void {
        this.db?.deleteObjectStore(store_name);
    }

    /**
     * 清空仓库
     * @param store_name 
     * @returns 
     */
    public ClearStore(store_name: TABLES): IDBRequest<undefined> | null {
        return this?.GetStore(store_name)?.clear() ?? null;
    }

    /**
     * 获取IndexedDB数据
     * @param store 
     * @param query 
     * @returns 
     */
    public static async GetValueAsync(store: IDBObjectStore, key: IDBValidKey | IDBKeyRange): Promise<RetVO> {
        return new Promise<RetVO>((r, e) => {
            let req = store.get(key);
            req.onsuccess = (ev) => {
                if (req.result) {
                    r({
                        flag: true,
                        data: req.result
                    });
                }
                else {
                    r({
                        flag: false,
                        data: req.result
                    });
                }
            }
            req.onerror = (e) => {
                r({
                    flag: false,
                    data: null
                })
            };
        });
    }
    /**
     * 添加数据
     * @param store 
     * @param value 
     */
    public static async AddAsync(store: IDBObjectStore, key: IDBValidKey | IDBKeyRange, data: any): Promise<RetVO> {
        return new Promise<RetVO>(r => {
            try {
                let req = store?.add(new TableVO(key, data));
                req.onsuccess = (ev) => {
                    r({
                        flag: true,
                        data: req.result
                    });
                };
                req.onerror = (ev) => {
                    r({
                        flag: false,
                        data: null
                    });
                };
            }
            catch (ex) {
                r({
                    flag: false,
                    data: null
                });
            }
        });
    }
    /**
     * 修改数据
     * @param store 
     * @param key 
     * @param data 
     * @returns 
     */
    public static async AddOrUpdateAsync(store: IDBObjectStore, key: IDBValidKey | IDBKeyRange, data: any): Promise<RetVO> {
        return new Promise<RetVO>(r => {
            if (store) {
                let req = store?.put(new TableVO(key, data));
                req.onsuccess = (ev) => {
                    r({
                        flag: true,
                        data: req.result
                    });
                };
                req.onerror = (ev) => {
                    r({
                        flag: false,
                        data: null
                    });
                };
            }
            else {
                r({
                    flag: false,
                    data: null
                });
            }
        });
    }
    /**
     * 删除数据
     * @param store 
     * @param key 
     * @param data 
     * @returns 
     */
    public static async DeleteAsync(store: IDBObjectStore, key: IDBValidKey | IDBKeyRange): Promise<RetVO> {
        return new Promise<RetVO>(r => {
            let req = store.delete(key);
            req.onsuccess = (ev) => {
                r({
                    flag: true,
                    data: req.result
                });
            };
            req.onerror = (ev) => {
                r({
                    flag: false,
                    data: null
                });
            };
        });
    }

    /**
     * 获取仓库数据列表
     * @param store 
     * @param start_index 开始位置
     * @param max_count 最大个数
     * @returns 
     */
    public static async GetListAsync(store: IDBObjectStore, start_index: number = 1, max_count: number = 100, openCursor: OpenCursorEnum.next): Promise<RetVO[]> {
        return new Promise<RetVO[]>(r => {
            //计算总量
            var range = IDBKeyRange.bound(start_index, max_count);
            var req = store.openCursor(range, openCursor);
            let list: RetVO[] = [];
            req.onsuccess = (ev) => {
                if (req.result) {
                    list.push({
                        flag: true,
                        data: req.result.value
                    });
                    req.result.continue();
                }
                else {
                    console.log("检索完毕!");
                    r(list);
                }
            };
            req.onerror = (ev) => {
                console.log("获取数据库列表出错!");
                r([]);
            };
        });
    }
}