/**
 * 返回数据结构体
 */
export default class BackDataVO {
    /**
     * 状态
     */
    private state: number | undefined;
    private action: string | undefined;
    private data: any;
    private error_msg: string | undefined;
    private rand_number: string | undefined;
    constructor(data: string | any) {
        let jobj:any = {};
        if (typeof data == "string") {
            jobj = JSON.parse(data);
        }
        else{
            jobj = data;
        }

        this.state = jobj.state;
        this.action = jobj.action;
        this.data = jobj.data;
        this.error_msg = jobj.error_msg;
        this.rand_number = jobj.rand_number;
    }

    /**
     * 获取键
     */
    public get KEY(): string | undefined {
        return this.action;
    }

    /**
     * 获取具体数据
     */
    public get DATA(): any {
        return this.data;
    }

    /**
     * 获取状态
     */
    public get STATUS(): number | undefined {
        return this.state;
    }

    /**
     * 获取当前的唯一编号
     */
    public get RAND_NUMBER(): string | undefined {
        return this.rand_number;
    }

    /**
     * 获取错误消息
     */
    public get ERROR_MESSAGE(): string | undefined {
        return this.error_msg;
    }

    /**
     * 检测数据是否OK
     * @returns 
     */
    public get IsOK(): boolean {
        if (this.state === 1)
            return true;
        return false;
    }
}