<template>
  <div class="star_box">
    <template v-if="star_num > 0">
      <img :key="i" v-for="(v, i) in star_num" :src="imgs[select_index]" />
    </template>
    <template v-else>
      <!-- <img src="image/zuanshi.png" /> -->
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgs: ["image/xing.png", "image/star.png"],
      select_index: 0,
    };
  },
  props: {
    star_num: Number,
    set_select_index: Number,
  },
  methods: {},
  mounted() {
    let index = 0;
    if (this.set_select_index) {
      if (typeof this.set_select_index == "string") {
        index = isNaN(parseInt(this.set_select_index))
          ? 0
          : parseInt(this.set_select_index);
      } else {
        index = this.set_select_index;
      }
    }
    this.select_index = index;
  },
};
</script>
<style scoped>
.star_box {
  display: flex;
  flex-wrap: nowrap;
  height: 80%;
  /* width: 100%; */
  overflow-y: auto;
}
.star_box::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.star_box > img {
  margin-right: 0px;
  height: 100%;
}
</style>