<template>
  <video
    v-if="
      asset_type_hash_map_vo?.assets_thumbnails_type == 1 &&
      AssetsWorksArr.length > 0 &&
      open_auto_show == true
    "
    autoplay
    loop
    :src="
      GetResourceVideo(
        AssetsWorksArr.length > 0 ? AssetsWorksArr[0].file_url : ''
      )
    "
  ></video>
  <img
    v-else-if="asset_type_hash_map_vo?.use_non_homogeneity == 1"
    :src="
      GetResourceIMG(
        asset_vo?.non_homogeneous_assets_thumbnails == null ||
          asset_vo == null ||
          asset_vo?.non_homogeneous_assets_thumbnails == ''
          ? asset_type_hash_map_vo?.assets_thumbnails
          : asset_vo?.non_homogeneous_assets_thumbnails
      )
    "
    alt=""
  />
  <img
    v-else
    :src="GetResourceIMG(asset_type_hash_map_vo?.assets_thumbnails)"
    alt=""
  />
</template>
<script>
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
export default {
  data() {
    return {};
  },
  props: {
    asset_type_hash_map_vo: Object,
    asset_vo: Object,
    pic_mode: String,
    open_auto_show: Boolean,
  },
  computed: {
    AssetsWorksArr() {
      if (
        Tools.IsNotNull(this.asset_type_hash_map_vo?.assets_works_url ?? "")
      ) {
        let arr = JSON.parse(this.asset_type_hash_map_vo?.assets_works_url);
        return arr;
      }
      return [];
    },
  },
  methods: {
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img, this.pic_mode ?? "800");
    },
    GetResourceVideo(video_url) {
      return Global.GetCoinIconURL(video_url);
    },
  },
  mounted() {},
};
</script>