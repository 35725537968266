<template>
  <div class="body">
    <TransferPassword
      v-if="show_transfer_password"
      :nick_name="yan_zheng_user_vo.nick_name"
      :call_back="PasswordCallBack"
    ></TransferPassword>

    <div class="transfer_queren_box" v-if="show_que_ren_shen_fen">
      <div class="transfer_queren_content">
        <span class="transfer_queren_title">请核实对方身份</span>
        <div class="transfer_queren_message">
          <div class="transfer_queren_user">
            <img :src="GetResourceIMG(yan_zheng_user_vo.head_pic)" alt="" />
            <span>{{ yan_zheng_user_vo.nick_name }}</span>
          </div>
          <div class="transfer_queren_btn_box">
            <button class="error_btn" @click="CloseQueRenShenFen">取消</button>
            <button class="success_btn" @click="TryPropTransfer">确认</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 转移窗口 -->
    <div class="prop_transfer_box" v-if="show_prop_transfer_box">
      <div class="prop_transfer_content">
        <div class="prop_transfer_title">
          <span>转赠-{{ select_prop_details.prop_goods_vo?.prop_name }}</span>
          <img         :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')"  @click="ClosePropTransferBox" />
        </div>
        <div class="prop_transfer_content_item">
          <div class="prop_transfer_item">
            <span>转赠目标</span>
            <input
              type="text"
              maxlength="11"
              v-model="to_uid_or_phone"
              placeholder="请输入对方手机号或UID"
            />
          </div>
          <div class="prop_transfer_item">
            <span>转赠数量(库存:{{select_prop_details?.prop_back_pack_vo?.num}})</span>
            <input
              type="number"
              v-model="to_num"
              placeholder="请输入转赠数量!"
            />
          </div>
        </div>
        <div class="prop_transfer_button_box">
          <button class="success_btn to_move_btn" @click="ShowQueRenShenFen">
            转赠
          </button>
        </div>
      </div>
    </div>
    <!-- 转移窗口 -->

    <div class="prop_head">
      <img @click="ClosePropDetails" src="image/back.png" />
      <span>道具详情</span>
    </div>
    <div class="content">
      <div class="top_box">
        <div class="top_box_img">
          <img :src="select_prop_details.prop_goods_vo.prop_img" />
        </div>
      </div>

      <div class="box lie">
        <div class="head">
          <span>{{ select_prop_details.prop_goods_vo?.prop_name }}</span>
        </div>
        <span>{{ GetPropTypeName() }}</span>
      </div>

      <div class="btn_box tab_box row">
        <div class="item to_gray" @click="NoOpen()">
          <img src="image/ui/asset_chain_details/share.png" alt="" />
          <span>分享</span>
        </div>

        <div
          :class="['item', CanShowTransfer() ? '' : 'to_gray']"
          @click="ShowPropTransferBox"
        >
          <img src="image/ui/asset_chain_details/zhuanzeng.png" alt="" />
          <span>转赠</span>
        </div>

        <div class="item to_gray" @click="NoOpen()">
          <img src="image/ui/asset_chain_details/swtx.png" alt="" />
          <span>设为头像</span>
        </div>
        <div class="item to_gray" @click="NoOpen()">
          <img src="image/ui/asset_chain_details/save.png" alt="" />
          <span>保存</span>
        </div>
      </div>

      <div class="tab_box lie">
        <div class="msg_item row">
          <div>
            <span>备</span>
            <span>注</span>
          </div>
          <div class="beizhu_txt">限时道具在最后一天无法转赠!</div>
        </div>
        <template v-if="select_prop_details.prop_goods_vo.prop_type == 2">
          <div
            class="msg_item row input_box"
            :key="i"
            v-for="(v, i) in select_prop_details?.prop_goods_vo
              ?.new_extended_data"
          >
            <span><span class="xinghao">*</span>{{ `${v.name}` }}</span>
            <input
              v-if="v.type == 'text'"
              :maxlength="v.max"
              type="text"
              v-model="v.value"
              :placeholder="v.name"
            />
            <input
              v-if="v.type == 'phone'"
              :maxlength="11"
              type="text"
              v-model="v.value"
              :placeholder="v.name"
            />
          </div>
        </template>
        <div class="msg_item row">
          <div>
            <span>道</span>
            <span>具</span>
            <span>描</span>
            <span>述:</span>
          </div>
          <div
            class="font_left"
            v-html="select_prop_details?.prop_goods_vo?.prop_description"
          ></div>
        </div>

        <div class="msg_item row">
          <div>
            <span>可</span>
            <span>转</span>
            <span>赠:</span>
          </div>
          <div>
            {{ select_prop_details.prop_goods_vo.can_transfer ? "是" : "否" }}
          </div>
        </div>

        <div class="msg_item row">
          <div>
            <span>背</span>
            <span>包</span>
            <span>ID:</span>
          </div>
          <div>
            {{ select_prop_details.prop_back_pack_vo.id }}
          </div>
        </div>

        <div class="msg_item row">
          <div>
            <span>库</span>
            <span>存</span>
          </div>
          <div>
            {{ select_prop_details.prop_back_pack_vo.num }}
          </div>
        </div>

        <div class="msg_item row">
          <div>
            <span>开</span>
            <span>始</span>
            <span>时</span>
            <span>间:</span>
          </div>

          <div class="datetime_box">
            <span>
              {{
                select_prop_details.prop_back_pack_vo.start_time ?? "不限"
              }}</span
            >
          </div>
        </div>
        <div class="msg_item row">
          <div>
            <span>结</span>
            <span>束</span>
            <span>时</span>
            <span>间:</span>
          </div>

          <div class="datetime_box">
            <span>{{
              select_prop_details.prop_goods_vo.end_time ?? "不限"
            }}</span>
          </div>
        </div>

        <div
          class="msg_item row"
          v-if="select_prop_details.prop_back_pack_vo.is_lock == 1"
        >
          <div>
            <span>道</span>
            <span>具</span>
            <span>已</span>
            <span>上</span>
            <span>锁:</span>
          </div>
          <div>
            {{ select_prop_details.prop_back_pack_vo.lock_description }}
          </div>
        </div>

        <div class="msg_item row">
          <div>
            <span>解锁时间</span>
          </div>
          <div>
            {{ select_prop_details.prop_back_pack_vo.unlock_time }}
          </div>
        </div>
      </div>
    </div>
    <div class="button_box">
      <button
        class="success_btn"
        v-if="select_prop_details.prop_goods_vo.prop_type == 1"
        @click="ToApplyPropAsync"
      >
        打开盲盒
      </button>
      <button
        class="success_btn"
        v-if="select_prop_details.prop_goods_vo.prop_type == 2"
        @click="ToApplyPropAsync"
      >
        立即兑换
      </button>
      <button
        class="success_btn"
        v-if="select_prop_details.prop_goods_vo.prop_type == 3"
        @click="ToApplyPropAsync"
      >
        开红包
      </button>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
import LookBaoAssetsImageThumbnail from "@/components/lookbao_components/LookBaoAssetsImageThumbnail.vue";
import { PropTypeEnum } from "@/ts/SuperEnum";
import TransferPassword from "@/components/TransferPasswordComponent.vue";
export default {
  data() {
    return {
      show_transfer_password: false, //转赠密码锁
      yan_zheng_user_vo: {}, //身份认证用户对象
      show_que_ren_shen_fen: false, //确认身份

      show_prop_transfer_box: false, //显示转移道具窗口
      to_uid_or_phone: "",
      to_num: "",
    };
  },
  props: {
    select_prop_details: Object,
    prop_details_close_fun: function() {},
  },
  components: {
    LookBaoAssetsImageThumbnail,
    TransferPassword,
  },
  watch: {
    //      this.to_num = this.select_prop_details.prop_back_pack_vo.num;
    to_num(_new, _old) {
      if (_new > this.select_prop_details.prop_back_pack_vo.num) {
        this.to_num = this.select_prop_details.prop_back_pack_vo.num;
        return;
      }
      if (_new <= 0) {
        this.to_num = "";
        return;
      }
      let vv = parseInt(_new);
      if (isNaN(vv)) {
        vv = "";
      }
      this.to_num = vv;
    },
  },
  methods: {
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },

    /**
     * 关闭确认身份
     */
    CloseQueRenShenFen() {
      this.show_que_ren_shen_fen = false;
    },

    /**
     * 显示转移窗口
     */
    ShowPropTransferBox() {
      if (!this.CanShowTransfer()) return;
      if (this.select_prop_details.prop_back_pack_vo.num <= 0) {
        Tools.AlertError("道具不足,无法转移!");
        this.ClosePropDetails();
        return;
      }
      this.to_uid_or_phone = "";
      
      if (this.select_prop_details.prop_back_pack_vo.num > 0) this.to_num = 1; //this.select_prop_details.prop_back_pack_vo.num;
      this.show_prop_transfer_box = true;
    },
    ClosePropTransferBox() {
      this.show_prop_transfer_box = false;
    },
    /**
     * 确认身份
     */
    async ShowQueRenShenFen() {
      if (!this.CanTransfer()) return;
      if (!this.CanShowTransfer()) return;
      if (!Global.IsRealName) return;
      this.yan_zheng_user_vo = {};
      let res = await Tools.GetHtmlAsync("GetUserVOFromPhoneOrUID", {
        search: this.to_uid_or_phone,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        this.yan_zheng_user_vo = res.data;
        if (this.yan_zheng_user_vo.uid == Global.login_user_vo.uid) {
          Tools.AlertError("不能转赠给自己!");
          return;
        }
        this.ClosePropTransferBox();
        this.show_que_ren_shen_fen = true;
      }
    },

    /**
     * 检测是否可转移
     */
    CanTransfer() {
      
      if (Tools.IsNull(this.to_uid_or_phone)) {
        Tools.AlertError("请输入转账目标!");
        return false;
      }
      let to_num =
        typeof this.to_num == "string" ? parseInt(this.to_num) : this.to_num;
      if (to_num <= 0 || isNaN(to_num)) {
        Tools.AlertError("请输入转账数量!");
        return false;
      }

      if (to_num > this.select_prop_details.prop_back_pack_vo.num) {
        Tools.AlertError("转移数量不足!");
        return false;
      }

      if (!this.select_prop_details.prop_goods_vo.can_transfer) {
        Tools.AlertError("当前道具不允许转赠!");
        return false;
      }

      let now_time = Tools.GetNowTime;
      if (Tools.IsNotNull(this.select_prop_details.prop_goods_vo.end_time)) {
        let end_time = Tools.TimeToLong(
          this.select_prop_details.prop_goods_vo.end_time
        );
        if (end_time <= now_time) {
          Tools.AlertError("道具已过期!");
          return false;
        }
        if (end_time - now_time <= 24 * 60 * 60 * 1000) {
          Tools.AlertError("道具已不足24小时过期,已无法转移!");
          return false;
        }
      }

      return true;
    },
    /**
     * 是否可显示转赠按钮
     */
    CanShowTransfer() {
      if (this.select_prop_details.prop_back_pack_vo.num <= 0) {
        return false;
      }

      if (!this.select_prop_details.prop_goods_vo.can_transfer) {
        return false;
      }

      let now_time = Tools.GetNowTime;
      if (Tools.IsNotNull(this.select_prop_details.prop_goods_vo.end_time)) {
        let end_time = Tools.TimeToLong(
          this.select_prop_details.prop_goods_vo.end_time
        );
        if (end_time <= now_time) {
          return false;
        }

        if (end_time - now_time <= 24 * 60 * 60 * 1000) return false;
      }

      return true;
    },

    /**
     * 输入密码回调
     */
    async PasswordCallBack(pwd) {
      let res = await Tools.GetHtmlAsync("PayUnlock", { pwd: pwd });
      if (Tools.CheckHtmlIsOK(res)) {
        this.PropTransferAsync();
      } else {
        this.TryPropTransfer();
      }
    },

    /**
     * 尝试转移
     */
    async TryPropTransfer() {
      this.CloseQueRenShenFen();
      if (!this.CanTransfer()) return;
      if (!Global.IsRealName) return;

      //检测是否存在密码
      let res = await Tools.GetHtmlAsync("CheckHavePayPassword", {});
      if (Tools.CheckHtmlIsOK(res)) {
        let d = res.data;
        let must_to_unlock = d.must_to_unlock;
        let have_pay_pwd = d.have_pay_pwd;
        if (have_pay_pwd) {
          //如果存在密码,并且必须解锁
          //进行解锁
          if (must_to_unlock) {
            this.$nextTick(() => {
              this.show_transfer_password = true;
            });
          } else {
            //不需要解锁直接转移
            this.PropTransferAsync();
          }
        } else {
          //跳转到密码设置界面
          Global.ShowSetTransferPassword(() => {
            this.PropTransferAsync();
          });
        }
      }
    },

    /**
     * 转移道具
     */
    async PropTransferAsync() {
      if (!this.CanTransfer()) return;
      if (!Global.IsRealName) return;

      if (this.select_prop_details.prop_back_pack_vo.num <= 0) {
        Tools.AlertError("道具不足,无法转移!");
        this.ClosePropDetails();
        return;
      }
      let res = await Tools.GetHtmlAsync("PropTransfer", {
        id: this.select_prop_details.prop_back_pack_vo.id,
        num: this.to_num,
        to_uid_or_phone: this.to_uid_or_phone,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        Tools.AlertSuccess("转移成功!");
        this.ClosePropDetails();
      }
    },
    GetCanClick() {
      // let prop_back_pack_vo = this.select_prop_details.prop_back_pack_vo;
      // let prop_goods_vo = this.select_prop_details.prop_goods_vo;
      // if(Tools.IsNotNull(prop_goods_vo.end_time)){
      // }
    },
    NoOpen(v) {
      if (Tools.IsNotNull(v)) {
        Tools.AlertError(v);
      } else {
        Tools.AlertError("暂未开放!");
      }
    },
    GetPropTypeName() {
      return PropTypeEnum[this.select_prop_details.prop_goods_vo.prop_type];
    },
    GetExtendedData() {
      let v = this.select_prop_details;
      let extended_data = v.prop_goods_vo.extended_data;
      if (Tools.IsNotNull(extended_data)) {
        v.prop_goods_vo.new_extended_data = JSON.parse(extended_data);
      } else {
        v.prop_goods_vo.new_extended_data = {};
      }
    },
    /**
     * 使用道具
     */
    async ToApplyPropAsync() {
      Global.MessageBox("系统提示!", "您确定要使用此道具么?", async () => {
        let prop_back_pack_vo = this.select_prop_details.prop_back_pack_vo;
        let prop_goods_vo = this.select_prop_details.prop_goods_vo;
        let v = await Global.ToApplyPropAsync(
          prop_back_pack_vo.id,
          prop_goods_vo.new_extended_data
        );
        if (v.flag) {
          switch (v.prop_type) {
            case PropTypeEnum.盲盒道具:
              let d = v.d;
              let title = d.title;
              let img_url = d.img_url;
              let asset_type = d.asset_type;
              Global.ShowPrizePromptWindow(
                title,
                img_url,
                `请到${asset_type == 0 ? "我的藏品" : "道具背包"}查看!`
              );
              break;
            default:
              Tools.AlertSuccess(v.msg);
              break;
          }
          this.ClosePropDetails();
        }
      });
    },
    ClosePropDetails() {
      this.prop_details_close_fun && this.prop_details_close_fun();
      Global.ClosePropDetails();
    },
  },
  created() {},
  mounted() {
    this.GetExtendedData();
  },
};
</script>
<style lang="less" scoped>
.body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  background-color: @c000000_F0F1F5;
  .prop_head {
    height: 36px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    img {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 10px;
    }
  }

  .button_box {
    width: 100%;
    // margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    & > button {
      width: 100%;
      height: 35px;
      border-radius: 10px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  padding: 0px 15px;
  width: 100%;
  height: 100%;

  & > * {
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .top_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .top_box_img {
      width: 100%;
      height: calc(100vw - 30px);
      img,
      video {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}

.box {
  margin: 0px 15px;
  padding: 15px;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: @c191F24_ffffff;
  border-radius: 6px;
  height: 70px;
  margin: 10px 0px;
  & > span:nth-of-type(1) {
    background-color: blueviolet;
    position: absolute;
    right: 0px;
    top: 0px;
    // width: 60px;
    height: 24px;
    padding: 2px 10px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    // line-height: 24px;
    color: white;
  }
}

.head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  & > span:nth-of-type(2) {
    color: #ff7100;
    display: inline-block;
  }
}

.row {
  display: flex;
}
.lie {
  display: flex;
  flex-direction: column;
}

.tab_box {
  background-color: @c191F24_ffffff;
  margin: 0px 15px;
  padding: 15px;
  border-radius: 6px;
  margin-top: 20px;
}

.font_left {
  text-align: left;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  img {
    width: 44px !important;
    height: 44px !important;
  }
  span {
    display: inline-block;
    white-space: nowrap;
    box-sizing: border-box;
    font-size: 14px;
  }
}

.btn_box {
  display: flex;
  justify-content: space-around;
  margin-top: 0px !important;
}

.msg_item {
  font-size: 14px;
  margin-bottom: 15px;
  & > div:nth-of-type(1) {
    width: 56px;
    color: @FFFFFF_c040506;
    margin-right: 15px;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }
  & > div:nth-of-type(2) {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.input_box {
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 6px;
  input {
    height: 30px;
    font-size: 12px;
  }
  .xinghao {
    color: red;
  }
}

.datetime_box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

// 转移窗口
.prop_transfer_box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;

  .prop_transfer_content {
    background-color: @c191F24_ffffff;
    width: 65%;
    padding: 10px;
    border-radius: 10px;
    .prop_transfer_title {
      display: flex;
      font-size: 16px;
      justify-content: center;
      margin-bottom: 10px;
      position: relative;
      img {
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }

    .prop_transfer_content_item {
      display: flex;
      flex-direction: column;
      .prop_transfer_item {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-bottom: 10px;
        input {
          border: 0px;
          height: 30px;
          text-align: center;
          border-radius: 10px;
        }
      }
    }
  }

  .prop_transfer_button_box {
    .to_move_btn {
      border-radius: 10px;
      width: 100%;
      height: 30px;
    }
  }
}
// 转移窗口

/* 转赠提示框 */
.transfer_queren_box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.transfer_queren_title {
  display: flex;
  justify-content: center;
}

.transfer_queren_content {
  background-color: #191f23;
  display: flex;
  flex-direction: column;
  width: 85%;
  padding: 20px;
  border-radius: 15px;
}

.transfer_queren_user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 14px;
}
.transfer_queren_user > img {
  width: 60px;
  height: 60px;
  border-radius: 6px;
}
.transfer_queren_user > span {
  margin: 5px 0px;
}

.transfer_queren_btn_box {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.transfer_queren_btn_box > button {
  width: 30%;
  /* height: 20px; */
  font-size: 14px;
  border-radius: 20px;
}
/* 转赠提示框结束 */

.beizhu_txt {
  color: #ffb800;
  font-weight: bold;
}
</style>