<!-- 玲珑塔信息框显示爆率，进入章节 -->
<template>
  <div class="llt_msg_box">
    <div class="llt_msg_content_box">
      <img
        class="close_btn"
        @click="CloseLLTMessageBox"
        src="~@/assets/image/ui/linglongta/x.png"
        alt=""
      />

      <div
        v-if="show_top"
        class="top_content"
        :style="`background-image: url(${chapter_floor_vo?.start_floor_bg_img});`"
      >
        <span class="top_title"
          >第{{ chapter_floor_vo?.floor_num }}章
          <span>{{ chapter_floor_vo?.floor_name }}</span></span
        >
        <button @click="OpenChapter" class="warning_btn">进入章节</button>
      </div>

      <div class="llt_msg_content">
        <div class="llt_msg_tab">
          <span>合成材料</span>
        </div>
        <div class="llt_msg_lattice_box">
          <div
            class="lattice_item"
            :key="i"
            v-for="(v, i) in chapter_floor_lattice_list"
          >
            <img
              v-if="v.asset_type == 1"
              :src="
                GetResourceIMG(v?.asset_type_hash_map_vo?.assets_thumbnails)
              "
            />
            <img v-if="v.asset_type == 2" :src="v?.prop_goods_vo?.prop_img" />

            <span class="asset_title_name" v-if="v.asset_type == 1">{{
              v?.asset_type_hash_map_vo?.assets_title
            }}</span>
            <span class="asset_title_name" v-if="v.asset_type == 2">{{
              v?.prop_goods_vo?.prop_name
            }}</span>
          </div>
        </div>
        <div class="llt_msg_tab">
          <span>可合成物品</span>
        </div>
        <div class="llt_gl_list_box">
          <div class="item">
            <div>
              <span>编号</span>
            </div>
            <div>
              <span>物品</span>
            </div>
            <div>
              <span>名称</span>
            </div>
            <!-- <div>
              <span>概率</span>
            </div> -->
            <div>
              <span>类型</span>
            </div>
          </div>

          <div
            class="item"
            :key="i"
            v-for="(v, i) in chapter_floor_probability_list"
          >
            <div>
              <span>{{ i + 1 }}</span>
            </div>
            <div>
              <img
                v-if="v.asset_type == 1"
                :src="GetResourceIMG(v?.asset_hash_map_vo?.assets_thumbnails)"
                alt=""
              />
              <img
                v-if="v.asset_type == 2"
                :src="v?.prop_goods_vo?.prop_img"
                alt=""
              />
            </div>
            <div>
              <span>{{
                v.asset_type == 1
                  ? v?.asset_hash_map_vo?.assets_title
                  : v?.prop_goods_vo?.prop_name
              }}</span>
            </div>
            <!-- <div>
              <span
                >{{ ((v?.default_probability ?? 0) * 100).toFixed(2) }}%</span
              >
            </div> -->
            <div>
              <span>{{ v?.asset_type == 1 ? "藏品" : "道具" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
export default {
  data() {
    return {
      my_floor_num: 0, //当前所在章
      is_finish: false, //是否完成了本章
      server_can_compose: false, //服务器检测是否可合成
      chapter_vo: null, //章节对象
      chapter_floor_vo: null, //章节层对象
      //关联通关凭证数据
      credential_vo: null, //通关凭证
      //格子相关列表
      chapter_floor_lattice_list: [], //格子数量
      //爆率相关列表
      chapter_floor_probability_list: [], //爆率数据
    };
  },
  props: {
    chapter_id: Number,
    floor_num: Number,
    show_top: Boolean, //是否显示顶部导航图
  },
  methods: {
    GetResourceIMG(url) {
      return Global.GetCoinIconURL(url);
    },
    CloseLLTMessageBox() {
      Global.CloseLLTMessageBox();
    },
    OpenChapter() {
      this.CloseLLTMessageBox();
      Tools.GoTo(`/llt_compose`, {
        id: this.chapter_id,
        floor_num: this.chapter_floor_vo.floor_num,
      });
    },
    async GetChapterFloorDataFromFloorNumAsync() {
      let res = await Tools.GetHtmlAsync("GetChapterFloorDataFromFloorNum", {
        chapter_id: this.chapter_id,
        floor_num: this.floor_num,
      });
      if (Tools.CheckHtmlIsOK(res, false)) {
        let d = res.data;
        this.chapter_vo = d.chapter_vo;
        this.chapter_floor_vo = d.chapter_floor_vo;
        this.server_can_compose = d.server_can_compose;
        this.credential_vo = d.credential_vo; //通关凭证
        this.is_finish = d.is_finish;
        this.my_floor_num = d.my_floor_num;
        this.chapter_floor_lattice_list = d.chapter_floor_lattice_list;
        this.chapter_floor_probability_list = d.chapter_floor_probability_list;
      } else {
        this.CloseLLTMessageBox();
      }
    },
  },
  mounted() {
    this.GetChapterFloorDataFromFloorNumAsync();
  },
};
</script>
<style scoped lang="less">
.llt_msg_box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
  .llt_msg_content_box {
    background-color: #252933;
    width: calc(100% - 60px);
    border-radius: 10px;

    max-height: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .top_content {
      min-height: 30vw;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center auto;
      flex-direction: column;
      align-items: center;
      text-shadow: 0 0 2px #000000, 0 0 2px #000000;
      & > .top_title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
      }

      img {
        width: 100%;
        height: 100%;
      }
      button {
        width: 65%;
        text-shadow: 0 0 2px #000000, 0 0 2px #000000;
        height: 35px;
        border-radius: 20px;
      }
    }

    .close_btn {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 100;
    }

    .llt_msg_content {
      padding: 0px 20px 20px 20px;
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: @c303339_ffffff;
      .llt_msg_lattice_box {
        display: flex;
        flex-wrap: wrap;
        .lattice_item {
          overflow: hidden;
          width: 25%;
          img {
            width: 60px;
            height: 60px;
            border-radius: 6px;
          }
        }
      }

      .llt_msg_tab {
        margin: 10px 0px;
        span {
          font-size: 16px;
          font-weight: bold;
        }
      }

      .llt_gl_list_box {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        .item {
          display: flex;
          height: 55px;
          font-size: 12px;
          white-space: nowrap;
          div {
            width: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-width: 51px;
            }
          }
        }
      }
    }
  }
}

.asset_title_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>