import { CurrencySystemIdEnum } from "@/ts/SuperEnum";
import LookBaoWalletServer from "../LookBaoWallet/LookBaoWalletServer";
import I_WalletServer from "../WalletInterface/I_WalletServer";
import AddressVO from "./AddressVO";

/**
 * 币种体系结构体
 */
export default class CurrencySystemAddressVO {
    /**
     * 钱包服务对象
     */
    public wallet_server: I_WalletServer | null = null;
    /**
     * 币种体系ID
     */
    public currency_type_id: CurrencySystemIdEnum = CurrencySystemIdEnum.LookBao;
    /**
     * 地址
     */
    public address: AddressVO;
    public privateKey: string = "";
    public publicKey: string = "";
    public currency_type_name: string = "";
    /**
     * 合约地址列表
     */
    public contract_address_list: AddressVO[] = [];

    /**
     * 重写ToJson
     * @returns 
     */
    toJSON() {
        let v = {
            "currency_type_id": this.currency_type_id,
            "address": this.address,
            "privateKey": this.privateKey,
            "publicKey": this.publicKey,
            "currency_type_name": this.currency_type_name,
            "contract_address_list": this.contract_address_list,
        };
        return v;
    }

    constructor(currency_type_id: CurrencySystemIdEnum, address: AddressVO, privateKey: string, publicKey: string) {
        this.currency_type_id = currency_type_id;
        this.address = address;
        this.privateKey = privateKey;
        this.publicKey = publicKey;
        this.currency_type_name = CurrencySystemIdEnum[currency_type_id];
    }

    public async initAsync() {
        
        switch (this.currency_type_id) {
            case CurrencySystemIdEnum.LookBao:
                this.wallet_server = await (new LookBaoWalletServer()).InitAsync();
                
                
                break
            default:
                // this.wallet_server = new LookBaoWalletServer();
                break;
        }
    }
}