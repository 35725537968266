<template>
  <div class="lottery_results_box">
    <div class="lottery_results_content">
      <span>恭喜获得</span>
      <div class="lie lottery_results_img_box">
        <img
          class="lottery_results_img"
          :src="GetResourceIMG(prize_prompt_obj?.img_url)"
        />
        <span>{{ prize_prompt_obj?.title }}</span>
        <span class="sm">{{ prize_prompt_obj?.describe }}</span>
      </div>
      <button class="lottery_results_queding_btn success_btn" @click="CloseMe">
        知道了
      </button>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
export default {
  data() {},
  props: {
    prize_prompt_obj: Object,
  },
  methods: {
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    CloseMe() {
      Global.ClosePrizePromptWindow();
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
/* 抽奖结果 */
.lottery_results_box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 600;
}
.lottery_results_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 80%; */
  background-color: #191f23;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
}
.lottery_results_img_box {
  align-items: center;
  position: relative;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.lottery_results_img {
  width: 180px;
  height: 180px;
  border-radius: 10px;
}
.lottery_results_queding_btn {
  border-radius: 15px;
  margin-top: 15px;
  width: 80%;
}
.lottery_results_img_box_title {
  text-align: left;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.star_box {
  height: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
}
/* 抽奖结果 */

/* 抽奖记录 */
.luck_draw_record_box {
  display: flex;
  flex-direction: column;
}
.luck_draw_record_head {
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 10px 0px;
}
.luck_draw_record_head > div:nth-child(1) {
  font-size: 24px;
}
.luck_draw_record_head > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.luck_draw_record_content {
}
.luck_draw_record_item {
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
  /* background-color: #191F23; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  /* margin-bottom: 10px; */
}

.luck_draw_record_item > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.luck_draw_record_item > div:nth-child(1) > span:nth-child(1) {
  margin-bottom: 5px;
}
.you_icon {
  width: 25px;
  height: 25px;
}
/* 抽奖记录 */
</style>
