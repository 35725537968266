import BigNumber from "bignumber.js";
import { TransferTypeEnum } from "../../SuperEnum";
import Tools from "../../Tools";
import I_WalletContractServer from "../WalletInterface/I_WalletContractServer";
import I_WalletServer from "../WalletInterface/I_WalletServer";
import LookBaoWalletServer from "./LookBaoWalletServer";

export default class LookBaoContract implements I_WalletContractServer {
    private wallet: LookBaoWalletServer;
    private myContract: any;
    /**
     * 合约描述代码
     */
    private abi_json: string = "";
    /**
     * 合约地址
     */
    private contract_address: string = "";

    constructor(wallet: LookBaoWalletServer | I_WalletServer, abi_json: string, contract_address: string) {
        this.wallet = wallet as LookBaoWalletServer;
        let abi = {};
        if (Tools.IsJson(abi_json)) {
            abi = JSON.parse(abi_json);
        }
        this.abi_json = abi_json;
        this.contract_address = contract_address;
        try {
            this.myContract = new this.wallet.web3.eth.Contract(abi, contract_address);
        }
        catch (e) {

            console.log(e);
        }
    }

    /**
     * 合约模型调用
     * @param contract_obj 合约对象
     */
    private ContractMethods() {
        return this.myContract.methods;
    }

    /**
     * 总发放金额
     */
    public async TotalSupplyAsync(): Promise<number> {
        return this.ContractMethods().totalSupply().call();
    }

    /**
     * 获取合约地址名
     */
    public async GetFullNameAsync(): Promise<string> {
        return this.ContractMethods().name().call();
    }

    /**
     * 获取简称
     */
    public async GetSymbolNameAsync(): Promise<string> {
        return this.ContractMethods().symbol().call();
    }

    /**
     * 获取位数
     */
    public async GetDecimalsAsync(): Promise<number> {
        return this.ContractMethods().decimals().call();
    }

    /**
     * 获取余额
     * @param address 
     */
    public async BalanceOf(address: string): Promise<number> {
        return this.ContractMethods().balanceOf(address).call();
    }

    /**
     * 发送合约交易
     * @param from_address 发送地址
     * @param from_private_key 发送人key
     * @param to_address 发送到地址
     * @param amount 发送金额
     * @param extend_data 扩展数据
     */
    async SendTransferAsync(from_address: string, from_private_key: string, to_address: string, amount: number, extend_data: {
        transfer_type: TransferTypeEnum,
        transfer_data: any,
    }, gas: number = 21000, gasPrice: number = 1000000000, gasLimit: number = 21000): Promise<any> {

        var x = new BigNumber(amount);
        
        var transfer = this.ContractMethods().transfer(to_address, x.toFixed());
        var encodedABI = transfer.encodeABI();
        let hash = await this.wallet?.SendSignedTransactionAsync(
            from_address,
            this.contract_address,
            from_private_key, x.toFixed(), gas, gasPrice, gasLimit, encodedABI, true, extend_data);
        await Tools.GetHtmlAsync("AddUpperChainStructureTask", {
            from_address: from_address,
            to_address: to_address,
            amount: amount / (10 ** 18),
            contract_address: this.contract_address,
            hash: hash
        });
        return hash;
    }
}