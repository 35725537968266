<template>
  <div class="global_loading">
    <a-spin :tip="global_loading_msg"></a-spin>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    global_loading_msg: String,
  },
};
</script>
<style scoped>
.global_loading {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 20000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>