<template>
  <div class="select_coin_body">
    <div class="select_coin_content">
      <span class="close_select_coin" @click="CloseMe">X</span>
      <div class="select_coin_head_content">
        <span v-if="show_input_pwd">请输入支付密码</span>
        <span
          class="nick_name"
          v-if="custom_title == '' || custom_title == null"
          >向
          {{ nick_name }}
          转赠</span
        >
        <span class="transfer_pwd_input_tab" v-else>
          {{ custom_title }}
        </span>
      </div>

      <div v-if="show_input_pwd" class="input_pwd_box">
        <div :key="index" v-for="(v, index) in pwd_input_arr" class="pwd_input">
          <span v-if="v">•</span>
        </div>
      </div>

      <button
        class="success_btn qrzf_btn"
        @click="ShowInputPwdAsync"
        v-if="!show_input_pwd"
      >
        {{
          custom_buttom_text == "" || custom_buttom_text == null
            ? "确认支付"
            : custom_buttom_text
        }}
      </button>
    </div>

    <div class="input_password_keyboard" v-if="show_input_pwd">
      <button @click="InputPwdAsync('1')">1</button>
      <button @click="InputPwdAsync('2')">2</button>
      <button @click="InputPwdAsync('3')">3</button>
      <button @click="InputPwdAsync('4')">4</button>
      <button @click="InputPwdAsync('5')">5</button>
      <button @click="InputPwdAsync('6')">6</button>
      <button @click="InputPwdAsync('7')">7</button>
      <button @click="InputPwdAsync('8')">8</button>
      <button @click="InputPwdAsync('9')">9</button>
      <button class="input_pwd_other_color_btn"></button>
      <button @click="InputPwdAsync('0')">0</button>
      <button
        style="background-image: url('image/tuige.png')"
        @click="InputPwdAsync('back')"
        class="input_pwd_other_color_btn"
      ></button>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
export default {
  data() {
    return {
      show_input_pwd: true,
      pwd_input_arr: ["", "", "", "", "", ""],
    };
  },
  props: {
    nick_name: String, //目标昵称
    //自定义标题
    custom_title: String, //自定义标题
    //自定义按钮内容
    custom_buttom_text: String, //自定义按钮内容
    call_back: function(pwd) {}, //回调函数
    close_callback:function(){}, //取消(点了关闭了)
  },
  methods: {
    CloseMe() {
      this.close_callback && this.close_callback();
      this.$parent.show_transfer_password = false;
    },
    async InputPwdAsync(v) {
      switch (v) {
        case "back":
          for (let i = this.pwd_input_arr.length - 1; i >= 0; i--) {
            const pwd_v = this.pwd_input_arr[i];
            if (pwd_v != "") {
              this.pwd_input_arr[i] = "";
              break;
            }
          }
          break;
        default:
          for (let i = 0; i < this.pwd_input_arr.length; i++) {
            const pwd_v = this.pwd_input_arr[i];
            if (pwd_v == "") {
              this.pwd_input_arr[i] = v;
              break;
            }
          }
          break;
      }
      let is_full = true;
      for (let i = 0; i < this.pwd_input_arr.length; i++) {
        const pwd_v = this.pwd_input_arr[i];
        if (pwd_v == "") {
          is_full = false;
          this.input_pwd = "";
          break;
        } else {
          this.input_pwd += pwd_v;
        }
      }
      if (is_full) {
        this.call_back && this.call_back(this.input_pwd);
        this.ClearInputPwd();
        this.CloseMe();
      }
    },
    /**
     * 清空密码
     */
    ClearInputPwd() {
      this.input_pwd = "";
      for (let i = 0; i < this.pwd_input_arr.length; i++) {
        this.pwd_input_arr[i] = "";
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="less">
.transfer_account_body {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgb(24, 24, 24);
  color: white;
  z-index: 3000;
}

.transfer_account_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.input_password_keyboard {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: rgb(61, 61, 61);
}
.input_password_keyboard > button {
  background-color: @bottom_class;
  flex: 1 1 30%;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
  height: 50px;
}

.input_pwd_other_color_btn {
  background-color: @zhifumima_bgc !important;
  background-position: center center;
  background-clip: border-box;
  background-size: auto 50%;
  background-repeat: no-repeat;
}

.head_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
}

.head_img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.nick_name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}

.jian_pan_box {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  bottom: 0px;
  height: 212px;
}
button {
  box-sizing: border-box;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  margin-bottom: 3px;
  border: 0px;
  font-size: 22px;
  font-weight: bold;
  border-radius: 10px;
  box-sizing: border-box;
}
.left > button {
  background-color: #191f23;
}

.left {
  width: 80%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 200px;
  margin-right: 2px;
}
.left > button {
  width: 30%;
  height: 50px;
  /* border: 0px; */
  flex: 1 0 30%;
  margin-left: 1px;
}

.right {
  width: 20%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.right > button {
  widows: 20%;
  height: 50px;
}

.d_width {
  width: 66% !important;
}
.d_height {
  height: 80% !important;
}
.transer_box {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  border-top: 1px solid rgb(26, 26, 26);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #191f23;
  height: 100%;
}
.transer_box input {
  width: 100%;
  height: 40px;
  /* border-radius: 10px; */
  /* border: 1px solid rgb(245, 245, 245); */
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.transer_box > span {
  font-size: 12px;
}

.input_box {
  display: flex;
  margin: 10px 0px;
  letter-spacing: 2px;
}

.input_box > span {
  font-size: 40px;
  /* font-weight: bold; */
  line-height: 40px;
  margin-right: 10px;
}

.input_box > input {
  border-radius: 10px;
  color: white !important;
  text-align: center;
}

.payment_method_body,
.select_coin_body {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: @home_bgc_class;
  z-index: 900;
  /* justify-content: center; */
  align-items: center;
}

.select_coin_content {
  width: 80%;
  padding: 30px 20px 10px 20px;
  background-color: @bottom_class;
  border-radius: 20px;
  position: relative;
  margin-top: 50%;
}

.show_payment_content {
  min-width: 80%;
  padding: 30px 20px 10px 20px;
  background-color: #191f23;
  border-radius: 20px;
  position: relative;
  margin-top: 35%;
}

.show_payment_content .back_btn {
  position: absolute;
  left: 15px;
  top: 15px;
}
.show_payment_content .title {
  font-size: 18px;
  /* font-weight: bold; */
}

.select_coin_head_content {
  display: flex;
  flex-direction: column;
}
.select_coin_head_content > * {
  margin-bottom: 5px;
}

.select_coin_head_content > div > .amount {
  font-size: 20px;
  font-weight: bold;
}
.coin_amount_box {
  font-size: 20px;
}

.select_coin_head_content > .nick_name {
  font-weight: 500;
}

.select_coin_zffs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
  white-space: nowrap;
}

.select_coin_zffs .icon {
  margin-right: 5px;
}

.select_coin_zffs > div {
  display: flex;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
}

.qrzf_btn {
  width: 70%;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
}

.close_select_coin {
  position: absolute;
  left: 15px;
  top: 15px;
}

.payment_method_list {
  margin-top: 10px;
}
.payment_item {
  font-size: 14px;
  padding: 10px 0px;
  border-top: 1px solid rgb(245, 245, 245);
  display: flex;
  justify-items: center;
  white-space: nowrap;
}

.payment_item > *:nth-child(1) {
  margin-right: 5px;
}

.payment_item .amount {
  margin-right: auto;
  font-weight: bold;
}

.back_btn {
  position: fixed;
  left: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
}
.active_coin {
  border-radius: 50%;
}

.danwei {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}
.you_icon {
  width: 15px;
  height: 15px;
}
.select_coin_zffs_row {
  display: flex;
  align-items: center;
}

.input_pwd_box {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 40px;
  margin-top: 10px;
}

.pwd_input {
  display: flex;
  width: 15%;
  background-color: @home_bgc_class;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
}

.ling_qian_tab {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.transfer_pwd_input_tab {
  color: @ffffff_324460;
  font-size: 12px;
}
</style>