<template>
  <div class="transfer_account_body">
    <!-- 支付方式选择 -->
    <div class="payment_method_body" v-if="show_payment_method">
      <div class="show_payment_content">
        <div>
          <my-image
            class="back_btn icon"
            src="image/back.png"
            @click="ClosePaymentMethod"
          />
          <span class="title">选择支付方式 </span>
        </div>
        <div class="payment_method_list">
          <div class="payment_item" @click="SelectCoinIndex(-1)">
            <my-image class="icon" src="image/ling_qian_icon.png" />
            <span class="amount"
              >{{ this.my_user_vo.balance
              }}<span class="ling_qian_tab">(零钱)</span></span
            >
            <my-image
              v-if="-1 === select_coin_index"
              class="icon active_coin"
              src="image/gou_xuan.png"
              alt=""
            />
          </div>

          <div class="payment_item" @click="SelectCoinIndex(-2)">
            <my-image
              class="icon"
              :src="require('@/assets/image/wode/miu2.png')"
            />
            <span class="amount"
              >{{ this.my_user_vo.miu_amount
              }}<span class="ling_qian_tab">({{host_platform_vo?.main_integral_alias}})</span></span
            >
            <my-image
              v-if="-2 === select_coin_index"
              class="icon active_coin"
              src="image/gou_xuan.png"
              alt=""
            />
          </div>

          <div
            class="payment_item"
            :key="index"
            v-for="(item, index) in coin_list"
            @click="SelectCoinIndex(index)"
          >
            <my-image class="icon" :src="item.coin_img" />
            <!-- <span>{{ item.coin_alias }}</span> -->
            <span class="amount">
              {{ item.amount }} {{ item.coin_symbol }}</span
            >
            <my-image
              v-if="index == select_coin_index"
              class="icon active_coin"
              src="image/gou_xuan.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 支付方式选择结束 -->

    <!-- 支付选项窗口 -->
    <div class="select_coin_body" v-if="show_select_coin_body">
      <div class="select_coin_content">
        <span class="close_select_coin" @click="CloseSelectCoinBody">X</span>
        <div class="select_coin_head_content">
          <span v-if="show_input_pwd">请输入支付密码</span>
          <span class="nick_name"
            >向
            {{ is_payment ? app_vo.app_name : user_vo?.nick_name }}
            转账</span
          >
          <div class="coin_amount_box">
            <template v-if="select_coin_index == -1">
              <span class="danwei">{{host_platform_vo?.main_integral_alias}}</span>
              <span class="amount">{{ input_number }}</span>
            </template>
            <template v-else-if="select_coin_index == -2">
              <span class="amount">{{ input_number }}</span>
              <span class="danwei">{{ host_platform_vo?.main_integral_alias }}</span>
            </template>
            <template v-else>
              <span>฿ </span>
              <span class="amount">{{ input_number }}</span>
              <span class="danwei">&nbsp;{{host_platform_vo?.main_integral_alias}}</span>
            </template>
          </div>
        </div>
        <div class="select_coin_zffs">
          <span>支付方式</span>
          <div @click="ShowPaymentMethod" class="select_coin_zffs_row">
            <my-image class="icon" :src="GetSelectCoin.coin_img" alt="" />
            <template v-if="select_coin_index != -1">
              <span
                >{{ GetSelectCoin.amount }}{{host_platform_vo?.main_integral_alias}}</span
              >
            </template>
            <template v-else>
              <span>{{ GetSelectCoin.amount }}</span>
            </template>

            <my-image class="you_icon" src="image/you.png" />
          </div>
        </div>

        <div v-if="show_input_pwd" class="input_pwd_box">
          <div
            :key="index"
            v-for="(v, index) in pwd_input_arr"
            class="pwd_input"
          >
            <span v-if="v">•</span>
          </div>
        </div>

        <button
          class="success_btn qrzf_btn"
          @click="ShowInputPwdAsync"
          v-if="!show_input_pwd"
        >
          确认支付
        </button>
      </div>

      <div class="input_password_keyboard" v-if="show_input_pwd">
        <button @click="InputPwdAsync('1')">1</button>
        <button @click="InputPwdAsync('2')">2</button>
        <button @click="InputPwdAsync('3')">3</button>
        <button @click="InputPwdAsync('4')">4</button>
        <button @click="InputPwdAsync('5')">5</button>
        <button @click="InputPwdAsync('6')">6</button>
        <button @click="InputPwdAsync('7')">7</button>
        <button @click="InputPwdAsync('8')">8</button>
        <button @click="InputPwdAsync('9')">9</button>
        <button class="input_pwd_other_color_btn"></button>
        <button @click="InputPwdAsync('0')">0</button>
        <button
          style="background-image: url('image/tuige.png')"
          @click="InputPwdAsync('back')"
          class="input_pwd_other_color_btn"
        ></button>
      </div>
    </div>

    <!-- 支付选项窗口 -->

    <div class="transfer_account_content">
      <my-image @click="GoBack" class="icon back_btn" src="image/back.png" />
      <div class="head_box">
        <my-image
          :src="GetCoinIconURL(is_payment ? app_vo.icon : user_vo?.head_pic)"
          class="head_img"
        />
        <span class="nick_name">{{
          is_payment ? app_vo.app_name : user_vo?.nick_name
        }}</span>
      </div>

      <div class="transer_box">
        <span>转账金额</span>
        <div class="input_box">
          <!-- <span>฿</span> -->
          <input type="text" v-model="input_number" disabled />
        </div>
      </div>

      <div class="jian_pan_box">
        <div class="left">
          <button @click="InputNumber('1')">1</button>
          <button @click="InputNumber('2')">2</button>
          <button @click="InputNumber('3')">3</button>
          <button @click="InputNumber('4')">4</button>
          <button @click="InputNumber('5')">5</button>
          <button @click="InputNumber('6')">6</button>
          <button @click="InputNumber('7')">7</button>
          <button @click="InputNumber('8')">8</button>
          <button @click="InputNumber('9')">9</button>
          <button @click="InputNumber('0')" class="d_width">0</button>
          <button @click="InputNumber('.')">.</button>
        </div>
        <div class="right">
          <button class="warning_btn" @click="InputNumber('del')">删除</button>
          <button @click="InputNumber('send')" class="success_btn d_height">
            转账
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import AccountManagement from "@/ts/blockchain/AccountManagement";
import CoinTypeManager from "@/ts/blockchain/CoinTypeManager";
import Global from "@/ts/Global";
import {
  CurrencySystemIdEnum,
  InputBoxTypeEnum,
  TransferTypeEnum,
} from "@/ts/SuperEnum";
import CoinTypeVO from "@/ts/blockchain/vo/CoinTypeVO";
import { inject } from "@vue/runtime-core";
export default {
  data() {
    return {
      show_input_pwd: false,
      input_number: "",
      input_pwd: "", //输入的密码
      show_select_coin_body: false, //是否显示支付小窗口
      show_payment_method: false, //是否显示支付方式
      select_coin_index: -1, //默认选择支付币种
      coin_list: [], //币种列表
      app_vo: {},
      unified_order: {}, //订单数据
      is_payment: false, //是否为付款
      make_order_number: "",
      pwd_input_arr: ["", "", "", "", "", ""],
    };
  },
  setup() {
    let my_user_vo = inject("user_vo");
    const host_platform_vo = inject("host_platform_vo");
    return {
      my_user_vo,
      host_platform_vo
    };
  },

  computed: {
    GetSelectCoin() {
      if (this.select_coin_index === -1) {
        return {
          amount: this.my_user_vo.balance,
          coin_symbol: "￥",
          coin_img: "image/ling_qian_icon.png",
        };
      } else if (this.select_coin_index === -2) {
        return {
          amount: this.my_user_vo.miu_amount,
          coin_symbol: ` ${this.host_platform_vo.main_integral_alias}`,
          coin_img: require("@/assets/image/wode/miu2.png"),
        };
      }
      if (this.coin_list.length > 0) {
        return this.coin_list[this.select_coin_index];
      }
      return {};
    },
  },
  props: {
    user_vo: Object,
    order_number: String,
    transfer_finish_call: function (hash, amount, coin_type, to_user_vo) {},
  },
  methods: {
    async InputPwdAsync(v) {
      switch (v) {
        case "back":
          for (let i = this.pwd_input_arr.length - 1; i >= 0; i--) {
            const pwd_v = this.pwd_input_arr[i];
            if (pwd_v != "") {
              this.pwd_input_arr[i] = "";
              break;
            }
          }
          break;
        default:
          for (let i = 0; i < this.pwd_input_arr.length; i++) {
            const pwd_v = this.pwd_input_arr[i];
            if (pwd_v == "") {
              this.pwd_input_arr[i] = v;
              break;
            }
          }
          break;
      }
      let is_full = true;
      for (let i = 0; i < this.pwd_input_arr.length; i++) {
        const pwd_v = this.pwd_input_arr[i];
        if (pwd_v == "") {
          is_full = false;
          this.input_pwd = "";
          break;
        } else {
          this.input_pwd += pwd_v;
        }
      }
      if (is_full) {
        try {
          let res = await Tools.GetHtmlAsync("PayUnlock", {
            pwd: this.input_pwd,
          });
          if (Tools.CheckHtmlIsOK(res)) {
            this.TryToPayAsync();
          } else {
            // Tools.AlertError("支付密码错误!");
            this.ClearInputPwd();
          }
        } catch (ex) {
          Tools.AlertError("出错了,请重试!");
        }
      }
    },
    CloseSelectCoinBody() {
      if (this.is_payment) {
        this.GoBackAppletAsync(true, "", true);
        return;
      }
      this.show_select_coin_body = false;
    },
    GetCoinIconURL(img) {
      return Global.GetCoinIconURL(img);
    },
    /**
     * 显示输入密码框
     */
    async ShowInputPwdAsync() {
      let coin = this.GetSelectCoin;

      if (coin == null) {
        Tools.AlertError("没有找到可用币种!");
        return;
      }

      if (coin instanceof CoinTypeVO) {
        let amount = await coin.UpdateAmountAsync();
        if (amount < this.input_number) {
          Tools.AlertError("余额不足,无法转账!");
          return;
        }
      } else {
        let balance = 0;
        switch (this.select_coin_index) {
          case -1: //零钱转账
            balance = this.my_user_vo.balance;
            if (balance < this.input_number) {
              Tools.AlertError("余额不足,无法转账!");
              return;
            }
            break;
          case -2: //MIU转账
            balance = this.my_user_vo.miu_amount;
            if (balance < this.input_number) {
              Tools.AlertError(`${this.host_platform_vo?.main_integral_alias}余额不足,无法转账!`);
              return;
            }
            break;
          default:
            Tools.AlertError("没有找到可用币种!");
            return;
        }
      }

      //检查是否存在支付密码
      let res = await Tools.GetHtmlAsync("CheckHavePayPassword", {});
      if (Tools.CheckHtmlIsOK(res)) {
        let d = res.data;
        let must_to_unlock = d.must_to_unlock;
        let have_pay_pwd = d.have_pay_pwd;
        if (have_pay_pwd) {
          if (must_to_unlock) {
            //存在支付密码
            this.show_input_pwd = true;
          } else {
            this.TryToPayAsync();
          }
        } else {
          //不存在支付密码
          Global.ShowSetTransferPassword(() => {
            // this.input_pwd = Global.PayPassword;
            this.TryToPayAsync();
          });
        }
      }
    },
    /**
     * 尝试支付
     */
    async TryToPayAsync() {
      await Global.ShowGlobalLoadingAsync("正在支付...", async () => {
        try {
          //进行解锁
          this.ClearInputPwd();
          /**
           * 获取订单数据
           */
          if (this.is_payment) {
            if (this.select_coin_index == -1) {
              Tools.AlertError("零钱暂不支持支付下单!");
              return;
            }
            let res = await Tools.GetHtmlAsync("GetOrderDataFromOrderNumber", {
              order_number: this.make_order_number,
            });
            if (Tools.CheckHtmlIsOK(res)) {
              let order = res.data;
              switch (order.pay_state) {
                case 0: //等待支付
                  break;
                case 1: //支付中
                  Tools.AlertError("订单正在支付,请不要重复操作!");
                  await this.GoBackAppletAsync(
                    false,
                    "订单正在支付,请不要重复操作!"
                  );
                  return;
                case 2: //支付成功
                  Tools.AlertError("已完成支付，请不要重复操作!");
                  await this.GoBackAppletAsync(
                    false,
                    "已完成支付，请不要重复操作!"
                  );
                  return;
                case 3: //支付失败
                  Tools.AlertError("支付失败，请重新下单!");
                  await this.GoBackAppletAsync(false, "支付失败，请重新下单!");
                  return;
                default:
                  Tools.AlertError("未知的支付状态,操作结束!");
                  await this.GoBackAppletAsync(
                    false,
                    "未知的支付状态,操作结束!"
                  );
                  return;
              }
            }
          }

          let coin = this.GetSelectCoin;
          if (coin == null) {
            Tools.AlertError("没有找到可用币种!");
            return;
          }

          if (coin instanceof CoinTypeVO) {
            let amount = await coin.UpdateAmountAsync();
            if (amount < this.input_number) {
              Tools.AlertError("余额不足,无法转账!");
              return;
            }
          } else {
            if (this.select_coin_index != -1 && this.select_coin_index != -2) {
              Tools.AlertError("没有找到可用币种!");
              return;
            }
          }

          //调用支付
          if (this.is_payment) {
            let hash = await this.ToTransferAsync(
              coin,
              this.app_vo.payment_collection_address,
              TransferTypeEnum.购物,
              {
                order_number: this.make_order_number,
                uid: Global.login_user_vo.uid,
                appid: this.app_vo.id,
              }
            );
            if (Tools.IsNull(hash)) {
              Tools.AlertError("交易上链时失败!");
              this.GoBackAppletAsync(false, "交易上链时失败!");
              return;
            }
            //更新下单状态为支付中
            let up_res = await Tools.GetHtmlAsync("SetUnifiedOrderPaymenting", {
              order_number: this.make_order_number,
              hash: hash,
            });
            if (Tools.CheckHtmlIsOK(up_res)) {
              Tools.AlertSuccess("支付请求已发送!");
              this.GoBackAppletAsync(true, hash);
            } else {
              Tools.AlertSuccess("支付已发出，订单状态更新失败了!");
              this.GoBackAppletAsync(false, "支付已发出，订单状态更新失败了!");
            }
          } else {
            switch (this.select_coin_index) {
              case -1: //零钱转账
                let res = await Tools.GetHtmlAsync("LegalCurrencyTransfer", {
                  to_uid: this.user_vo.uid,
                  amount: this.input_number,
                });
                if (Tools.CheckHtmlIsOK(res)) {
                  // Tools.AlertSuccess("转账成功!");
                  this.show_select_coin_body = false;
                  this.show_payment_method = false;
                  this.GoBack();
                }
                break;
              case -2: //MIU转账
                let _res = await Tools.GetHtmlAsync("MIUTransfer", {
                  to_uid: this.user_vo.uid,
                  amount: this.input_number,
                });
                if (Tools.CheckHtmlIsOK(_res)) {
                  // Tools.AlertSuccess("转账成功!");
                  this.show_select_coin_body = false;
                  this.show_payment_method = false;
                  this.GoBack();
                }
                break;
              default:
                //币种转账
                await this.ToTransferAsync(
                  coin,
                  this.user_vo.wallet_address,
                  TransferTypeEnum.转账,
                  {}
                );
                break;
            }
          }
        } catch(ex) {
        } finally {
        }
      });
    },
    /**
     * 清空密码
     */
    ClearInputPwd() {
      this.input_pwd = "";
      for (let i = 0; i < this.pwd_input_arr.length; i++) {
        this.pwd_input_arr[i] = "";
      }
    },
    /**
     * 转账
     * @param coin 币种对象
     * @param to_address 转账目标地址
     * @param transfer_type 转账类型
     * @param extend_data 附加属性
     */
    async ToTransferAsync(coin, to_address, transfer_type, extend_data) {
      //获取我的钱包
      let wallet_vo = AccountManagement.GetNowSelectAccountWalletVO(
        Global.login_user_vo.uid
      );
      if (wallet_vo == null) {
        Tools.AlertError("没有找到您的钱包!");
        return;
      }

      let eth = wallet_vo.GetWalletServer(CurrencySystemIdEnum.LookBao);
      if (eth == null) {
        Tools.AlertError("转账失败，节点对象不存在!");
        return;
      }

      let gas_price = await eth.eth_gasPriceAsync();
      let private_key = wallet_vo.private_key;
      let from_address = wallet_vo.address_vo.address;
      try {
        //获取合约

        let hash = await coin.contract_server?.SendTransferAsync(
          from_address,
          private_key,
          to_address, //from_address, //this.to_address,
          parseFloat(this.input_number) * 10 ** coin.reserved_decimal,
          {
            transfer_type: transfer_type,
            transfer_data: extend_data,
          },
          710000,
          gas_price * 10 ** 9, //10000000000,
          710000
        );

        if (!this.is_payment) {
          this.transfer_finish_call &&
            this.transfer_finish_call(
              hash,
              this.input_number,
              {
                coin_img: coin.coin_img,
                coin_alias: coin.coin_alias,
                coin_type: coin.coin_type,
                coin_symbol: coin.coin_symbol,
                coin_full_name: coin.coin_full_name,
              },
              this.user_vo
            );
          this.show_select_coin_body = false;
          this.show_payment_method = false;
          this.GoBack();
        } else {
          return hash;
        }
      } catch (ex) {
        Tools.AlertError(ex.message);
      }
    },
    SelectCoinIndex(index) {
      this.select_coin_index = index;
      this.ClosePaymentMethod();
    },
    GoBack() {
      this.$parent.show_transfer_accounts = false;
    },
    ClosePaymentMethod() {
      this.show_payment_method = false;
      this.show_select_coin_body = true;
    },
    ShowPaymentMethod() {
      if (this.is_payment) return;
      this.show_payment_method = true;
      this.show_select_coin_body = false;
    },
    InputNumber(v) {
      switch (v) {
        case ".": //
          if (this.input_number.indexOf(".") == -1) this.input_number += v;
          break;
        case "del": //删除
          if (Tools.IsNotNull(this.input_number))
            this.input_number = this.input_number.substr(
              0,
              this.input_number.length - 1
            );
          break;
        case "send": //发送
          let send_v = parseFloat(this.input_number);
          if (isNaN(send_v) || send_v == 0) {
            Tools.AlertError("转账必须大于0!");
            return;
          }
          this.ClearInputPwd();
          this.show_input_pwd = false;
          this.show_select_coin_body = true;
          break;
        default:
          if (
            parseFloat(this.input_number) == 0 &&
            this.input_number.length == 1
          )
            return;
          if (this.input_number.length > 10) return;
          this.input_number += v;
          break;
      }
    },
    async UpdateAmountAsync() {
      console.log("开始初始化钱包数据!");
      //更新月
      await CoinTypeManager.singleton.InitAsync(Global.login_user_vo.uid);
      //获取币种列表
      let list = CoinTypeManager.singleton.GetAllCoinTypeList();

      this.coin_list = list.Where((x) => x.contract_type_id == 1);
      for (let i = 0; i < this.coin_list.length; i++) {
        const v = this.coin_list[i];
        v.coin_img = Global.GetCoinIconURL(v.coin_img);
        await v.UpdateAmountAsync();
      }
      console.log("初始化=====" + list.length + "个币种!");
    },
    /**
     * 返回到小程序
     * @param flag 是否立即返回 默认等待一会
     */
    async GoBackAppletAsync(flag, d, close_now = false) {
      this.$parent.SendActionToApplet &&
        this.$parent.SendActionToApplet(flag, d);

      this.$parent.GoBackAppletAsync &&
        (await this.$parent.GoBackAppletAsync());

      // if (close_now) {
      //   this.$parent.SendActionToApplet &&
      //     this.$parent.SendActionToApplet(flag, d);

      //   this.$parent.GoBackAppletAsync &&
      //     (await this.$parent.GoBackAppletAsync());
      // } else {
      //   setTimeout(async () => {
      //     this.$parent.SendActionToApplet &&
      //       this.$parent.SendActionToApplet(flag, d);

      //     this.$parent.GoBackAppletAsync &&
      //       (await this.$parent.GoBackAppletAsync());
      //   }, 3000);
      // }
    },
  },
  async mounted() {
    
    this.make_order_number = this.order_number;

    await this.UpdateAmountAsync();

    // if (this.coin_list.length <= 0) {
    //   if (Tools.StrIsNotNull(this.make_order_number)) {
    //     await this.GoBackAppletAsync(false, "您目前没有可支付币种");
    //   } else {
    //     Tools.AlertError("您目前没有可转账币种!");
    //     this.GoBack();
    //   }
    //   return;
    // }

    // this.make_order_number = "6265ade0365e470d9cb6911fc3e167a1";
    /**
     * 订单是否为空
     */
    if (Tools.StrIsNotNull(this.make_order_number)) {
      //获取订单数据
      let res = await Tools.GetHtmlAsync("GetOrderDataFromOrderNumber", {
        order_number: this.make_order_number,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        this.unified_order = res.data;
      } else {
        Tools.AlertError(res.error_msg);
        await this.GoBackAppletAsync(false, res.error_msg);
        return;
      }

      let amount = this.unified_order?.amount ?? 0;
      if (amount <= 0) {
        Tools.AlertError("支付金额错误!");
        await this.GoBackAppletAsync(false, "支付金额错误!");
        return;
      }

      /**
       * 判断支付状态
       */

      switch (this.unified_order.pay_state) {
        case 0: //等待支付
          break;
        case 1: //支付中
          Tools.AlertError("订单正在支付,请不要重复操作!");
          await this.GoBackAppletAsync(false, "订单正在支付,请不要重复操作!");
          break;
        case 2: //支付成功
          Tools.AlertError("已完成支付，请不要重复操作!");
          await this.GoBackAppletAsync(false, "已完成支付，请不要重复操作!");
          break;
        case 3: //支付失败
          Tools.AlertError("支付失败，请重新下单!");
          await this.GoBackAppletAsync(false, "支付失败，请重新下单!");
          break;
        default:
          Tools.AlertError("未知的支付状态,操作结束!");
          await this.GoBackAppletAsync(false, "未知的支付状态,操作结束!");
          break;
      }

      let appid = this.unified_order.appid;
      if (appid > 0) {
        //获取APPID对应的APP数据
        let res = await Tools.GetHTMLDAppAsync("GetDAppVOFromAppID", {
          app_id: appid,
        });
        if (Tools.CheckHtmlIsOK(res)) {
          this.app_vo = res.data;

          if (Tools.IsNull(this.app_vo.payment_collection_address)) {
            Tools.AlertError("此应用收款地址未配置,无法支付!");
            await this.GoBackAppletAsync(
              false,
              "此应用收款地址未配置,无法支付!"
            );
            return;
          }

          if (this.app_vo.payment_coin_type <= 0) {
            Tools.AlertError("此应用没有开通支付通道,无法支付!");
            await this.GoBackAppletAsync(
              false,
              "此应用没有开通支付通道,无法支付!"
            );
            return;
          }

          /**
           * 筛选币种
           */
          this.select_coin_index = -1;
          for (let i = 0; i < this.coin_list.length; i++) {
            const v = this.coin_list[i];

            if (v.coin_type == this.app_vo.payment_coin_type) {
              this.select_coin_index = i;
              break;
            }
          }
          if (this.select_coin_index == -1) {
            Tools.AlertError("您要支付的币种不存在!");
            await this.GoBackAppletAsync(false, "您要支付的币种不存在!");
            return;
          }

          this.is_payment = true;
          this.input_number = amount;
          this.show_select_coin_body = true;
        } else {
          Tools.AlertError("APP获取失败,无法交易!");
          await this.GoBackAppletAsync(false, "APP获取失败,无法交易!");
          return;
        }
      } else {
        Tools.AlertError("小程序数据获取失败,交易取消!");
        await this.GoBackAppletAsync(false, "小程序数据获取失败,交易取消!");
        return;
      }
    }
  },
};
</script>
<style lang="less" scoped>
.transfer_account_body {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color:@bgc_class;
  color: @ffffff_324460;
  z-index: 3000;
}

.transfer_account_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.input_password_keyboard {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: @box_bg;
}
.input_password_keyboard > button {
  background-color: @box_bg;
  flex: 1 1 30%;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
  height: 50px;
  border: 1px solid @border_;
}

.input_pwd_other_color_btn {
  background-position: center center;
  background-clip: border-box;
  background-size: auto 50%;
  background-repeat: no-repeat;
}

.head_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
}

.head_img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.nick_name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}

.jian_pan_box {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  bottom: 0px;
  height: 212px;
}
button {
  box-sizing: border-box;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  margin-bottom: 3px;
  border: 0px;
  font-size: 22px;
  font-weight: bold;
  border-radius: 10px;
  box-sizing: border-box;
}
.left > button {
  background-color: @box_bg;
}

.left {
  width: 80%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 200px;
  margin-right: 2px;
}
.left > button {
  width: 30%;
  height: 50px;
  /* border: 0px; */
  flex: 1 0 30%;
  margin-left: 1px;
}

.right {
  width: 20%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.right > button {
  widows: 20%;
  height: 50px;
}

.d_width {
  width: 66% !important;
}
.d_height {
  height: 80% !important;
}
.transer_box {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  height: 100%;
}
.transer_box input {
  width: 100%;
  height: 40px;
  /* border-radius: 10px; */
  /* border: 1px solid rgb(245, 245, 245); */
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.transer_box > span {
  font-size: 12px;
}

.input_box {
  display: flex;
  margin: 10px 0px;
  letter-spacing: 2px;
}

.input_box > span {
  font-size: 40px;
  /* font-weight: bold; */
  line-height: 40px;
  margin-right: 10px;
}

.input_box > input {
  border-radius: 10px;
  color: @ffffff_324460;
  text-align: center;
  background: @box_bg;
}

.payment_method_body,
.select_coin_body {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 600;
  /* justify-content: center; */
  align-items: center;
}

.select_coin_content {
  width: 80%;
  padding: 30px 20px 10px 20px;
  background-color: @box_bg;
  border-radius: 20px;
  position: relative;
  margin-top: 35%;
}

.show_payment_content {
  min-width: 80%;
  padding: 30px 20px 10px 20px;
  background-color: @box_bg;
  border-radius: 20px;
  position: relative;
  margin-top: 35%;
}

.show_payment_content .back_btn {
  position: absolute;
  left: 15px;
  top: 15px;
}
.show_payment_content .title {
  font-size: 18px;
  /* font-weight: bold; */
}

.select_coin_head_content {
  display: flex;
  flex-direction: column;
}
.select_coin_head_content > * {
  margin-bottom: 5px;
}

.select_coin_head_content > div > .amount {
  font-size: 20px;
  font-weight: bold;
}
.coin_amount_box {
  font-size: 20px;
}

.select_coin_head_content > .nick_name {
  font-weight: 500;
}

.select_coin_zffs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
  white-space: nowrap;
}

.select_coin_zffs .icon {
  margin-right: 5px;
}

.select_coin_zffs > div {
  display: flex;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
}

.qrzf_btn {
  width: 70%;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
}

.close_select_coin {
  position: absolute;
  left: 15px;
  top: 15px;
}

.payment_method_list {
  margin-top: 10px;
}
.payment_item {
  font-size: 14px;
  padding: 10px 0px;
  border-top: 1px solid rgb(245, 245, 245);
  display: flex;
  justify-items: center;
  white-space: nowrap;
}

.payment_item > *:nth-child(1) {
  margin-right: 5px;
}

.payment_item .amount {
  margin-right: auto;
  font-weight: bold;
}

.back_btn {
  position: fixed;
  left: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
}
.active_coin {
  border-radius: 50%;
}

.danwei {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}
.you_icon {
  width: 15px;
  height: 15px;
}
.select_coin_zffs_row {
  display: flex;
  align-items: center;
}

.input_pwd_box {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 40px;
  margin-top: 10px;
}

.pwd_input {
  display: flex;
  width: 15%;
  background-color: @bgc_class;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
}

.ling_qian_tab {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
</style>