<template>
  <div
    ref="home"
    class="home"
    @touchstart="home_touch_start"
    @touchmove="home_touch_move"
    @touchend="home_touch_end"
  >
    <!-- <div class="new_title">
      <div class="new_title_left">
        <span class="new_title_left_text">消息</span>
        <span>（1）</span>
      </div>
      <div class="new_title_right">
        <img class="new_title_right_img1" src="@/assets/image/xiaoxi/tongxunlu.png" alt="">
        <img class="new_title_right_img2" src="@/assets/image/xiaoxi/tianjia.png" alt="">
      </div>
    </div> -->
    <div class="applet_sousuo_box" v-if="show_applet_sousuo">
      <div class="applet_title">
        <span>小程序</span>
      </div>

      <my-image
        class="go_back_btn"
        src="image/back.png"
        @click="CloseAppletSouSuo"
      />

      <div class="applet_sousuo_body">
        <input
          type="applet_sousuo"
          v-model="search_applet_content"
          placeholder="搜索小程序"
          @keyup.enter="SearchAppletListAsync()"
          ref="search_input"
        />
        <div class="sousuo_btn" @click="SearchAppletListAsync">
          <span>搜索</span>
        </div>
      </div>
      <template v-if="search_applet_list.length == 0">
        <div class="applet_sousuo_content_title">
          <span>建议使用</span>
        </div>
        <div class="applet_item_box">
          <div
            class="applet_item"
            :key="index"
            v-for="(v, index) in hot_applet_list"
            @click="OpenApp(v.id)"
          >
            <my-image
              class="applet_icon"
              :src="GetIconResource(v.icon, '160')"
              alt=""
            />
            <span>{{ v.app_name }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="search_applet_list_box">
          <div
            class="search_applet_list_item"
            :key="index"
            v-for="(v, index) in search_applet_list"
            @click="OpenApp(v.id)"
          >
            <my-image
              class="search_applet_icon"
              :src="GetIconResource(v.icon, '160')"
              alt=""
            />
            <div>
              <span>{{ v.app_name }}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div
      class="applet_box"
      v-show="show_applet_box"
      ref="applet_box"
      @touchstart="applet_touch_start"
      @touchmove="applet_touch_move"
      @touchend="applet_touch_end"
    >
      <my-image
        class="go_back_btn"
        src="image/back.png"
        @click="CloseAppletWindow"
      />
      <div class="applet_title">
        <span>小程序</span>
      </div>
      <input
        class="applet_sousuo"
        type="text"
        placeholder="搜索小程序"
        @click="ShowAppletSouSuo"
      />
      <div class="applet_content_title">
        <span>最近使用的小程序</span>
        <!-- <span>更多</span> -->
      </div>
      <div class="applet_item_box">
        <div
          class="applet_item"
          :key="index"
          v-for="(v, index) in open_applet_history_list"
          @click="OpenApp(v.app_id)"
        >
          <my-image
            class="applet_icon"
            :src="GetIconResource(v.icon, '160')"
            alt=""
          />
          <span>{{ v.app_name }}</span>
        </div>
      </div>

      <div class="applet_content_title">
        <span>我的小程序</span>
      </div>
      <div class="applet_item_box">
        <div
          class="applet_item"
          :key="index"
          v-for="(v, index) in my_applet_list"
          @click="OpenApp(v.id)"
        >
          <my-image
            class="applet_icon"
            :src="GetIconResource(v.icon, '160')"
            alt=""
          />
          <span>{{ v.app_name }}</span>
        </div>
      </div>
      <span class="applet_bottom_title">{{ APP_NAME }} 小程序</span>
    </div>

    <div class="content">
      <div class="top_content_box">
        <div v-if="notice_list.length > 0" class="item" @click="OpenNotice">
          <div class="head_pic_box">
            <my-image class="notice_icon" src="image/notice_icon.png" />
            <div v-if="CheckHaveHongDian('new_message')" class="dian"></div>
          </div>
          <div class="item_content">
            <div>
              <span class="nick_name system_title">消息通知</span>
              <span class="time_span">{{ notice_list[0].create_time }}</span>
            </div>
            <span class="last_msg"
              >来自 - {{ notice_list[0].from_user_vo?.nick_name }} [{{
                GetNoticeTypeName(notice_list[0].notice_type)
              }}]</span
            >
            <!-- <div class="hei_line"></div> -->
          </div>
        </div>

        <div class="item" @click="AddFriend()">
          <div class="head_pic_box">
            <img class="notice_icon" src="image/tianjiapengyou.png" />
          </div>
          <div class="item_content">
            <div>
              <span class="nick_name system_title">添加好友</span>
              <span class="time_span"></span>
            </div>
            <span class="last_msg">添加好友，端到端安全加密</span>
          </div>
        </div>
        <!-- <div class="item item_b" @click="UpdateMyData">
          <div class="head_pic_box">
            <img class="notice_icon" src="image/gerenshezhi.png" />
          </div>
          <div class="item_content">
            <div>
              <span class="nick_name system_title">个人设置</span>
              <span class="time_span"></span>
            </div>
            <span class="last_msg">修改头像、昵称、性别</span>
          </div>
        </div> -->
        <!-- <div class="item item_b" @click="OpenAnQuan">
          <div class="head_pic_box">
            <img class="notice_icon" src="image/anquanshezhi.png" />
          </div>
          <div class="item_content">
            <div>
              <span class="nick_name system_title">安全设置</span>
              <span class="time_span"></span>
            </div>
            <span class="last_msg">设置支付密码、静音等</span>
          </div>
        </div> -->
      </div>

      <div class="top_content_box">
        <transition-group appear :name="open_transition ? 'v' : 'no'">
          <template :key="index" v-for="(item, index) in message_list">
            <div
              v-if="item.v.user_vo"
              :class="['item', item.v.is_live ? 'is_live' : '']"
              @click="OpenChatWindow(item.key, item.v.user_vo)"
              v-longtap.stop="{
                fn: LongTapMessage,
                item: item.v.user_vo,
                type: 1,
              }"
            >
              <my-image
                class="is_live_icon"
                v-if="item.v.is_live"
                src="image/live_right_icon.png"
                alt=""
              />
              <!-- <div :class="['head_pic_box', item.v.online ? '' : 'offline']"> -->
              <div :class="['head_pic_box', item.v.online ? '' : '']">
                <my-image
                  class="is_top"
                  src="image/top.png"
                  v-if="item.is_top"
                />
                <my-image
                  class="head_pic_icon"
                  :src="GetIconResource(item.v.user_vo.head_pic, '160')"
                />
                <div v-if="CheckHaveHongDian(item.key)" class="dian"></div>
                <!-- <div class="online_dian" v-if="item.v.online">
                <div></div>
              </div> -->
              </div>

              <div class="item_content">
                <div>
                  <span class="nick_name"
                    >{{ item.v.user_vo.nick_name }}
                    <!-- <span v-if="item.v.online" class="online_tab">在线</span> -->
                  </span>

                  <span class="time_span">{{ ShowDateTime(item.v.time) }}</span>
                </div>
                <span
                  class="last_msg last_show_messsage"
                  v-html="ShowMessage(item.v.msg)"
                ></span>
              </div>
            </div>

            <div
              v-else-if="item.v.group_vo"
              :class="['item', item.v.is_live ? 'is_live' : '']"
              @click="OpenChatWindow(item.key, item.v.group_vo)"
              v-longtap.stop="{
                fn: LongTapMessage,
                item: item.v.group_vo,
                type: 2,
              }"
            >
              <my-image
                class="is_live_icon"
                v-if="item.v.is_live"
                src="image/live_right_icon.png"
                alt=""
              />
              <div class="head_pic_box">
                <my-image
                  class="is_top"
                  src="image/top.png"
                  v-if="item.is_top"
                />
                <my-image
                  class="head_pic_icon"
                  :src="GetIconResource(item.v.group_vo.icon, '160')"
                />
                <div v-if="CheckHaveHongDian(item.key)" class="dian"></div>
              </div>
              <div class="item_content">
                <div>
                  <span class="nick_name">{{
                    item.v.group_vo.group_name
                  }}</span>
                  <span class="time_span">{{ ShowDateTime(item.v.time) }}</span>
                </div>
                <span
                  class="last_msg last_show_messsage"
                  v-html="ShowMessage(item.v.msg)"
                ></span>
              </div>
            </div>
          </template>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
let start_y = 0;
let move_y = 0;
let start_applet_y = 0;
let move_applet_y = 0;

import Global from "@/ts/Global";
import SuperEvents from "@/ts/SuperEvents";
import Tools from "@/ts/Tools";
import IM_User from "@/ts/vo/IM_User";
import IM_Group from "@/ts/vo/IM_Group";
import { defineComponent, inject } from "vue";
import {
  AudioResourceEnum,
  ChatTypeEnum,
  NoticeTypeEnum,
} from "@/ts/SuperEnum";
import CordovaPlugin from "@/ts/CordovaPlugin";
import Config from "@/ts/Config";
import SuperTimer from "@/ts/SuperTimer";

let _global = {
  /**
   * 初始化
   */
  async InitAsync() {
    await this.GetMessageListAsync();
    await this.GetAllNoticeListAsync();
    await this.GetMyDAppListAsync();
    await this.GetHotAppletListAsync(); //热门小程序列表
    // await this.CheckUserOnLineAsync(); //获取用户是否在线
    //获取离线消息(如果没有缓存拉取离线数据)
    this.GetOffineMessageAsync();
  },
  /**
   * 获取离线消息
   */
  async GetOffineMessageAsync() {
    try {
      let res = await Tools.GetHtmlAsync("GetOffineMessage", {});
      if (Tools.CheckHtmlIsOK(res, false)) {
        let d = res.data;
        let friend_message_list = d.friend_message_list;
        let group_message_list = d.group_message_list;
        if (friend_message_list) {
          /**
           * 获取消息是内容
           */
          friend_message_list.forEach(async (v) => {
            let chat_vo = v.chat_vo;
            let from_user_vo = v.from_user_vo;
            if (this.user_vo) {
              //添加到本地发送记录
              await Global.AddTopMessageAsync(
                {
                  type: chat_vo.message_type,
                  time: chat_vo.create_time,
                  id: chat_vo.id,
                  group_id: 0,
                  from_user: from_user_vo,
                  to_user: this.user_vo,
                  data: chat_vo.message,
                },
                false
              );
            }
          });
        }
        if (group_message_list) {
          group_message_list.forEach(async (v) => {
            let chat_vo = v.chat_vo;
            let group_vo = v.group_vo;
            let from_user_vo = v.from_user_vo;
            //添加到本地发送记录
            if (this.user_vo) {
              await Global.AddTopMessageAsync(
                {
                  type: chat_vo.message_type,
                  time: chat_vo.create_time,
                  id: chat_vo.id,
                  group_id: group_vo.id,
                  group_vo: group_vo,
                  from_user: from_user_vo,
                  to_user: this.user_vo,
                  data: chat_vo.message,
                },
                false
              );
            }
          });
        }

        await this.GetMessageListAsync();
      }
    } catch (e) {
      // console.log();
    }
  },
  LongTapMessage(e, d) {
    let type = d.type;
    let item = d.item;

    switch (type) {
      case 1:
        let uid = item.uid;
        //删除
        Global.ShowGeneralMenu([
          {
            menu_name: "删除",
            menu_click: async () => {
              await this.RemoveMessageAsync(uid);
            },
          },
        ]);
        break;
      case 2:
        let group_id = item.id;
        Global.ShowGeneralMenu([
          {
            menu_name: "删除",
            menu_click: async () => {
              await this.RemoveMessageAsync(`group_${group_id}`);
            },
          },
        ]);
        break;
    }
  },
  UpdateMyData() {
    Global.ShowUpdateAccount();
  },
  OpenAnQuan() {
    Tools.GoTo("/settings");
  },
  AddFriend() {
    Tools.GoTo("/search_friend");
  },
  ShowAppletSouSuo() {
    this.search_applet_list = [];
    this.search_applet_content = "";
    this.show_applet_sousuo = true;

    this.$nextTick(() => {
      let search_input = this.$refs["search_input"];
      if (search_input != null) {
        search_input.focus && search_input.focus();
      }
    });
  },
  CloseAppletSouSuo() {
    this.search_applet_list = [];
    this.search_applet_content = "";
    this.show_applet_sousuo = false;
  },
  /**
   * 搜索小程序
   */
  async SearchAppletListAsync() {
    if (Tools.IsNull(this.search_applet_content)) {
      Tools.AlertError("搜索内容不能为空!");
      return;
    }
    let res = await Tools.GetHTMLDAppAsync("SearchAppletList", {
      search: this.search_applet_content,
    });
    if (Tools.CheckHtmlIsOK(res, false)) {
      this.search_applet_list = res.data;
    }
    this.search_applet_content = "";
  },
  /**
   * 获取热门小程序
   */
  async GetHotAppletListAsync() {
    let res = await Tools.GetHTMLDAppAsync("GetDAppList", {
      page: 1,
      num: 4,
      show_hot: true,
    });
    if (Tools.CheckHtmlIsOK(res, false)) {
      this.hot_applet_list = res.data;
    }
  },
  async CheckUserOnLineAsync() {
    if (this.message_list) {
      let uids = [];
      this.message_list.forEach((item) => {
        if (item.v.user_vo != null) {
          uids.push(item.v.user_vo.uid);
        }
      });
      if (uids.length > 0) {
        let res = await Tools.GetHtmlAsync("CheckUserOnLine", {
          uids: JSON.stringify(uids),
        });
        if (Tools.CheckHtmlIsOK(res, false)) {
          let v_list = res.data;
          v_list.forEach(async (v) => {
            let f_uid = v.uid;
            let f_online = v.online;
            for (let i = 0; i < this.message_list.length; i++) {
              let item = this.message_list[i];
              if (typeof item.v == "object") {
                if (item.v.user_vo != null) {
                  if (item.v.user_vo.uid == f_uid) {
                    item.v.online = f_online;

                    //更新数据库
                    let db = (await Global.GetDBAsync()).GetStore(
                      "temporary_message",
                      "readwrite"
                    );
                    if (db != null) {
                      let v = await db.GetValueAsync(f_uid);
                      if (v != null) {
                        if (v.flag) {
                          let to_d = v.data;
                          if (to_d != null) {
                            to_d.v.online = f_online;
                            //进行更新
                            await db.AddOrUpdateAsync(f_uid, to_d.v);
                          }
                        }
                      }
                    }
                    break;
                  }
                }
              }
            }
          });
        }
      }
    }
  },
  /**
   * 获取我的小程序列表
   */
  async GetMyDAppListAsync() {
    this.GetOpenAppletHistory();
    let res = await Tools.GetHTMLDAppAsync("GetDAppList", {
      page: 1,
      num: 2000,
      show_my_app: true,
    });
    if (Tools.CheckHtmlIsOK(res, false)) {
      this.my_applet_list = res.data;
    }
  },
  GetOpenAppletHistory() {
    let list = Global.GetOpenAppletHistory();
    this.open_applet_history_list = list;
  },
  CheckHaveHongDian(key) {
    return Global.CheckHaveHongDian(key);
  },
  ShowMessage(v) {
    let m_list = Global.GetMakeTextAndFaceMesssage(v);
    let ret_v = "";
    m_list.forEach((m) => {
      if (typeof m == "string") {
        ret_v += m;
      } else if (m.type == 0) {
        ret_v += m.msg;
      } else if (m.type == 1) {
        if (m.config != null) {
          ret_v += `<my-image class="face_icon msg_face" src="image/transparent.png"
                  style="background-image: url('${m.path}'); 
                  animation-timing-function:steps(${m.config.frameCount}); 
                  background-size:${m.config.allWidth}%;"
              />`;
        }
      }
    });
    return ret_v;
  },
  OpenNotice() {
    Tools.GoTo("/notice");
  },
  GetIconResource(img, pic_mode) {
    return Global.GetCoinIconURL(img, pic_mode);
  },
  async GetAllNoticeListAsync() {
    let res = await Tools.GetHtmlAsync("GetAllNoticeList", {
      page: 1,
      num: 1,
    });
    if (Tools.CheckHtmlIsOK(res)) {
      this.notice_list = res.data.data;
      this.notice_list.forEach((v) => {
        v.from_user_vo = JSON.parse(v.from_user_vo);
        v.data = JSON.parse(v.data);
        v.create_time = this.ShowDateTime(v.create_time);
      });
    }
  },
  GetNoticeTypeName(notice_type) {
    return NoticeTypeEnum[notice_type];
  },

  ShowDateTime(_time) {
    return Tools.ShowHomeDateTime(_time);
  },
  GoTo(href) {
    Tools.GoTo(href);
  },
  OpenChatWindow(key, vo) {
    Global.RemoveNewMessageDian(key);
    if (vo instanceof IM_User) {
      Tools.GoTo(
        "/chat_window",
        {
          uid: vo.uid,
        },
        {
          user_vo: vo,
        }
      );
    } else if (vo instanceof IM_Group) {
      Tools.GoTo("/chat_window", {
        group_id: vo.id,
      });
    }
  },
  async GetMessageListAsync() {
    let friend_list = new Array();
    let group_list = new Array();
    //获取好友列表
    let res = await Tools.GetHtmlAsync("GetMyFriendList", {
      show_is_black: true,
    });
    if (Tools.CheckHtmlIsOK(res, false)) {
      friend_list = res.data;
      Object.setPrototypeOf(friend_list, Array.prototype);
    }
    //获取群列表
    res = await Tools.GetHtmlAsync("GetGroupList", {});
    if (Tools.CheckHtmlIsOK(res, false)) {
      group_list = res.data;
      Object.setPrototypeOf(group_list, Array.prototype);
    }
    let all_list = [];
    try {
      all_list = await (
        await Global.GetDBAsync()
      ).GetAllListAsync("temporary_message");
    } catch (ex) {}

    /**
     * 过滤不存在的群和好友
     */
    let list = [];
    for (let i = 0; i < all_list.length; i++) {
      const x = all_list[i];
      if (x.v.group_vo) {
        Object.setPrototypeOf(x.v.group_vo, IM_Group.prototype);
        //检测是否存在这个群
        if (
          group_list.Where((y) => y.group_vo.id == x.v.group_vo.id).length == 0
        )
          continue;
      }
      if (x.v.user_vo) {
        Object.setPrototypeOf(x.v.user_vo, IM_User.prototype);
        if (
          friend_list.Where(
            (y) => y.frined_vo.friend_uid == x.v.user_vo?.uid ?? ""
          ).length == 0
        )
          continue;
      }
      list.push(x);
    }

    // list.forEach((x) => {
    //   if (x.v.group_vo) {
    //     Object.setPrototypeOf(x.v.group_vo, IM_Group.prototype);
    //   }
    //   if (x.v.user_vo) {
    //     Object.setPrototypeOf(x.v.user_vo, IM_User.prototype);
    //   }
    // });

    let show_list = [];
    let top_user_list = []; //置顶个人列表
    let top_group_list = []; //置顶群列表
    let other_data_list = []; //其他数据
    /**
     * 好友列表
     */
    list.OrderByDesc((x) => Tools.TimeToLong(x.v.time)); //先进行一次时间排序

    list.forEach((x) => {
      if (x.v.user_vo != null) {
        if (
          friend_list.Where((y) => {
            if (y.frined_vo.friend_uid == (x.v.user_vo?.uid ?? "")) {
              if (y.frined_vo.is_black_list == 1) {
                x.is_black = true;
                return false;
              }
              if (Tools.IsNotNull(y.frined_vo.remark_nick_name))
                x.v.user_vo.nick_name = y.frined_vo.remark_nick_name;
              if (y.frined_vo.message_top == 1) {
                return true;
              }
            }
            return false;
          }).length > 0
        ) {
          top_user_list.push(x);
        } else {
          other_data_list.push(x);
        }
      } else {
        if (
          group_list.Where((y) => {
            if (x?.v?.group_vo?.id == y?.group_vo?.id) {
              if (y?.user_group_vo?.message_top == 1) {
                return true;
              }
            }
            return false;
          }).length > 0
        ) {
          top_group_list.push(x);
        } else {
          other_data_list.push(x);
        }
      }
    });
    top_user_list.forEach((v) => {
      if (!v.is_black) {
        v.is_top = true;
        show_list.push(v);
      }
    });
    top_group_list.forEach((v) => {
      if (!v.is_black) {
        v.is_top = true;
        show_list.push(v);
      }
    });
    other_data_list.OrderByDesc((x) => Tools.TimeToLong(x.v.time));

    other_data_list.forEach((v) => {
      if (!v.is_black) {
        show_list.push(v);
      }
    });

    this.message_list = show_list;

    this.ToCheckIsLiveAsync();
  },
  /**
   * 去检测直播状态
   */
  async ToCheckIsLiveAsync() {
    /**
     * 检查是否存在直播
     */
    let check_uid_list = [];
    let message_list = this?.message_list;

    for (let i = 0; i < message_list.length; i++) {
      const item = message_list[i];
      if (item.v.group_vo != null) {
        //群直播检测
        if (await this.CheckGroupIsLiveAsync(item.v.group_vo.id)) {
          item.v.is_live = true;
        } else {
          item.v.is_live = false;
        }
      } else if (item.v.user_vo != null) {
        check_uid_list.push(item.v.user_vo.uid);
      }
    }

    let is_live_user_list = await this.CheckUserIsLiveAsync(check_uid_list);

    message_list.forEach((item) => {
      if (item.v.user_vo != null) {
        if (
          is_live_user_list.Where((x) => x == item.v.user_vo.uid).length > 0
        ) {
          item.v.is_live = true;
        } else {
          item.v.is_live = false;
        }
      }
    });
  },
  async CheckGroupIsLiveAsync(group_id) {
    let res = await Tools.GetHtmlAsync("CheckGroupIsLiveing", {
      group_id: group_id,
    });
    if (Tools.CheckHtmlIsOK(res, false)) {
      let d = res.data;
      let is_liveing = d.is_liveing;
      if (is_liveing) {
        return true;
      } else {
        return false;
      }
    }
  },
  async CheckUserIsLiveAsync(uids) {
    let res = await Tools.GetHtmlAsync("CheckUserIsLiveing", {
      uids: JSON.stringify(uids),
    });
    if (Tools.CheckHtmlIsOK(res, false)) {
      let live_list = res.data;
      let is_live_uids = [];
      for (let i = 0; i < live_list.length; i++) {
        const v = live_list[i];
        is_live_uids.push(v.uid);
      }
      return is_live_uids;
    }
    return [];
  },
  home_touch_start(e) {
    if (this.IsWeb) return;
    //开始Touch
    console.log("按下");
    start_y = e.targetTouches[0].clientY;
    move_y = 0;
  },
  home_touch_move(e) {
    if (this.IsWeb) return;
    this.show_applet_box = true;
    //移动Touch
    let now_y = e.targetTouches[0].clientY;
    let doc = this.$refs["home"];
    move_y = now_y - start_y;
    let body_height = document.body.clientHeight;
    if (doc.scrollTop == 0) {
      if (move_y > 0) {
        let applet_box = this.$refs["applet_box"];
        applet_box.style.top = body_height * -1 + move_y + "px";
      }
      // console.log("移动的范围:" + move_y);
    }
  },
  home_touch_end(e) {
    if (this.IsWeb) return;
    //结束Touch
    // let now_y = e.targetTouches[0].clientY;
    let body_height = document.body.clientHeight;
    let applet_box = this.$refs["applet_box"];
    if (move_y > 200) {
      this.GetMyDAppListAsync();
      applet_box.style.top = "0px";
    } else {
      applet_box.style.top = body_height * -1 + "px";
    }
  },

  applet_touch_start(e) {
    start_applet_y = e.targetTouches[0].clientY;
    move_applet_y = 0;
  },
  applet_touch_move(e) {
    let now_y = e.targetTouches[0].clientY;
    move_applet_y = now_y - start_applet_y;
    if (move_applet_y < 0) {
      let body_height = document.body.clientHeight;
      let applet_box = this.$refs["applet_box"];
      applet_box.style.top = move_applet_y + "px";
    }
  },
  applet_touch_end(e) {
    let body_height = document.body.clientHeight;
    let applet_box = this.$refs["applet_box"];
    if (move_applet_y < -200) {
      applet_box.style.top = body_height * -1 + "px";
    } else {
      applet_box.style.top = "0px";
    }
  },
  /**
   * 关闭小程序窗口
   */
  CloseAppletWindow() {
    let body_height = document.body.clientHeight;
    let applet_box = this.$refs["applet_box"];
    applet_box.style.top = body_height * -1 + "px";
  },
  /**
   * 删除消息
   */
  async RemoveMessageAsync(key) {
    let temporary_message = (await Global.GetDBAsync()).GetStore(
      "temporary_message"
    );
    if (temporary_message) {
      await temporary_message.DeleteAsync(key);
      await this.GetMessageListAsync();
    }
    Global.CloseGeneralMenu();
  },
  async OpenApp(app_id) {
    await Global.OpenAppletAsync(this, app_id, {});
  },
};

export default {
  name: "Home",
  data() {
    return {
      open_transition: false,
      APP_NAME: Config.APP_NAME,
      search_applet_content: "", //搜索内容
      show_applet_box: false,
      message_list: [],
      notice_list: [],
      my_applet_list: [],
      open_applet_history_list: [],
      hot_applet_list: [], //热门
      search_applet_list: [], //搜索的内容
      show_applet_sousuo: false, //是否显示搜索框
    };
  },
  props: {
    menu_index: Number,
  },
  setup() {
    const user_vo = inject("user_vo");
    const env = inject("env");
    return {
      user_vo,
      env,
    };
  },
  watch: {
    menu_index(_new, _old) {
      if (_new == 0) {
        this.InitAsync();
      }
    },
  },
  computed: {
    IsWeb() {
      return Global.GetVueAppTitle == "webbuild" ? true : false;
    },
  },
  methods: _global,
  activated() {
    this.InitAsync();
  },
  deactivated() {},
  async mounted() {
    Global.home = this;

    /**
     * 初始化授权
     */
    if (!Global.is_start) {
      Global.is_start = true;
      //清空角标
      Global.badge_number = 0;

      SuperTimer.Start(
        "get_permission",
        () => {
          Global.app && Global.app.CheckAppPermissionAsync();
          return false;
        },
        2000
      );
    }

    let applet_box = this.$refs["applet_box"];
    applet_box.style.top = document.body.clientHeight * -1 + "px";

    this.InitAsync();

    /**
     * 上线
     */
    SuperEvents.AddGroupEvent(this, "OnLineEvent", async (_this, d) => {
      let uid = d.DATA.uid;
      // await this.CheckUserOnLineAsync();
    });

    /**
     * 离线
     */
    SuperEvents.AddGroupEvent(this, "OnOfflineEvent", async (_this, d) => {
      let uid = d.DATA.uid;
      // await this.CheckUserOnLineAsync();
    });

    /**
     * APP恢复
     */
    SuperEvents.AddCallEventListener(this, "OnAppResume", async () => {
      //恢复窗口
      await this.GetOffineMessageAsync();
      //清空角标
      Global.badge_number = 0;
    });

    SuperEvents.AddGroupEvent(
      this,
      "OnSayMessage",
      async (_this, data_vo) => {
        let from_user = data_vo.DATA.from_user;
        let to_user = data_vo.DATA.to_user;
        let group_vo = data_vo.DATA.group_vo;
        let group_id = data_vo.DATA.group_id;
        let id = data_vo.DATA.id;
        let type = data_vo.DATA.type;
        let forward = data_vo.DATA.forward;

        let href = Tools.GetNowRouteHref();
        try {
          if (!data_vo.DATA.is_avoid_disturb) {
            if (data_vo.DATA.from_user.uid != this.user_vo?.uid)
              if (href == "/home_page") {
                Tools.PlayVoice(AudioResourceEnum.CUE_TONE);
              }
          }
        } catch (e) {}
        //播放声音
        let flush_list = true;
        let can_send_message =
          !(await CordovaPlugin.IsTopActivityAsync()) &&
          CordovaPlugin.IsLookBaoApp();

        if (href != "/home_page" || can_send_message) {
          flush_list = false;
          let no_message_notification_list = [
            "/chat_window",
            "/business_card_select",
          ];
          if (
            no_message_notification_list.indexOf(href) == -1 ||
            can_send_message
          ) {
            if (group_id > 0) {
              if (!data_vo.DATA.is_avoid_disturb) {
                Global.ShowMessageNotificationAsync(
                  type,
                  group_vo.group_name,
                  `${from_user.nick_name}`,
                  `${data_vo.DATA.data}`,
                  group_vo.icon,
                  group_vo.id,
                  null
                );
                Tools.PlayVoice(AudioResourceEnum.CUE_TONE);
              }
            } else {
              if (!data_vo.DATA.is_avoid_disturb) {
                Global.ShowMessageNotificationAsync(
                  type,
                  from_user.nick_name,
                  from_user.nick_name,
                  data_vo.DATA.data,
                  from_user.head_pic,
                  0,
                  from_user
                );
                Tools.PlayVoice(AudioResourceEnum.CUE_TONE);
              }
            }
          }
        }

        Global.AddTopMessageAsync(data_vo.DATA, flush_list);
      },
      1
    );

    /**
     * 消息通知
     */
    SuperEvents.AddGroupEvent(
      this,
      "OnNewNotice",
      async (_this, d) => {
        Global.AddNewMessageDian("new_message");
        await this.GetAllNoticeListAsync();
      },
      2
    );

    //群直播监听
    SuperEvents.AddGroupEvent(
      this,
      "OnStartLive",
      async (_this, data) => {
        let d = data.DATA;
        let live_room_vo = d.live_room_vo;
        let group_vo = d.group_vo;
        await this.ToCheckIsLiveAsync();
      },
      1
    );

    /**
     * 停播事件
     */
    SuperEvents.AddGroupEvent(this, "OnStopLive", async (_this, data_vo) => {
      let d = data_vo.DATA;
      let live_room_vo = d.live_room_vo;
      let group_id = live_room_vo.group_id;
      let uid = live_room_vo.uid;
      await this.ToCheckIsLiveAsync();
    });

    /**
     * 好友信息变更通知
     */
    SuperEvents.AddGroupEvent(
      this,
      "OnFriendInformationChange",
      async (_this, data_vo) => {
        let d = data_vo.DATA;
        let nick_name = d.nick_name;
        let head_pic = d.head_pic;
        let uid = d.uid;
        /**
         * 更新数据库
         */
        let db = (await Global.GetDBAsync()).GetStore(
          "temporary_message",
          "readwrite"
        );
        if (db != null) {
          let v = await db.GetValueAsync(uid);
          if (v != null) {
            if (v.flag) {
              let to_d = v.data;
              if (to_d != null) {
                to_d.v.user_vo.nick_name = nick_name;
                to_d.v.user_vo.head_pic = head_pic;
                //进行更新
                await db.AddOrUpdateAsync(uid, to_d.v);
                await this.GetMessageListAsync();
              }
            }
          }
        }
      }
    );

    /**
     * 群信息变更
     */
    SuperEvents.AddGroupEvent(
      this,
      "OnGroupInformationChange",
      async (_this, data_vo) => {
        let d = data_vo.DATA;
        let group_id = d.group_id;
        let group_name = d.group_name;
        let icon = d.icon;
        let db = (await Global.GetDBAsync()).GetStore(
          "temporary_message",
          "readwrite"
        );
        if (db != null) {
          let v = await db.GetValueAsync(`group_${group_id}`);
          if (v != null) {
            if (v.flag) {
              let to_group_vo = v.data;
              if (to_group_vo != null) {
                if (Tools.IsNotNull(group_name))
                  to_group_vo.v.group_vo.group_name = group_name;
                if (Tools.IsNotNull(icon)) to_group_vo.v.group_vo.icon = icon;
                //进行更新
                await db.AddOrUpdateAsync(`group_${group_id}`, to_group_vo.v);
                await this.GetMessageListAsync();
              }
            }
          }
        }
      }
    );

    /**
     * 获取通知字符串
     */
    let notify_json_item = await CordovaPlugin.GetNotifyJsonMsgAsync();
    if (notify_json_item.flag) {
      await Global.ProcessingNotificationMessagesAsync(notify_json_item.data);
    }

    this.open_transition = true;
  },
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  overflow-y: scroll;
  background: @home_bgc_class;
}

.new_title {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 16px;
  align-items: center;
}

.new_title_left {
  font-size: 16px;
  font-weight: 500;
  color: #324460;
  line-height: 20px;
}

.new_title_right_img1 {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
.new_title_right_img2 {
  width: 20px;
  height: 20px;
}
.content {
  margin-left: 0px;
  /* margin-top: 20px; */

  /* height: calc(100% - 100px); */
}

.home::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* .content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
} */

.item {
  display: flex;
  /* margin-bottom: 5px; */
  /*background-color: #191f23;*/
  padding: 15px 20px;
  position: relative;
  overflow: hidden;
  margin-top: 1px;
  background-color: @c292929_ffffff;
  border-bottom: 1px solid @c303339_F5F6F7;
}
.head_pic_box {
  width: 44px !important;
  height: 44px !important;
  min-width: 44px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

.head_pic_icon {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: inline-block;
  box-sizing: border-box;
  object-fit: cover;
}

.item_content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
}

.item_content > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: auto;
  color: @ffffff_324460;
}

.item_content > span {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 12px;
}

.last_msg {
  color: #9ba1a7;
}

.last_show_messsage {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 88% !important; */
  display: inline-block;
}

.time_span {
  font-size: 12px;
  color: #9ba1a7;
  white-space: nowrap;

  /* margin-right: 20px; */
}

.nick_name {
  /* font-weight: bold; */
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notice_icon {
  /* background-color: rgb(20, 136, 244); */
  /* padding: 5px; */
  width: 44px !important;
  height: 44px !important;
  border-radius: 10px;
}

.notice_icon_tjhy {
  background-color: rgb(20, 136, 244);
}

.notice_icon_grsz {
  background-color: rgb(254, 172, 26);
}

.notice_icon_aqsz {
  background-color: rgb(2, 209, 137);
}

.is_top {
  position: absolute;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
}

.dian {
  width: 10px;
  height: 10px;
  background-color: red;
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 50%;
}

.online_dian {
  width: 16px;
  height: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
}

.online_dian > div {
  background-color: #42b983;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
}

.offline {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
</style>
<style>
.msg_face {
  width: 30px;
  height: 30px;
  display: inline-block;
  box-sizing: content-box;
  vertical-align: middle;
}

.is_live {
  animation: color_move 2s infinite linear;
}

.is_live_icon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

@keyframes color_move {
  0% {
    background-color: #191f23;
  }

  50% {
    background-color: rgb(81, 81, 81);
  }

  100% {
    background-color: #191f23;
  }
}

/* 小程序窗口 */
.applet_box {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
  background-color: #070d0d;
  color: white;
  padding: 0px 30px 10px 30px;
  transition: all 0.5s;
}

.applet_title {
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.applet_sousuo {
  height: 30px;
  width: 100%;
  border-radius: 10px;
  text-align: center;
}

.applet_content_title {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  margin: 20px 0px 10px 0px;
}

.applet_item_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.applet_item {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 12px;
  overflow: hidden;
  max-width: 25%;
  flex: 1 0 25%;
  margin: 5px 0px;
}

.applet_icon {
  width: 40px;
  height: 40px;
  margin-bottom: 3px;
  border-radius: 50%;
}

.applet_bottom_title {
  margin-top: auto;
  display: block;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

/* 小程序窗口结束 */

.applet_sousuo_box {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #070d0d;
  z-index: 510;
  color: white;
  padding: 0px 30px 10px 30px;
}

.back_icon {
  width: 20px;
  height: 20px;
}

.applet_sousuo_body {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  /* height: 40px; */
  /* padding: 0px 10px; */
}

.applet_sousuo_body > input {
  width: 100%;
  border-radius: 10px;
  border: 0px;
  background-color: #191f23;
  color: white;
  text-align: center;
  height: 30px;
}

.applet_sousuo_body > span {
  min-width: 40px;
  text-align: right;
}

.search_applet_list_box {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  flex-direction: column;
}

.search_applet_list_item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1px;
  font-size: 16px;
  align-items: center;
  /* background-color: #191F23; */
  padding: 10px 10px;
}

.search_applet_icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.applet_sousuo_content_title {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  padding: 0px 10px;
  margin: 20px 0px 10px 0px;
}

.go_back_btn {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 15px;
}

.sousuo_btn {
  position: absolute;
  right: 0px;
  background-color: rgb(0, 128, 255);
  text-align: center !important;
  display: flex;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 50px;
  align-items: center;
  justify-content: center;
}

.system_title {
  /* color: rgb(20, 136, 244) !important; */
}

.online_tab {
  color: aqua;
  font-size: 12px !important;
  border: 1px solid aqua;
  padding: 0px 5px;
  border-radius: 10px;
  display: inline-block;
  max-height: 16px;
  line-height: 16px;
}

.top_content_box {
  margin-bottom: 10px;
}

.hei_line {
  background-color: #070d0d;
  height: 1px;
  margin-top: 10px;
}
</style>