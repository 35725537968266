<template>
  <div class="transfer_state_body">
    <div class="content">
      <my-image
        class="close_btn"
        src="image/close.png"
        alt=""
        @click="this.$parent.show_transfer_state_window = false"
      />
      <div class="title">正在上链的任务</div>
      <div class="list_content" v-if="upper_chain_data && upper_chain_data.list && upper_chain_data.list.length > 0">
        <div class="item" :key="index" v-for="(v,index) in upper_chain_data.list">
          <my-image class="icon" :src="GetResourceImg(v?.coin_vo?.coin_img)" alt="" />
          <div class="item_content">
            <div class="nick_name_box">
              <span
                >{{ v.from_user_vo && v.from_user_vo.nick_name }} -
                {{ v.to_user_vo && v.to_user_vo.nick_name }}</span
              >
              <span class="up_lian_run" v-if="v.transaction_status == -1"
                >上链中</span
              >
              <span class="up_lian_success" v-if="v.transaction_status == 1"
                >成功上链</span
              >
              <span class="up_lian_error" v-if="v.transaction_status == 0"
                >上链失败</span
              >
            </div>
            <span>{{ v.amount }} {{ v.coin_vo.coin_symbol }}</span>
            <span>{{ v.hash }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
export default {
  data() {
    return {};
  },
  props: {
    upper_chain_data: Object,
  },
  methods: {
    GetResourceImg(img) {
      return Global.GetCoinIconURL(img);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.transfer_state_body {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1100;
  box-sizing: border-box;
}
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0px;
}
.content {
  background-color: #191F23;
  display: flex;
  flex-direction: column;
  width: 85%;
  max-height: 80%;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  border-radius: 10px;
}

.content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.list_content {
  background-color: #191F23;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  box-sizing: border-box;
  display: inline-block;
}

.list_content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}


.icon {
  width: 50px;
  height: 50px;
  display: inline-block;
  box-sizing: border-box;
}

.item {
  display: flex;
  margin-bottom: 10px;
  height: 75px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  overflow: hidden;
}
.item_content {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.item_content > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  box-sizing: border-box;
}
.close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  box-sizing: border-box;
}

.nick_name_box {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
}
.nick_name_box > * {
  display: inline-block;
  box-sizing: border-box;
}

.up_lian_run {
  color: #ffb800;
}
.up_lian_success {
  color: #42b983;
}
.up_lian_error {
  color: #ff5722;
}
</style>