import BackDataVO from "./vo/BackDataVO";
import BaseEventVO from "./vo/BaseEventVO";
import ServerCallBackEvent from "./vo/ServerCallBackEvent";
import ServerWaitEvent from "./vo/ServerWaitEvent";

/**
 * 事件驱动器
 */
export default class SuperEvents {
    /**
     * 事件结构体
     */
    private static event_list: BaseEventVO[] = [];
    /**
     * CALL EVENT 事件
     */
    private static call_event_dic: {
        [key: string]: {
            _this: any,
            call_fun: (d: any, _this: any) => void
        }
    } = {};
    /**
     * 提交调用事件
     * @param key 
     * @param data 
     * @returns 
     */
    public static SubmitEvent(key: string, data: string | any): boolean {
        let v = this.call_event_dic[key];
        if (v) {
            let d = data;
            if (typeof data == "string") {
                d = JSON.parse(data);
            }
            this.call_event_dic[key].call_fun(d, v._this);
            return true;
        }
        return false;
    }

    /**
     * 添加回调事件
     * @param _this 
     * @param key 
     * @param call_fun 
     */
    public static AddCallEventListener(_this: any, key: string, call_fun: (data: any, _this: any) => void): void {
        this.call_event_dic[key] = {
            _this: _this,
            call_fun: call_fun
        };
    }

    /**
     * 删除调用字典
     * @param key 
     */
    public static RemoveCallEventListener(key: string): void {
        delete this.call_event_dic[key];
    }

    /**
     * 添加事件
     * @param _this 
     * @param key 
     * @param call_back_fun 回调地址
     * @param group_id 
     */
    public static AddGroupEvent(_this: any, key: string, call_back_fun: (_this: any, data_vo: BackDataVO) => void, group_id: number = 0) {
        let event_vo: ServerCallBackEvent = new ServerCallBackEvent(_this, key, call_back_fun, group_id, 0);
        event_vo.call_back_fun = call_back_fun;
        /**
         * 检查是否存在
         */
        for (let i = this.event_list.length - 1; i >= 0; i--) {
            const v = this.event_list[i];
            if (v.group_id == group_id && v.key == key) {
                this.event_list.splice(i, 1);
            }
        }
        this.event_list.push(event_vo);
    }

    /**
     * 添加事件
     * @param event_vo 
     */
    public static AddEvents(event_vo: BaseEventVO) {
        //#region 进行检测如果存在则进行覆盖处理
        for (let i = 0; i < this.event_list.length; i++) {
            const v = this.event_list[i];
            if (v.group_id == event_vo.group_id && v.key == event_vo.key) {
                //进行覆盖处理
                this.event_list[i] = event_vo;
            }
        }
        //#endregion
        this.event_list.push(event_vo);
    }

    /**
     * 执行事件
     * @param back_data 
     */
    public static CallEvent(back_data: BackDataVO) {
        let find_ok = false;

        for (let i = 0; i < this.event_list.length; i++) {
            const v = this.event_list[i];
            if (v != null) {
                if (v.key === back_data.KEY) {
                    if (v instanceof ServerCallBackEvent) {
                        if (v.max_use_count > 0 || v.max_use_count == 0) {
                            if (v.surplus_count > v.max_use_count && v.max_use_count > 0) {
                                continue; //下一个
                            }
                            //进行执行
                            find_ok = true;
                            if (v.call_back_fun) {
                                v.surplus_count--;
                                if (v.surplus_count < 0 && v.max_use_count > 0)
                                    v.can_use = false; //直接设置成不在可用
                                v.call_back_fun(v.bind_this, back_data);
                            }
                            else {
                                console.log("回调没有找到方法!");
                            }
                        }
                    }

                    /**
                     * 执行回调
                     */
                    if (v instanceof ServerWaitEvent) {
                        if (v.wait_rand_number == back_data.RAND_NUMBER) {
                            v.can_use = false;
                            find_ok = true;
                            if (v.call_back_fun)
                                v.call_back_fun(v.bind_this, back_data);
                        }
                    }
                }
            }
        }

        if (!find_ok) {
            console.log(`【${back_data.KEY}】没有找到接收监听器!`);
        }
        //#region 清空不可用的事件
        for (let i = this.event_list.length - 1; i >= 0; i--) {
            const v = this.event_list[i];
            if (!v.can_use) {
                console.log(`卸载事件:${v.key} ${v.GetEventTypeName} 持续生命周期:${v.LifeCycleTime}`);
                this.event_list.splice(i, 1);
            }
        }
        //#endregion
    }
}