<template>
  <div class="pay_body">
    <div class="pay_content">
      <my-image @click="CloseMe" class="close_btn" src="image/close.png" alt="" />
      <div class="lie lie_center">
        <my-image
          class="icon"
          :src="GetIconResource(coin_type_vo.coin_img)"
          alt=""
        />
        <span class="amount_txt">
          <span class="amount">{{ pay_amount }}</span>
          {{ coin_type_vo.coin_symbol }}
        </span>
        <span class="content_txt">
          {{ pay_message }}
        </span>
      </div>
      <button class="success_btn pay_btn" @click="ToPayAsync()">
        确认支付
      </button>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
import CoinTypeManager from "@/ts/blockchain/CoinTypeManager";
import Tools from "@/ts/Tools";
import { InputBoxTypeEnum, TransferTypeEnum } from "@/ts/SuperEnum";
import AccountManagement from "@/ts/blockchain/AccountManagement";
import { inject } from '@vue/runtime-core';
export default {
  data() {
    return {
      coin_type_vo: {},
    };
  },
    setup() {
    const user_vo = inject("user_vo");
    return {
      user_vo,
    };
  },
  props: {
    pay_transfer_type: TransferTypeEnum, //转账类型
    pay_message: String,
    pay_amount: Number,
    pay_coin_type: Number, //支付币种类型
    pay_address: String, //支付目标地址
    pay_extend_data: Object, //扩展数据
  },
  methods: {
    GetIconResource(img) {
      return Global.GetCoinIconURL(img);
    },
    async ToPayAsync() {
      let old_pwd = Global.PayPassword;
      let input_pwd = "";
      if (Tools.IsNull(old_pwd)) {
        input_pwd = await Global.ShowInputBoxAsync(
          "请设置一个支付密码",
          "",
          InputBoxTypeEnum.Password
        );
        if (Tools.IsNull(input_pwd)) {
          Tools.AlertError("支付密码不能为空!");
          return;
        }
        let que_ren_pwd = await Global.ShowInputBoxAsync(
          "请再次输入",
          "",
          InputBoxTypeEnum.Password
        );
        if (Tools.IsNull(que_ren_pwd)) {
          Tools.AlertError("确认密码不能为空!");
          return;
        }
        if (input_pwd != que_ren_pwd) {
          Tools.AlertError("支付密码与确认密码不匹配!");
          return;
        }
        Global.PayPassword = input_pwd;
      } else {
        input_pwd = await Global.ShowInputBoxAsync(
          "请输入支付密码!",
          "",
          InputBoxTypeEnum.Password
        );
        if (Tools.IsNull(input_pwd)) {
          Tools.AlertError("密码不能为空!");
          return;
        }
        if (input_pwd != old_pwd) {
          Tools.AlertError("支付密码错误!");
          return;
        }
      }

      //调用支付
      let hash = await this.ToTransferAsync(
        this.coin_type_vo,
        this.pay_amount,
        this.pay_address.sys_address,
        this.pay_transfer_type,
        this.pay_extend_data
      );
      if (Tools.IsNull(hash)) {
        Tools.AlertError("交易上链时失败!");
        this.CloseMe();
        return;
      }
      Tools.AlertSuccess("支付正在上链!");
      this.CloseMe();

      //更新下单状态为支付中
      // let up_res = await Tools.GetHtmlAsync("SetUnifiedOrderPaymenting", {
      //   order_number: this.make_order_number,
      //   hash: hash,
      // });
      // if (Tools.CheckHtmlIsOK(up_res)) {
      //   Tools.AlertSuccess("支付请求已发送!");
      //   this.CloseMe();
      // } else {
      //   Tools.AlertSuccess("支付已发出，订单状态更新失败了!");
      //   this.CloseMe();
      // }
    },

    /**
     * 转账
     * @param coin 币种对象
     * @param amount 金额
     * @param to_address 转账目标地址
     * @param transfer_type 转账类型
     * @param extend_data 附加属性
     */
    async ToTransferAsync(coin,amount, to_address, transfer_type, extend_data) {
      //获取我的钱包
      let wallet_vo = AccountManagement.GetNowSelectAccountWalletVO(
        this.user_vo.uid
      );
      if (wallet_vo == null) {
        Tools.AlertError("没有找到您的钱包!");
        return;
      }
      let eth = wallet_vo.GetWalletServer(CurrencySystemIdEnum.LookBao);
      if (eth == null) {
        Tools.AlertError("转账失败，节点对象不存在!");
        return;
      }

      let gas_price = await eth.eth_gasPriceAsync();
      let private_key = wallet_vo.private_key;
      let from_address = wallet_vo.address_vo.address;
      try {
        //获取合约
        let hash = await coin.contract_server?.SendTransferAsync(
          from_address,
          private_key,
          to_address, //from_address, //this.to_address,
          (typeof amount=="string"? parseFloat(amount) : amount) * 10 ** coin.reserved_decimal,
          {
            transfer_type: transfer_type,
            transfer_data: extend_data,
          },
          710000,
          gas_price * 10 ** 9, //10000000000,
          710000
        );
        return hash;
      } catch (ex) {
        Tools.AlertError(ex.message);
      }
    },
    CloseMe() {
      this.$parent.show_unified_payment = false;
    },
  },
  async mounted() {
    //获取币种数据
    let coin_type_vo = CoinTypeManager.singleton.GetCoinTypeVOFromCoinType(
      this.pay_coin_type
    );
    if (coin_type_vo == null) {
      Tools.AlertError("币种获取失败,无法拉起统一支付!");
      this.CloseMe();
    } else {
      let amount = await coin_type_vo.UpdateAmountAsync(); //更新余额
      if (amount < this.pay_amount) {
        Tools.AlertError("余额不足,无法拉起统一支付!");
        this.CloseMe();
        return;
      }
      this.coin_type_vo = coin_type_vo;
    }
  },
};
</script>
<style scoped>
.pay_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.pay_content {
  background-color: #191F23;
  padding: 30px 10px 10px 10px;
  position: relative;
  width: 80%;
  border-radius: 20px;
}
.row {
  display: flex;
  flex-direction: row;
}

.lie {
  display: flex;
  flex-direction: column;
}

.row_center {
  justify-content: center;
}

.lie_center {
  align-items: center;
}

.icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.pay_btn {
  padding: 5px 30px;
  border-radius: 20px;
  margin-top: 20px;
}

.content_txt {
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
.amount_txt {
  font-size: 12px;
  margin-top: 10px;
}

.amount {
  font-size: 25px;
  color: greenyellow;
  font-weight: bold;
}
</style>    