<template>
  <div class="withdrawal_body">
    <div class="withd_rawal_record_body">
      <div>
        <span>实物兑换记录</span>
        <img :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" @click="CloseMe()" />
      </div>
      <Page
        v-show="physical_exchange_application_list.length > 0"
        action="GetMyPhysicalExchangeApplication"
        ref="GetMyPhysicalExchangeApplication"
        :pageNum="30"
        :CompleteDataFunAsync="GetMyPhysicalExchangeApplication"
        class="withd_rawal_record_content"
      >
        <div
          :key="i"
          :class="[
            'withd_rawal_record_item',
            v.state == 1
              ? 'success_active'
              : v.state == 2
              ? 'error_active'
              : '',
          ]"
          v-for="(v, i) in physical_exchange_application_list"
        >
          <div>
            <div>
              <span>物品名:</span>
              <span>{{ v.item_name }}</span>
            </div>

            <div>
              <span>申请时间:</span>
              <span>{{ v.application_time }}</span>
            </div>

            <div>
              <span>审核时间:</span>
              <span>{{ v.processing_time ?? "无" }}</span>
            </div>

            <div>
              <span>审核状态:</span>
              <span>{{ GetState(v.state) }}</span>
            </div>

            <div :key="i" v-for="(e, i) in GetExtendedData(v.extended_data)">
              <span>{{ e.name }}:</span>
              <span>{{ e.value }}</span>
            </div>
          </div>
          <div>
            <span>备注:</span>
            <span>{{ v.remarks ? v.remarks : "无" }}</span>
          </div>
        </div>
      </Page>

      <div
        class="no_data"
        v-show="physical_exchange_application_list.length == 0"
      >
        <span>暂无数据</span>
      </div>
    </div>
  </div>
</template>
<script>
import { inject } from "@vue/runtime-core";
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import Page from "@/components/PageComponent.vue";
export default {
  setup() {
    const user_vo = inject("user_vo");
    return {
      user_vo,
    };
  },
  components: {
    Page,
  },
  data() {
    return {
      show_withd_rawal_record: false, //显示提现记录
      physical_exchange_application_list: [],
    };
  },
  methods: {
    GetBalance(v) {
      if (typeof v == "number") return v.toFixed(2);
      return 0;
    },
    GetExtendedData(v) {
      try {
        let arr = JSON.parse(v);
        return arr;
      } catch(ex) {}
    },
    GetState(state) {
      switch (state) {
        case 0:
          return "未审核";
        case 1:
          return "已发货";
        case 2:
          return "已拒绝";
        default:
          return "未知";
      }
    },
    GetMyPhysicalExchangeApplication(d, page) {
      if (page == 1) {
        this.physical_exchange_application_list = d.data;
      } else {
        this.physical_exchange_application_list.PushAll(d.data);
      }
    },
    async GetMyPhysicalExchangeApplicationAsync() {
      this.$refs["GetMyPhysicalExchangeApplication"]?.Start({});
    },

    CloseMe() {
      this.$parent.show_exchange_record = false;
    },
  },
  mounted() {
    this.GetMyPhysicalExchangeApplicationAsync();
  },
};
</script>
<style scoped lang="less">
.withdrawal_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  // background-color: @c000000_F0F1F5;
  z-index: 1001;
}

.withdrawal_body > div {
  background-color: @c000000_F0F1F5;
  width: 100%;

}

.withdrawal_content_0 {
  position: absolute;
  bottom: 0px;
  font-size: 16px;
  img {
    // width: 24px;
    // height: 24px;
    position: absolute;
    right: 10px;
  }

  div {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 35px 10px;
    border-bottom: 1px solid @c191F24_E1E2E6;
  }
  div:nth-of-type(1) {
    justify-content: center !important;
    padding: 0px 10px;
  }
  div:last-of-type {
    padding: 0px !important;
  }
}

.withdrawal_content {
  height: 100%;
  width: 100%;
  .withdrawal_content_title {
    display: flex;
    justify-content: center;
    position: relative;
    height: 60px;
    align-items: center;
    font-size: 16px;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 10px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 20px;
    font-size: 14px;
    margin-bottom: 20px;
    span:nth-of-type(1),
    span:nth-of-type(2) {
      color: @FFFFFF_c040506;
      margin-bottom: 5px;
      font-size: 14px;
    }
    span:nth-of-type(2) {
      text-align: right;
      display: block;
      width: 100%;
    }

    input,
    select {
      height: 40px;
      border-radius: 6px;
      width: 100%;
      padding: 0px 10px;
    }
  }
}

.que_ding_btn {
  background-color: #02cdb0;
  border-radius: 0px;
  border: 0px;
  width: 100%;
  height: 45px;
}

.button_box {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  button {
    background-color: #02cdb0;
    width: 100%;
    height: 45px;
    border-radius: 20px;
  }
}

.balance {
  position: absolute;
  right: 10px;
  font-size: 12px;
}

.withd_rawal_record_body {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: @c000000_ffffff;
  & > div:nth-of-type(1) {
    height: 40px !important;
    background-color: @c000000_ffffff;
    display: flex;
    align-items: center;
  }
}

// 提现记录
.withd_rawal_record_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  & > div:nth-of-type(1) {
    height: 24px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      width: 16px;
      height: 16px;
      position: absolute;
      // top: 10px;
      right: 10px;
    }
  }
}

.withd_rawal_record_item {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  font-size: 12px;
  background-color: @c303339_ffffff;
  margin-bottom: 5px;
  & > div:nth-of-type(1) {
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 50%;
      text-align: left;
      margin-bottom: 5px;
    }
    & > div > span:nth-of-type(1) {
      color: @ffffff_324460;
    }
  }
}

.success_active {
  background-color: #42b98366;
}
.error_active {
  background-color: #ff572266;
}
// 提现记录

.no_data {
  display: flex;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>