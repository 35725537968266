<template>
<!-- 通用菜单 -->
  <div class="image_menu_box">
    <span :key="index" v-for="(v,index) in general_menu_list" @click="v.menu_click">{{v.menu_name}}</span>
    <span @click="CloseMe">取消</span>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    general_menu_list:[]
  },
  methods: {
    CloseMe() {
      
      this.$parent.show_general_menu = false;
    }
  },
  mounted(){

  }
};
</script>
<style lang="less" scoped>
.image_menu_box {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0px;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1px 0px 0px 0px;
  box-sizing: border-box;
  /* border-radius: 20px; */
}
.image_menu_box > span {
  margin-bottom: 1px;
  color: @ffffff_324460;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: block;
  background-color: @c000000_FFFFFF;
  padding: 20px;
  box-sizing: border-box;
}
</style>