<template>
  <div class="payment_box">
    <TransferAccounts
      :transfer_finish_call="transfer_finish_call"
      :order_number="order_number"
    ></TransferAccounts>
  </div>
</template>
<script>
import TransferAccounts from "@/components/TransferAccounts.vue";
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import CordovaPlugin from "@/ts/CordovaPlugin";
export default {
  components: {
    TransferAccounts,
  },
  props: {
    order_number: String,
    action_guid: String,
  },
  data() {
    return {
      app_id: null,
      user_vo: {},
      /**
       * 转账支付回调
       */
      transfer_finish_call: (hash, amount, coin_type_vo, to_user_vo) => {
        // Global.ToSayAsync(
        //   ChatTypeEnum.TRANSACTION,
        //   this.user_vo,
        //   this.group_vo,
        //   {
        //     hash: hash,
        //     state: TransactionStatusEnum.NONE,
        //     amount: amount,
        //     coin_type_vo,
        //     to_user_vo,
        //   }
        // );
      },
    };
  },
  methods: {
    /**
     * 返回到小程序
     */
    async GoBackAppletAsync() {
      this.$parent.show_payment_box = false;
      await CordovaPlugin.RecoveryAppletAsync(); //执行恢复操作
    },
    SendActionToApplet(flag, d) {
      CordovaPlugin.SendActionToApplet(this.action_guid, {
        flag,
        data: d,
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.payment_box {
  height: 100%;
  width: 100%;
}
</style>