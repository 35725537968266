<template>
  <div class="withdrawal_body">
    <!-- 银行卡列表弹窗 -->
    <div v-show="show_withd_rawal_record" class="withd_rawal_record_body">
      <div>
        <span>银行卡信息</span>
        <img
          :src="
            !$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')
          "
          @click="HideWithdrawalRecord()"
        />
      </div>
      <Page
        action="GetMyBankCardList"
        ref="GetMyBankCardList"
        :pageNum="30"
        :CompleteDataFunAsync="GetMyBankCardList"
        class="withd_rawal_record_content"
      >
        <div class="nolist" v-if="refund_apply_lists.length <= 0">
          未上传银行卡
        </div>
        <div
          v-else
          :key="i"
          class="withd_rawal_record_item success_active"
          v-for="(v, i) in refund_apply_lists"
        >
          <div class="row">
            <div>
              <div>
                <span>银行卡号:</span>
                <span>{{ v.bank_card_number }}</span>
              </div>
              <!-- 
              <div>
                <span>持卡人姓名:</span>
                <span>{{ v.real_name }}</span>
              </div>

              <div>
                <span>持卡人身份证号:</span>
                <span>{{
                  v.card_id
                }}</span>
              </div> -->

              <div>
                <span>持卡人手机号:</span>
                <span>{{ v.phone }}</span>
              </div>
            </div>
            <div
              v-if="v.is_default"
              class="bangding"
            >
              已绑定
            </div>
            <div v-else>
              <button @click="DefaultBinding(v)" class="morenbtn">
                设为默认
              </button>
            </div>
          </div>
        </div>
        <div class="button_box">
                 
          <button @click="showBankconfiguration" class="borderstyle">
            银行卡配置
          </button>
          <button @click="cashWithdrawal">提现</button>
           
        </div>
      </Page>
    </div>
    <!-- 银行卡配置弹窗 -->
    <div v-show="bank_card_configuration" class="withd_rawal_record_body">
      <div>
        <span>银行卡配置</span>
        <img
          :src="
            !$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')
          "
          @click="closeBankconfiguration()"
        />
      </div>
      <div class="item margin">
                <span>银行卡号</span>        
        <input v-model="accountCode" type="text" placeholder="请输入银行卡号" />
             
      </div>

           
      <!-- <div class="item">
                <span>持卡人姓名</span>        
        <input v-model="real_name" type="text" placeholder="请输入持卡人姓名" />
             
      </div>
           
      <div class="item">
                <span>持卡人身份证号</span>        
        <input
          v-model="real_name"
          type="text"
          placeholder="请输入持卡人身份证号"
        />
             
      </div> -->
           
      <div class="item margin">
                <span>持卡人手机号</span>        
        <input v-model="tel" type="text" placeholder="请输入持卡人手机号" />
             
      </div>
      <!-- <span></span> -->
      <div class="itemTop">
        <Switch v-model="is_default" Title="是否为默认卡"></Switch>
      </div>
           
      <div class="button_box">
                <button @click="AddLedgerBankCardAsync">提交</button>      
      </div>
    </div>

    <div v-show="show_account_opening" class="withd_rawal_record_body">
      <div>
        <span>开户设置</span>
        <img
          :src="
            !$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')
          "
          @click="HideAccountOpening()"
        />
      </div>
      <div class="item margin">
                <span>开户人姓名</span>        
        <input
          v-model="accountopeningObj.accountName"
          type="text"
          placeholder="请输入开户人姓名"
        />
             
      </div>

           
      <div class="item margin">
                <span>银行卡号</span>        
        <input
          v-model="accountopeningObj.accountCode"
          type="text"
          placeholder="请输入银行卡号"
        />
             
      </div>
      <div class="item margin">
                <span>证件号码</span>        
        <input
          v-model="accountopeningObj.idCode"
          type="text"
          placeholder="请输入证件号码(身份证)"
        />
             
      </div>
           
      <div class="item margin">
                <span>持卡人电话号</span>        
        <input
          v-model="accountopeningObj.tel"
          type="text"
          placeholder="请输入持卡人电话号"
        />
             
      </div>
           
      <div class="item margin">
                <span>邮箱</span>        
        <input
          v-model="accountopeningObj.email"
          type="text"
          placeholder="请输入邮箱(非必填)"
        />
             
      </div>
           
      <div class="button_box">
                <button @click="AccountOpeningSettings">提交</button>      
      </div>
    </div>

    <!-- 分账提现弹窗 -->
    <div v-show="show_cash_withdrawal" class="withd_rawal_record_body">
      <div>
        <span>提现</span>
        <img
          :src="
            !$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')
          "
          @click="HideCashWithdrawal()"
        />
      </div>
      <!-- <div class="item">
                <span>提现金额</span>        
        <input
          v-model="CashWithdrawalAmount"
          type="text"
          placeholder="请输入提现金额"
        />
             
      </div> -->
      <div class="item margin_top">
        <span
          >提现金额(提现金额不能低于{{
            refund_deduction_props_vo?.refund_config_vo?.minimum_amount
          }})</span
        >
        <input
          v-model="CashWithdrawalAmount"
          type="text"
          placeholder="请填写提现金额"
        />
        <span
          >手续费:{{
            use_deduction
              ? 0
              : refund_deduction_props_vo?.refund_config_vo
                  ?.service_charge_percentage * 100
          }}%</span
        >
      </div>
           
      <div class="button_box">
               
        <button @click="RefundApplyAsync">提交可提现({{ ShowAmount }})</button>
             
      </div>
    </div>

    <!-- 分账提现方式弹窗 -->
    <div class="withdrawal_content_0" v-show="select_model == 0">
      <div>
        <span>提现方式</span>
        <img
          v-if="$store.state.colorbtn"
          :src="
            !$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')
          "
          @click="CloseMe"
        />
        <img
          class="cont_btn"
          v-else
          src="@/assets/image/xiaoxi/blackx.png"
          @click="CloseMe"
        />
      </div>
      <div @click="ShowWithdrawal" v-if="whetherCheck">
        <span>查看提现记录</span>
      </div>
      <div @click="ShowWithdrawalRecord" v-if="whetherCheck">
        <span>使用银行卡</span>
      </div>
      <div @click="ShowAccountOpening" v-else>
        <span>开户设置</span>
      </div>
      <div>
        <button class="que_ding_btn"></button>
      </div>
    </div>
    <div v-show="show_withd_rawal" class="withd_rawal_record_body">
      <div>
        <span>提现记录</span>
        <img
          :src="
            !$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')
          "
          @click="HideWithdrawal()"
        />
      </div>
      <Page
        action="GetRefundApplyList"
        ref="GetRefundApplyList"
        :pageNum="30"
        :CompleteDataFunAsync="GetRefundApplyList"
        class="withd_rawal_record_content"
      >
        <div
          :key="i"
          class="withd_rawal_record_item success_active"
          v-for="(v, i) in refund_apply_list"
        >
          <div>
            <div>
              <span>提现金额:</span>
              <span>￥{{ v.refund_apply_vo.amount }}</span>
            </div>

            <div>
              <span>申请时间:</span>
              <span>{{ v.refund_apply_vo.application_time }}</span>
            </div>

            <div>
              <span>提现方式:</span>
              <span>{{
                v.refund_apply_vo.method == 1 ? "支付宝" : "银行卡"
              }}</span>
            </div>

            <div>
              <span>审核时间:</span>
              <span>{{ v.refund_apply_vo.processing_time ?? "无" }}</span>
            </div>

            <div>
              <span>审核状态:</span>
              <span>{{ GetState(v.refund_apply_vo.state) }}</span>
            </div>
          </div>
          <div>
            <span>备注:</span>
            <span>{{
              v.refund_apply_vo.remarks ? v.refund_apply_vo.remarks : "无"
            }}</span>
          </div>
        </div>
      </Page>
    </div>
  </div>
</template>
  <script>
import { inject } from "@vue/runtime-core";
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import Page from "@/components/PageComponent.vue";
import Switch from "@/components/lookbao_components/LookBaoSwitch.vue";

export default {
  setup() {
    const user_vo = inject("user_vo");
    return {
      user_vo,
    };
  },
  watch: {
    amount(_new, _old) {
      if (_new > this.user_vo.balance) this.amount = this.user_vo.balance;
      if (_new <= 0) this.amount = "";
    },
  },
  computed: {
    CanToRefund() {
      if (isNaN(this.amount)) return false;
      if (Tools.IsNull(this.amount)) return false;
      if (
        this.amount >=
        this.refund_deduction_props_vo?.refund_config_vo?.minimum_amount
      )
        return true;
      return false;
    },
    /**
     * 显示可提现金额
     */
    ShowAmount() {
      let v = 0;
      if (this.use_deduction) {
        if (typeof this.amount == "number") {
          v = this.amount.toFixed(2);
        } else {
          v = parseFloat(this.amount);
          if (isNaN(v)) {
            v = 0;
          }
          v = v.toFixed(2);
        }
      } else {
        let service_charge_percentage =
          this.refund_deduction_props_vo?.refund_config_vo
            ?.service_charge_percentage;
        if (service_charge_percentage <= 0) service_charge_percentage = 1;

        v = (this.amount - this.amount * service_charge_percentage).toFixed(2);
      }
      if (isNaN(v)) {
        return "0.00";
      }
      return v;
    },
  },
  components: {
    Page,
    Switch,
  },
  data() {
    return {
      use_deduction: false, //是否使用抵扣
      refund_deduction_props_vo: {},
      ls_select_model: 1, //临时选项
      select_model: 0, //0选择提现方式 1支付宝 2银行卡
      zfb_account: "", //支付宝账号
      real_name: "", //真实姓名
      amount: "", //提现金额
      bank_card_number: "", //银行卡号
      deposit_bank: "", //开户行
      show_withd_rawal_record: false, //显示提现记录
      bank_card_configuration: false, //银行卡配置
      refund_apply_list: [], //提现记录列表
      refund_apply_lists: [], //银行卡列表
      whetherCheck: false, //检测是否开户
      //   whetherCheck
      show_account_opening: false, //显示开户页面
      accountopeningObj: {},
      tel: "",
      is_default: false,
      accountCode: "",
      show_cash_withdrawal: false,
      show_withd_rawal: false,
    };
  },
  methods: {
    GetBalance(v) {
      if (typeof v == "number") return v.toFixed(2);
      return 0;
    },
    /**
     * 获取资源图
     */
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    GetState(state) {
      switch (state) {
        case 0:
          return "未审核";
        case 1:
          return "已通过";
        case 2:
          return "已拒绝";
        default:
          return "未知";
      }
    },

    QueRenSelectModel() {
      this.select_model = this.ls_select_model;
    },
    //显示银行卡设置
    ShowWithdrawalRecord() {
      this.GetMyBankCardListAsync();
      this.show_withd_rawal_record = true;
    },
    HideWithdrawalRecord() {
      this.refund_apply_list = [];
      this.show_withd_rawal_record = false;
    },

    //显示提现记录
    ShowWithdrawal() {
      this.GetRefundApplyListAsync();
      this.show_withd_rawal = true;
    },
    HideWithdrawal() {
      this.show_withd_rawal = false;
    },

    GetRefundApplyList(d, page) {
      if (page == 1) {
        this.refund_apply_list = d.data;
      } else {
        this.refund_apply_list.PushAll(d.data);
      }
    },
    async GetRefundApplyListAsync() {
      // let res = await Tools.GetHtmlAsync("GetRefundApplyList", {
      //   state: -1,
      // });
      // if (Tools.CheckHtmlIsOK(res)) {
      //   this.refund_apply_list = res.data.data;
      // }
      this.$refs["GetRefundApplyList"]?.Start({
        state: -1,
      });
    },
    //显示开户设置
    ShowAccountOpening() {
      this.show_account_opening = true;
    },
    HideAccountOpening() {
      this.show_account_opening = false;
    },
    /**
     *
     */
    async GetMyRefundDeductionPropsAsync() {
      let res = await Tools.GetHtmlAsync("GetMyRefundDeductionProps", {});
      if (Tools.CheckHtmlIsOK(res)) {
        this.refund_deduction_props_vo = res.data;
      }
    },
    CloseMe() {
      this.ls_select_model = 1;
      this.select_model = 0;
      this.$parent.show_withdrawalfenzhang = false;
    },
    async ToRefundApplyAsync() {
      if (!this.CanToRefund) return;
      if (Tools.IsNull(this.amount)) {
        Tools.AlertError("提现金额不能为空!");
        return;
      }
      let amount = parseFloat(this.amount);
      if (amount <= 0) {
        Tools.AlertError("提现金额必须大于0!");
        return;
      }
      Global.MessageBox("系统提示!", "您确定要提现么?", async () => {
        switch (parseInt(this.select_model)) {
          case 1:
            if (Tools.IsNull(this.zfb_account)) {
              Tools.AlertError("支付宝账号不能为空!");
              return;
            }
            if (Tools.IsNull(this.real_name)) {
              Tools.AlertError("支付宝姓名不能为空!");
              return;
            }
            break;
          case 2:
            if (Tools.IsNull(this.bank_card_number)) {
              Tools.AlertError("银行卡号不能为空!");
              return;
            }
            if (Tools.IsNull(this.real_name)) {
              Tools.AlertError("银行用户名不能为空!");
              return;
            }
            if (Tools.IsNull(this.deposit_bank)) {
              Tools.AlertError("开户行不能为空!");
              return;
            }
            break;
          default:
            Tools.AlertError("提现方式错误!!");
            return;
        }
        //开始提交数据

        // if(this.select_model=1){
        //   this.bank_card_number='',
        //   this.deposit_bank=''
        // }else{
        //   this.zfb_account=''
        // }
        let res = await Tools.GetHtmlAsync("RefundApply", {
          zfb_account: this.zfb_account,
          real_name: this.real_name,
          amount: this.amount,
          bank_card_number: this.bank_card_number,
          deposit_bank: this.deposit_bank,
          method: this.select_model,
          use_deduction: this.use_deduction ? 1 : 0,
        });
        if (Tools.CheckHtmlIsOK(res)) {
          Tools.AlertSuccess("操作成功!");
          Global.UpdateMyLoginUserAsync();
          this.CloseMe();
        }
      });
    },
    //显示银行卡配置
    showBankconfiguration() {
      this.bank_card_configuration = true;
    },
    //关闭银行卡配置
    closeBankconfiguration() {
      this.bank_card_configuration = false;
    },
    //检测用户是否开户
    CheckHaveCollectionAccountAsync() {
      Tools.GetHtmlAsync("CheckHaveCollectionAccount", {}).then((res) => {
        if (Tools.CheckHtmlIsOK(res)) {
          if (res.data.is_open) {
            this.whetherCheck = true;
          } else {
            this.whetherCheck = false;
          }
        }
      });
    },
    //创建开户
    async AccountOpeningSettings() {
      await Global.ShowGlobalLoadingAsync("开户中请稍后....", async (m) => {
        Tools.GetHtmlAsync("UserCollectionAccountOpening", {
          tel: this.accountopeningObj.tel,
          accountName: this.accountopeningObj.accountName,
          accountCode: this.accountopeningObj.accountCode,
          idCode: this.accountopeningObj.idCode,
          email: this.accountopeningObj.email,
        }).then((res) => {
          if (Tools.CheckHtmlIsOK(res)) {
            this.GetMyBankCardListAsync();
            this.show_account_opening = false;
            this.show_withd_rawal_record = true;
          }
        });
      });
    },
    //添加银行卡
    AddLedgerBankCardAsync() {
      Tools.GetHtmlAsync("AddLedgerBankCard", {
        accountCode: this.accountCode,
        tel: this.tel,
        is_default: this.is_default ? 1 : 2,
      }).then((res) => {
        if (Tools.CheckHtmlIsOK(res)) {
          this.bank_card_configuration = false;
          this.GetMyBankCardListAsync();
        }
      });
    },
    //获取分账银行卡列表
    // GetMyBankCardList(d, page) {
    //   if (page == 1) {
    //     this.refund_apply_list = d.data;
    //   } else {
    //     this.refund_apply_list.PushAll(d.data);
    //   }
    // },
    // async GetMyBankCardListAsync() {
    //   // let res = await Tools.GetHtmlAsync("GetRefundApplyList", {
    //   //   state: -1,
    //   // });
    //   // if (Tools.CheckHtmlIsOK(res)) {
    //   //   this.refund_apply_list = res.data.data;
    //   // }
    //   this.$refs["GetMyBankCardList"]?.Start({
    //     state: -1,
    //   });
    // },

    //获取分账银行卡列表
    async GetMyBankCardListAsync() {
      let res = await Tools.GetHtmlAsync("GetMyBankCardList", {
        page: 1,
        num: 30,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        this.refund_apply_lists = res.data.data;
      }
    },
    //绑定
    async DefaultBinding(row) {
      let res = await Tools.GetHtmlAsync("SetDefaultBankCardFromID", {
        id: row.id,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        this.GetMyBankCardListAsync();
      }
    },
    //提现
    cashWithdrawal() {
      this.show_cash_withdrawal = true;
    },
    //关闭提现
    HideCashWithdrawal() {
      this.show_cash_withdrawal = false;
    },
    //提现
    async RefundApplyAsync() {
      let res = await Tools.GetHtmlAsync("RefundApply", {
        method: 3,
        amount: this.CashWithdrawalAmount,
        use_deduction: 0,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        Tools.AlertSuccess("操作成功!");
        Global.UpdateMyLoginUserAsync();
        this.CloseMe();
      }
    },
  },
  mounted() {
    this.amount = this.GetBalance(this.user_vo.balance);
    this.GetMyRefundDeductionPropsAsync();
    this.CheckHaveCollectionAccountAsync();
  },
};
</script>
  <style scoped lang="less">
.withdrawal_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.withdrawal_body > div {
  background-color: @bottom_class;
  width: 100%;
}
.cont_btn {
  width: 16px !important;
  height: auto !important;
}
.withdrawal_content_0 {
  position: absolute;
  bottom: 0px;
  font-size: 16px;

  img {
    width: 16px;
    position: absolute;
    right: 10px;
  }

  div {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 35px 10px;
    border-bottom: 1px solid @tixian_border;
  }
  div:nth-of-type(1) {
    justify-content: center !important;
    padding: 0px 10px;
  }
  div:last-of-type {
    padding: 0px !important;
  }
}

.withdrawal_content {
  height: 100%;
  width: 100%;
  .withdrawal_content_title {
    display: flex;
    justify-content: center;
    position: relative;
    height: 60px;
    align-items: center;
    font-size: 16px;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 10px;
    }
  }
}

.que_ding_btn {
  background-color: @bottom_class;
  border-radius: 0px;
  border: 0px;
  width: 100%;
  height: 45px;
  color: white;
}

.button_box {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  bottom: 60px;
  position: absolute;

  button {
    border: 0px;
    background-color: #02cdb0;
    margin: 10px;
    width: 100%;
    height: 45px;
    border-radius: 20px;
    color: white;
  }
}

.balance {
  position: absolute;
  right: 10px;
  font-size: 12px;
}

.withd_rawal_record_body {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: @c000000_F0F1F5;
  & > div:nth-of-type(1) {
    height: 40px !important;
    background-color: @c000000_F0F1F5;
    display: flex;
    align-items: center;
  }
}

// 提现记录
.withd_rawal_record_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  & > div:nth-of-type(1) {
    height: 24px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      position: absolute;
      // top: 10px;
      right: 10px;
    }
  }
}

.withd_rawal_record_item {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  font-size: 12px;
  background-color: @c303339_ffffff;
  margin-bottom: 5px;
  & > div:nth-of-type(1) {
    font-size: 16px;

    & > div {
      text-align: left;
      margin-bottom: 5px;
    }
    & > div > span:nth-of-type(1) {
      color: @ffffff_324460;
    }
  }
}

.success_active {
  background-color: @c313940_F6F6F6;
  position: relative;
  border-radius: 10px;
  margin: 10px;
}
.error_active {
  background-color: #ff572266;
  margin: 10px;
  border-radius: 5px;
}
// 提现记录

.daoju_dikou {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  & > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    text-align: left !important;
    span {
      display: inline-block;
      width: 100%;
      text-align: left !important;
    }
  }
  & > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 10px;
    }
  }
}
.select {
  background-color: @input_bgc;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px;
  font-size: 14px;
  span:nth-of-type(1),
  span:nth-of-type(2) {
    color: @FFFFFF_c040506;
    margin-bottom: 5px;
    font-size: 14px;
  }
  span:nth-of-type(2) {
    text-align: right;
    display: block;
    width: 100%;
  }

  input,
  select {
    padding: 0px 10px;
    width: 100%;
    height: 40px;
    border: 0px;
    background-color: transparent;
    outline: none;
    color: var(--FFFFFF_c040506, #ffffff);
    font-size: 16px;
    border-bottom: 2px solid #f0ba52;
    margin-bottom: 20px;
  }
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    border: 0;
    color: #ffffff;
    background-color: #f58220;
    padding: 5px;
    border-radius: 5px;
    font-size: 13px;
  }
}
.itemTop {
  margin: 40px 20px;
}
.margin {
  margin-bottom: -60px;
}
.margin_top {
  margin-top: 30px;
}
.nolist {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.borderstyle {
  color: #02cdb0 !important;
  border: 1px #02cdb0 solid !important;
  background-color: @c000000_F0F1F5 !important;
}
.bangding {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #5b6670;
  padding: 2px 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #02cdb0;
  color: white;
}
</style>