import CordovaPlugin from "./CordovaPlugin";
import Global from "./Global";
import Tools from "./Tools";

export default class AppletPlugin {
    /**
     * 等待小程序监听
     */
    public static InitAppletEventListening() {
        let w: any = window;
        if (w.AppletEventListening == null)
            w.AppletEventListening = (guid: string, action: string, data: string) => {
                switch (action) {
                    case "GetLoginInformation": //获取登录账号数据
                        let user_vo = Global.login_user_vo;
                        if (user_vo != null) {
                            this.SendActionToApplet(guid, {
                                id: user_vo.id,
                                account: user_vo.account,
                                nick_name: user_vo.nick_name,
                                uid: user_vo.uid,
                                head_pic: Global.GetCoinIconURL(user_vo.head_pic),
                                wallet_address: user_vo.wallet_address
                            });
                        }
                        break;
                    case "ToShare": //分享
                        let share_obj = JSON.parse(data);
                        Tools.GoTo("/share_window", {}, {
                            share_obj: share_obj
                        });
                        break;
                    case "ToPayment": //支付
                        let payment_vo = JSON.parse(data);
                        let order_number = payment_vo.order_number;
                        Global.ShowPayment(order_number, guid);
                        break;
                }
            };
    }
    /**
     * 发送消息到小程序端
     * @param guid 
     * @param data 
     */
    private static SendActionToApplet(guid: string, data: any) {
        CordovaPlugin.SendActionToApplet(guid, data);
    }
}