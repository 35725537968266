<template>
  <div :class="['body', is_popup ? 'popup_body' : '']">
    <div class="head" v-if="is_popup">
      <span>{{ title }}</span>
      <img @click="ClosePopupWebView" class="back_btn" src="image/back.png" />
    </div>
    <iframe
      key="my_web_view"
      v-if="url != ''"
      :class="is_popup ? 'popup_iframe' : ''"
      id="iframe"
      name="refresh_name"
      sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
      scrolling="auto"
      :src="GetURL"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
export default {
  data() {
    return {
      url: "",
      title: "",
    };
  },
  props: {
    web_url: String,
    web_title: String,
    is_popup: Boolean,
  },
  watch: {
    title(_new, _old) {
      Global.SetTitle(_new);
    },
  },
  computed: {
    GetURL() {
      if (!this.url) this.url = "";
      if (this.url.indexOf("?") == -1) {
        return this.url + `?t=${Tools.GetNowTime}`;
      } else {
        return this.url + `&t=${Tools.GetNowTime}`;
      }
    },
    ClosePopupWebView(){
      Tools.ClosePopupWebView();
    }
  },
  methods: {},
  mounted() {
    let p = Tools.GetParams();
    if (this.is_popup) {
      
      if (Tools.IsNotNull(this.web_url)) {
        this.url = this.web_url;
        this.title = this.web_title;
        return;
      }
    }
    this.url = p.url;
    this.title = p.title;

    // Global.SetButtonList([
    //   {
    //     name: "刷新",
    //     click: () => {
    //       let my_iframe = document.querySelector("#iframe");
    //       my_iframe?.remove();

    //       let body = document.querySelector(".body");
    //       let _iframe = document.createElement("iframe");
    //       let span = document.createElement("span");
    //       span.innerHTML="1234";

    //       // iframe.setAttribute("id", "iframe");
    //       // iframe.setAttribute("name", "refresh_name");
    //       // iframe.setAttribute(
    //       //   "sandbox",
    //       //   "allow-forms allow-scripts allow-same-origin allow-popups"
    //       // );
    //       // iframe.setAttribute("scrolling", "auto");
    //       // iframe.setAttribute("frameborder", "0");
    //
    //       body.appendChild(_iframe);
    //       body.appendChild(span);
    //       _iframe.setAttribute("src", this.url);
    //     },
    //   },
    // ]);
  },
};
</script>
<style lang="less" scoped>
.body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: @home_bgc_class;
}
html {
    background-color: @home_bgc_class!important;
    color: white;
    font-size: 12px;
  }
iframe {
  width: calc(100% + 20px);
  height: calc(100% + 15px);
  border: none;
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
iframe::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.popup_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.popup_iframe {
  position: static !important;
}
.back_btn {
  width: 20px;
  height: 20px;
  position: fixed;
  /* top: 5px; */
  left: 5px;
}
.head {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>