var EthUtil = require('ethereumjs-util');
var Wallet = require('ethereumjs-wallet');
const hdAddress = require("hd-address")
const eosEcc = require('eosjs-ecc');
import Global from "../Global";
import { CurrencySystemIdEnum } from "../SuperEnum";
import Tools from "../Tools";
import BaseWallet, { ChangeEnum, CoinTypeEnum, MnemonicStrengthEnum, WordLanguageEnum } from "./BaseWallet";
import AccountWalletVO from "./vo/AccountWalletVO";
import AddressVO from "./vo/AddressVO";
import CurrencySystemAddressVO from "./vo/CurrencySystemAddressVO";


/**
 * 账号管理类
 */
export default class AccountManagement {
    /**
     * 助记词缓存
     */
    private static MnemonicCacheDic: { [key: string]: any } = {};
    /**
     * 是否已经初始化过
     */
    public static is_init: boolean = false;
    /**
     * 钱包初始化订阅函数队列
     */
    public static InitializeSubscriptionQueue: (() => void)[] = [];

    /**
     * 账号的GUID
     */
    private static guid: string = "";
    /**
     * 账号列表
     */
    private static account_wallet_dic: { [uid: string]: AccountWalletVO[] } = {};
    /**
     * 当前选择的账号索引
     */
    private static select_account_wallet_index: number = 0;
    /**
     * 密钥
     */
    private static pwd: string = "";

    /**
     * 添加到初始化消息队列
     * @param call 
     */
    public static AddToWalletInitializeQueue(call: () => void): void {
        if (!this.is_init) {
            this.InitializeSubscriptionQueue.push(call);
        }
        else {
            call();
        }
    }

    /**
     * 监测是否存在钱包账号
     */
    public static CheckHaveAccountWallet(uid: string): boolean {
        if (this.account_wallet_dic[uid]) {
            return this.account_wallet_dic[uid].length > 0;
        }
        else {
            this.account_wallet_dic[uid] = [];
            return false;
        }
    }

    /**
     * 根据助记词获取钱包信息
     * @param in_mnemonic 
     * @returns 
     */
    public static async GetWalletDataFromMnemonicAsync(in_mnemonic: string, coin_type: CoinTypeEnum = CoinTypeEnum.IM) {
        try {
            if (coin_type == null)
                coin_type = CoinTypeEnum.IM;

            console.log(`MM1:${Tools.GetNowTime}`);

            //创建一个钱包
            let ms: {
                mnemonic: string,
                seed: Promise<Buffer> | null
            } = {
                mnemonic: in_mnemonic,
                seed: null
            };
            if (Tools.IsNull(in_mnemonic)) { 
                ms = BaseWallet.CreateMnemonicAndSeed(
                    "",
                    MnemonicStrengthEnum.L_12,
                    WordLanguageEnum.english
                );
            }

            console.log(`MM2:${Tools.GetNowTime}`);

            let mnemonic = ms.mnemonic;
            let seedBuffer: Buffer;
            let seed: string;
            if (!this.MnemonicCacheDic[in_mnemonic]) {
                ms.seed = BaseWallet.MnemonicToSeed(in_mnemonic, "");
                console.log(`MM2-1:${Tools.GetNowTime}`);
                seedBuffer = await ms.seed;
                console.log(`MM2-2:${Tools.GetNowTime}`);
                seed = seedBuffer.toString("hex");
                console.log(`MM3:${Tools.GetNowTime}`);
                this.MnemonicCacheDic[in_mnemonic] = {
                    seed: seed,
                    seedBuffer: seedBuffer
                };
            }
            else {
                let vv = this.MnemonicCacheDic[in_mnemonic];
                seed = vv.seed;
                seedBuffer = vv.seedBuffer;
            }


            //生成引导地址(公链引导地址)
            let derve_path_external = BaseWallet.CreateDervePath(
                coin_type,
                0,
                ChangeEnum.ExternalChain,
                0
            );
            console.log(`MM4:${Tools.GetNowTime}`);
            let hd = hdAddress.HD(seedBuffer, hdAddress.keyType.seed);
            console.log(`MM5:${Tools.GetNowTime}`);
            //创建以太坊钱包
            var hdWallet = BaseWallet.CreateNewHDWallet(seedBuffer);
            console.log(`MM6:${Tools.GetNowTime}`);
            //获取地址于私钥
            var address_and_key = BaseWallet.GetWalletAddressAndPrivateKey(
                hdWallet._hdwallet,
                derve_path_external
            );
            console.log(`MM7:${Tools.GetNowTime}`);
            //公共扩展密钥
            let publicExtendedKey = address_and_key.publicExtendedKey;

            let address: string = address_and_key.address;

            let privateKey = address_and_key.privateKey; //私钥
            let publicKey = address_and_key.publicKey.toString("hex"); //公钥


            switch (coin_type) {
                case CoinTypeEnum.BTC:
                    address = hd.BTC.getAddress(0, 0, ChangeEnum.ExternalChain).address;
                    break;
                case CoinTypeEnum.EOS:
                    let eosPrivate = eosEcc.seedPrivate(seed);
                    console.log("EOS私钥：", eosPrivate)
                    const eosPubkey = eosEcc.privateToPublic(eosPrivate);
                    console.log("EOS公钥：", eosPubkey)
                    address = eosPubkey;
                    privateKey = eosPrivate;
                    publicKey = eosPubkey;
                    break;
                case CoinTypeEnum.TRX:
                    address = hd.TRX.getAddress(0, 0, ChangeEnum.ExternalChain).address;
                    break;
                default:
                    break;
            }
            console.log(`MM8:${Tools.GetNowTime}`);

            console.log(`公钥:${publicKey}`);
            console.log(`私钥${privateKey}`);
            console.log(`助记词:${mnemonic}`);
            if (publicKey.indexOf("0x") == -1)
                publicKey = "0x" + publicKey;
            console.log(`MM9:${Tools.GetNowTime}`);
            return {
                flag: true,
                address: address,
                privateKey: privateKey,
                publicKey: publicKey,
                coin_type: coin_type,
            }
        }
        catch (ex) {
            console.log(`MM10:${Tools.GetNowTime}`);
            return {
                flag: false,
                address: "",
                privateKey: "",
                publicKey: "",
                coin_type: coin_type
            };
        }
    }

    /**
     * 创建一个新的钱包
     * @param uid 用户UID
     * @param wallet_name 钱包名称
     * @param pwd 密钥用于生成Keystore
     * @param coin_type 货币类型
     * @param in_mnemonic 助记词(强制使用助记词创建)
     * @param in_private_key 私钥(强制使用私钥创建)
     * @param in_keystore 根据Keystore恢复私钥(配合密码恢复)
     */
    public static async AddNewWalletAsync(uid: string, wallet_name: string, pwd: string, coin_type: CoinTypeEnum, in_mnemonic: string = "", in_private_key = "", in_keystore = "") {
        try {

            //创建一个钱包
            let ms = BaseWallet.CreateMnemonicAndSeed(
                "",
                MnemonicStrengthEnum.L_12,
                WordLanguageEnum.english
            );
            if (Tools.StrIsNotNull(in_mnemonic)) {
                ms.mnemonic = in_mnemonic;
                ms.seed = BaseWallet.MnemonicToSeed(in_mnemonic, "");
            }
            let mnemonic = ms.mnemonic;
            let seedBuffer = await ms.seed;
            let seed = seedBuffer.toString("hex");

            //生成引导地址(公链引导地址)
            let derve_path_external = BaseWallet.CreateDervePath(
                60,
                0,
                ChangeEnum.ExternalChain,
                0
            );


            //创建以太坊钱包
            var hdWallet = BaseWallet.CreateNewHDWallet(seedBuffer);

            //获取地址于私钥
            var address_and_key = BaseWallet.GetWalletAddressAndPrivateKey(
                hdWallet._hdwallet,
                derve_path_external
            );

            //公共扩展密钥
            let publicExtendedKey = address_and_key.publicExtendedKey;

            let address: string = address_and_key.address;
            let address_external = address_and_key.address;
            let privateKey = address_and_key.privateKey; //私钥
            let publicKey = address_and_key.publicKey.toString("hex"); //公钥

            //监测是否是in_keystore恢复
            if (Tools.StrIsNotNull(in_keystore)) {

                try {
                    in_private_key = await BaseWallet.GetPrivateFromKeystoreAsync(in_keystore, pwd);
                }
                catch (ex) {

                    throw ex;
                }
            }

            //监测是否强制私钥恢复
            if (Tools.StrIsNotNull(in_private_key)) {
                privateKey = in_private_key;
                const privateKeyBuffer = EthUtil.toBuffer(privateKey);
                const wallet = Wallet.default.fromPrivateKey(privateKeyBuffer);
                publicKey = wallet.getPublicKeyString(); //重新计算公钥
                publicKey = EthUtil.toBuffer(publicKey);
                let addr = "0x" + EthUtil.publicToAddress(publicKey).toString('hex');
                address = addr; //重新计算地址 
                //清空其他数据
                mnemonic = "";
            }

            console.log(`公钥:${publicKey}`);
            console.log(`私钥${privateKey}`);
            console.log(`助记词:${mnemonic}`);
            if (publicKey.indexOf("0x") == -1)
                publicKey = "0x" + publicKey;


            if (this.account_wallet_dic[uid] == null)
                this.account_wallet_dic[uid] = [];
            this.account_wallet_dic[uid].Clear();

            let account_wallet_vo = await (new AccountWalletVO(uid, wallet_name, privateKey, publicKey, mnemonic, pwd).InitAsync())
            this.account_wallet_dic[uid].push(account_wallet_vo);
            this.select_account_wallet_index = this.account_wallet_dic[uid].length - 1;
            return true;
        }
        catch (ex) {
            return false;
        }
    }

    /**
     * 获取当前选择的钱包账号数据 
     */
    public static GetNowSelectAccountWalletVO(uid: string): AccountWalletVO | null {
        try {

            let wallet_list = this.account_wallet_dic[uid];
            if (wallet_list == null) {
                return null;
            }
            if (this.select_account_wallet_index + 1 > wallet_list.length)
                this.select_account_wallet_index = 0;
            let acc_vo = wallet_list[this.select_account_wallet_index];
            if (acc_vo == null)
                return null;
            return acc_vo;
        }
        catch (ex) {
            return null;
        }
    }

    /**
     * 根据账号的GUID选择一个钱包
     * @param uid 用户UID
     * @param guid 根据钱包GUID筛选
     */
    public static SelectWalletFromGUID(uid: string, guid: string) {
        for (let i = 0; i < this.account_wallet_dic[uid].length; i++) {
            const acc_vo = this.account_wallet_dic[uid][i];
            if (acc_vo.guid == guid) {
                this.select_account_wallet_index = i;
                break;
            }
        }
    }

    /**
     * 获取资产
     * @param coin_type 
     */
    public static async GetAmountFromCoinTypeAsync(uid: string, coin_type: CoinTypeEnum): Promise<number> {
        let v = await this.GetAddressVOFromCoinTypeAsync(uid, coin_type);
        if (v == null)
            return 0;
        return v.amount;
    }

    /**
     * 根据货币类型获取金额
     */
    public static async GetAddressVOFromCoinTypeAsync(uid: string, coin_type: CoinTypeEnum | null, currency_system_id: CurrencySystemIdEnum = CurrencySystemIdEnum.LookBao): Promise<AddressVO | null> {
        let v = this.GetNowSelectAccountWalletVO(uid);
        if (v == null)
            return v;

        if (coin_type == null)
            coin_type = Global.GetSelectCoinType();
        let cs_v = v.currency_system_address_list[currency_system_id];
        if (cs_v.address.coin_type == coin_type) {

            await cs_v.address.UpdateAmountAsync();
            return cs_v.address;
        }
        for (let i = 0; i < cs_v.contract_address_list.length; i++) {
            const addr = cs_v.contract_address_list[i];

            if (addr.coin_type == coin_type) {

                await addr.UpdateAmountAsync();
                return addr;
            }
        }

        return null;
    }

    /**
     * 获取主币种地址结构
     */
    public static GetMainAddressWalletAddressVO(uid: string): AddressVO | null {
        let v = this.GetNowSelectAccountWalletVO(uid);
        if (v == null)
            return v;
        return v.currency_system_address_list[Global.GetSelectCurrencySystemID()].address;
    }

    /**
     * 获取当前选择的币种的地址数据
     */
    public static async GetNewSelectAddressVOAsync(uid: string, currency_system_id: CurrencySystemIdEnum = CurrencySystemIdEnum.LookBao): Promise<AddressVO | null> {
        let coin_type: CoinTypeEnum = Global.GetSelectCoinType();
        let v = this.GetNowSelectAccountWalletVO(uid);
        if (v == null)
            return v;
        let csa_vo = v.currency_system_address_list[currency_system_id];
        if (csa_vo.address.coin_type == coin_type) {
            await csa_vo.address.UpdateAmountAsync();
            return csa_vo.address;
        }
        for (let i = 0; i < csa_vo.contract_address_list.length; i++) {
            const addr = csa_vo.contract_address_list[i];
            if (addr.coin_type == coin_type) {
                await addr.UpdateAmountAsync();
                return addr;
            }
        }
        return null;
    }

    /**
     * 获取总资产
     */
    public static async GetAllAmountAsync(uid: string, currency_system_id: CurrencySystemIdEnum = CurrencySystemIdEnum.LookBao): Promise<number> {

        let amount = 0;
        let v = this.GetNowSelectAccountWalletVO(uid);
        if (v == null)
            return 0;

        let cs_v = v.currency_system_address_list[currency_system_id];
        if (cs_v.address == null)
            return 0;
        await cs_v.address.UpdateAmountAsync();
        amount += cs_v.address.amount;

        for (let i = 0; i < cs_v.contract_address_list.length; i++) {
            const addr = cs_v.contract_address_list[i];

            await addr.UpdateAmountAsync();
            amount += addr.amount;
        }
        return amount;
    }

    /**
     * 删除账号
     * @param guid 
     */
    public static RemoveAccountWallet(uid: string, guid: string) {
        for (let i = 0; i < this.account_wallet_dic[uid].length; i++) {
            const v = this.account_wallet_dic[uid][i];
            if (v.guid == guid) {
                //开始删除
                this.account_wallet_dic[uid].splice(i, 1);
                break;
            }
        }
    }

    /**
     * 获取我的所有账号列表
     */
    public static GetAllAccountWalletList(uid: string): AccountWalletVO[] {
        return this.account_wallet_dic[uid];
    }
    /**
     * 清空未激活的账号
     */
    public static ClearNotActivatedAccountWallet(uid: string) {
        for (let i = this.account_wallet_dic[uid].length - 1; i >= 0; i--) {
            let v = this.account_wallet_dic[uid][i];
            if (!v.activated) {
                this.account_wallet_dic[uid].splice(i, 1);
            }
        }
    }
    /**
     * 存储数据
     */
    public static async Save(uid: string) {
        this.ClearNotActivatedAccountWallet(uid);

        let json_data = JSON.stringify({
            guid: this.guid,
            account_wallet_list: this.account_wallet_dic[uid],
            select_account_wallet_index: this.select_account_wallet_index
        });

        Tools.SetLocalStorage(`ACCOUNT_WALLET_DATA_${uid}`, json_data);
        console.log("私钥已存储!");
    }

    /**
     * 加载账号数据
     */
    public static LoadAccountWalletData(uid: string): boolean {
        let account_wallet_data = Tools.GetLocalStorage(`ACCOUNT_WALLET_DATA_${uid}`, "");
        if (Tools.StrIsNotNull(account_wallet_data)) {
            let v = JSON.parse(account_wallet_data as string);
            Object.setPrototypeOf(v, AccountManagement.prototype);
            this.guid = v.guid;
            (v.account_wallet_list as AccountWalletVO[]).forEach((t: AccountWalletVO) => {
                Object.setPrototypeOf(t, AccountWalletVO.prototype);
                if (t.currency_system_address_list) {
                    for (const key in t.currency_system_address_list) {
                        if (Object.prototype.hasOwnProperty.call(t.currency_system_address_list, key)) {
                            const k = t.currency_system_address_list[key];
                            if (k) {
                                Object.setPrototypeOf(k, CurrencySystemAddressVO.prototype);
                                Object.setPrototypeOf(k.address, AddressVO.prototype);

                                if (k.contract_address_list) {
                                    k.contract_address_list.forEach(cavo => {
                                        Object.setPrototypeOf(cavo, AddressVO.prototype);
                                    });
                                }
                            }
                        }
                    }
                }
            });

            this.account_wallet_dic[uid] = v.account_wallet_list;
            this.select_account_wallet_index = v.select_account_wallet_index;

            /**
             * 执行初始化后的任务
             */
            if (this.InitializeSubscriptionQueue != null) {
                this.InitializeSubscriptionQueue.forEach(v => {
                    v(); //执行初始化任务
                });
                //清空全部任务
                this.InitializeSubscriptionQueue.splice(0, this.InitializeSubscriptionQueue.length);
            }
            this.is_init = true;
            return true;
        }
        return false;
    }
}