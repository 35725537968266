<template>
  <div class="lb_body">
    <span :class="['lb_input_name ', is_select ? 'lb_select_input_name' : '']">{{
      Title
    }}</span>
    <a-date-picker
      inputReadOnly
      class="bottom_10 date_time_input"
      :default-value="content"
      v-if="InputType == 'datetime'"
      show-time
      :placeholder="title == null ? '请输入时间+日期' : title"
      @change="OnDateTimeChange"
    />
    <label class="upload_file_img" v-else-if="InputType == 'image'">
      <input
        type="file"
        accept="image/*"
        :class="Css"
        @change="_OnUploadFile($event)"
        style="left: -9999px; position: absolute"
      />
      <img
        :src="
          content != '' && content
            ? GetResourceIMG(content)
            : 'image/update_photo.png'
        "
        alt=""
      />
    </label>
    <input
      v-else
      :type="InputType ? InputType : 'text'"
      :class="['lb_input',Css, is_select ? 'lb_select_input' : '']"
      @focus="SelectIt"
      @blur="NoSelect"
      v-model="content"
      :maxlength="Maxlength"
      :placeholder="PlaceHolder"
    />
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
export default {
  data() {
    return {
      is_select: false,
      content: "",
      
    };
  },
  props: {
    Title: String,
    Css:String,
    InputType: "text" | "number" | "password" | "datetime" | "image",
    PlaceHolder: String,
    Maxlength: Number,
    BindData: Object,
    modelValue: Object,
    /**
     * 时间回调
     */
    OnDateTimeChange: function (date, dateString) {},
    /**
     * 上传回调函数
     */
    OnUploadFile: function (flag, file_url) {},
  },
  watch: {
    modelValue(_new, _old) {
      this.content = _new;
    },
    content(_new, _old) {
      if (_new != this.modelValue) {
        this.$emit("update:modelValue", _new);
        this.$emit("update", this.BindData);
      }
    },
  },
  methods: {
    SelectIt() {
      this.is_select = true;
    },
    NoSelect() {
      this.is_select = false;
    },
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    async _OnUploadFile(event) {
      var files = event.target.files;
      if (files.length > 0) {
        let res = await Tools.UploadFileAsync("UploadFile", {}, files);
        if (Tools.CheckHtmlIsOK(res)) {
          let v = res.data[0];
          this?.OnUploadFile(true, v.FileURL);
        }
      } else {
        this?.OnUploadFile(false, "无上传文件!");
      }
    },
  },
  created() {
    this.content = this.modelValue;
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.lb_body {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  width: 100%;
}

.lb_select_input_name {
  color: rgb(240, 186, 82) !important;
}

.lb_input {
  display: inline-block;
  width: 100%;
  height: 40px;
  border: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  background-color: transparent;
  outline: none;
  color: @FFFFFF_c040506;
  font-size: 16px;
  border-bottom: 2px solid rgb(240, 186, 82) !important;

}

.lb_select_input {
  border-bottom: 2px solid rgb(240, 186, 82) !important;
}

.lb_input_name {
  font-size: 12px;
  text-align: left;
  color: @FFFFFF_c040506;
}

.upload_file_img {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }
}
</style>