import Global from "@/ts/Global";
import { CurrencySystemIdEnum } from "@/ts/SuperEnum";

/**
 * 币种体系结构
 */
export default class CurrencySystemVO {
    id?: number;
    currency_name?: string;
    currency_system_id?: number;
    currency_describe?: string;
    currency_icon?: string;
    state?: number;
    default_node_address?: string;
    default_node_websocket_address?: string;
    chain_id?: string;
    network_id?: string;

    public static GetCurrencySystemVOFromID(currency_system_id: CurrencySystemIdEnum): {
        flag: boolean,
        currency_system_vo: CurrencySystemVO
    } {
        let list = Global.currency_system_list.Where(x => x.currency_system_id == currency_system_id);
        
        if (list.length > 0)
            return {
                flag: true,
                currency_system_vo: list[0]
            };
        return {
            flag: false,
            currency_system_vo: {}
        };
    }
}