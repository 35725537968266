import { AppletTypeEnum } from "../SuperEnum";

/**
 * 小程序启动结构体
 */
export default class AppletStartParameterVO {
    /**
     * APPID
     */
    public AppID: string = "";

    public AppStartType: AppletTypeEnum = AppletTypeEnum.APP;
    /**
     * 绑定的当前请求页面VUE对象
     */
    public BindVUE: any;
    /**
     * Window绑定VUE界面窗口
     */
    public AppletWindowVUE: any;
    /**
     * 启动参数(传值进来的)
     */
    public Parameter: any;
    /**
     * App基础数据
     */
    public AppBaseData: any;
}