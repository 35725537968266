<template>
  <!-- LookBao小程序/小游戏授权登录 -->
  <div class="auth_body">
    <div :class="['auth_box', showPicker ? 'bottom' : '']">
      <div class="auth_box_row">
        <div class="row">
          <div class="img">
            <img :src="dapp_authorization?.app_icon" />
          </div>
          <div class="platform_name">{{ dapp_authorization?.app_name }}</div>
          <div>申请</div>
        </div>
        <div><img src="image/guanyu.png" /></div>
      </div>
      <div class="font">获取你的昵称，头像</div>
      <hr />
      <div class="user_info">
        <div class="row">
          <div>
            <img :src="dapp_authorization?.user_head_pic" />
          </div>
          <div class="user_font">
            <div class="platform_name font_width">
              {{ dapp_authorization?.user_nick_name }}
            </div>
            <div class="grey">微信昵称头像</div>
          </div>
        </div>
        <div class="gouxuan">
          <img src="image/gouxuan.png" />
        </div>
      </div>
      <hr />
      <div class="button">
        <button class="refuse" @click="RefuseClick">拒绝</button>
        <button class="allow" @click="SubmitUserLoginAuthorizationAsync()">
          允许
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
export default {
  props: {
    dapp_authorization: {
      app_name: String,
      app_icon: String,
      user_head_pic: String,
      user_nick_name: String,
    },
    authorization_callback: function (d) {},
    appid_authorization: String,
  },
  data() {
    return {
      showPicker: false,
    };
  },
  methods: {
    /**
     * 提交登录授权
     */
    async SubmitUserLoginAuthorizationAsync() {
      let res = await Tools.GetHTMLDAppAsync("SubmitUserLoginAuthorization", {
        appid: this.appid_authorization,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        "授权成功!".AlertSuccess();
        this.authorization_callback && this.authorization_callback(res.data);
        this.showPicker = false;
      }
    },
    RefuseClick() {
      this.showPicker = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.auth_body {
  position: relative;
  z-index: 20000;
  .bottom {
    bottom: 0 !important;
  }
  .auth_box {
    position: fixed;
    background-color: @c181818_ffffff;
    width: 100%;
    padding: 30px 20px;
    bottom: -300px;
    transition: all 0.2s linear;
    border-radius: 10px 10px 0px 0px;
    .auth_box_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      img {
        width: 18px;
        height: 18px;
      }
      .img > img {
        width: 23px;
        height: 23px;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .font {
      display: flex;
      font-size: 15px;
      margin: 20px 0 10px 0;
      font-weight: bold;
    }
    .user_info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      .gouxuan > img {
        width: 25px;
        height: 25px;
      }
      .user_font {
        text-align: left;
        .grey {
          font-size: 12px;
          color: rgb(100, 100, 100);
        }
      }
    }
    .button {
      margin: 30px 0 10px 0;
      .refuse {
        background-color: @c2B2B2B_F2F2F2;
        border: 1px solid @c2B2B2B_F2F2F2;
        border-radius: 5px;
        padding: 5px 35px;
        margin-right: 20px;
        color: @ffffff_c02cdb0;
      }
      .allow {
        background-color: rgb(5, 193, 96);
        border: 1px solid rgb(5, 193, 96);
        border-radius: 5px;
        padding: 5px 35px;
        color: white;
      }
    }
  }
  .platform_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;
    margin-right: 8px;
  }
  .font_width {
    width: 200px;
  }
  hr {
    border: none;
    height: 1px;
    background-color: @c242424_E6E6E6;
    margin: 15px 0;
  }
}
</style>