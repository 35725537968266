<template>
  <div class="knapsack_body">
    <div class="prop_details_body" v-if="show_prop_details_body">
      <div class="prop_details_content">
        <img
          class="prop_details_close"
          :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')"
          @click="ClosePropDetailsBody"
        />
        <div class="prop_details_head">
          <span>{{ select_prop_details.prop_goods_vo.prop_name }}</span>
          <img :src="select_prop_details.prop_goods_vo.prop_img" />
        </div>
        <div class="prop_description">
          <span>{{ select_prop_details.prop_goods_vo.prop_description }}</span>
        </div>

        <template v-if="select_prop_details.prop_goods_vo?.prop_type == 1">
          <div class="buttom_box">
            <button class="success_btn dui_huan_btn" @click="ToApplyPropAsync">
              开盲盒
            </button>
          </div>
        </template>

        <template v-if="select_prop_details.prop_goods_vo?.prop_type == 2">
          <div
            class="input_item"
            :key="i"
            v-for="(v, i) in select_prop_details.prop_goods_vo
              .new_extended_data"
          >
            <span>{{ `请输入${v.name}` }}</span>
            <input type="text" v-model="v.value" />
          </div>
          <div class="buttom_box">
            <button class="success_btn dui_huan_btn" @click="ToApplyPropAsync">
              兑换
            </button>
          </div>
        </template>

        <template v-if="select_prop_details.prop_goods_vo?.prop_type == 3">
          <div class="buttom_box">
            <button class="success_btn dui_huan_btn" @click="ToApplyPropAsync">
              开红包
            </button>
          </div>
        </template>
      </div>
    </div>

    <div class="knapsack_content">
      <div class="knapsack_title">
        <span>道具背包</span>
        <img :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')"  @click="CloseKnapsack" />
      </div>
      <div class="knapsack_item_content">
        <template v-if="knapsack_list.length > 0">
          <div
            class="item"
            @click="ShowPropDetails(v)"
            :key="i"
            v-for="(v, i) in knapsack_list"
          >
            <template v-if="v">
              <img :src="v?.prop_goods_vo?.prop_img" />
              <span>x{{ v?.prop_back_pack_vo?.num }}</span>
              <span>{{ v?.prop_goods_vo?.prop_name }}</span>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="no_data">
            <span>暂无道具</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
import InputBox from "@/components/input_box.vue";
export default {
  components: {
    InputBox,
  },
  data() {
    return {
      max_knapsack_count: 240,
      knapsack_list: [],
      select_prop_details: {},
      show_prop_details_body: false,
    };
  },
  methods: {
    GetExtendedData(v) {
      let extended_data = v.prop_goods_vo.extended_data;
      if (Tools.IsNotNull(extended_data)) {
        v.prop_goods_vo.new_extended_data = JSON.parse(extended_data);
      } else {
        v.prop_goods_vo.new_extended_data = {};
      }
    },
    CanUse(v) {
      if (v.prop_back_pack_vo.num <= 0) return false;
      if (v.prop_goods_vo.can_use != 1) {
        Tools.AlertError("当前道具不能使用!");
        return false;
      }
      return true;
    },
    /**
     * 使用道具
     */
    async ToApplyPropAsync() {
      Global.MessageBox("系统提示!", "您确定要使用此道具么?", async () => {
        let prop_back_pack_vo = this.select_prop_details.prop_back_pack_vo;
        let prop_goods_vo = this.select_prop_details.prop_goods_vo;
        let v = await Global.ToApplyPropAsync(
          prop_back_pack_vo.id,
          prop_goods_vo.new_extended_data
        );
        if (v.flag) {
          this.GetMyPropBackpackListAsync();
          this.ClosePropDetailsBody();
        } else {
          Tools.AlertError(v.msg);
        }
      });
    },
    CloseKnapsack() {
      Global.CloseKnapsack();
    },
    ShowPropDetails(v) {
      if (!this.CanUse(v)) return;
      Global.ShowPropDetails(v, () => {
        this.GetMyPropBackpackListAsync();
      });
    },
    /**
     * 获取我的背包数据列表
     */
    async GetMyPropBackpackListAsync() {
      let res = await Tools.GetHtmlAsync("GetMyPropBackpackList", {
        page: 1,
        num: this.max_knapsack_count,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        let d_list = res.data.data;
        this.knapsack_list = d_list;
      }
    },
    /**
     * 重置背包
     */
    ResetKnapsack() {
      // for (let i = 0; i < this.max_knapsack_count; i++) {
      //   this.knapsack_list[i] = null;
      // }
    },
    ClosePropDetailsBody() {
      this.show_prop_details_body = false;
    },
  },
  mounted() {
    // for (let i = 0; i < this.max_knapsack_count; i++) {
    //   this.knapsack_list.push(null);
    // }
    this.GetMyPropBackpackListAsync();
  },
};
</script>
<style scoped lang="less">
.knapsack_body {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 200;
  display: flex;
}

.knapsack_content {
  height: 50%;
  width: 100%;
  background-color: #191f23;
  margin-top: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .knapsack_title {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 20px 0px;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 10px;
    }
  }
}

.knapsack_item_content {
  padding: 0px 10px;
  display: block;
  height: 100%;
  overflow-y: auto;
  .item {
    width: 28%;
    height: 28vw;
    float: left;
    margin-left: 4%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #303539;
    padding-bottom: 5px;
    border-radius: 6px;
    margin-bottom: 10px;
    border: 0px;
    font-size: 12px;
    img {
      width: 100%;
      height: 80%;
      border: 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-bottom: 5px;
    }
    & > span:nth-of-type(1) {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 10;
      background-color: blueviolet;
      padding: 0px 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.prop_details_body {
  position: fixed;
  z-index: 300;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.prop_details_content {
  position: relative;
  background-color: #191f23;
  max-width: 90%;
  min-width: 80%;
  max-height: 70%;
  padding: 10px 20px;
  border-radius: 6px;
  .input_item {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
    margin-bottom: 10px;
    input {
      height: 34px;
    }
  }
  .prop_details_head {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 6px;
    }
  }

  .prop_description {
    margin: 10px 0px;
    span {
      text-align: left;
      display: block;
    }
  }

  .dui_huan_btn {
    height: 30px;
    width: 100%;
    border-radius: 6px;
  }

  .prop_details_close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>