export default class SliderUnlock {
    private $: any = (v: string) => {
        return document.querySelector(v);
    };

    constructor(elm: string, options: any, success: () => void) {
        var me: any = this;
        var $elm = me.checkElm(elm) ? this.$(elm) : this.$;
        success = me.checkFn(success) ? success : function () { };
        var opts = {
            successLabelTip: "成功验证!",
            duration: 200,
            swipestart: true,
            min: 0,
            max: $elm.clientWidth,
            index: 0,
            IsOk: false,
            lableIndex: 0
        } as any;
        for (const key in options) {
            if (Object.prototype.hasOwnProperty.call(opts, key)) {
                const e = opts[key];
                opts[key] = e;
            }
        }
        me.elm = $elm;
        me.opts = opts;
        me.swipestart = opts.swipestart;
        me.min = opts.min;
        me.max = opts.max;
        me.index = opts.index;
        me.isOk = opts.isOk;
        me.labelWidth = me.elm.querySelector('#label').clientWidth;
        me.sliderBg = me.elm.querySelector('#slider_bg');
        me.lableIndex = opts.lableIndex;
        me.success = success;
    }

    init() {
        var me: any = this;
        me.updateView();

        me.elm.querySelector("#label").addEventListener("mousedown", function (this: any, event: any) {
            let doc: any = document as any;
            var e = event || window.event;
            me.lableIndex = e.clientX - this.offsetLeft;

            me.handerIn();

            doc.onmousemove = function (ea: any) {
                if (!me.isOk) {
                    ea.preventDefault();
                    me.index = ea.clientX - me.lableIndex;

                    if ((me.index + me.labelWidth) >= me.max) {
                        me.index = me.max - me.labelWidth - 2;
                        me.swipestart = false;
                        me.isOk = true;
                    }
                    if (me.index < 0) {
                        me.index = me.min;
                        me.isOk = false;
                    }
                    if (me.index + me.labelWidth + 2 >= me.max && me.max > 0 && me.isOk) {

                        this.querySelector('#labelTip').value = me.opts.successLabelTip;
                        this.querySelector('#labelTip').style = `'color': '#fff'; text-align: left;padding-left: 5px;`;

                        me.success();
                    }
                    me.sliderBg.style = `width:${me.index + 2}`;
                    me.elm.querySelector("#label").style = `left:${me.index + "px"}`;
                }
            }

            doc.onmouseup = function (ea: any) {

                me.swipestart = false;
                if (!me.isOk) {
                    me.index = 0;
                    me.sliderBg.style = `width:${me.index + 2}`;
                    me.elm.querySelector("#label").style = "left:" + me.index + "px";
                }
                doc.onmousemove = null;
                doc.mouseout = null;
            }


            doc.mouseout = function (ea: any) {
                me.swipestart = false;
                if (!me.isOk) {
                    me.index = 0;
                    me.sliderBg.style = 'width' + (me.index + 2);
                    me.elm.querySelector("#label").style = "left" + me.index + "px";
                }
                doc.onmousemove = null;
                doc.mouseout = null;
            }


        });
        me.elm.querySelector("#label").addEventListener("touchstart", function (this: any, event: any) {
            var e = event || window.event;
            me.lableIndex = (e.originalEvent || e).touches[0].pageX - this.offsetLeft;
            me.handerIn();
        });
        me.elm.querySelector("#label").addEventListener("touchmove", function (event: any) {
            me.handerMove(event, "mobile");
        });

        me.elm.querySelector("#label").addEventListener("touchend", function (event: any) {
            me.handerOut();
        });
    }

    handerIn() {
        var me: any = this;
        if (!me.swipestart)
            return;
        me.swipestart = true;
        me.min = 0;
        me.max = me.elm.clientWidth;
    };
    handerOut() {
        var me: any = this;

        if (!me.isOk) {
            me.reset();
        }
        else {
            me.swipestart = false;
        }
    };
    handerMove(event: any, type: any) {

        var me: any = this;
        if (me.swipestart) {
            event.preventDefault();
            event = event || window.event;
            if (type == "mobile") {
                console.log(`显示一下::::pageX=${(event.originalEvent || event).touches[0].pageX} me.lableIndex===${me.lableIndex}`);
                me.index = event.touches[0].pageX - me.lableIndex;
            } else {
                console.log(`PC显示一下::::clientX=${event.clientX} me.lableIndex===${me.lableIndex}`);
                me.index = event.clientX - me.lableIndex;
            }
            me.move();
        }
    };
    move() {
        var me: any = this;

        if ((me.index + me.labelWidth) >= me.max) {
            me.index = me.max - me.labelWidth - 2;
            me.swipestart = false;
            me.isOk = true;
        }
        if (me.index < 0) {
            me.index = me.min;
            me.isOk = false;
        }

        if (me.index + me.labelWidth + 2 >= me.max && me.max > 0 && me.isOk) {

            let labelTip = this.$('#labelTip');
            labelTip.innerText = me.opts.successLabelTip;
            labelTip.style = 'color:black;margin-left:-20px';
            me.success();
        }
        me.updateView();
    };
    updateView() {
        var me: any = this;
        me.sliderBg.style = `width:${me.index + 2}`;
        me.elm.querySelector("#label").style = `left:${me.index + "px"}`;
    };
    reset() {
        var me: any = this;
        me.index = 0;
        me.swipestart = true;
        me.updateView();
    };
    checkElm(elm: any) {
        let t = this.$(elm);
        if (t) {
            return true;
        } else {
            throw "this element does not exist.";
        }
    };
    checkFn(fn: any) {
        if (typeof fn === "function") {
            return true;
        } else {
            throw "the param is not a function.";
        }
    };

}