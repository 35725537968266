<template>
  <div class="menu_body" ref="menu">
    <template v-if="menu_list.length == 0">
      <div
        class="menu_item"
        :key="index"
        v-for="(item, index) in $route.meta.menu_list"
        @click="item.click();this.$parent.show_menu=false;"
      >
        <my-image v-if="item.icon" :src="item.icon" />
        <span>{{ item.name }}</span>
      </div>
    </template>
    <template v-else>
      <div
        class="menu_item"
        :key="index"
        v-for="(item, index) in menu_list"
        @click="item.click();this.$parent.show_menu=false;"
      >
        <my-image v-if="item.icon" :src="item.icon" />
        <span>{{ item.name }}</span>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    menu_list: [],
  },
  data() {
    return {};
  },
  mounted() {
    this.menu_vo = this;
  },
};
</script>

<style scoped>
.menu_body {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 601;
  /* width: 30%; */
  background-color: #4B4C4D;
  color: white;
  right: 0px;
  padding: 10px;
  border-radius: 5px;
}

.menu_item {
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid #191F23;
  font-size: 14px;
}

.menu_item > img {
  width: 36px;
  height: 36px;
}
.menu_item > span {
  /* margin-left: 10px; */
}
</style>