<template>
  <div class="lookbao_scan_box">
    <img class="lookbao_scan_close_btn"         :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" />
    <mumu-get-qrcode @success="qrcodeSucess" @error="qrcodeError" />
  </div>
</template>
<script>
import mumuGetQrcode from "@/components/mumu-getQrcode.vue";
import Tools from "@/ts/Tools";
// 嫌路径长的话可以单独复制出来
export default {
  components: {
    mumuGetQrcode,
  },
  methods: {
    CloseMe() {
      this.$parent.show_h5_scan = false;
    },
    qrcodeSucess(data) {
      let json_item = Tools.ToJsonVO(data);
      if (json_item.flag) {
        window.SubmitEventCall("OnQRMessageEvent", json_item.data);
      } else {
        window.SubmitEventCall("OnQRMessageEvent", data);
      }
      this.CloseMe();
    },
    qrcodeError(err) {
      console.log(err);
      uni.showModal({
        title: "摄像头授权失败",
        content: "摄像头授权失败，请检测当前浏览器是否有摄像头权限。",
        success: () => {
          uni.navigateBack({});
        },
      });
    },
  },
};
</script>
<style scoped lang='less'>
.lookbao_scan_box {
  background-color: #333;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  .lookbao_scan_close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    z-index: 100;
  }
  .mask {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 320px;
    background-color: aliceblue;
    z-index: 999;

    button {
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>