<template>
  <div class="lb_switch_box">
    <span>{{ Title }}</span>
    <a-switch v-model:checked="checked" />
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    let checked = ref(false);
    return {
      checked,
    };
  },
  props: {
    Title: String,
    modelValue: Boolean,
    BindData: Object,
  },
  watch: {
    modelValue(_new, _old) {
      
      if (typeof _new == "number") {
        if (_new > 0) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      } else {
        this.checked = _new;
      }
      if (!this.is_init) {
        if (this.is_seting == false) {
          this.$emit("update", this.BindData, this);
        }
      }
      this.is_seting = false;
      this.is_init = false;
    },
    checked(_new, _old) {
      this.$emit("update:modelValue", _new);
      this.is_init = false;
    },
  },
  data() {
    return {
      is_seting: false,
      is_init: true
    };
  },
  methods: {
    Set(check) {
      this.is_init = false;
      this.is_seting = true;
      this.checked = check;
    },
  },
  mounted() {
    let v = this.modelValue;
    if (typeof v == "number") {
      if (v > 0) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    } else {
      this.checked = v;
    }
  },
};
</script>
<style scoped lang="less">
.ant-switch-checked {
    background-color: #1BCDAF !important;
}
.lb_switch_box {
  display: flex;
  font-size: 12px;
  align-items: center;
  color: @FFFFFF_c040506;
}

</style>