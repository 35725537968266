import Config from "./Config";
import Global from "./Global";
import { I_Server } from "./I_Server";
import SuperEvents from "./SuperEvents";
import SuperTimer from "./SuperTimer";
import Tools from "./Tools";
import BackDataVO from "./vo/BackDataVO";
import ServerWaitEvent from "./vo/ServerWaitEvent";

class HeartCheck {
    private timeout: number = 20000; //20ms
    private _ws: WebSocket | null = null;
    constructor(ws: WebSocket) {
        this._ws = ws;
    }
    public Reset() {
        this.Stop();
        this.Start();
    }

    public Stop() {
        SuperTimer.Stop("HeartCheck");
    }

    public Start() {
        SuperTimer.Start("HeartCheck", () => {
            try {
                this._ws?.send("0");

            }
            catch (ex) {

            }
            finally {
                return true;
            }
        }, this.timeout);
    }
}

export default class WebServer implements I_Server {
    private ws_url = "";
    private ws: WebSocket | null = null;

    /**
     * 是否已经连接
     */
    private is_connect: boolean = false;
    private heart_check: HeartCheck | null = null;
    private interval?: NodeJS.Timer | number;
    public success_connect_event: (() => void) | null = null;
    constructor() {
        this.ws_url = Config.WEBSOCKET_URL;
    }

    /**
     * 发送ping
     */
    private async PingAsync() {
        await this.SendAsync(this, "ping", {});
    }

    public Start(success_connect_event: (() => void) | null) {
        Global.app && Global.app.SetConnectState('connecting');
        success_connect_event && (this.success_connect_event = success_connect_event);
        this.ws = new WebSocket(this.ws_url);
        this.ws.onopen = async (ev) => {
            console.log("socket连接成功!");

            Global.app && Global.app.SetConnectState('connected');
            this.is_connect = true;
            this.heart_check = new HeartCheck(this.ws as WebSocket);

            SuperTimer.Stop("WebScoketServer");
            SuperTimer.StartAsync("WebScoketServer", async () => {
                //发送ping
                await this.PingAsync();
                return true;
            }, 15000);

            //发送ping
            await this.PingAsync();

            Global.home && Global.home.GetOffineMessageAsync();

            this.success_connect_event && this.success_connect_event();
        };

        this.ws.onmessage = (ev) => {
            let v = ev.data;
            let back_data = new BackDataVO(v);
            SuperEvents.CallEvent(back_data);
        };

        this.ws.onclose = async (ev) => {
            console.log("连接已关闭!");
            SuperTimer.Stop("WebScoketServer");
            this.is_connect = false;
            await this.ReConnectAsync();
        };

        this.ws.onerror = (ev) => {
            SuperTimer.Stop("WebScoketServer");
            console.log("连接出错!");
        };
    }
    /**
     * 服务重连
     * @param url 
     */
    public async ReConnectAsync(): Promise<boolean> {
        console.log("正在重连.....");
        await this.ToCloseAsync();
        //准备重连
        setTimeout(() => {
            this.Start(null);
        }, 1500);
        return true;
    }
    /**
     * 关闭连接
     */
    public async ToCloseAsync(): Promise<boolean> {
        try {
            this.heart_check?.Stop();
            this.heart_check = null;
            //强制关闭连接
            this.ws?.close(999);
            this.ws = null;

        }
        catch(ex) {
            return false;
        }
        finally {
            this.heart_check = null;
            this.ws = null;
        }
        return true;
    }

    /**
     * 发送数据
     * @param action 
     * @param data 
     */
    public Send(action: string, data: any): void {
        let json: string = JSON.stringify({
            action: action,
            data: data,
            rand_number: Tools.GUID,
            token: Global.GetLoginToken,
            use_platform_guid : Global.GetUsePlatformGuid
        });
        this.ws?.send(json);
    }
    /**
     * 同步请求接口
     * @param action 请求键
     * @param data 请求数据
     * @param wait_time 默认时间3秒
     */
    public SendAsync(bind_this: any, action: string, data: any, wait_time: number = 3000): Promise<{
        flag: boolean,
        _this: any,
        err_msg: string,
        data_vo: BackDataVO | null
    } | null> {
        let rand_number: string = Tools.GUID;
        let wait_event = new ServerWaitEvent(bind_this, action, rand_number, wait_time);

        return new Promise((r) => {
            try {
                wait_event.call_back_fun = (_this: any, data_vo: BackDataVO): void => {
                    clearTimeout(wait_event.timeout_Interval as number);
                    r({
                        flag: true,
                        _this: _this,
                        err_msg: "",
                        data_vo: data_vo
                    });
                }

                SuperEvents.AddEvents(wait_event);

                let json: string = JSON.stringify({
                    action: action,
                    data: data,
                    rand_number: rand_number,
                    token: Global.GetLoginToken,
                    use_platform_guid : Global.GetUsePlatformGuid
                });
                this.ws?.send(json);



                wait_event.timeout_Interval = setTimeout(() => {

                    clearTimeout(wait_event.timeout_Interval as number);
                    r({
                        flag: false,
                        _this: bind_this,
                        err_msg: "执行超时!",
                        data_vo: null
                    });
                }, wait_time);
                // console.log(`启动定时器:${wait_event.timeout_Interval}/ms!`);
            }
            catch (e) {
                r({
                    flag: false,
                    _this: bind_this,
                    data_vo: null,
                    err_msg: e as string
                });
            }
        })
    }
}