import Config from "./Config";
import Global from "./Global";
import { CALL_MEDIA_TYPE, ChatTypeEnum, TransactionStatusEnum } from "./SuperEnum";
import Tools from "./Tools";
import { IM_Order } from "./vo/IM_Order";
import IM_User from "./vo/IM_User";

/**
 * 插件
 */
export namespace Camera {
    export enum DestinationType {
        /**
         * 数据URL
         */
        DATA_URL = 0,
        /**
         * 文件URL
         */
        FILE_URI = 1,
        /**
         * 移动端URL
         */
        NATIVE_URI = 2
    }
    /**
     * 数据来源
     */
    export enum PictureSourceType {
        PHOTOLIBRARY = 0,
        /**
         * 摄像机
         */
        CAMERA = 1,
        /**
         * 图库
         */
        SAVEDPHOTOALBUM = 2
    }
}
export default class CordovaPlugin {
    /**
     * 进行连接IM
     */
    public static ConnectAsync(): Promise<boolean> {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                let user_vo = Global.login_user_vo;
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "Connect",
                    params: [
                        Global.IM_TOKEN,
                        user_vo?.uid,
                        user_vo?.nick_name,
                        user_vo?.head_pic
                    ]
                }, (v) => {

                    r(true);
                }, (e) => {
                    r(false);
                });
            }
            else {
                r(true);
            }
        });
    }

    /**
     * 连接到websocket服务
     */
    public static ConnectIMWebSocketAsync() {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "ConnectIMWebSocket",
                    params: []
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                console.log("只有APP端才能连接到websocket!");
                r(false);
            }
        });
    }

    /**
     * 重连
     */
    public static IMWebSocketReConnectAsync() {
        return new Promise((r) => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "IMWebSocketReConnect",
                    params: []
                }, () => {
                    console.log("IMWebSocketReConnect 成功!");

                    r(true);
                }, () => {
                    console.log("IMWebSocketReConnect 失败!");
                    r(false);
                });
            }
            else {
                console.log("只有APP端才能关闭到websocket!");
                r(false);
            }
        });
    }

    /**
     * 关闭连接
     */
    public static IMWebSocketCloseAsync(): Promise<boolean> {
        return new Promise((r) => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "IMWebSocketClose",
                    params: []
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                console.log("只有APP端才能关闭到websocket!");
                r(false);
            }
        });
    }

    /**
     * 发送IM数据
     */
    public static IMWebSocketSendAsync(data: string) {
        return new Promise((r) => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "IMWebSocketSend",
                    params: [
                        data
                    ]
                }, (v) => {
                    r(v);
                }, () => {
                    r(false);
                });
            }
            else {
                console.log("只有APP端才能发送Socket数据包!");
                r(false);
            }
        });
    }

    /**
     * 设置是否可以返回
     * @param b 
     * @returns 
     */
    public static SetCanGoBack(b: boolean = true): Promise<boolean> {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                let user_vo = Global.login_user_vo;
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "SetCanGoBack",
                    params: [
                        b
                    ]
                }, (v) => {
                    r(true);
                }, (e) => {
                    r(false);
                });
            }
            else {
                r(true);
            }
        });
    }
    /**
     * 查看是否正在推流
     */
    public static GetLiveIsPushAsync(): Promise<boolean> {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                let user_vo = Global.login_user_vo;
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "GetLiveIsPush",
                    params: []
                }, (v) => {
                    if (v == 1) {
                        r(true);
                    }
                    else {
                        r(false);
                    }
                }, (e) => {
                    r(false);
                });
            }
            else {
                r(true);
            }
        });
    }

    /**
     * 视频类型
     * @param UserID 
     * @param MediaType 
     * @returns 
     */
    public static StartSingleCallAsync(UserID: string, MediaType: CALL_MEDIA_TYPE = CALL_MEDIA_TYPE.CALL_MEDIA_TYPE_VIDEO): Promise<boolean> {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "StartSingleCall",
                    params: [
                        UserID,
                        MediaType
                    ]
                }, (v: any) => {

                    r(true);
                }, (e: any) => {

                    r(false);
                });
            }
            else {
                r(true);
            }
        });
    }

    /**
     * 发起多人通话
     * @param user_id_arr 用户列表
     * @param group_name 群名称
     * @param media_type 音视频类型
     * @returns 
     */
    public static StartMultiCallAsync(user_id_arr: string[], group_name: string, media_type: CALL_MEDIA_TYPE = CALL_MEDIA_TYPE.CALL_MEDIA_TYPE_VIDEO) {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "StartMultiCall",
                    params: [
                        group_name,
                        user_id_arr,
                        media_type
                    ]
                }, (v: any) => {
                    r(true);
                }, (e: any) => {

                    r(false);
                });
            }
            else {
                r(true);
            }
        });
    }

    /**
     * 打开文件选择器
     */
    public static OpenFileAsync() {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "OpenFile",
                    params: []
                }, (v: any) => {
                    r(true);
                }, (e: any) => {
                    r(false);
                });
            }
        });
    }

    /**
     * 打开图库相机
     * @param quality 质量50
     * @param sourceType 来源类型
     * @param destinationType 返回类型
     * @returns 
     */
    public static OpenCameraAsync(quality: number = 50, sourceType: Camera.PictureSourceType = Camera.PictureSourceType.SAVEDPHOTOALBUM, destinationType: Camera.DestinationType = Camera.DestinationType.FILE_URI): Promise<{
        flag: boolean,
        img_url: string,
        mime_type: string
    }> {
        return new Promise<{
            flag: boolean,
            img_url: string,
            mime_type: string
        }>(r => {
            try {
                if (navigator.hasOwnProperty("camera")) {
                    navigator.camera && navigator.camera.getPicture(
                        (d) => {
                            let v = JSON.parse(d);
                            let img_url = v.img_url;
                            let mime_type = v.mime_type;

                            r({
                                flag: true,
                                img_url: img_url,
                                mime_type: mime_type
                            });
                        },
                        (message) => {

                            r({
                                flag: false,
                                img_url: message,
                                mime_type: ""
                            });
                        },
                        {
                            quality: quality, // 相片质量是50
                            sourceType: sourceType, // 设置从图片库获取
                            destinationType: Camera.DestinationType.FILE_URI, // 以文件路径返回
                        }
                    );
                }
                else {
                    r({
                        flag: false,
                        img_url: "",
                        mime_type: ""
                    });
                }
            }
            catch (ex) {
                r({
                    flag: false,
                    img_url: "打开图库失败!",
                    mime_type: ""
                });
            }
        });

    }

    /**
     * 上传文件
     * @param file 文件路径
     */
    public static UploadFileAsync(file: string, mime_type: string, guid: string = "", progress: ((v: number) => void) | null = null, finish_call: ((flag: boolean) => void) | null = null): Promise<{
        flag: boolean,
        error_msg: string,
        data: {
            FileName: string,
            FileURL: string,
            OriginalFileName: string,
            OriginalFileNameNoExtension: string,
            MimeType: string
        }[] | null
    }> {
        return new Promise<{
            flag: boolean,
            error_msg: string,
            data: {
                FileName: string,
                FileURL: string,
                OriginalFileName: string,
                OriginalFileNameNoExtension: string,
                MimeType: string
            }[] | null
        }>(r => {
            let options = new FileUploadOptions();
            options.fileKey = "file";
            options.fileName = file.substr(file.lastIndexOf('/') + 1);
            let ft = new FileTransfer();
            var SERVER = `${Config.API_URL}/?a=UploadFile&mime_type=${mime_type}`;//上传接口

            //进度
            ft.onprogress = function (progressEvent) {
                if (progressEvent.lengthComputable) {
                    let jd = progressEvent.loaded / progressEvent.total;
                    // console.log("当前进度：" + jd);
                    if (Tools.IsNotNull(guid)) {
                        let g = Global.upload_file_progress_dic[guid];
                        if (g) {
                            g.progress = jd;
                            g.progress_call_fun && g.progress_call_fun(jd);
                        }
                    }
                    progress && progress(jd);
                }
            };

            ft.upload(file, encodeURI(SERVER), (success_vo: {
                bytesSent: number,
                objectId: string,
                response: string,
                responseCode: number
            }) => {
                if (success_vo.responseCode == 200) {
                    let g = Global.upload_file_progress_dic[guid];
                    if (g) {
                        g.finish_call_fun && g.finish_call_fun(true);
                        delete Global.upload_file_progress_dic[guid];
                    }
                    finish_call && finish_call(true);
                    let v = JSON.parse(success_vo.response);
                    r({
                        flag: true,
                        error_msg: "",
                        data: v.data
                    });
                }
                else {
                    let g = Global.upload_file_progress_dic[guid];
                    if (g) {
                        g.finish_call_fun && g.finish_call_fun(false);
                        delete Global.upload_file_progress_dic[guid];
                    }
                    finish_call && finish_call(false);
                    r({
                        flag: false,
                        error_msg: `上传失败:${success_vo.responseCode}`,
                        data: null
                    });
                }
            }, (e) => {
                let g = Global.upload_file_progress_dic[guid];
                if (g) {
                    g.finish_call_fun && g.finish_call_fun(false);
                    delete Global.upload_file_progress_dic[guid];
                }
                finish_call && finish_call(false);
                r({
                    flag: false,
                    error_msg: "上传失败",
                    data: null
                });
            }, options);
        });
    }

    /**
     * 开始录音
     */
    public static StartRecordAudioAsync(status_call_back: (status: number) => void): Promise<boolean> {
        return new Promise<boolean>((r) => {
            try {
                if (Global.media_rec != null) {
                    Global.media_rec.release();
                    Global.media_rec = null;
                }
                let file_name: string = Tools.GUID;
                let file: string = `${Tools.GetFileSystemPath()}${file_name}.mp3`;
                Global.media_rec = new Media(file, () => {
                    console.log("成功录音");
                    r(true);
                }, (err) => {
                    console.log("错误录音");
                    try {
                        Tools.AlertError("录音错误!");
                        this.StopRecordAudioAsync();
                    }
                    catch (e) {

                    }
                    r(false);
                }, (status) => {
                    console.log("录音状态:" + status);
                    status_call_back && status_call_back(status);
                });
                Global.media_rec.startRecord();
            }
            catch (e) {
                console.log("录音报错了:" + e);
                r(false);
            }
        });
    }

    /**
     * 停止录音
     */
    public static StopRecordAudioAsync(): Promise<{
        flag: boolean,
        duration: number,
        url: string
    }> {
        return new Promise<{
            flag: boolean,
            duration: number,
            url: string
        }>(async r => {
            try {

                if (Global.media_rec != null) {
                    // let duration: number = Global.media_rec.getDuration(); //音频时长
                    Global.media_rec?.stopRecord();
                    let url: string = (Global.media_rec as any).src;
                    //获取音频时长
                    let duration: number = await CordovaPlugin.GetAudioDurationAsync(url);
                    r({
                        flag: true,
                        duration: duration,
                        url: url
                    });
                }
                else {
                    r({
                        flag: false,
                        duration: 0,
                        url: ""
                    });
                }
            }
            catch (ex) {
                r({
                    flag: false,
                    duration: 0,
                    url: ""
                });
            }
        });
    }
    /**
     * 获取音频时长
     * @param url 
     * @returns 
     */
    public static GetAudioDurationAsync(url: string): Promise<number> {
        return new Promise<number>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "GetAudioDuration",
                    params: [
                        url
                    ]
                }, (v: number) => {
                    r(v);
                }, (e: any) => {
                    r(0);
                });
            }
        });
    }

    /**
     * 分享图文连接
     * @param title 
     * @param content 
     * @param image_url 
     * @param href 
     */
    public static ToShareWeb(title: string, content: string, image_url: string, href: string): void {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "ToShareWeb",
                params: [
                    title,
                    content,
                    image_url,
                    href
                ]
            }, (v: any) => {

            }, (e: any) => {
                Tools.AlertError(e);
            });
        }
        else {
            Tools.AlertError("非APP版本无法使用!");
        }
    }

    /**
     * 分享图片
     * @param title 
     * @param img_url 
     */
    public static ToShareImage(title: string, img_url: string): void {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "ToShareImage",
                params: [
                    title,
                    img_url
                ]
            }, (v: any) => {

            }, (e: any) => {
                Tools.AlertError(e);
            });
        }
        else {
            Tools.AlertError("非APP版本无法使用!");
        }
    }

    /**
     * 保存Base64图片到本地相册
     * @param title 
     * @param base64 
     */
    public static ToSaveFileFromBase64(title: string, base64: string): void {
        console.log(`${base64}`);

        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "SaveImageFromBase64",
                params: [
                    title,
                    base64
                ]
            }, (v: any) => {
                Tools.AlertSuccess("保存成功!");
            }, (e: any) => {
                Tools.AlertError(e);
            });
        }
        else {
            Tools.AlertError("原生平台可进行保存!");
        }
    }

    /**
     * 打开扫一扫
     */
    public static OpenQRAsync() {
        if (this.IsLookBaoApp()) {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "OpenQR",
                    params: [

                    ]
                }, (v: number) => {

                }, (e: any) => {

                });
            }
            else {
                Tools.AlertError("只有APP才能扫一扫");
            }
        }
        else {
            Global.OpenH5Scan((d) => {
                window.SubmitEventCall("OnQRMessageEvent", d);
            });
        }
    }
    /**
     * 保存远程文件
     * @param url 
     * @param new_file_name 
     * @param success_call 
     * @param error_call 
     */
    public static SaveFileFromURL(id: number, url: string, new_file_name: string, success_call: () => void, error_call?: () => void): void {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "SaveFileFromURL",
                params: [
                    id,
                    url,
                    new_file_name
                ]
            }, (v: number) => {
                success_call && success_call();
            }, (e: any) => {
                error_call && error_call();
            });
        }
    }

    /**
     * 拷贝内容到剪切板
     * @param content 
     */
    public static CopyContentToClipboardAsync(clip_content: string): Promise<boolean> {
        return Tools.CopyContentToClipboardAsync(clip_content);
    }

    /**
     * 获取剪切板内容
     * @param clip_content 
     * @returns 
     */
    public static GetContentFromClipboardAsync(clip_content: string): Promise<string> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "GetContentFromClipboard",
                    params: [
                        clip_content
                    ]
                }, (v: string) => {
                    return r(v);
                }, (e: any) => {
                    r("");
                });
            }
            else {
                r("");
            }
        });
    }


    /**
     * 生成二维码并保存到相册
     * @param data 要保存的数据
     * @param oirginalid 保存文件名(唯一标识)
     * @returns 
     */
    public static CreateQRImageAsync(data: string, oirginalid: string): Promise<boolean> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "CreateQRImage",
                    params: [
                        data,
                        oirginalid
                    ]
                }, (v: string) => {
                    return r(true);
                }, (e: any) => {
                    r(false);
                });
            }
            else {
                r(false);
            }
        });
    }
    /**
     * 获取文件列表
     * @param dir_name 
     * @returns 
     */
    public static GetFileListAsync(dir_name: string): Promise<string[]> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "GetFileList",
                    params: [
                        dir_name
                    ]
                }, (v: string) => {
                    return r(JSON.parse(v));
                }, (e: any) => {
                    r([]);
                });
            }
            else {
                r([]);
            }
        });
    }


    /**
     * 读取文本文件
     * @param file_path 文件全路径 
     * @returns 
     */
    public static ReadTxtFileAsync(file_path: string): Promise<string> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "ReadTxtFile",
                    params: [
                        file_path
                    ]
                }, (v: string) => {
                    return r(v);
                }, (e: any) => {
                    r("");
                });
            }
            else {
                r("");
            }
        });
    }

    /**
     * 写入文本文件
     * @param content 文本内容 
     * @param dir_name 文件夹名
     * @param fileName 文件名 加不加后缀都行
     * @returns 
     */
    public static WriteTxtFileAsync(content: string, dir_name: string, fileName: string): Promise<boolean> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "WriteTxtFile",
                    params: [
                        content,
                        dir_name,
                        fileName
                    ]
                }, (v: boolean) => {
                    return r(v);
                }, (e: any) => {
                    r(false);
                });
            }
            else {
                r(false);
            }
        });
    }

    /**
     * 检查文件是否存在
     * @param dir_name 文件夹名
     * @param file_name 文件全路径
     * @returns 
     */
    public static CheckFileIsExistsAsync(dir_name: string, file_name: string): Promise<boolean> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "CheckFileIsExists",
                    params: [
                        dir_name,
                        file_name
                    ]
                }, (v: boolean) => {
                    return r(true);
                }, (e: any) => {
                    r(false);
                });
            }
            else {
                r(false);
            }
        });
    }

    /**
     * 获取设备信息
     * @returns 
     */
    public static GetDeviceInformationAsync(): Promise<any> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "GetDeviceInformation",
                    params: []
                }, (v: string) => {
                    return r(JSON.parse(v));
                }, (e: any) => {
                    r(null);
                });
            }
            else {
                r(null);
            }
        });
    }
    /**
     * 获取临时的推送消息JSON数据
     * @returns 
     */
    public static GetNotifyJsonMsgAsync(): Promise<{
        flag: boolean,
        data: any
    }> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "GetNotifyJsonMsg",
                    params: []
                }, (v: string) => {
                    console.log(`获取到推送通知JSON:${v}`);
                    return r({
                        flag: true,
                        data: JSON.parse(v)
                    });
                }, (e: any) => {
                    r({
                        flag: false,
                        data: null
                    });
                });
            }
            else {
                r({
                    flag: false,
                    data: null
                });
            }
        });
    }

    /**
     * 打开小程序
     * @param appid 要打开的APPID
     * @param param 传参
     * @param no_cache 是否强制加载 无缓存 默认有缓存
     * @param custom_address 自定义地址
     * @param is_fast 是否为快应用(影响显示方式)
     * @returns 
     */
    public static OpenAppletAsync(appid: number, param: any, no_cache: boolean = false, custom_address: string = "", is_fast: boolean = false): Promise<boolean> {
        return new Promise(async r => {
            //获取小程序数据
            let res = await Tools.GetHTMLDAppAsync("GetDAppVOFromAppID", { app_id: appid });
            if (Tools.CheckHtmlIsOK(res)) {
                let app_vo = res.data;
                Global.AddOpenAppletHistory(app_vo);
            }

            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "OpenApplet",
                    params: [
                        appid,
                        param != null ? JSON.stringify(param) : "{}",
                        Global.GetLoginToken,
                        Config.API_URL,
                        Config.DAPP_URL,
                        Config.WEBSOCKET_URL,
                        is_fast,
                        custom_address,
                        no_cache
                    ]
                }, () => {
                    r(true);
                }, (err: string) => {
                    Tools.AlertError(err);
                    r(false);
                });
            }
            else {
                Tools.AlertError("打开小程序失败!");
                r(false);
            }
        });
    }

    /**
     * 恢复小程序
     * @returns 
     */
    public static RecoveryAppletAsync(): Promise<boolean> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "RecoveryApplet",
                    params: [
                        Global.GetLoginToken,
                        Config.API_URL,
                        Config.DAPP_URL,
                        Config.WEBSOCKET_URL,
                    ]
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                r(false);
            }
        });
    }

    /**
     * 关闭小程序
     * @returns 
     */
    public static CloseAppletAsync(): Promise<boolean> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "CloseApplet",
                    params: []
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                r(false);
            }
        });
    }


    /**
     * 发送指令到小程序端
     * @param guid 
     * @param data 
     */
    public static SendActionToApplet(guid: string, data: any): Promise<boolean> {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "SendActionToApplet",
                    params: [
                        guid,
                        JSON.stringify(data)
                    ]
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                r(false);
            }
        });
    }

    /**
     * 播放直播
     * @returns 
     */
    public static PlayLiveAsync(rtsp: string) {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "PlayLive",
                    params: [
                        rtsp
                    ]
                }, () => {
                    console.log("成功开始播放....");
                    r(true);
                }, () => {
                    console.log("播放失败....");
                    r(false);
                });
            }
            else {
                console.log("只有APP端才生效!");
                r(false);
            }
        });
    }


    /**
     * 初始化推流
     * @returns 
     */
    public static InitPushAsync() {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "InitPush",
                    params: [
                    ]
                }, () => {
                    console.log("初始化推流成功....");
                    r(true);
                }, () => {
                    console.log("初始化推流失败....");
                    r(false);
                });
            }
            else {
                console.log("只有APP端才生效!");
                r(false);
            }
        });
    }

    /**
     * 停止直播
     * @returns 
     */
    public static StopLiveAsync() {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "StopLive",
                    params: [
                    ]
                }, () => {
                    console.log("停止成功....");
                    r(true);
                }, () => {
                    console.log("停止失败....");
                    r(false);
                });
            }
            else {
                console.log("只有APP端才生效!");
                r(false);
            }
        });
    }

    /**
     * 开始推送
     * @param app_id 
     * @param live_room_id 
     * @param group_id 
     * @param live_address 
     * @param push_live_address 
     * @returns 
     */
    public static StartPushAsync(app_id: number, live_room_id: number, group_id: number, live_address: string, push_live_address: string) {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "StartPush",
                    params: [
                        app_id,
                        live_room_id,
                        group_id,
                        live_address,
                        push_live_address
                    ]
                }, () => {
                    console.log("推送成功....");
                    r(true);
                }, () => {
                    console.log("推送失败....");
                    r(false);
                });
            }
            else {
                console.log("只有APP端才生效!");
                r(false);
            }
        });
    }

    /**
     * 恢复推送
     */
    public static RecoveryPushAsync() {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "RecoveryPush",
                    params: []
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                console.log("只有APP端才生效!");
                r(false);
            }
        });
    }

    /**
     * 推送暂停
     */
    public static SuspendPushAsync() {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "SuspendPush",
                    params: []
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                console.log("只有APP端才生效!");
                r(false);
            }
        });
    }

    /**
     * 停止推送
     * @returns 
     */
    public static StopPushAsync() {
        return new Promise(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "StopPush",
                    params: []
                }, () => {
                    console.log("停止推送成功....");
                    r(true);
                }, () => {
                    console.log("停止推送失败....");
                    r(false);
                });
            }
            else {
                console.log("只有APP端才生效!");
                r(false);
            }
        });
    }
    /**
     * 屏幕翻转
     */
    public static ScreenFilpping() {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "ScreenFilpping",
                params: []
            }, () => {

            }, () => {

            });
        }
        else {
            console.log("只有APP端才生效!");
        }
    }
    /**
     * 关闭播放器
     */
    public static ClosePlayer() {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "ClosePlayer",
                params: []
            }, () => {
                console.log("成功关闭播放器");

            }, () => {
                console.log("关闭播放器失败");
            });
        }
        else {
            console.log("只有APP端才能关闭播放器!");
        }
    }


    /**
 * 切换前后摄像头
 */
    public static SwitchCamera() {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "SwitchCamera",
                params: []
            }, () => {

            }, () => {

            });
        }
        else {
            console.log("只有APP端才能切换摄像头!");
        }
    }

    /**
     * 链金额变更
     */
    public static OnUpperChainChangeAsync(hash: string, transaction_status: TransactionStatusEnum) {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "OnUpperChainChange",
                params: [
                    hash,
                    transaction_status
                ]
            }, () => {

            }, () => {

            });
        }
        else {
            console.log("只有APP端才能链金额变更!");
        }
    }

    /**
     * 购买回调事件
     */
    public static OnPaymentStatusCallBackAsync(data_json: string) {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "OnPaymentStatusCallBack",
                params: [
                    data_json
                ]
            }, () => {

            }, () => {

            });
        }
        else {
            console.log("只有APP端才能购买回调事件!");
        }
    }

    /**
     * 是否在顶层
     * @returns 
     */
    public static IsTopActivityAsync(): Promise<boolean> {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "IsTopActivity",
                    params: []
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                console.log("只有APP端才能检测是否在顶层!");
                r(false);
            }
        });
    }


    /**
     * 是否在LookBaoAPP中
     * @returns 
     */
    public static IsLookBaoApp() {
        if (window.hasOwnProperty('SuperCurrency')) {
            return true;
        }
        return false;
    }

    /**
     * 请求HTML
     * @returns 
     */
    public static GetHTMLAsync(url: string, action: string, data: any): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }> {
        return new Promise<{
            state: number,
            msgstate: string,
            key: string,
            error_msg: string,
            data: any
        }>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "GetHTML",
                    params: [
                        url,
                        action,
                        typeof data == "string" ? data : JSON.stringify(data)
                    ]
                }, (v) => {
                    let d = JSON.parse(v);
                    r(d);
                }, () => {
                    r({
                        state: 0,
                        msgstate: "请求失败",
                        key: action,
                        error_msg: "请求失败",
                        data: {}
                    });
                });
            }
            else {
                console.log("只有APP端才能进行网络请求!");
                r({
                    state: 0,
                    msgstate: "只有APP端才能进行网络请求",
                    key: action,
                    error_msg: "只有APP端才能进行网络请求",
                    data: {}
                });
            }
        });
    }

    /**
     * 显示全局通知消息
     * @returns 
     */
    public static ShowMessageNotificationAsync(type: ChatTypeEnum, title: string, nick_name: string, content: string, icon: string, group_id: number, user_vo: IM_User | null): Promise<boolean> {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "ShowMessageNotification",
                    params: [
                        type,
                        title,
                        nick_name,
                        content,
                        icon,
                        group_id,
                        JSON.stringify(user_vo)
                    ]
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                console.log("只有APP端才能显示全局通知消息!");
                r(false);
            }
        });
    }

    /** 
    * 播放音频 
    */
    public static PlayVoiceAsync(voice_url: string): Promise<boolean> {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "PlayVoice",
                    params: [
                        voice_url
                    ]
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                console.log("只有APP端才能播放音频!");
                r(false);
            }
        });
    }

    /**
     * 获取授权
     * @returns 
     */
    public static GetAppPermissionAsync(): Promise<{
        flag: boolean
        have_draw_overlays: boolean,
        is_ignoring_battery: boolean
    }> {
        return new Promise<{
            flag: boolean,
            have_draw_overlays: boolean,
            is_ignoring_battery: boolean
        }>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "GetAppPermission",
                    params: [

                    ]
                }, (v) => {
                    let d = {
                        flag: false,
                        have_draw_overlays: false,
                        is_ignoring_battery: false
                    };
                    if (typeof v == "string")
                        d = JSON.parse(v);
                    r({
                        flag: true,
                        have_draw_overlays: d.have_draw_overlays,
                        is_ignoring_battery: d.is_ignoring_battery
                    });
                }, () => {
                    r({
                        flag: false,
                        have_draw_overlays: false,
                        is_ignoring_battery: false
                    });
                });
            }
            else {
                console.log("只有APP端才能获取授权!");
                r({
                    flag: false,
                    have_draw_overlays: false,
                    is_ignoring_battery: false
                });
            }
        });
    }

    /**
     * 执行授权
     */
    public static ApplicationPermissionsAsync(): Promise<boolean> {
        return new Promise<boolean>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "ApplicationPermissions",
                    params: [

                    ]
                }, () => {
                    r(true);
                }, () => {
                    r(false);
                });
            }
            else {
                console.log("只有APP端才能执行授权!");
                r(false);
            }
        });
    }

    /**
     * 设置角标数量
     * @param num 
     */
    public static SetBadgeNum(num: number) {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "SetBadgeNum",
                params: [
                    num
                ]
            }, () => {

            }, () => {
            });
        }
        else {
            console.log("只有APP端才能设置角标!");
        }
    }

    /**
     * 更新登录Token
     */
    public static UpdateLoginToken(): void {
        let token = Global.GetLoginToken;
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "UpdateLoginToken",
                params: [
                    token
                ]
            }, () => {

            }, (e: string) => {

            });
        }
    }

    /**
     * 微信登录
     */
    public static WXLoginAsync(): Promise<{
        flag: boolean,
        d: any
    }> {
        return new Promise<{
            flag: boolean,
            d: any
        }>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "WXLogin",
                    params: [

                    ]
                }, (d) => {
                    r({
                        flag: true,
                        d: d
                    });
                }, () => {
                    r({
                        flag: false,
                        d: {}
                    });
                });
            }
            else {
                console.log("只有APP端才能微信登录!");
                r({
                    flag: false,
                    d: {}
                });
            }
        });
    }

    /**
     * 法币支付
     */
    public static LegalCurrencyPaymentAsync(order_vo: IM_Order): Promise<{
        flag: boolean,
        msg: string
    }> {
        return new Promise<{
            flag: boolean,
            msg: string
        }>(r => {
            if (window.hasOwnProperty('SuperCurrency')) {
                SuperCurrency.JavaScriptCall({
                    mod_name: "SuperCurrency",
                    action: "LegalCurrencyPayment",
                    params: [
                        order_vo.order_number
                    ]
                }, () => {
                    r({
                        flag: true,
                        msg: ""
                    });
                }, (e: string) => {
                    r({
                        flag: false,
                        msg: e
                    });
                });
            }
            else {
                console.log("只有APP端才能进行支付!");
                r({
                    flag: false,
                    msg: "只有APP端才能进行支付!"
                });
            }
        });
    }

    /**
     * 更新登录Token
     */
    public static CloseApp(): void {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "CloseAPP",
                params: [

                ]
            }, () => {

            }, (e: string) => {

            });
        }
    }

    public static GetHaveFilePromissionAsync(): Promise<boolean> {
        return new Promise<boolean>((r, e) => {
            try {
                if (window.hasOwnProperty('SuperCurrency')) {
                    SuperCurrency.JavaScriptCall({
                        mod_name: "SuperCurrency",
                        action: "GetHaveFilePromission",
                        params: [

                        ]
                    }, (v) => {
                        let f = typeof v == "number" ? v : parseInt(v);
                        if (f == 1) { //存在读写权限
                            r(true);
                        }
                        else { //不存在读写权限
                            r(false);
                        }
                    }, (e: string) => {
                        r(true);
                    });
                }
                else {
                    r(true);
                }
            }
            catch (ex) {
                r(true);
            }
        });
    }

    /**
     * 设置文件读写权限
     */
    public static SetHaveFilePromission(): void {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "SetHaveFilePromission",
                params: [

                ]
            }, () => {

            }, (e: string) => {

            });
        }
    }

    /**
     * 尝试更新APP
     */
    public static TryToUpdate() {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "TryToUpdate",
                params: [

                ]
            }, () => {

            }, (e: string) => {

            });
        }
    }

    /**
     * 打开浏览器
     * @param url 
     */
    public static TryOpenWebView(url: string, success_fun: () => void, err_fun: () => void) {
        if (window.hasOwnProperty('SuperCurrency')) {
            SuperCurrency.JavaScriptCall({
                mod_name: "SuperCurrency",
                action: "OpenWebView",
                params: [
                    url
                ]
            }, () => {
                success_fun && success_fun();
            }, (e: string) => {
                err_fun && err_fun();
            });
        }
    }


}