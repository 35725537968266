import router from "@/router";

/**
 * 页面数据缓存类
 */
export default class PageDataCache {
    private static dic: { [url: string]: any } = {};

    /**
     * 获取缓存数据
     * @param other_key 
     * @returns 
     */
    public static GetCacheData(other_key: string): any {
        let v = router;
        let path = v.currentRoute.value.fullPath;
        path = `${path}_${other_key}`;
        return this.dic[path];
    }

    /**
     * 添加缓存键值
     * @param key 
     * @param value 
     * @param other_key 
     */
    public static AddCache(key: string, value: any, other_key: string = "") {
        let v = router;
        let path = v.currentRoute.value.fullPath;
        path = `${path}_${other_key}`;
        this.dic[path] = {};
        this.dic[path][key] = value;

    }

    /**
     * 缓存数据
     * @param page 
     * @param other_key 
     */
    public static CacheData(page: any, other_key: string = ""): void {
        let v = router;
        let path = v.currentRoute.value.fullPath;
        
        path = `${path}_${other_key}`;
        this.dic[path] = {};
        let make_d = {};
        if (page.$data) {
            make_d = page.$data;
        }
        else {
            make_d = page;
        }
        for (const key in make_d) {
            if (Object.prototype.hasOwnProperty.call(make_d, key)) {
                if (key == "_")
                    continue;
                const v = (make_d as any)[key];
                switch (typeof v) {
                    case "bigint":
                    case "boolean":
                    case "number":
                    case "object":
                    case "string":
                    case "symbol":
                        console.log(`缓存${key}`);
                        
                        this.dic[path][key] = v;
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /**
     * 检查是否存在缓存
     */
    public static CheckHaveCache(key: string | any, other_key: string = "") {
        let path = key;
        if (typeof path != "string") {
            path = key.path;
        }
        if(!path){
            let v = router;
            path = v.currentRoute.value.fullPath;
        }
        path = `${path}_${other_key}`;
        if (this.dic[path])
            return true;
        return false;
    }

    /**
     * 恢复数据
     * @param page 
     */
    public static RecoverData(page: any, other_key: string = ""): boolean {
        let v = router;
        let path = v.currentRoute.value.fullPath;
        
        path = `${path}_${other_key}`;
        console.log(`尝试恢复缓存:${path}`);
        let count = 0;
        for (const key in this.dic[path]) {
            if (Object.prototype.hasOwnProperty.call(this.dic[path], key)) {
                const v = this.dic[path][key];
                page[key] = v;
                console.log(`正在恢复 key=${key}`);
                if(key=="message_list"){
                    
                }
                count++;
            }
        }
        if (count > 0) {
            console.log(`成功恢复:${count}个数据!`);
            return true;
        }

        return false;
    }
}