const Web3 = require("web3");
var Tx = require('ethereumjs-tx').Transaction;
import Global from '@/ts/Global';
import { CurrencySystemIdEnum, TransferTypeEnum } from '@/ts/SuperEnum';
import Tools from '@/ts/Tools';
import Common from 'ethereumjs-common';
import CoinTypeManager from '../CoinTypeManager';
import CurrencySystemVO from '../vo/CurrencySystemVO';
import I_WalletServer from '../WalletInterface/I_WalletServer';
import LookBaoPendingTransactions from './LookBaoPendingTransactions';
import LookBao_RPC from './LookBaoRpc';
var Wallet = require('ethereumjs-wallet');
/**
 * ETH钱包类
 */
export default class LookBaoWalletServer extends LookBao_RPC implements I_WalletServer {
    /**
     * 订阅列表
     */
    private sub_scribe_list: any[] = [];
    /**
     * 服务节点地址
     */
    private service_node_address: string = "";
    /**
     * WebSocket节点
     */
    private service_node_websocket_address: string = "";
    private chainId: string = "-1"; //频道
    private networkId: string = "-1"; //
    /**
     * ETH钱包类
     * @param service_node_address 节点地址
     * @param service_node_websocket_address websocket
     * @param chainId 节点频道
     * @param networkId 节点网络ID
     */
    constructor() {
        super();
    }

    public async InitAsync() {
        await this.InitConfigAsync();
        await this.ConnectServiceAsync();
        return this;
    }

    /**
     * 获取余额
     * @param address 
     */
    public async GetBalanceAsync(address: string): Promise<number> {
        return await this.eth_getBalance(address, "latest");
    }

    /**
     * 初始化配置
     */
    private async InitConfigAsync() {

        /**
         * 获取币种体系列表
         */
        let res = await Tools.GetHtmlAsync("GetCurrencySystemList", {});
        if (Tools.CheckHtmlIsOK(res, false)) {
            let v_list = res.data;
            v_list.forEach((v: CurrencySystemVO) => {
                Object.setPrototypeOf(v, CurrencySystemVO.prototype);
                if (Global.currency_system_list.Where(x => x.id == v.id).length == 0)
                    Global.currency_system_list.push(v);
            });
        }

        let v = CurrencySystemVO.GetCurrencySystemVOFromID(CurrencySystemIdEnum.LookBao);
        
        if (v.flag) {

            this.service_node_address = v.currency_system_vo.default_node_address ?? '';
            this.service_node_websocket_address = v.currency_system_vo.default_node_websocket_address ?? '';
            this.chainId = v.currency_system_vo.chain_id ?? '-1';
            this.networkId = v.currency_system_vo.network_id ?? '-1';
            if (Tools.IsNull(this.service_node_websocket_address)) {

            }
        }
        else {

        }
    }

    /**
     * 初始化订阅事件
     */
    private InitSubScribe(): void {
        /**
         * 订阅待支付等待
         */
        let subcribe = this.web3.eth.subscribe('pendingTransactions', (error: any, result: any) => {
            if (error) {
                console.error(error);
            }
            else {
                console.log(`新的等待交易:${result}`);
            }
        }).on("data", (transaction: any) => {
            console.log(`Data返回新的等待交易:${transaction}`);
        });
        this.sub_scribe_list.push(subcribe);

        /**
         * 订阅新块产生
         */
        subcribe = this.web3.eth.subscribe("newBlockHeaders", (error: any, result: any) => {
            if (error) {
                console.error(error);
            }
        }).on("data", async (blockHeader: any) => {
            await LookBaoPendingTransactions.CheckIsConfirmedAsync(this);
        });
        this.sub_scribe_list.push(subcribe);

        /**
         * 节点同步订阅事件
         */
        subcribe = this.web3.eth.subscribe("syncing", (error: any, result: any) => {
            if (!error) {
                console.log(result);
            }
            else {
                console.error(error);
            }
        }).on("data", (isSyncing: boolean) => {
            if (isSyncing) {
                console.log("节点同步中....");
            }
            else {
                console.log("节点已同步!");
            }
        });
        this.sub_scribe_list.push(subcribe);

    }

    /**
     * 卸载订阅
     */
    private UnSubScribe() {
        this.sub_scribe_list.forEach(subscribe => {
            subscribe.unsubscribe(function (error: any, success: any) {
                if (success) {
                    console.log('Successfully unsubscribed!');
                }
            });
        });
    }

    /**
     * 启动并链接到节点
     */
    public async ConnectServiceAsync() {

        let connected = false;
        let hasProviderEnded = false;
        let provider = new Web3.providers.WebsocketProvider(this.service_node_websocket_address);

        let reconnectInterval = 3000; //重连时间

        try {
            if (this.web3 != null) {

                // this.web3 = new Web3(Web3.currentProvider);
            } else {

                //#region 识别断线重连
                provider.on('connect', async () => {

                    console.log("websocket已经建立链接!");
                    if (Global.login_user_vo?.uid)
                        await CoinTypeManager.singleton.InitAsync(Global.login_user_vo?.uid as string);
                });
                provider.on('error', (err: any) => {
                    console.log("链接出现了错误" + (err && err.message));
                    console.log(`连接失败，${reconnectInterval / 1000}秒后重连!`);
                });
                provider.on('end', async (err: any) => {
                    await this.ToReConnectAsync(hasProviderEnded, provider, reconnectInterval);
                });
                //#endregion

                this.web3 = new Web3(
                    // new Web3.providers.HttpProvider(this.service_node_address)
                    provider
                );
                // console.log(`服务节点连接中:${this.service_node_address}`)
                console.log(`服务节点连接中WebScoket:${this.service_node_websocket_address}`)

            }
            let v = await this.web3.eth.net.isListening();
            if (v) {
                console.log(`节点连接成功!`);
                connected = true;
                //初始化订阅
                //this.InitSubScribe();

            }
            else {
                console.log(`节点连接失败!`);

            }
        }
        catch (ex) {

            // Tools.SetInitMessage(`重连`);
            // setTimeout(async () => {
            //     console.log(`节点连接失败,网络不通，${reconnectInterval / 1000}秒后重连`);
            //     // Tools.SetInitMessage(`节点连接失败,网络不通，${reconnectInterval / 1000}秒后重连`);
            //     await this.ToReConnectAsync(provider, reconnectInterval);
            // }, 1500);
        }
        finally {
            return connected;
        }
    }
    /**
     * 重连
     * @param provider 
     * @param reconnectInterval 
     */

    private ToReConnectAsync(hasProviderEnded: boolean, provider: any, reconnectInterval: number) {
        console.log(`准备重连....`);
        this.web3 = null;
        // Tools.SetInitMessage(`${reconnectInterval / 1000}秒后重连!`);

        // handle multiple event calls sent by Web3JS library  
        if (hasProviderEnded) return;

        // setting hashProviderEnded to true as sometimes the end event is fired multiple times by the provider 
        hasProviderEnded = true;

        // reset the current provider  
        provider.reset();
        // removing all the listeners of provider. 
        provider.removeAllListeners("connect");
        provider.removeAllListeners("error");
        provider.removeAllListeners("end");

        setTimeout(async () => {
            console.log(`重连中....`);
            // if (Global.app)
            //     await (Global.app as any).ConnectWalletNodeAsync();
            
            this.ConnectServiceAsync();
        }, reconnectInterval);
    }

    /**
     * 获取加密文件
     * @param privateKey 私钥 
     * @param pwd 密码
     */
    public static async MakeKeystoreAsync(privateKey: string, pwd: string) {

        //填入自己的密钥
        privateKey = privateKey.replace("0x", "");
        var key = Buffer.from(privateKey, 'hex');
        var wallet = Wallet.default.fromPrivateKey(key);
        //填入自己设置的密码
        var keystore = await wallet.toV3String(pwd);
        return keystore;
    }

    /**
     * 制作附加数据转换成十六进制
     * @param extend_data 
     * @param max_count 拼装最大长度
     * @returns 
     */
    public MakeExtendData(extend_data: any, max_count: number = 1024): {
        flag: boolean,
        error_msg: string,
        hex: string
    } {

        let ret_hex: string = "";
        try {
            if (extend_data == null)
                return {
                    flag: true,
                    error_msg: "",
                    hex: ""
                };
            let json = JSON.stringify(extend_data);
            if (json === "")
                return {
                    flag: false,
                    error_msg: "格式错误!",
                    hex: ""
                };
            var hexCharCode = [];
            for (var i = 0; i < json.length; i++) {
                hexCharCode.push((json.charCodeAt(i)).toString(16));
            }
            ret_hex = hexCharCode.join("");



            if (ret_hex.length > max_count)
                return {
                    flag: false,
                    error_msg: "超出最大附加长度",
                    hex: ret_hex
                };
            /**
             * 增加长度
             */
            ret_hex = `${ret_hex}${"0".repeat(max_count - ret_hex.length)}`;
        }
        catch (ex) {
            return {
                flag: false,
                error_msg: "转码时出现了错误!",
                hex: ""
            }
        }

        return {
            flag: true,
            error_msg: "",
            hex: ret_hex,
        };
    }

    /**
     * 发送交易
     * @param from_address 发送地址
     * @param to_address 接收地址
     * @param from_private_key 发送人私钥
     * @param value 发送金额
     * @param gas 使用的油量(默认21000)
     * @param gasPrice 油价(默认1000000000)  1的10次幂
     * @param is_contract 是否是合约模式
     * @param data 16进制数据
     * @param extend_data 扩展数据 合约代码叠加到最后
     */
    async SendSignedTransactionAsync(from_address: string, to_address: string, from_private_key: string, value: string, gas: number = 21000, gasPrice: number = 1000000000, gasLimit: number = 21000, data: string = "", is_contract: boolean = false, extend_data: {
        transfer_type: TransferTypeEnum,
        transfer_data: any,
    } | null = null): Promise<string> {
        return new Promise<string>(async (successfun, errfun) => {
            try {

                let _from = from_address;
                let default_nonce: string = await this.web3.utils.toHex((await this.web3.eth.getTransactionCount(_from)));
                let nonce: string = await this.web3.utils.toHex((await this.web3.eth.getTransactionCount(_from, "pending")));
                let latest: string = await this.web3.utils.toHex((await this.web3.eth.getTransactionCount(_from, "latest")));
                let earliest: string = await this.web3.utils.toHex((await this.web3.eth.getTransactionCount(_from, "earliest")));

                console.log(`default_nonce====${default_nonce}`);
                console.log(`pending====${nonce}`);
                console.log(`latest====${latest}`);
                console.log(`earliest====${earliest}`);

                console.log(`使用latest:===${latest}`);


                let file_nonce: string = "0x00";
                let is_find_node = Tools.GetLocalStorage(`${_from}_nonce`, "");
                if (is_find_node) {
                    file_nonce = is_find_node;
                    file_nonce = '0x' + ((parseInt(file_nonce) + 1).toString(16));
                }
                else {
                    console.log("没有找到可转账索引!");
                    // await fs.writeFileSync(config_file, nonce);

                }
                nonce = '0x' + (Math.max(Math.max(parseInt(latest), parseInt(nonce)), parseInt(file_nonce))).toString(16);

                console.log("计算后的nonce===" + nonce);

                if (from_private_key.indexOf("0x") != -1)
                    from_private_key = from_private_key.replace("0x", "");
                let privateKey = Buffer.from(from_private_key, "hex");
                let txParams: any = {
                    nonce: nonce,
                    gas: gas,
                    to: to_address,
                    // data: data,
                    // EIP 155 chainId - mainnet: 1, ropsten: 3
                    chainId: this.chainId,
                    gasPrice: gasPrice,
                    gasLimit: gasLimit,
                    value: parseFloat(value) * 10 ** 18,
                };

                if (is_contract) { //是否是合约支付

                    let extend_data_item = this.MakeExtendData(extend_data);
                    if (!extend_data_item.flag) {
                        errfun(extend_data_item.error_msg);
                        return;
                    }

                    txParams = {
                        nonce: nonce,
                        gasLimit: gasLimit,
                        gasPrice: gasPrice,
                        to: to_address,
                        gas: gas,
                        data: `${data}${extend_data_item.hex}`,
                        value: '0x00',
                        // EIP 155 chainId - mainnet: 1, ropsten: 3
                        chainId: this.chainId,
                    };
                }

                let chainParams: any = Common.forCustomChain(
                    'mainnet',
                    {
                        name: 'my-network',
                        networkId: parseInt(this.networkId),
                        chainId: parseInt(this.chainId),
                    },
                    'petersburg',
                );
                let obj: any = {
                    common: chainParams
                };

                let tx = new Tx(txParams, obj);
                tx.sign(privateKey);
                this.web3.eth.sendSignedTransaction("0x" + tx.serialize().toString("hex"), async (err: any, v: any) => {
                    if (err == null) {
                        console.log(v);
                        console.log("已成功提交到链上等待确认!");
                        console.log("存储nonce====" + nonce);
                        Tools.SetLocalStorage(`${_from}_nonce`, nonce);
                        successfun(v);
                        LookBaoPendingTransactions.AddNewTrHash(v); //添加确认交易Hash等待
                        await LookBaoPendingTransactions.CheckIsConfirmedAsync(this);
                    }
                    else {
                        console.log(err);
                        errfun(err);
                    }
                }).on('receipt', (receipt: any) => {
                    console.log("交易已确认......");
                    console.log(receipt.transactionHash);
                }).on('transactionHash', (hash: string) => {
                    console.log("在交易发出并得到有效的交易哈希值:" + hash);

                }).on('confirmation', function (confirmationNumber: number, receipt: any) {
                    // console.log("出现了新的确认:" + confirmationNumber + " 收据:" + JSON.stringify(receipt));
                }).on('error', (err: any) => {
                    console.log("出错了:" + err);
                    errfun(err);
                });
            }
            catch (ex) {
                errfun(ex);
            }
        });
    }
}