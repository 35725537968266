<template>
  <div class="notice_body row lie_center" v-if="notice_vo">
    <div>
      <!-- <my-image class="close_btn" src="image/x.png" @click.stop="CloseMe" /> -->
      <my-image
        class="icon"
        :src="GetIconUrl(notice_vo?.from_user_vo?.head_pic)"
        alt=""
      />
      <div class="lie content">
        <span
          >收到 - “{{ notice_vo?.from_user_vo?.nick_name }}” 的一个通知</span
        >
        <span>{{ GetNoticeTypeName(notice_vo?.notice_type) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
import { NoticeTypeEnum } from "@/ts/SuperEnum";
export default {
  data() {
    return {};
  },
  props: {
    notice_vo: Object,
  },
  methods: {
    GetNoticeTypeName(v) {
      return NoticeTypeEnum[v];
    },
    OpenNotice() {
      Tools.GoTo("/notice");
    },
    GetIconUrl(img) {
      return Global.GetCoinIconURL(img);
    },
    CloseMe() {
      this.$parent.show_notice = false;
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.notice_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  color: white;
  z-index: 1000;
  box-sizing: border-box;
  justify-content: center;
  pointer-events: none;
  & > div {
    background-color: #191f23;
    border: 1px solid #303539;
    border-top: 0px;
    position: relative;
    width: 80%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    padding: 10px 10px 10px 10px;

    .content {
      display: flex;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.lie {
  display: flex;
  flex-direction: column;
}

.row_center {
  display: flex;
  justify-content: center;
}
.lie_center {
  display: flex;
  align-items: center;
}

.content {
  text-align: left;
  margin-left: 10px;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.content > span:nth-child(2) {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 5px;
}

.close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>