<template>
  <div class="update_body">
    <div class="update_content">
      <img @click="CloseMe" class="CloseMe" :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" />
      <div class="update_message">
        <span>您的版本过低</span>
        <span v-html="content"></span>
      </div>
      <div class="button_box">
        <button @click="ToTryUpdate" class="success_btn">确认后刷新</button>
      </div>
    </div>
  </div>
</template>
<script>
import CordovaPlugin from "@/ts/CordovaPlugin";
import Tools from "@/ts/Tools";
export default {
  props: {
    update_app_version: String, //版本号
  },
  data() {
    return {
      content: "",
      btn_msg: "",
    };
  },
  methods: {
    ToTryUpdate() {
      this.CloseMe();
      if (CordovaPlugin.IsLookBaoApp()) {
        CordovaPlugin.TryToUpdate();
      } else {
        window.location.reload();
      }
    },
    /**
     * 关闭自己
     */
    CloseMe() {
      this.$parent.show_update_app_box = false;
    },
  },
  mounted() {
    if (CordovaPlugin.IsLookBaoApp()) {
      this.content = `发现版本V${this.update_app_version}<br>如查找更新失败请在 "我的"->"关于" 检查更新!`;
      this.btn_msg = `尝试寻找更新`;
    } else {
      this.content = `发现版本V${this.update_app_version}<br>如刷新失败清手动点击浏览器刷新尝试`;
      this.btn_msg = `点击尝试刷新`;
    }
  },
};
</script>

<style lang="less" scoped>
.CloseMe {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
}
.update_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  .update_content {
    position: relative;
    background-color: @bgc_class;
    padding: 30px 20px 20px 20px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    .button_box{
      margin-top: 10px;
      button{
        border-radius: 6px;
        height: 28px;
        padding: 0px 10px;
      }
    }
    .update_message {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: left;
    }
  }
}
</style>