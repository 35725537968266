<template>
  <div class="super_message_box">
    <div class="super_message">
      <img class="super_message_close_img"  :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" @click="CloseMe" />
      <div class="super_message_top_content">
        <span class="super_message_title">{{ bind_obj.title }}</span>
        <span class="super_message_content">{{ bind_obj.content }}</span>
      </div>

      <div class="super_message_button_box">
        <button
          class="success_btn"
          v-if="bind_obj.show_cancel_btn"
          @click="CancelFun"
        >
          {{
            bind_obj.cancel_text == null || bind_obj.cancel_text == ""
              ? "取消"
              : bind_obj.cancel_text
          }}
        </button>
        <button class="warning_btn" v-if="bind_obj.show_ok_btn" @click="OkFun">
          {{
            bind_obj.ok_text == null || bind_obj.ok_text == ""
              ? "确定"
              : bind_obj.ok_text
          }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
export default {
  data() {
    return {};
  },
  props: {
    bind_obj: Object,
  },
  methods: {
    CancelFun() {
      this.bind_obj.cancel_fun && this.bind_obj.cancel_fun();
      this.CloseMe();
    },
    OkFun() {
      this.CloseMe();
      this.bind_obj.ok_fun && this.bind_obj.ok_fun();
    },
    CloseMe() {
      Global.HideSuperMessageBox();
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.super_message_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 13000;
  background-color: rgba(0, 0, 0, 0.8);
  .super_message {
    background-color: @bottom_class;
    max-width: 80%;
    min-width: 60%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .super_message_close_img {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .super_message_top_content {
      display: flex;
      flex-direction: column;
      padding: 20px 40px 0px 40px;

      .super_message_title {
        font-size: 16px;
        color: @ffffff_324460;
      }

      .super_message_content {
        font-size: 14px; 
         color: @ffffff_324460;
        margin-top: 10px;
      }
    }

    .super_message_button_box {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      button {
        width: 100%;
        height: 35px;
      }
    }
  }
}
</style>