<template>
  <div class="permission_body">
    <div class="permission_content">
      <span class="permission_title">本应用还需要以下权限</span>

      <template v-if="!have_draw_overlays">
        <span class="permission_name">悬浮窗口 </span>
        <span class="permission_tab">(用于音视频,直播等小窗口)</span>
      </template>

      <template v-if="!is_ignoring_battery">
        <span class="permission_name">后台服务 </span>
        <span class="permission_tab">(保证通讯不中断,实时接收来电信息)</span>
      </template>

      <button class="error_btn permission_btn" @click="ApplicationPermissionsAsync">确认授权</button>
    </div>
  </div>
</template>
<script>
import CordovaPlugin from "@/ts/CordovaPlugin";
export default {
  data() {
    return {};
  },
  props: {
    have_draw_overlays: Boolean,
    is_ignoring_battery: Boolean,
  },
  methods: {
    async ApplicationPermissionsAsync() {
      await CordovaPlugin.ApplicationPermissionsAsync();
      this.$parent.show_permission_window = false;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.permission_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.permission_content {
  background-color: #070D0D;
  padding: 30px;
  padding-bottom: 20px;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.permission_content > span {
  text-align: left;
}

.permission_title {
  font-size: 18px;
  text-align: center !important;
  margin-bottom: 20px;
}

.permission_tab {
  font: 12px;
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 5px;
}

.permission_btn {
  margin-top: 10px;
  height: 35px;
  font-size: 16px;
  border-radius: 10px;
}
</style>