<template>
  <LookBaoMinizeApplet
    v-if="minize_applet_state.show"
    :app_id="minize_applet_state.app_id"
  ></LookBaoMinizeApplet>
  <!-- 分站切换 -->
  <SwitchSites v-if="show_platform_switch" />
  <!--更新-->
  <ToUpdate v-if="show_toupdate" :progress="update_progress" />
  <!-- LookBao授权登录组件 -->
  <LookBaoAuthorization
    :dapp_authorization="dapp_authorization"
    :appid_authorization="appid_authorization"
    :authorization_callback="authorization_callback"
    ref="lookbao_authorization"
  ></LookBaoAuthorization>
  <!-- 红包生成器 -->
  <RedpackTools
    v-if="show_redpack_tools"
    :red_packet_callback="red_packet_callback"
    :red_packet_model="red_packet_model"
    :use_temp_id="red_packet_use_temp_id"
    :allow_use_old_prop="red_packet_allow_use_old_prop"
    :allow_use_old_now_count="red_packet_allow_use_old_now_count"
  ></RedpackTools>
  <!-- 图形验证码 -->
  <Slide
    :phoneNum="slide_phone_num"
    :verification_type="slide_verification_type"
    :slide_success_callback="slide_success_callback"
    :slide_error_callback="slide_error_callback"
    :slide_cancel_callback="slide_cancel_callback"
    v-if="show_slide"
  ></Slide>
  <!-- H5扫一扫 -->
  <H5LookBaoScan v-if="show_h5_scan"></H5LookBaoScan>
  <AppUpdateWindow
    v-if="show_update_app_box"
    :update_app_version="update_app_version"
  ></AppUpdateWindow>
  <!-- 游戏窗口 -->
  <LookBaoApplet
    ref="applet_game_box"
    v-show="show_applet_game_box"
    :applet_bind_vue="applet_bind_vue"
    :app_start_param_vo="app_start_param_vo"
    :OnLoaded="applet_box_onload"
  ></LookBaoApplet>

  <!-- 资产选择器 -->
  <AssetSelectorBox
    :asset_selector_filter_type="asset_selector_filter_type"
    :asset_selector_filter_value="asset_selector_filter_value"
    :SelectAssetSelectorCallBackFun="SelectAssetSelectorCallBackFun"
    :use_sub_assets="SelectAssetSelectorUseSubAssets"
    :hide_tab="asset_selector_hide_tab"
    :title="asset_selector_title"
    :asset_selector_is_back_end="asset_selector_is_back_end"
    :asset_selector_is_any_mode="asset_selector_is_any_mode"
    :asset_selector_is_me="asset_selector_is_me"
    :asset_selector_redpacket_prop_obj="asset_selector_redpacket_prop_obj"
    :is_use_count="asset_selector_filter_is_use_count"
    :show_only_join_substitution="show_only_join_substitution"
    :substitution_input_or_output="substitution_input_or_output"
    v-if="show_asset_selector_box"
  ></AssetSelectorBox>

  <!-- 玲珑塔 -->
  <LLTMessageBox
    :show_top="llt_message_show_top"
    :chapter_id="llt_message_chapter_id"
    :floor_num="llt_message_floor_num"
    v-if="show_llt_message_box"
  ></LLTMessageBox>
  <SuperMessageBox
    :bind_obj="super_msg_bind_obj"
    v-if="show_super_message_box"
  ></SuperMessageBox>
  <!-- 我的NFT邀请好友列表 -->
  <MyNftInviteFriendsList
    v-if="show_my_nft_invite_friend_list"
  ></MyNftInviteFriendsList>
  <!-- 桶管理面板 -->
  <BucketBox v-if="show_bucket_box"></BucketBox>
  <!-- 交易面板 -->
  <TransactionPanel v-if="show_transaction_panel"> </TransactionPanel>

  <!-- 获取物品提示 -->
  <PrizePromptWindow
    :prize_prompt_obj="prize_prompt_obj"
    v-if="show_prize_prompt_window"
  ></PrizePromptWindow>
  <!-- 道具详情组件 -->
  <PropDetails
    :select_prop_details="select_prop_details"
    :prop_details_close_fun="prop_details_close_fun"
    v-if="show_prop_details"
  ></PropDetails>
  <Withdrawal v-if="show_withdrawal"></Withdrawal>
  <Withdrawalfenzhang v-if="show_withdrawalfenzhang"></Withdrawalfenzhang>
  <ExchangeRecord v-if="show_exchange_record"></ExchangeRecord>
  <Knapsack v-if="show_knapsack"></Knapsack>
  <LookBaoKeyboard
    v-if="show_lookbao_keyboard"
    :default_keyboard_number="default_keyboard_number"
    :show_keyboard_title="show_keyboard_title"
    :keyboard_finish_call="keyboard_finish_call"
  ></LookBaoKeyboard>
  <!-- <SystemNotice
    :content="system_notice_describe"
    :title="system_notice_title"
    v-if="show_system_notice"
  ></SystemNotice> -->
  <my-web-view
    v-if="show_web_view"
    :is_popup="true"
    :web_url="web_url"
    :web_title="web_title"
  ></my-web-view>
  <PrivacyPolicy
    :show_yszc_window_call_back="show_yszc_window_call_back"
    v-if="show_yszc_window"
  >
  </PrivacyPolicy>
  <UserInfromationViewing
    v-if="show_user_infromation_viewing"
    :show_user_vo="user_infromation_viewing_vo"
  >
  </UserInfromationViewing>
  <RealNameAuthentication
    v-show="show_real_name_authentication"
  ></RealNameAuthentication>
  <PPIcon
    ref="PPIcon"
    :default_uplink_status_data="default_uplink_status_data"
    v-show="show_pp_icon && !show_uplink_status_window"
  ></PPIcon>
  <Menu ref="menu_body" :menu_list="menu_list" v-show="show_menu"></Menu>
  <AccountSystemUpgrade
    v-if="show_to_account_system_upgrade"
  ></AccountSystemUpgrade>
  <GlobalLoading
    v-show="show_global_loading"
    :global_loading_msg="global_loading_msg"
  ></GlobalLoading>
  <UplinkStatusWindow v-if="show_uplink_status_window"></UplinkStatusWindow>
  <!-- <UplinkStatusAlert
    v-show="!show_pp_icon"
    ref="UplinkStatusAlert"
    :default_uplink_status_data="default_uplink_status_data"
  ></UplinkStatusAlert> -->
  <PermissionWindow
    v-if="show_permission_window"
    :have_draw_overlays="have_draw_overlays"
    :is_ignoring_battery="is_ignoring_battery"
  ></PermissionWindow>
  <MessageNotification
    ref="message_notification"
    :user_vo="message_notification_user_vo"
    :group_id="message_notification_group_id"
    :type="message_notification_type"
    :title="message_notification_title"
    :nick_name="message_notification_nick_name"
    :content="message_notification_content"
    :icon="message_notification_icon"
  ></MessageNotification>

  <MnemonicWindow
    v-if="show_mnemonic_window"
    :login_vue="login_vue"
  ></MnemonicWindow>
  <SetTransferPassword
    v-if="show_set_transfer_password"
    :set_transfer_password_call_fun="set_transfer_password_call_fun"
  ></SetTransferPassword>
  <GeneralMenu
    :general_menu_list="general_menu_list"
    v-if="show_general_menu"
  ></GeneralMenu>
  <Payment
    v-if="show_payment_box"
    :order_number="payment_order_number"
    :action_guid="payment_action_guid"
  ></Payment>
  <!-- 统一支付窗口 -->
  <UnifiedPayment
    v-if="show_unified_payment"
    :pay_transfer_type="pay_transfer_type"
    :pay_message="pay_message"
    :pay_amount="pay_amount"
    :pay_coin_type="pay_coin_type"
    :pay_address="pay_address"
    :pay_extend_data="pay_extend_data"
  >
  </UnifiedPayment>
  <ConnectServerState
    :servre_connect_state="servre_connect_state"
  ></ConnectServerState>
  <NoticeMessage v-if="show_notice" :notice_vo="notice_vo"></NoticeMessage>
  <MessageBox
    v-if="show_message_box"
    :open_no_ti_shi="open_no_ti_shi"
    :other_tishi_data="other_tishi_data"
    :message_box_title="message_box_title"
    :message_box_content="message_box_content"
    :message_box_success_call="message_box_success_call"
    :message_box_cancel_call="message_box_cancel_call"
  >
  </MessageBox>
  <UpdateAccount
    :show_base="show_base"
    v-if="show_update_account"
  ></UpdateAccount>
  <Alert
    class="alert_css"
    v-if="show_alert"
    :show_alert_state="show_alert_state"
    :message="alert_message"
  ></Alert>

  <InputBox
    v-if="show_input_box"
    :title="input_title"
    :content="input_content"
    :call_fun="input_call_fun"
    :input_box_type="input_box_type"
    :input_max_length="input_max_length"
  ></InputBox>

  <HEAD
    @click="CloseMenu"
    :title="head_title"
    :online="head_online"
    v-show="!$route.meta.hide_head && !close_header"
  >
  </HEAD>
  <div
    class="app_body"
    @click="CloseMenu"
    :style="
      $route.meta.background_color == null
        ? ''
        : 'background-color:' + $route.meta.background_color
    "
  >
    <router-view
      v-slot="{ Component }"
      v-if="isRouterAlive"
      ref="router_view"
      @click="CloseMenu"
    >
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
  </div>
</template>
<script>
require("@/ts/SuperExt");
import GlobalLoading from "@/components/global_loading.vue";
import Alert from "./components/alert.vue";
import HEAD from "./components/head.vue";
import Global from "./ts/Global";
import Tools from "./ts/Tools";
import WebServer from "./ts/WebServer";
import AppServer from "./ts/AppServer";
import InputBox from "./components/input_box.vue";
import CordovaPlugin from "@/ts/CordovaPlugin";
import AccountManagement from "@/ts/blockchain/AccountManagement";
import UpdateAccount from "@/components/update_account.vue";
import SuperEvents from "./ts/SuperEvents";
import MessageBox from "@/components/messagebox.vue";
import NoticeMessage from "@/components/NoticeMessage.vue";
import {
  AudioResourceEnum,
  ChatTypeEnum,
  SlideVerificationTypeEnum,
  ThemeTypeEnum,
  AppletTypeEnum,
} from "./ts/SuperEnum";
import ConnectServerState from "@/components/connect_server_state.vue";
import AppletPlugin from "@/ts/AppletPlugin";
import UnifiedPayment from "@/components/unified_payment.vue"; //统一支付窗口
import TransferIcon from "@/components/transfer_icon.vue"; //转账图标
import TransferStateWindow from "@/components/transfer_state_window.vue"; //转账状态窗口
import Payment from "@/components/Payment.vue"; //输入框转账界面
import GeneralMenu from "@/components/general_menu.vue"; //通用菜单
import SetTransferPassword from "@/components/SetTransferPassword.vue"; //修改密码窗口
import MnemonicWindow from "@/components/mnemonic_window.vue"; //助记词窗口
import SuperTimer from "./ts/SuperTimer";
import MessageNotification from "@/components/message_notification.vue"; //消息弹框
import PermissionWindow from "@/components/permission_window.vue"; //授权窗口
import UplinkStatusWindow from "@/components/uplink_status_window.vue"; //上链状态
import UplinkStatusAlert from "@/components/uplink_status_alert.vue"; //上链状态弹出框
import Menu from "@/components/menu.vue"; //菜单
import AccountSystemUpgrade from "@/components/AccountSystemUpgrade.vue";
import PPIcon from "@/components/PicturePictureIconComponent.vue";
import RealNameAuthentication from "@/components/RealNameAuthentication.vue"; //实名认证
import { inject, provide, reactive, readonly, ref, toRefs } from "vue";
import MyWebView from "@/views/WebView.vue";
import UserInfromationViewing from "@/components/UserInformationViewing.vue"; //用户信息查看
import PrivacyPolicy from "@/components/PrivacyPolicy.vue"; //隐私政策面板
import LookBaoKeyboard from "@/components/lookbao_components/LookBaoKeyboard.vue"; //软键盘
// import SystemNotice from "@/components/SystemNotice.vue"; //系统公告
import Withdrawalfenzhang from "@/components/withdrawalfenzhang.vue"; //提现窗口
import Withdrawal from "@/components/withdrawal.vue"; //提现窗口
import Knapsack from "@/components/Knapsack.vue"; //道具背包
import PropDetails from "@/components/PropDetails.vue"; //道具详情页
import PrizePromptWindow from "@/components/PrizePromptWindow.vue"; //获得物品提示

//获取实物兑换列表
import ExchangeRecord from "@/components/ExchangeRecord.vue"; //获取实物兑换记录
import TransactionPanel from "@/components/TransactionPanel.vue"; //交易面板

//桶管理面板
import BucketBox from "@/components/BucketComponents.vue"; //桶管理面板

import MyNftInviteFriendsList from "@/components/MyNftInviteFriendsList.vue"; //我的邀请记录列表

//超级提示框
import SuperMessageBox from "@/components/lookbao_components/LookBaoSuperMessageBox.vue"; //超级提示框

//玲珑塔消息框
import LLTMessageBox from "@/components/LingLongTaMessageBox.vue"; //玲珑塔消息框

//资产选择器
import AssetSelectorBox from "@/components/AssetSelectorComponent.vue"; //资产选择器

// 游戏窗口
import LookBaoApplet from "@/components/lookbao_components/LookBaoApplet.vue"; //LookBao游戏窗口

import AppUpdateWindow from "@/components/AppUpdateWindow.vue"; //更新APP窗口

/**
 * LookBaoH5扫一扫
 */
import H5LookBaoScan from "@/components/lookbao_components/LookBaoScan.vue";

/**
 * 验证码
 */
import Slide from "@/components/Slide.vue";

/**
 * 红包生成器
 */
import RedpackTools from "@/components/RedpacketTools.vue";

/**
 * LookBao授权登录组件
 */
import LookBaoAuthorization from "@/components/lookbao_components/LookBaoAuthorization.vue";
/**
 * 平台切换
 */
import SwitchSites from "@/components/SwitchSites.vue";

/**
 * 更新组件
 */
import ToUpdate from "@/components/ToUpdate.vue";
/**
 * 最小化小程序
 */
import LookBaoMinizeApplet from "@/components/lookbao_components/LookBaoMinimizeApplet.vue";

export default {
  components: {
    GlobalLoading,
    HEAD,
    Alert,
    InputBox,
    UpdateAccount,
    MessageBox,
    NoticeMessage,
    ConnectServerState,
    UnifiedPayment,
    TransferIcon,
    TransferStateWindow,
    Payment,
    GeneralMenu,
    SetTransferPassword,
    MnemonicWindow,
    MessageNotification,
    PermissionWindow,
    UplinkStatusWindow,
    UplinkStatusAlert,
    Menu,
    AccountSystemUpgrade,
    PPIcon,
    RealNameAuthentication, //实名认证窗口
    MyWebView,
    UserInfromationViewing, //用户信息查看
    PrivacyPolicy, //隐私政策
    LookBaoKeyboard, //软键盘
    // SystemNotice, //系统公告
    Withdrawal, //提现窗口
    Withdrawalfenzhang,//分账显示的提醒窗口
    Knapsack, //道具背包
    ExchangeRecord, //实物兑换记录
    PropDetails, //道具详情页
    PrizePromptWindow, //获取物品提示窗口
    TransactionPanel, //交易面板
    BucketBox, //桶管理面板
    MyNftInviteFriendsList, //我的邀请好友列表窗口
    SuperMessageBox, //超级提示框
    LLTMessageBox, //玲珑塔信息框 包含进入章节与爆率等信息
    AssetSelectorBox, //资产选择器
    LookBaoApplet, //游戏窗口Box
    AppUpdateWindow, //更新APP窗口
    H5LookBaoScan, //H5 LookBao扫一扫
    Slide, //图形验证码
    RedpackTools, //红包生成器
    LookBaoAuthorization, //LookBao授权登录组件(小程序,小游戏)
    SwitchSites, //分站切换
    LookBaoMinizeApplet, //最小化小程序窗口
    ToUpdate, //更新页面组件
  },
  setup() {
    const theme_obj = reactive({});
    const user_vo = reactive({});
    const ty_xy = ref(false);
    let env = ref("");
    env = Global.GetNODE_ENV;
    provide("user_vo", user_vo);
    provide("theme_obj", theme_obj);
    provide("ty_xy", ty_xy);
    provide("env", env);
    provide("host_platform_vo", Global.host_platform_vo);
    provide("premission_list", Global.premission_list); //暴露权限列表

    // let host_platform_vo = inject("host_platform_vo");

    return {
      user_vo,
      theme_obj,
      ty_xy,
      host_platform_vo: Global.host_platform_vo,
    };
  },
  data() {
    return {
      /**
       * 最小化小程序窗口属性
       */
      minize_applet_state: {
        show: false,
        app_id: 0,
      },

      /**
       * 显示分站切换
       */
      show_platform_switch: false,
      //更新组件
      show_toupdate: false,
      update_progress: 0, //更新进度
      //#region 小程序授权相关变量
      dapp_authorization: {}, //要授权的DAPP
      appid_authorization: "", //要授权的APPID
      authorization_callback: function (d) {}, //授权回调
      //#endregion

      //#region 红包打包器
      show_redpack_tools: false, //显示红包生成器
      red_packet_callback: function (red_packet_model, d) {},
      red_packet_model: 0, //红包打包器模式
      red_packet_use_temp_id: 0,
      red_packet_allow_use_old_prop: false, //是否允许老资产
      /**
       * 允许老资产的时候传入已使用数量(用于判定是否可以继续打包)
       */
      red_packet_allow_use_old_now_count: 0,
      //#endregion

      //#region 验证码选择器
      show_slide: false, // 显示验证码
      slide_phone_num: "", //验证手机号
      slide_verification_type: 0, //藏品类型
      slide_success_callback: function (d) {},
      slide_error_callback: function (msg) {},
      slide_cancel_callback: function () {},

      //#endregion

      //#region 扫一扫
      show_h5_scan: false, //H5 扫一扫
      h5_scan_call_back: null, //H5 扫一扫回调
      //#endregion

      //#region LookBao更新窗口
      show_update_app_box: false, //显示更新APP窗口
      update_app_version: "", //要更新的版本
      //#endregion

      //#region 显示小程序相关
      show_applet_game_box: false, //是否显示小程序窗口
      applet_bind_vue: {},
      app_start_param_vo: {},
      applet_box_is_loaded: false, //是否已经加载过了
      //#endregion

      //#region 资产选择器
      asset_selector_title: "", //资产筛选器标题
      asset_selector_hide_tab: false, //资产选择器 是否隐藏头部tab标签
      asset_selector_filter_type: 0, //过滤类型 1主藏品 2道具 3子藏品
      asset_selector_filter_value: "", //过滤内容
      asset_selector_is_back_end: false, //是否为后端使用
      SelectAssetSelectorCallBackFun: (filter_type, d) => {},
      SelectAssetSelectorUseSubAssets: false, //是否使用子资产选择
      show_asset_selector_box: false, //资产选择器
      //#endregion

      show_llt_message_box: false, //是否显示玲珑塔
      llt_message_chapter_id: 0, //玲珑塔章节id
      llt_message_floor_num: 0, //玲珑塔层数
      llt_message_show_top: false, //是否显示顶部导航

      /**
       * 超级弹框绑定数据
       */
      super_msg_bind_obj: {},

      super_msg_cancel_text: "", //

      show_super_message_box: false, //是否显示超级提示框
      show_my_nft_invite_friend_list: false, //显示我的NFT邀请好友列表
      show_bucket_box: false, //显示桶管理面板
      show_transaction_panel: false, //显示交易窗口
      show_prize_prompt_window: false, //是否显示获得物品窗口
      prize_prompt_obj: {}, //获取到物品的对象

      show_prop_details: false, //是否显示道具详情
      select_prop_details: {}, //道具详情页数据
      prop_details_close_fun: () => {}, //详情关闭回调
      show_exchange_record: false, //显示实物兑换记录
      show_withdrawal: false, //显示提现
      show_withdrawalfenzhang:false,//显示分账提现
      show_knapsack: false, //显示道具背包
      show_system_notice: false, //显示系统公告
      system_notice_title: "",
      system_notice_describe: "",

      show_lookbao_keyboard: false, //是否显示键盘
      default_keyboard_number: "", //键盘默认值
      show_keyboard_title: true, //是否显示键盘头部标签
      keyboard_finish_call: (number) => {}, //键盘完成回调

      show_yszc_window: false, //是否显示隐私政策

      show_yszc_window_call_back: () => {},
      // 用户详情展示
      show_user_infromation_viewing: false,
      user_infromation_viewing_vo: null,
      // 用户详情展示

      show_web_view: false,
      web_url: "",
      web_title: "",
      menu_list: [], //菜单列表
      show_real_name_authentication: false, //是否显示实名认证窗口
      show_pp_icon: false, //是否显示画中画ICON
      show_to_account_system_upgrade: false, //是否显示系统账号升级
      show_menu: false, //是否显示菜单
      close_header: false, //是否关闭头部导航
      show_global_loading: false, //是否正在loading
      global_loading_msg: "", //Loading内容
      show_uplink_status_window: false, //上链状态窗口列表
      default_uplink_status_data: {}, //上链弹出框默认数据
      show_permission_window: false,
      have_draw_overlays: true,
      is_ignoring_battery: true,
      isRouterAlive: true,
      message_notification_group_id: 0,
      message_notification_user_vo: null,
      message_notification_type: 0,
      message_notification_title: "",
      message_notification_nick_name: "",
      message_notification_content: "",
      message_notification_icon: "",
      show_mnemonic_window: false,
      login_vue: null,
      show_set_transfer_password: false,
      set_transfer_password_call_fun: () => {},
      show_general_menu: false,
      show_base: false,
      show_payment_box: false,
      general_menu_list: [], //通用菜单列表
      payment_order_number: "",
      payment_action_guid: "",
      //统一支付体系
      show_unified_payment: false,
      pay_transfer_type: 0, //转账类型
      pay_message: "",
      pay_amount: 0,
      pay_coin_type: 0,
      pay_address: "",
      pay_extend_data: {},
      //统一支付体系

      servre_connect_state: "11",
      wait_close_notice: 0,
      head_title: "",
      head_online: false,
      show_alert: false,
      alert_message: "",
      select_menu_index: 0,

      input_title: "",
      input_content: "",
      input_box_type: 0,
      input_max_length: 0, //输入框最大长度
      input_call_fun: function (txt) {},
      show_input_box: false,

      show_update_account: false,
      show_notice: true,
      notice_vo: null,

      show_message_box: false,
      open_no_ti_shi: false,
      other_tishi_data: "",
      message_box_title: "",
      message_box_content: "",
      message_box_success_call: () => {},
      message_box_cancel_call: () => {},
      //消息弹出页
      show_chat_group: false,
    };
  },
  watch: {
    user_vo: {
      deep: true,
      handler(_new, _old) {
        if (_new) {
          Global.login_user_vo = _new;
        }
      },
    },
    $route(to, from) {
      Global.SetTitle("");
      Global.SetButtonList([]);
      Global.SetTitleOnLine(false);

      if (Global.host_platform_vo)
        if (Tools.IsNotNull(Global.host_platform_vo?.guid)) {
          if (Global.GetUsePlatformGuid != Global.host_platform_vo?.guid)
            Global.GetPlatformFromHostAsync();
        }

      if (to.path != "/push_live") {
        // Tools.GetHtmlAsync("GetMyLive", {}).then((r) => {
        //   if (Tools.CheckHtmlIsOK(r, false)) {
        //     let live_room_vo = r.data;
        //     //执行停播
        //     Tools.GetHtmlAsync("StopLive", {
        //       live_room_id: live_room_vo.id,
        //     });
        //   }
        // });
      }
    },
  },
  created() {
    Global.app = this;
    Global.InitTheme();
  },
  methods: {
    /**
     * 检查更新
     */
    CheckUpdate(){
      SuperTimer.Start("CheckUpdate",()=>{
        window?.LookBaoJS?.CheckUpdate();
        return true;
      },240 * 1000);
    },
    /**
     * 恢复小程序窗口
     */
    RecoveryAppletWindow() {
      this.CloseMinizeAppletState();
      this.$refs["applet_game_box"]?.ToStartLookBaoApplet();
      this.show_applet_game_box = true;
    },
    /**
     * 小时最小化小程序窗口
     */
    ShowMinizeAppletState(app_id) {
      this.minize_applet_state.app_id = app_id;
      this.minize_applet_state.show = true;
    },
    /**
     * 关闭最小化小程序窗口
     */
    CloseMinizeAppletState() {
      this.minize_applet_state.app_id = 0;
      this.minize_applet_state.show = false;
    },
    /**
     * 显示授权窗口
     */
    PullUpAuthorization(appid, callback) {
      this.appid_authorization = appid;
      this.authorization_callback = callback;
      Tools.GetHTMLDAppAsync("PullUpAuthorization", {
        appid: appid,
      }).then((res) => {
        if (Tools.CheckHtmlIsOK(res)) {
          let dapp = res.data;
          this.dapp_authorization = dapp;
          if (this.$refs["lookbao_authorization"])
            this.$refs["lookbao_authorization"].showPicker = true;
        }
      });
    },

    SetThemeObj(obj) {
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const v = obj[key];
          this.theme_obj[key] = v;
        }
      }
    },
    /**
     * 打开红包打包器
     */
    OpenRedpackTools(
      red_packet_model,
      red_packet_callback,
      red_packet_allow_use_old_prop,
      red_packet_allow_use_old_now_count,
      red_packet_use_temp_id
    ) {
      switch (red_packet_model) {
        case 1:
          if (red_packet_use_temp_id <= 0) {
            Tools.AlertError("模板ID不能为空!");
            return;
          }
          break;
      }
      this.red_packet_model = red_packet_model;
      this.red_packet_callback = red_packet_callback;
      this.red_packet_use_temp_id = red_packet_use_temp_id;
      this.red_packet_allow_use_old_prop = red_packet_allow_use_old_prop;
      this.red_packet_allow_use_old_now_count =
        red_packet_allow_use_old_now_count;
      this.show_redpack_tools = true;
    },
    /**
     * 关闭红包打包器
     */
    CloseRedpackTools() {
      this.show_redpack_tools = false;
    },
    /**
     * 打开验证码
     */
    OpenSlideVerification(
      slide_phone_num,
      slide_verification_type,
      slide_success_callback,
      slide_error_callback,
      slide_cancel_callback
    ) {
      this.show_slide = true; // 显示验证码
      this.slide_verification_type = slide_verification_type;
      this.slide_phone_num = slide_phone_num; //验证手机号
      this.slide_success_callback = slide_success_callback; //验证码回调
      this.slide_error_callback = slide_error_callback; //验证码回调
      this.slide_cancel_callback = slide_cancel_callback; //验证码回调
    },
    /**
     * 隐藏验证码
     */
    CloseSlideVerification() {
      this.show_slide = false;
    },

    /**
     * H5扫一扫
     */
    OpenH5Scan(callback) {
      this.show_h5_scan = true; //H5 扫一扫
      this.h5_scan_call_back = callback; //H5 扫一扫回调
    },

    /**
     * 显示更新版
     */
    ShowUpdateAppBox(version) {
      this.update_app_version = version;
      this.show_update_app_box = true;
    },
    /**
     * 打开小程序
     */
    OpenAppletAsync(applet_bind_vue, app_start_param_vo) {
      this.applet_bind_vue = applet_bind_vue;
      this.app_start_param_vo = app_start_param_vo;
      this.$nextTick(() => {
        this.$refs["applet_game_box"]?.ToStartLookBaoApplet();
        this.show_applet_game_box = true;
      });
    },
    /**
     * 隐藏小程序窗口
     */
    HideAppletBox() {
      this.show_applet_game_box = false;
    },
    /**
     * 显示资产选择器
     */
    ShowAssetSelectorBox(
      SelectAssetSelectorCallBackFun,
      use_sub_assets,
      asset_selector_filter_type,
      asset_selector_filter_value,
      asset_selector_hide_tab,
      asset_selector_title,
      is_back_end,
      is_any_mode,
      is_me,
      asset_selector_redpacket_prop_obj,
      asset_selector_filter_is_use_count,
      show_only_join_substitution,
      substitution_input_or_output
    ) {
      this.SelectAssetSelectorCallBackFun = SelectAssetSelectorCallBackFun;
      this.SelectAssetSelectorUseSubAssets = use_sub_assets;
      this.asset_selector_filter_type = asset_selector_filter_type;
      this.asset_selector_filter_value = asset_selector_filter_value;
      this.asset_selector_hide_tab = asset_selector_hide_tab;
      this.asset_selector_title = asset_selector_title;
      this.asset_selector_is_back_end = is_back_end;
      this.asset_selector_is_any_mode = is_any_mode;
      this.asset_selector_redpacket_prop_obj =
        asset_selector_redpacket_prop_obj; //是否显示红包道具合成入口
      this.asset_selector_filter_is_use_count =
        asset_selector_filter_is_use_count; //已使用数量
      this.show_only_join_substitution = show_only_join_substitution; //仅显示可置换列表 true 启用 false未启用
      this.substitution_input_or_output = substitution_input_or_output; //1为input 2为output
      this.show_asset_selector_box = true;
      this.asset_selector_is_me = is_me; //只限自己
    },
    /**
     * 隐藏资产选择器
     */
    HideAssetSelectorBox() {
      this.show_asset_selector_box = false;
    },

    /**
     * 显示玲珑塔
     */
    ShowLLTMessageBox(
      llt_message_chapter_id,
      llt_message_floor_num,
      llt_message_show_top
    ) {
      this.llt_message_chapter_id = llt_message_chapter_id;
      this.llt_message_floor_num = llt_message_floor_num;
      this.llt_message_show_top = llt_message_show_top;
      this.show_llt_message_box = true;
    },

    /**
     * 关闭玲珑塔
     */
    CloseLLTMessageBox() {
      this.show_llt_message_box = false;
    },

    /**
     * 关闭超级弹出框
     */
    ShowSuperMessageBox(obj) {
      this.super_msg_bind_obj = obj;
      this.show_super_message_box = true;
    },
    /**
     * 关闭超级弹出框
     */
    HideSuperMessageBox() {
      this.show_super_message_box = false;
    },
    /**
     * 显示我的NFT邀请好友列表
     */
    ShowMyNftInviteFriendList() {
      this.show_my_nft_invite_friend_list = true;
    },
    /**
     * 隐藏我的NFT邀请好友列表
     */
    HideMyNftInviteFriendList() {
      this.show_my_nft_invite_friend_list = false;
    },
    /**
     * 显示桶管理
     */
    ShowBucketBox() {
      this.show_bucket_box = true;
    },
    /**
     * 显示更新页面
     * */
    ShowToUpdate() {
      this.show_toupdate = true;
    },
    /**
     * 
     */
    SetProgress(num) {
      this.update_progress = num;
    },
    /**
     * 关闭桶管理
     */
    CloseBucketBox() {
      this.show_bucket_box = false;
    },

    /**
     * 显示提现窗口
     */
    ShowWithdrawal() {
      this.show_withdrawal = true;
    },
    ShowWithdrawalfenzhang(){
      this.show_withdrawalfenzhang=true
    },
    /**
     * 显示道具详情
     */
    ShowPropDetails(select_prop_details, close_fun) {
      this.show_prop_details = true;
      this.select_prop_details = select_prop_details;
      this.prop_details_close_fun = close_fun;
    },
    ClosePropDetails() {
      this.show_prop_details = false;
      this.select_prop_details = {};
    },

    /**
     * 显示获取物品窗口
     */
    ShowPrizePromptWindow(title, img_url, describe) {
      this.prize_prompt_obj = {
        title: title,
        img_url: img_url,
        describe: describe,
      };
      this.show_prize_prompt_window = true;
    },
    /**
     * 关闭获取物品窗口
     */
    ClosePrizePromptWindow() {
      this.prize_prompt_obj = {};
      this.show_prize_prompt_window = false;
    },

    /**
     * 查看实物兑换记录
     */
    ShowExchangeRecord() {
      this.show_exchange_record = true;
    },
    /**
     * 显示系统公告
     */
    ShowSystemNotice() {
      return;
      try {
        Tools.GetHtml("GetSystemNotice", {}, (r) => {
          if (Tools.CheckHtmlIsOK(r, false)) {
            let d = r.data;
            let title = d.title;
            let describe = d.describe;
            let version = d.version;
            if (Tools.IsNotNull(title) && Tools.IsNotNull(describe)) {
              // Global.MessageBox(title, describe);
              this.system_notice_title = title;
              this.system_notice_describe = describe;
              let now_save_version = Tools.GetLocalStorage(
                "system_notice_version"
              );
              if (now_save_version != version) {
                Tools.SetLocalStorage("system_notice_version", version);
                this.show_system_notice = true;
              }
            }
          }
        });
      } catch (ex) {}
    },
    /**
     * 显示lookbao键盘
     */
    ShowLookBaoKeyboard(
      default_keyboard_number,
      show_keyboard_title,
      keyboard_finish_call
    ) {
      this.default_keyboard_number = default_keyboard_number;
      this.show_keyboard_title = show_keyboard_title;
      this.keyboard_finish_call = keyboard_finish_call;
      this.show_lookbao_keyboard = true;
    },
    /**
     * 打开背包
     */
    OpenKnapsack() {
      this.show_knapsack = true;
    },
    /**
     * 关闭背包
     */
    CloseKnapsack() {
      this.show_knapsack = false;
    },
    /**
     * 显示用户详情数据
     */
    ShowUserInfromationViewing(user_vo) {
      this.user_infromation_viewing_vo = user_vo;
      this.show_user_infromation_viewing = true;
    },
    /**
     * 打开URL
     */
    OpenURLToWebView(title, url) {
      this.web_url = url;
      this.web_title = title;
      this.show_web_view = true;
    },
    ClosePopupWebView() {
      this.show_web_view = false;
      this.web_url = "";
      this.web_title = "";
    },
    async UpdateMyLoginUserAsync() {
      let res = await Tools.GetHtmlAsync("UpdateMyLoginUser", {});
      if (Tools.CheckHtmlIsOK(res)) {
        Tools.SetObj(this.user_vo, res.data.user_vo);
      }
    },
    /**
     * 显示账户系统
     */
    ShowAccountSystemUpgrade() {
      this.show_to_account_system_upgrade = true;
    },
    SetMenuList(menu_list) {
      this.menu_list = menu_list;
    },
    /**
     * 显示全局Loading
     */
    ShowGlobalLoading(msg) {
      this.show_global_loading = true;
      this.global_loading_msg = msg;
    },
    /**
     * 隐藏全局Loading
     */
    HideGlobalLoading() {
      this.show_global_loading = false;
    },
    Reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    CloseMenu() {
      this.show_menu = false;
    },
    ShowMenu(top, left) {
      if (this.show_menu) {
        this.CloseMenu();
        return;
      }
      this.show_menu = true;
      let menu_body = this.$refs["menu_body"];
      menu_body.$el.style.top = top;
      menu_body.$el.style.left = left;
    },
    /**
     * 显示通知
     */
    ShowMessageNotification(
      type,
      title,
      nick_name,
      content,
      icon,
      group_id,
      user_vo
    ) {
      this.message_notification_type = type;
      this.message_notification_title = title;
      this.message_notification_nick_name = nick_name;

      this.message_notification_user_vo = user_vo;
      this.message_notification_group_id = group_id;

      this.message_notification_content = content;
      this.message_notification_icon = icon;
      this.$refs["message_notification"].Show();
    },
    /**
     * 隐藏通知
     */
    HideMessageNotification() {
      this.$refs["message_notification"].Hide();
    },
    /**
     * 显示助记词WINDOW窗口
     */
    ShowMnemonicWindow(login_vue) {
      this.login_vue = login_vue;
      this.show_mnemonic_window = true;
    },

    /**
     * 关闭助记词窗口
     */
    CloseMnemonicWindow() {
      this.login_vue = null;
      this.show_mnemonic_window = false;
    },
    /**
     * 显示设置支付密码窗口
     */
    ShowSetTransferPassword(set_transfer_password_call_fun) {
      this.set_transfer_password_call_fun = set_transfer_password_call_fun;
      this.show_set_transfer_password = true;
    },
    /**
     * 显示通用菜单
     */
    ShowGeneralMenu(general_menu_list) {
      this.general_menu_list = general_menu_list;
      this.show_general_menu = true;
    },
    /**
     * 关闭通用菜单
     */
    CloseGeneralMenu() {
      this.general_menu_list = [];
      this.show_general_menu = false;
    },
    ShowPayment(payment_order_number, payment_action_guid) {
      this.payment_order_number = payment_order_number;
      this.payment_action_guid = payment_action_guid;
      this.show_payment_box = true;
    },
    /**
     * 显示统一支付面板
     */
    ShowUnifiedPayment(
      pay_transfer_type, //转账类型
      pay_address,
      pay_coin_type,
      pay_amount,
      pay_message,
      pay_extend_data
    ) {
      this.pay_transfer_type = pay_transfer_type;
      this.pay_address = pay_address;
      this.pay_coin_type = pay_coin_type;
      this.pay_amount = pay_amount;
      this.pay_message = pay_message;
      this.pay_extend_data = pay_extend_data;
      this.show_unified_payment = true;
    },
    /**
     * 设置连接状态
     */
    SetConnectState(v) {
      switch (v) {
        case "connecting": //连接中
          if (SuperTimer.HaveKey("ServerConnectLoading")) {
            return;
          }
          SuperTimer.StartAsync(
            "ServerConnectLoading",
            () => {
              this.servre_connect_state = v;
              return false;
            },
            3000
          );
          break;
        case "connected": //已连接
          SuperTimer.Stop("ServerConnectLoading");
          this.servre_connect_state = v;
          break;
      }
    },
    /**
     * 弹出消息框
     * @param title
     * @param content
     * @param success_fun
     * @param cancel_fun
     * @param open_no_ti_shi 是否显示不在提示控件
     * @param other_tishi_data 参与运算提示框特殊内容(只影响是否显示提示框参加MD5加密)
     */
    MessageBox(
      title,
      content,
      success_fun,
      cancel_fun,
      open_no_ti_shi,
      other_tishi_data
    ) {
      this.open_no_ti_shi = open_no_ti_shi ? true : false;
      this.message_box_title = title;
      this.message_box_content = content;
      this.message_box_success_call = success_fun;
      this.message_box_cancel_call = cancel_fun;
      this.other_tishi_data = other_tishi_data;
      this.show_message_box = true;
    },
    /**
     * 显示修改个人信息面板
     */
    ShowUpdateAccount(show_base) {
      this.show_base = show_base;
      this.show_update_account = true;
    },
    ShowAlert(message, state) {
      this.alert_message = message;
      this.show_alert = true;
      this.show_alert_state = state;
      SuperTimer.Start(
        "alert_time_out",
        () => {
          this.show_alert = false;
          return false;
        },
        3500
      );
    },
    SetTitle(title) {
      this.head_title = title;
    },
    SetTitleOnLine(online) {
      if (Tools.GetNowRouteHref() != "/chat_window") {
        this.head_online = false;
        return;
      }
      this.head_online = online;
    },
    SelectMenu(index) {
      this.select_menu_index = index;
      switch (this.select_menu_index) {
        case 0:
          Tools.GoTo("/my_world");
          break;
        case 1:
          Tools.GoTo("/shop");
          break;
        case 2:
          Tools.GoTo("/home_page");
          break;
        case 3:
          Tools.GoTo("/home_page");
          break;
        case 4:
          Tools.GoTo("/my");
          break;
      }
    },
    ShowInputBox(
      title,
      content,
      input_box_type,
      input_call_fun,
      input_max_length
    ) {
      this.input_title = title;
      this.input_content = content;
      this.input_box_type = input_box_type;
      this.input_call_fun = input_call_fun;
      this.input_max_length = input_max_length;

      this.show_input_box = true;
    },
    async CheckLoginAsync() {
      let res = await Tools.GetHtmlAsync("CheckIsLogin", {});
      if (Tools.CheckHtmlIsOK(res, false)) {
        let d = res.data;
        let is_login = d.is_login;
        let login_user_vo = d.login_user_vo;
        let to_account_system_upgrade = d.to_account_system_upgrade;
        if (is_login) {
          Global.login_user_vo = login_user_vo;
          Tools.SetObj(this.user_vo, login_user_vo);
          await Global.UpdateMyLoginUserAsync();
          //更新token
          CordovaPlugin.UpdateLoginToken();

          //获取设备信息并更新
          let device_information =
            await CordovaPlugin.GetDeviceInformationAsync();
          if (device_information != null) {
            //更新设备信息
            await Tools.GetHtmlAsync("UpdateDeviceInformation", {
              device_tokens:
                device_information && (device_information?.device_tokens ?? ""),
            });
          }

          //更新账户系统面板
          if (to_account_system_upgrade) {
            Global.ShowAccountSystemUpgrade();
          }
          return true;
        }
      }
      Global.HideGlobalLoading();
      return false;
    },
    async SetInitMessage(msg) {
      console.log(msg);
    },
    /**
     * 检测权限
     */
    async CheckAppPermissionAsync() {
      /**
       * 初始化授权
       */
      let permission_vo = await CordovaPlugin.GetAppPermissionAsync();
      if (permission_vo.flag) {
        let have_draw_overlays = permission_vo.have_draw_overlays;
        let is_ignoring_battery = permission_vo.is_ignoring_battery;
        this.have_draw_overlays = have_draw_overlays;
        this.is_ignoring_battery = is_ignoring_battery;
        if (!have_draw_overlays || !is_ignoring_battery) {
          this.show_permission_window = true;
        }
      }
    },
    ShowYSZCWindow(callback) {
      //判断是否是网页版本
      if (Global.IsWeb) {
        Tools.SetLocalStorage("show_yszc_window", "true");
        Tools.SetLocalStorage("ty_xy", "TRUE");
        callback && callback();
        return;
      }

      let yszc_window = Tools.GetLocalStorage("show_yszc_window");
      if (yszc_window != "true") {
        this.show_yszc_window = true;
        this.show_yszc_window_call_back = callback;
      } else {
        callback && callback();
      }
    },
  },
  async mounted() {
    this.CheckUpdate();
    // this.notice_vo = {
    //   from_user_vo: {
    //     head_pic:
    //       "https://img0.baidu.com/it/u=3936144422,3028180288&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
    //     nick_name: "昵称....111",
    //     notice_type: 1,
    //   },
    // };

    AppletPlugin.InitAppletEventListening(); //初始化小程序监听器
    Global.TransferInitialization();

    if (window.navigator.userAgent.indexOf("$LookBao$") == -1) {
      Global.server = new WebServer();
    } else {
      Global.server = new AppServer();
    }

    Global.server.Start(async () => {
      if (await this.CheckLoginAsync()) {
        console.log("账号已登录....");
        //如果登录成功，直接初始化钱包
        if (!(await Global.InitWalletAsync())) {
          Tools.AlertError("初始化钱包失败!");
        }
      }
    });

    /**
     * 添加区块链状态变更事件
     */
    SuperEvents.AddGroupEvent(
      this,
      "OnBlockChainTaskEvent",
      (_this, d) => {
        let v = d.DATA;
        console.log("设置数据");
        _this.default_uplink_status_data = v;
        _this?.$refs["PPIcon"]?.Show();
      },
      -1
    );

    /**
     * 刷新登录数据
     */
    SuperEvents.AddGroupEvent(
      this,
      "GetNowLoginAccountData",
      (_this, d) => {
        Tools.SetObj(this.user_vo, d.DATA.user_vo);
      },
      1
    );
    /**
     * 消息通知
     */
    SuperEvents.AddGroupEvent(this, "OnNewNotice", (_this, d) => {
      let v = d.DATA;
      let is_avoid_disturb = v.is_avoid_disturb;
      v.from_user_vo = JSON.parse(v.from_user_vo);
      v.data = JSON.parse(v.data);

      this.notice_vo = v;
      this.show_notice = true;
      if (!is_avoid_disturb) Tools.PlayVoice(AudioResourceEnum.NOTICE);
      // clearInterval(this.wait_close_notice);
      // this.wait_close_notice = setTimeout(() => {
      //   this.show_notice = false;
      // }, 5000);
      SuperTimer.Start(
        "wait_close_notice",
        () => {
          this.show_notice = false;
          return false;
        },
        5000
      );
    });

    /**
     * 扫一扫通知事件
     */
    SuperEvents.AddCallEventListener(this, "OnQRMessageEvent", (d) => {
      Tools.PlayVoice(AudioResourceEnum.SCAN_CODE);
      Global.RunLookBaoQR(d);
    });

    /**
     * 打开通知消息体
     */
    SuperEvents.AddCallEventListener(this, "OnOpenMessagePrompt", async (d) => {
      let group_id = d.group_id;
      let uid = d.uid;
      try {
        await Global.OpenChatWindowAsync(group_id, uid);
      } catch (e) {}
    });

    /**
     * 强制下线通知接收器
     */
    SuperEvents.AddGroupEvent(this, "OnForceOffline", (d) => {
      Tools.AlertError("您已被强制下线!");
      Global.HideGlobalLoading();
      Tools.GoTo("/");
    });

    SuperEvents.AddGroupEvent(this, "UnLogin", (d) => {
      // Tools.AlertSuccess("您已成功退出!");
      Global.ClearLoginData();
      Global.ClearLoginUserVO();
      Global.ClearUsePlatformGuid();
      Tools.GoTo("/");
    });

    /**
     * 支付回调通知
     */
    SuperEvents.AddGroupEvent(
      this,
      "OnPaymentStatusCallBack",
      async (_this, d) => {
        let data = d.DATA;
        let create_uid = data.create_uid;
        let pay_uid = data.pay_uid;
        let order_number = data.order_number;
        let app_id = data.app_id;
        let amount = data.amount;
        let coin_type_id = data.coin_type_id;
        let transaction_status = data.transaction_status;
        let block_number = data.block_number;
        let hash = data.hash;
        let transaction_timestamp = data.transaction_timestamp;
        /**
         * 推送到小程序
         */
        await CordovaPlugin.OnPaymentStatusCallBackAsync(JSON.stringify(data));
      },
      -1
    );

    //监听余额变更
    SuperEvents.AddGroupEvent(
      this,
      "OnBalanceChange",
      async (_this, d) => {
        //延迟加载
        SuperTimer.StartAsync(
          "OnBalanceChange",
          async () => {
            // await Global.UpdateMyLoginUserAsync();
            return false;
          },
          1000
        );
      },
      1
    );
    //更新用户数据
    SuperEvents.AddGroupEvent(this, "OnUserVOUpdate", (_this, d) => {
      Tools.SetObj(this.user_vo, d.DATA.user_vo);
    });
  },
};
</script>
<style lang="less">
// @inputcolor: #303539;

html,
body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  color: white;
  // text-shadow: 0 0 1px #000000, 0 0 1px #000000;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  background-color: @bgc_class;
}

button {
  user-select: none;
}

.app_body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: @home_bgc_class;
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: @FFFFFF_c040506;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.success_btn {
  background-color: #42b983;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.delete_btn {
  background-color: #ff5722;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.error_btn {
  background-color: #ff5722;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.warning_btn {
  background-color: #ffb800;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.infomation_btn {
  background-color: rgb(20, 136, 244);
  color: @ffffff_324460;
  border: 0px;
  border-radius: 0px;
}

select {
  background-color: @inputcolor;
  color: @ffffff_324460;
  box-sizing: border-box;
  outline: none;
  border: 0;
}

input[type="text"],
input[type="checkbox"],
input[type="number"],
input[type="password"],
textarea {
  background-color: @input_bgc;
  color: @ffffff_324460;
  padding: 0px 10px;
  box-sizing: border-box;
  outline: none;
  border: 0;
}

input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 18px;
  position: relative;
}

input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  border: 1px solid #d9d9d9;
}

input[type="checkbox"]:checked::before {
  content: "\2713";
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid #e50232;
  color: #e50232;
  font-size: 20px;
  font-weight: bold;
}

input:focus {
  outline: none !important;
}

img {
  object-fit: cover !important;
}

.ant-progress-text {
  color: @ffffff_324460 !important;
  font-size: 14px !important;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}

/* 下面的 .v-move 和 .v-leave-active 配合使用，能够实现列表后续的元素，渐渐地漂上来的效果 */
.v-move {
  transition: all 0.3s ease;
}

.v-leave-active {
  position: absolute;
}

/* 去掉动画 */
.no-enter-from,
.no-leave-to {
  opacity: 1;
}

.no-enter-to,
.no-leave-from {
  opacity: 1;
}

.no-enter-active,
.no-leave-active {
  transition: all 0.3s ease;
}

/* 下面的 .v-move 和 .v-leave-active 配合使用，能够实现列表后续的元素，渐渐地漂上来的效果 */
.no-move {
  transition: all 0.3s ease;
}

.no-leave-active {
  position: absolute;
}

/* 去掉动画 */

/* 灰色 */
.to_gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

/* 底色 */
.di_se,
input {
  background-color: @inputcolor;
}
</style>
