<template>
  <div class="body">
    <img :src="require('@/assets/images/update/head.png')" />
    <div class="font">
      <div class="title">发现新版本！</div>
      <div class="update_list">
        <!-- <div class="update">更新如下</div> -->
        <!-- <div>1.优化了新建工单界面;</div> -->
        <!-- <div>2.新增版本升级功能;</div>
        <div>3.修复了一些已知bug。</div> -->
        <div class="load_box" v-show="show_loade">
          <span id="result" class="lode_font"></span>
          <div class="box" id="div_box">
            <div class="load" id="load"></div>
          </div>
        </div>
      </div>
      <button @click="ShowLoade" v-if="show_button">立即升级</button>
    </div>
  </div>
</template>
  <script>
import { inject } from "vue";
import Config from "@/ts/Config";
import Tools from "@/ts/Tools";
export default {
  setup() {
    const host_platform_vo = inject("host_platform_vo");
    return {
      host_platform_vo,
    };
  },
  props: {
    progress: Number,
  },
  watch: {
    progress(_new, _old) {
      this.SetProgress(_new);
    },
  },
  computed: {},
  data() {
    return {
      show_loade: false,
      show_button: true,
    };
  },
  methods: {
    CloseMe() {
      this.$parent.show_toupdate = false;
    },
    /**
     * 设置进度条
     * @param {*} num
     */
    SetProgress(num) {
      var load = document.getElementById("load");
      var result = document.getElementById("result");
      let i = num;
      if (i < 100) {
        load.style.width = i * 2 + "px";
        result.innerText = i + "%";
      }
      if (i >= 100) {
        //下载完成
        this.CloseMe();
      }
    },
    ShowLoade() {
      this.show_loade = true;
      this.show_button = false;
      window?.LookBaoJS?.TryToDownApp();
    },
  },
  mounted() {},
};
</script>
  <style scoped lang="less">
.body {
  position: fixed;
  top: 30%;
  text-align: center;
  z-index: 10000;
  img {
    width: 80%;
  }
  .font {
    background-color: white;
    width: 80%;
    color: #a5a7a6;
    border: white 1px solid;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 16px;
    margin-top: -1px;
    .title {
      font-weight: bold;
      margin: 20px 10px;
      font-size: 20px;
      color: black;
    }
    .update_list {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin: 0px 20px 20px 20px;
    }
    button {
      background-color: #e74236;
      color: white;
      border: 1px #e74236 solid;
      padding: 5px 20px;
      border-radius: 5px;
      margin-bottom: 20px;
    }
    .update {
      color: black;
    }
    * {
      margin: 0;
      padding: 0;
    }
    .load_box {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .box {
        width: 200px;
        height: 3px;
        border: 1px solid #9e9e9e;
      }
      .load {
        width: 0px;
        height: 3px;
        background: #325976;
      }
      .lode_font {
        font-size: 15px;
        color: rgb(137, 182, 210);
        margin-right: 20px;
      }
    }
  }
}
</style>