<template>
  <div class="transaction_panel_body">
    <div class="transaction_panel_content">
      <img class="close_btn"         :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" />
      <div class="transaction_panel_title">
        <span>与 XX 交易</span>
      </div>
      <!-- 头部头像 -->
      <div class="transaction_panel_head_box">
        <img class="head_img"         :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" alt="" />
        <span>交易给</span>
        <img class="head_img"         :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" alt="" />
      </div>
      <!-- 头部头像 -->

      <!-- 物品列表(道具,藏品 等) -->
      <div class="transaction_panel_asset_content">
        <div class="tab_box">
          <span class="tab">交易资产列表</span>
          <button class="success_btn">添加交易物品</button>
        </div>
        <div class="transaction_panel_asset_item_list">
          <div class="transaction_panel_asset_item" :key="v" v-for="v in 10">
            <img class="asset_img"         :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" />
          </div>
        </div>
      </div>
      <!-- 物品列表 -->

      <!-- 聊天内容 -->
      <div class="transaction_panel_asset_say_content">
        <div class="tab_box">
          <span class="tab">聊天窗口</span>
        </div>
        <div class="transaction_panel_asset_message_content">
          <div class="class_say_msg_item" :key="v" v-for="v in 100">
            <div class="head_box">
              <span>XXX:</span>
            </div>
            <div class="message">你好啊</div>
            <!-- <span class="time_span">2020-01-01 00:00:00</span> -->
          </div>
        </div>
        <div class="input_box">
          <input type="text" placeholder="请输入聊天内容" />
          <button class="success_btn">发送</button>
        </div>
      </div>
      <!-- 聊天内容 -->

      <div class="pay_state_content">
        <div class="tab_box">
          <span class="tab">支付结果</span>
          <span>支付方式:零钱,凭据</span>
        </div>
        <div class="pay_state_msg">
          <div>
            <img v alt="" />
          </div>
          <div>
            <span>支付零钱:￥200</span>
          </div>
        </div>
      </div>

      <div class="transaction_panel_button_box">
        <button class="warning_btn">锁定资产</button>
        <button class="success_btn">同意交易</button>
        <button class="error_btn">取消交易</button>
        <button class="success_btn">支付</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped lang="less">
.transaction_panel_body {
  font-size: 12px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 600;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  .transaction_panel_content {
    display: flex;
    flex-direction: column;
    background-color: #191f23;
    min-width: 95%;
    height: 100%;
    padding: 10px;
    // border-radius: 10px;
    position: relative;

    .close_btn {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .transaction_panel_title {
      font-size: 18px;
    }

    .transaction_panel_head_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 10%;
    }

    // 资产列表
    .transaction_panel_asset_content {
      background-color: #303539;
      overflow: hidden;
      height: 30%;
      border-radius: 10px;
      .tab_box {
        display: flex;
        position: relative;
        padding: 5px 0px;
        button {
          position: absolute;
          top: 5px;
          right: 5px;
          border-radius: 6px;
          padding: 0px 10px;
        }
      }
      .transaction_panel_asset_item_list {
        display: inline-block;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }

        .transaction_panel_asset_item {
          flex: 28%;
          width: 28%;
          height: 28vw;
          display: inline-block;
          float: left;
          margin-bottom: 10px;
          margin-right: 2%;
        }
      }
    }

    // 聊天窗口
    .transaction_panel_asset_say_content {
      display: flex;
      flex-direction: column;
      background-color: #303539;
      margin-top: 10px;
      border-radius: 10px;
      padding: 10px 0px;
      height: 20%;

      .head_box {
        display: flex;
        flex-direction: column;
      }
      .transaction_panel_asset_message_content {
        padding: 5px 10px;
        max-height: 150px;
        min-height: 100px;
        overflow-y: auto;
        .class_say_msg_item {
          display: flex;
          flex-wrap: nowrap;
          img {
            width: 30px;
            height: 30px;
          }
          .message {
            width: 100%;
            text-align: left;
          }

          .time_span {
            margin-left: auto;
            display: inline-block;
            width: 100px;
          }
        }

        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
      }

      .input_box {
        width: 100%;
        display: flex;
        padding: 5px 10px;
        input {
          background-color: #191f23;
          height: 30px;
          border: 0px;
          width: 100%;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        button {
          height: 30px;
          border: 0px;
          width: 50px;
          white-space: nowrap;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }

    .pay_state_content {
      background-color: #303539;
      margin-top: 10px;
      border-radius: 10px;
      padding: 10px 0px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      max-height: 20%;
      width: 100%;
      box-sizing: border-box;

      .tab_box {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        width: 100%;
        box-sizing: border-box;
        span {
          padding: 0px 10px;
        }
      }

      .pay_state_msg {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
      }
    }

    // 操作按钮
    .transaction_panel_button_box {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      margin-top: auto;
      margin-bottom: 10px;
      button {
        width: 30%;
        height: 26px;
        border-radius: 10px;
        color: #191f23;
        font-weight: bold;
      }
    }
  }
}

.head_img {
  width: 65px;
  height: 65px;
}

.asset_img {
  width: 160px;
  height: 160px;
}

.tab {
  //   font-size: 16px;
  text-align: left;
  display: block;
  width: 100%;
  padding: 0px 10px;
}
</style>