<template>
  <div
    ref="pp_icon_body"
    :class="['pp_icon_body', is_default ? 'default_body' : '']"
    @touchstart="touch_start"
    @touchmove="touch_move"
    @touchend="touch_end"
  >
    <div class="task_content_box" v-show="count > 0">
      <span class="task_content">{{ count }}</span>
    </div>
    <div>{{ status_data.task_title }}</div>
    <div>{{ GetShowContent }}</div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
import SuperTimer from "@/ts/SuperTimer";
export default {
  props: {
    default_uplink_status_data: Object,
  },
  data() {
    return {
      now_x: 0,
      now_y: 0,
      move_x: 0,
      move_y: 0,
      is_default: true,
      count: 0,
      status_data: {},
      wait_dic: {}, //等待队列
    };
  },
  computed: {
    GetShowContent() {
      if (this.status_data) {
        if (this.status_data.steps_list) {
          let is_find = false;
          for (let i = 0; i < this.status_data.steps_list?.length; i++) {
            const v = this.status_data.steps_list[i];
            if (v.task_state == 1) {
              return `${v.title}(${v.current_count}/${v.max_count})`;
            }
          }
          if (this.status_data.steps_list.length > 0) {
            for (let i = this.status_data.steps_list?.length - 1; i >= 0; i--) {
              const v = this.status_data.steps_list[i];
              if (v.task_state == 2 || v.task_state == 3) {
                return `${v.title}(${v.current_count}/${v.max_count})`;
              }
            }
          }
        }
      }
    },
  },
  watch: {
    default_uplink_status_data(_new, _old) {
      let bct_vo = _new.bct_vo;
      if (bct_vo.task_guid != null) {
        if (!this.wait_dic[bct_vo.task_guid]) this.status_data = bct_vo;

        this.wait_dic[bct_vo.task_guid] = bct_vo;
        this.count = _new.count;
      }
    },
  },
  methods: {
    Hide() {
      SuperTimer.Start(
        "to_hide_pp_icon",
        () => {
          this.$parent.show_pp_icon = false;
          this.status_data = {};
          return false;
        },
        3000
      );
    },
    Show() {
      SuperTimer.Stop("to_hide_pp_icon");
      this.$parent.show_pp_icon = true;
      //启动定时器显示不同的数据
      SuperTimer.Start(
        "show_wait_data",
        () => {
          let find = 0;
          for (let key in this.wait_dic) {
            find++;
            this.status_data = this.wait_dic[key];
            if (
              this.status_data.work_state == 3 ||
              this.status_data.work_state == 2
            ) {
              //删除
              delete this.wait_dic[key];
            }
            break;
          }
          if (find == 0) {
            this.Hide();
            return false;
          }
          return true;
        },
        1000
      );
    },
    touch_start(e) {
      let y = e.targetTouches[0].clientY;
      let x = e.targetTouches[0].clientX;
      this.now_x = x;
      this.now_y = y;
      this.move_x = x;
      this.move_y = y;
    },
    touch_move(e) {
      let target = e.targetTouches[0];
      let pp_icon_body = this.$refs["pp_icon_body"];
      let pp_w = pp_icon_body.clientWidth;
      let pp_h = pp_icon_body.clientHeight;
      if (target) {
        this.move_x = target?.clientX;
        this.move_y = target?.clientY;
        let x = target?.clientX - pp_h / 2;
        let y = target?.clientY - pp_w / 2;

        let h = document.body.clientHeight;
        let w = document.body.clientWidth;
        console.log(`w:${pp_w} h:${pp_h} x:${x} y:${y}`);
        if (x <= 0) x = 0;
        if (y <= 0) y = 0;
        if (x > w - pp_w) {
          x = w - pp_w;
        }
        if (y > h - pp_h) {
          y = h - pp_h;
        }
        this.is_default = false;
        pp_icon_body.style = `top:${y}px; left:${x}px;`;
      }
    },
    touch_end(e) {
      if (this.move_x == this.now_x && this.move_y == this.now_y) {
        console.log("点击");
        Global.OpenUplinkStatusWindow();
      }

      SuperTimer.Start(
        "vvv",
        () => {
          this.is_default = true;
          return false;
        },
        1000
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
.pp_icon_body {
  z-index: 10000;
  background-color: rgb(45, 149, 224);
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50px;
  left: 10px;
  /* height: 80px; */
  /* width: 80px; */
  overflow: hidden;
  border-radius: 10px;
  padding: 5px;
  font-size: 14px;
  transition: opacity ease 0.2s;
  flex-wrap: nowrap;
}

.pp_icon_body > div {
  white-space: nowrap;
}

.default_body {
  opacity: 0.5 !important;
}

.task_content_box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.task_content {
  top: 0px;
  left: 0px;
  background-color: brown;
  color: white;
  padding: 0px 15px;
  display: inline-block;
  border-radius: 20px;
  font-size: 12px;
}
</style>