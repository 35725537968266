import Tools from "../../Tools";
import LookBaoWalletServer from "./LookBaoWalletServer";

export class TransactionVO {

    /**
     * 交易Hash
     */
    public txHash: string = "";
    /**
     * 当前交易所在块
     */
    public blockNumber: number = 0;
    /**
     * 是否已确认
     */
    public confirmed: boolean = false;
    /**
     * 块哈希
     */
    public blockHash: string = "";
    /**
     * 合约地址
     */
    public contractAddress: string = ""; //合约地址,
    /**
     * 当前块总消耗
     */
    public cumulativeGasUsed: number = 0;
    /**
     * 转账地址
     */
    public from: string = "";
    /**
     * 要使用的消耗
     */
    public gas: number = 0;
    /**
     * 交易的金额
     */
    public value: number = 0;
    /**
     * 本次使用的消耗
     */
    public gasUsed: number = 0;
    /**
     * 日志 不知道干啥的
     */
    public logs: any[] = [];

    public logsBloom: string = "";
    /**
     * 转账目标地址
     */
    public to: string = "";
    /**
     * 转账hash
     */
    public transactionHash: string = "";
    /**
     * 转账索引
     */
    public transactionIndex: number = 0;

    /**
     * 确认时间
     */
    public confirmed_time: number = 0;
    /**
     * 确认时间正常格式
     */
    public confirmed_time_str: string = "";

    constructor(txHash: string) {
        this.txHash = txHash;
    }
}
export default class LookBaoPendingTransactions {
    public static transction_vo_list: TransactionVO[] = [];
    public static AddNewTrHash(txHash: string) {
        this.transction_vo_list.push(new TransactionVO(txHash));
    }

    /**
     * 监测是否已确认
     */
    public static async CheckIsConfirmedAsync(wallet: LookBaoWalletServer) {
        for (let i = 0; i < this.transction_vo_list.length; i++) {
            const v = this.transction_vo_list[i];
            if (!v.confirmed) {
                //去监测
                let transaction_vo: {
                    blockHash: string,
                    blockNumber: number,
                    contractAddress: string, //合约地址,
                    cumulativeGasUsed: number,
                    from: string,
                    gasUsed: number,
                    logs: any[],
                    logsBloom: string,
                    status: boolean,
                    to: string,
                    transactionHash: string,
                    transactionIndex: number
                } | null = await wallet.eth_getTransactionReceipt(v.txHash);
                if (transaction_vo) {
                    v.blockNumber = transaction_vo.blockNumber;
                    v.blockHash = transaction_vo.blockHash;
                    v.contractAddress = transaction_vo.contractAddress;
                    v.cumulativeGasUsed = transaction_vo.cumulativeGasUsed;
                    v.from = transaction_vo.from;
                    v.gasUsed = transaction_vo.gasUsed;
                    v.logs = transaction_vo.logs;
                    v.logsBloom = transaction_vo.logsBloom;
                    v.to = transaction_vo.to;
                    v.transactionHash = transaction_vo.transactionHash;
                    v.transactionIndex = transaction_vo.transactionIndex;
                    if (v.logs.length > 0) {
                        v.value = parseInt(v.logs[0].data) / 10 ** 18
                    }
                    //获取块数据获取时间
                    let block_vo = await wallet.eth_getBlockByNumber(v.blockNumber);
                    v.confirmed_time = block_vo.timestamp;
                    if (v.confirmed_time > 0)
                        v.confirmed_time_str = Tools.UnixTimestampToDateTime(v.confirmed_time);
                    if (transaction_vo.status) {
                        v.confirmed = true;
                    }
                }
                else {
                    let gt_vo = await wallet.eth_getTransactionByHash(v.txHash);
                    if (gt_vo) {

                        v.blockNumber = gt_vo.blockNumber;
                        v.blockHash = gt_vo.blockHash;
                        v.from = gt_vo.from;
                        v.value = parseInt(gt_vo.value) / 10 ** 18;
                        v.gas = gt_vo.gas;
                        v.to = gt_vo.to;
                        v.transactionHash = gt_vo.hash;
                    }
                }
            }
        }
    }

    /**
     * 获取交易数据
     * @param txHash 
     */
    public static GetTransactionVO(txHash: string): {
        flag: boolean,
        vo: TransactionVO | null
    } {
        for (let i = 0; i < this.transction_vo_list.length; i++) {
            const v = this.transction_vo_list[i];
            if (v.txHash == txHash) {
                return {
                    flag: true,
                    vo: v
                };
            }
        }
        return {
            flag: false,
            vo: null
        };
    }

    /**
     * 获取某Hash是否已被确认
     */
    public static GetTransactionConfirmed(txHash: string): boolean {
        for (let i = 0; i < this.transction_vo_list.length; i++) {
            const v = this.transction_vo_list[i];
            if (v.txHash == txHash) {
                return v.confirmed;
            }
        }
        return false;
    }
}