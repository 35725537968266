<template>
  <div class="set_transfer_password_body" v-show="is_loaded">
    <my-image         :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" class="close_icon" @click="CloseMe()" />

    <!-- 支付密码重置 -->
    <div class="pay_pwd_reset_box" v-if="show_chongzhi_pwd_box">
      <div class="pay_pwd_reset_content">
        <img
          class="ClosePayPwdResetX"
          :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')"
          @click="ClosePayPwdReset"
        />
        <div class="send_reset_phone_box">
          <span>您的手机号:{{ user_vo.phone }}</span>
          <div class="send_code_box">
            <input
              type="text"
              maxlength="6"
              placeholder="请输入验证码"
              v-model="pay_pwd_reset_code"
            />
            <button
              :class="[
                'send_code_btn success_btn',
                send_code_ing ? 'to_gray' : '',
              ]"
              @click="SendCodeAsync"
            >
              {{ send_code_ing ? `${wait_time}s后再试` : "发送验证码" }}
            </button>
          </div>
        </div>

        <button class="ToResetPayPwd success_btn" @click="ToResetPayPwd">
          确认重置
        </button>
      </div>
    </div>

    <div class="title_box">
      <span class="title"
        >{{ model == 1 ? "请输入旧密码" : "设置支付密码" }}
      </span>
      <span class="title_content">{{
        model == 1
          ? "请输入支付密码,以验证身份。"
          : model == 2
          ? "请设置支付密码，用于支付验证。"
          : "请再次填写以确认"
      }}</span>
    </div>

    <div class="input_pwd_box">
      <div :key="index" v-for="(v, index) in pwd_input_arr" class="pwd_input">
        <span v-if="v">•</span>
      </div>
      <div class="finish_btn_box" v-if="model == 3">
        <button
          @click="Finish"
          :class="['success_btn', !IsCanClick() ? 'wait_click_btn' : '']"
        >
          完成
        </button>
      </div>
    </div>

    <div class="pay_pwd_clear_box" v-if="model == 1">
      <button
        @click="ShowChongZhiPwdBox"
        class="error_btn pay_pwd_clear_button"
      >
        密码重置
      </button>
    </div>

    <div class="input_password_keyboard">
      <button class="right_button" @click="InputPwdAsync('1')">1</button>
      <button class="right_button" @click="InputPwdAsync('2')">2</button>
      <button @click="InputPwdAsync('3')">3</button>
      <button @click="InputPwdAsync('4')">4</button>
      <button @click="InputPwdAsync('5')">5</button>
      <button @click="InputPwdAsync('6')">6</button>
      <button @click="InputPwdAsync('7')">7</button>
      <button @click="InputPwdAsync('8')">8</button>
      <button @click="InputPwdAsync('9')">9</button>
      <button class="input_pwd_other_color_btn"></button>
      <button @click="InputPwdAsync('0')">0</button>
      <button
        style="background-image: url('image/tuige.png')"
        @click="InputPwdAsync('back')"
        class="input_pwd_other_color_btn"
      ></button>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import SuperTimer from "@/ts/SuperTimer";
import { SlideVerificationTypeEnum } from "@/ts/SuperEnum";
export default {
  data() {
    return {
      is_loaded:false,
      user_vo: {},
      send_code_ing: false,
      wait_time: 120, //倒计时
      pay_pwd_reset_code: "", //重置密码的code
      model: 3, //模式 1输入旧密码 2设置密码 3重复设置密码
      pwd_input_arr: ["", "", "", "", "", ""],
      new_pwd: "",
      old_pwd: "", //旧密码
      show_chongzhi_pwd_box: false,
    };
  },
  props: {
    set_transfer_password_call_fun: function () {},
  },
  methods: {
    IsCanClick() {
      let can_click = true;
      for (let i = 0; i < this.pwd_input_arr.length; i++) {
        const v = this.pwd_input_arr[i];
        if (v == "") {
          can_click = false;
          break;
        }
      }
      return can_click;
    },
    async Finish() {
      if (this.model != 3) {
        Tools.AlertError("状态错误!");
        return;
      }
      let is_full = true;
      let now_all_pwd = "";
      for (let i = 0; i < this.pwd_input_arr.length; i++) {
        const pwd_v = this.pwd_input_arr[i];
        if (pwd_v == "") {
          is_full = false;
          break;
        } else {
          now_all_pwd += pwd_v;
        }
      }

      if (is_full) {
        if (this.new_pwd == now_all_pwd) {
          let res = await Tools.GetHtmlAsync("SetPayPassword", {
            old_pwd: Tools.IsNull(this.old_pwd) ? now_all_pwd : this.old_pwd,
            new_pwd: this.new_pwd,
          });
          if (Tools.CheckHtmlIsOK(res)) {
            Tools.AlertSuccess("密码设置成功!");
            this.set_transfer_password_call_fun &&
              this.set_transfer_password_call_fun();
            this.CloseMe(true);
          }
        } else {
          Tools.AlertError("确认密码错误!");
          this.ClearInputPwd();
        }
      } else {
        return;
      }
    },
    async InputPwdAsync(v) {
      switch (v) {
        case "back":
          for (let i = this.pwd_input_arr.length - 1; i >= 0; i--) {
            const pwd_v = this.pwd_input_arr[i];
            if (pwd_v != "") {
              this.pwd_input_arr[i] = "";
              break;
            }
          }
          break;
        default:
          for (let i = 0; i < this.pwd_input_arr.length; i++) {
            const pwd_v = this.pwd_input_arr[i];
            if (pwd_v == "") {
              this.pwd_input_arr[i] = v;
              break;
            }
          }
          break;
      }
      let is_full = true;
      let now_all_pwd = "";
      switch (this.model) {
        case 1:
          for (let i = 0; i < this.pwd_input_arr.length; i++) {
            const pwd_v = this.pwd_input_arr[i];
            if (pwd_v == "") {
              is_full = false;
              break;
            } else {
              now_all_pwd += pwd_v;
            }
          }
          if (is_full) {
            //检测旧密码是否正确
            let res = await Tools.GetHtmlAsync("CheckOldPayPassword", {
              pwd: now_all_pwd,
            });
            if (Tools.CheckHtmlIsOK(res)) {
              this.model = 2;
              this.old_pwd = now_all_pwd; //旧密码
              this.ClearInputPwd();
            } else {
              this.ClearInputPwd();
            }
          }
          break;
        case 2:
          for (let i = 0; i < this.pwd_input_arr.length; i++) {
            const pwd_v = this.pwd_input_arr[i];
            if (pwd_v == "") {
              is_full = false;
              break;
            } else {
              now_all_pwd += pwd_v;
            }
          }
          if (is_full) {
            this.new_pwd = now_all_pwd;
            this.model = 3;
            this.ClearInputPwd();
          }
          break;
        case 3:
          for (let i = 0; i < this.pwd_input_arr.length; i++) {
            const pwd_v = this.pwd_input_arr[i];
            if (pwd_v == "") {
              is_full = false;
              break;
            } else {
              now_all_pwd += pwd_v;
            }
          }
          if (is_full) {
            if (this.new_pwd != now_all_pwd) {
              this.ClearInputPwd();
              Tools.AlertError("两次输入密码不一致!");
            }
          }
          break;
      }
    },
    /**
     * 密码重置
     */
    async ToResetPayPwd() {
      let pay_pwd_reset_code = this.pay_pwd_reset_code;
      if (Tools.IsNull(pay_pwd_reset_code)) {
        Tools.AlertError("验证码不能为空!");
        return;
      }
      let res = await Tools.GetHtmlAsync("ResetPayPassword", {
        code: this.pay_pwd_reset_code,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        let d = res.data;
        Global.MessageBox(
          "系统提示!",
          `您的重置密码为:${d.new_pwd} 请及时更改!`
        );
        this.ClosePayPwdReset();
      }
    },

    async SendCodeAsync() {
      if (this.send_code_ing) {
        return;
      }
      this.send_code_ing = true;
      this.wait_time = 120;
      let flag = await this.SendMobilePhoneVerificationAsync(this.wait_time);
      if (flag) {
        SuperTimer.Start(
          "send_code_ing",
          () => {
            this.wait_time--;
            if (this.wait_time <= 0) {
              this.wait_time = 0;
              this.send_code_ing = false;
              return false;
            }
            return true;
          },
          1000
        );
      } else {
        this.send_code_ing = false;
      }
    },

    /**
     * 发送手机验证请求
     */
    async SendMobilePhoneVerificationAsync(wait_time) {
      // let res = await Tools.GetHtmlAsync("SendMobilePhoneVerification", {
      //   phone: this.user_vo.phone,
      //   wait_time: wait_time,
      // });
      // if (Tools.CheckHtmlIsOK(res)) {
      //   return true;
      // }
      // return false;
      return await new Promise((r) => {
        try {
          Global.OpenSlideVerification(
            this.user_vo.phone,
            SlideVerificationTypeEnum.VerificationPhone,
            (f) => {
              r(true);
            },
            (msg) => {
              Tools.AlertError(msg);
            },
            () => {
              // Tools.AlertError("已取消");
              r(false);
            }
          );
        } catch (ex) {
          r(false);
        }
      });
    },

    /**
     * 清空密码
     */
    ClearInputPwd() {
      for (let i = 0; i < this.pwd_input_arr.length; i++) {
        this.pwd_input_arr[i] = "";
      }
    },
    CloseMe(flag) {
      if (flag) {
        this.ClearInputPwd();
        this.$parent.show_set_transfer_password = false;
      } else {
        Global.MessageBox("系统提示!", "是否放弃设置支付密码?", () => {
          this.ClearInputPwd();
          this.$parent.show_set_transfer_password = false;
        });
      }
    },
    ShowChongZhiPwdBox() {
      this.show_chongzhi_pwd_box = true;
    },
    ClosePayPwdReset() {
      this.pay_pwd_reset_code = "";
      this.show_chongzhi_pwd_box = false;
    },
    async GetMyUserVOAsync() {
      let res = await Tools.GetHtmlAsync("GetMyUserVO", {});
      if (Tools.CheckHtmlIsOK(res)) {
        this.user_vo = res.data;
      }
    },
  },
  async mounted() {
   
   this.GetMyUserVOAsync();

    //检查是否存在密码
    let res = await Tools.GetHtmlAsync("CheckHavePayPassword", {});
    if (Tools.CheckHtmlIsOK(res)) {
      let d = res.data;
      let must_to_unlock = d.must_to_unlock;
      let have_pay_pwd = d.have_pay_pwd;
      if (have_pay_pwd) {
        this.model = 1;
      } else {
        this.model = 2;
      }
      this.is_loaded=true;
    } else {
      this.CloseMe();
    }

    
  },
};
</script>

<style scoped lang="less">
.set_transfer_password_body {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: @zhifumima_bgc;
  color: @FFFFFF_c040506;
  z-index: 3001;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title_box {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30%;
}

.title {
  font-size: 20px;
}

.title_content {
  font-size: 12px;
  margin-top: 10px;
}

.finish_btn_box {
  position: absolute;
  top: 80px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.success_btn {
  width: 40%;
  height: 40px;
  border-radius: 10px;
}

.wait_click_btn {
  width: 40%;
  height: 40px;
  background-color: @c191F24_ffffff;
  border-radius: 10px;
}

.input_password_keyboard {
  position: absolute;
  bottom: 0px;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: @home_bgc_class !important;
}
.input_password_keyboard > button {
  background-color: @home_bgc_class;
  flex: 1 0 30%;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
  height: 50px;
  display: inline-block;
  box-sizing: content-box;
  font-size: 16px;
  border: 0px;
}

.input_pwd_other_color_btn {
  background-color: @zhifumima_bgc !important;
  background-position: center center;
  background-clip: border-box;
  background-size: auto 50%;
  background-repeat: no-repeat;
}

.input_pwd_box {
  position: relative;
  display: flex;
  width: 89%;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 40px;
  margin-top: 10px;
}

.pwd_input {
  display: flex;
  width: 15%;
  background-color: @bottom_class;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
}
.close_icon {
  // width: 30px;
  // height: 30px;
  position: fixed;
  left: 10px;
  top: 10px;
}

.pay_pwd_clear_box {
}

.pay_pwd_clear_button {
  margin-top: 20px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 10px;
}

/* 密码重置窗口 */
.pay_pwd_reset_box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
}

.pay_pwd_reset_content {
  padding: 30px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  background-color: @c191F24_ffffff;
  border-radius: 10px;
  align-items: center;
  position: relative;
}

.send_code_btn {
  background-color: #ff5722;
  border-radius: 0px;
  border: 0px;
  white-space: nowrap;
  height: 30px;
  font-size: 12px;
}

.send_reset_phone_box {
  display: flex;
  flex-direction: column;
}
.send_code_box {
  display: flex;
  margin-top: 10px;
}

.send_code_box > input {
  height: 30px;
  border: 0px;
}

.ToResetPayPwd {
  height: 30px;
  font-size: 12px;
  margin-top: 10px;
}

.ClosePayPwdResetX {
  // height: 20px;
  // width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>