import BaseEventVO from "./BaseEventVO";

export default class ServerWaitEvent extends BaseEventVO {
    /**
     * 等待回归的数据
     */
    public wait_rand_number: string = "";
    /**
     * 等待时间 0为不限制
     */
    public wait_time: number = 0;

    /**
     * 超时定时器标识
     */
    public timeout_Interval?: NodeJS.Timeout | number;

    /**
     * 
     * @param bind_this 
     * @param key 
     * @param wait_rand_number 等待回归的数据
     * @param wait_time 等待时间 0为不限制
     */
    constructor(bind_this: any, key: string, wait_rand_number: string, wait_time: number) {
        super(bind_this, key, 9999999, 1);
        this.wait_rand_number = wait_rand_number;
        this.wait_time = wait_time;
    }
}