<template>
  <div ref="game_body" class="game_body">
    <div class="menu_box" v-show="show_close_btn">
      <img src="image/diandiandian_heng.png" />
      <div class="line"></div>
      <img src="image/applet_close_black.png" @click="MenuCloseApplet" />
    </div>
    <div class="game_content" v-show="app_start_param_vo?.AppStartType == 1">
      <canvas
        ref="GameCanvas"
        id="GameCanvas"
        oncontextmenu="event.preventDefault()"
        tabindex="0"
      ></canvas>
      <div id="splash">
        <div class="progress-bar stripes">
          <span style="width: 0%"></span>
        </div>
      </div>
    </div>

    <div class="game_content" v-show="app_start_param_vo?.AppStartType == 0">
      <iframe key="applet_box" ref="iframe" :src="EntryAddress"></iframe>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
import AppletStartParameterVO from "@/ts/vo/AppletStartParameterVO";
import { AppletTypeEnum } from "@/ts/SuperEnum";
import Tools from "@/ts/Tools";
import SuperEvents from "@/ts/SuperEvents";
export default {
  data() {
    return {
      show_close_btn: false,
      is_cocos_loaded: false,
      is_close: true,
    };
  },
  props: {
    OnLoaded: function () {},
    /**
     * 启动参数
     */
    app_start_param_vo: AppletStartParameterVO,
    applet_bind_vue: Object,
  },
  computed: {
    /**
     * 获取小程序进入地址
     */
    EntryAddress() {
      let url = "";

      let cube_id = this.app_start_param_vo?.Parameter?.cube_id ?? 0;

      url = this.app_start_param_vo?.AppBaseData?.entry_address;
      if (Tools.StrIsNotNull(url)) {
        if (url.indexOf("?") == -1) {
          if (!url.endsWith("/")) {
            url = `${url}/?platform_guid=${Global.GetUsePlatformGuid}&login_token=${Global.GetLoginToken}&is_lookbao=true&uid=${Global.SelectUID}&cube_id=${cube_id}`;
          } else {
            url = `${url}?platform_guid=${Global.GetUsePlatformGuid}&login_token=${Global.GetLoginToken}&is_lookbao=true&uid=${Global.SelectUID}&cube_id=${cube_id}`;
          }
        } else {
          url = `${url}&platform_guid=${Global.GetUsePlatformGuid}&login_token=${Global.GetLoginToken}&is_lookbao=true&uid=${Global.SelectUID}&cube_id=${cube_id}`;
        }
      }
      return url;
    },
  },
  watch: {
    is_close(n, o) {
      let d = this.$refs["game_body"];
      let h = window.innerHeight;

      if (n) {
        d.style = `top:${h * 2}px;`;
      } else {
        d.style = "top:0px;";
      }
    },
  },
  methods: {
    CloseNow() {
      this.app_start_param_vo &&
        this.app_start_param_vo.AppBaseData &&
        (this.app_start_param_vo.AppBaseData.entry_address = "");
      this.is_close = true;
    },
    CloseMe() {
      switch (this.app_start_param_vo?.AppStartType) {
        case AppletTypeEnum.APP:
          let iframe = this.$refs["iframe"];
          if (iframe) {
            if (Tools.IsNotNull(iframe.src)) {
              Global.ShowSuperMessageBox({
                title: "系统提示!",
                content: "您确定要关闭小程序么?",
                ok_text: "关闭",
                cancel_text: "最小化",
                show_ok_btn: true,
                show_cancel_btn: true,
                ok_fun: () => {
                  this.CloseNow();
                },
                cancel_fun: () => {
                  this.is_close = true;
                  Global.ShowMinizeAppletState(this.app_start_param_vo?.AppID);
                },
              });
            } else {
              this.is_close = true;
            }
          } else {
            this.is_close = true;
          }
          break;
        case AppletTypeEnum.Game:
          this.is_close = true;
          break;
      }
    },
    /**
     * 菜单关闭小程序
     */
    MenuCloseApplet() {
      switch (this.app_start_param_vo?.AppStartType) {
        case AppletTypeEnum.APP:
          this.CloseMe();
          break;
        case AppletTypeEnum.Game:
          window.LookBaoAppletSDK?.GameHall?.UnLoadGame();
          break;
      }
    },
    InitCocos() {
      let host = "game/web-mobile/";
      if (typeof VConsole !== "undefined") {
        window.vConsole = new VConsole();
      }

      var debug = window?._CCSettings?.debug;

      function loadScript(moduleName, cb) {
        function scriptLoaded() {
          document.body.removeChild(domScript);
          domScript.removeEventListener("load", scriptLoaded, false);
          cb && cb();
        }
        var domScript = document.createElement("script");
        domScript.async = true;
        domScript.src = moduleName;
        domScript.addEventListener("load", scriptLoaded, false);
        document.body.appendChild(domScript);
      }

      loadScript(
        debug ? `${host}cocos2d-js.js` : `${host}cocos2d-js-min.js`,
        function () {
          if (CC_PHYSICS_BUILTIN || CC_PHYSICS_CANNON) {
            loadScript(
              debug ? `${host}physics.js` : `${host}physics-min.js`,
              window.boot
            );
          } else {
            window.boot();
          }
        }
      );
    },
    /**
     * 开始执行LookBao小程序
     */
    ToStartLookBaoApplet() {
      this.is_close = false;
      let v = this.app_start_param_vo;

      this.show_close_btn =
        this.app_start_param_vo.AppBaseData.show_close_btn == 1;
      v.BindVUE = this.applet_bind_vue;
      v.AppletWindowVUE = this;

      if (v) {
        switch (v.AppStartType) {
          case AppletTypeEnum.APP:
            Tools.ShowLog("小程序", "加载的APP");
            //刷新
            // let iframe = this.$refs["iframe"];
            // if (
            //   iframe.src == this.app_start_param_vo.AppBaseData.entry_address
            // ) {
            //   this.app_start_param_vo &&
            //     this.app_start_param_vo.AppBaseData &&
            //     (this.app_start_param_vo.AppBaseData.entry_address = "");
            // }

            //
            break;
          case AppletTypeEnum.Game:
            Tools.ShowLog("小程序", "初始化游戏");

            if (this.is_cocos_loaded) {
              window.LookBaoAppletSDK.ToRunGame(v);
            } else {
              this.is_cocos_loaded = true;
              window.LookBaoAppletSDK.OnLoaded = () => {
                window.LookBaoAppletSDK.ToRunGame(v);
              };
              this.InitCocos();
            }
            break;
        }
      }
    },
  },
  created() {},
  mounted() {
    // let d = this.$refs["game_body"];
    // d.style = `top:${window.innerHeight * 2}px;`;
    window.MenuCloseApplet = this.MenuCloseApplet;
    window.AppletCloseNow = this.CloseNow;

    //添加平台变更监听器
    SuperEvents.AddCallEventListener(this, "OnCloseApplet", () => {
      this.CloseNow();
      Global.CloseMinizeAppletState();
    });
  },
};
</script>

<style scoped lang="less">
.game_body {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 2000;
  background-color: rgba(30, 30, 30);
  transition: all 0.4s ease-in;

  .menu_box {
    display: flex;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    width: 100px;
    justify-content: space-around;
    padding: 2px 10px;
    position: absolute;
    top: 18px;
    right: 2px;
    z-index: 2010;
    .line {
      border-left: 1px solid white;
      margin: 5% 0px;
      opacity: 0.4;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }

  .game_content {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      border: 0px;
    }
  }
}

#GameCanvas {
  width: 100%;
  height: 100%;
}
</style>