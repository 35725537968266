import { createStore } from 'vuex'

export default createStore({
    state: {
        colorbtn: true
    },
    mutations: {
        changeTheme(state) {
            // state.colorbtn == true ? state.colorbtn = false : state.colorbtn = true 
            state.colorbtn = !state.colorbtn;
        }
    },
    actions: {
    },
    modules: {
        
    }
})
