import * as bip39 from "bip39";
import * as util from 'ethereumjs-util';
const { hdkey } = require('ethereumjs-wallet')
var Wallet = require('ethereumjs-wallet');
/**
 * 币种体系
 */
export enum CoinTypeEnum {
    /**
     * 默认类型
     */
    Default = -1,
    /**
     * 钱包类型IM主币
     */
    IM = 60,//主币名称
    BTC = 0, //比特币体系
    OMNI = 200, //OMNI体系
    EOS = 194, //EOS体系
    TRX = 195, //(TRX)波场体系
    DOGE = 3, //DOGE体系
}

/**
 * 助记词语言
 */
export enum WordLanguageEnum {
    /**
     * 简体中文
     */
    chinese_simplified = "chinese_simplified",
    /**
     * 繁体中文
     */
    chinese_traditional = "chinese_traditional",
    /**
     * 英语
     */
    english = "english",
    /**
     * 法语
     */
    french = "french",
    /**
     * 意大利语 
     */
    italian = "italian",
    /**
     * 日语
     */
    japanese = "japanese",
    /**
     * 朝鲜语
     */
    korean = "korean",
    /**
     * 西班牙语
     */
    spanish = "spanish"
}


/**
 * 助记词长度
 */
export enum MnemonicStrengthEnum {
    L_12 = 128,
    L_15 = 160,
    L_18 = 192,
    L_21 = 224
}
/**
 * 外部链内部链枚举
 */
export enum ChangeEnum {
    /**
     * 公链
     */
    ExternalChain = 0,
    /**
     * 私链
     */
    InternalChain = 1
}

export default class BaseWallet {

    private static hdWallet: any;

    /**
     * 创建助记词
     * @param password 种子密码
     * @param len 助记词长度(12-15-18-21) 
     * @param worldLanguage 使用的世界语言
     */
    static CreateMnemonicAndSeed(password?: string, len: MnemonicStrengthEnum = MnemonicStrengthEnum.L_12, worldLanguage: WordLanguageEnum = WordLanguageEnum.english) {
        // 生成助记词
        var mnemonic: string = bip39.generateMnemonic(len, undefined, bip39.wordlists[worldLanguage.toString()]);
        // 生成随机种子
        return this.GetSeedFromMnemonic(mnemonic, password);
    }

    /**
     * 根据助记词获取种子
     * @param mnemonic 
     * @param password 
     */
    static GetSeedFromMnemonic(mnemonic: string, password?: string) {
        console.log("正在生成种子.....此处很耗时!");
        var seed = this.MnemonicToSeed(mnemonic, password);
        return {
            mnemonic,
            seed
        };
    }

    /**
     * 助记词生成种子
     * @param mnemonic 助记词
     * @param password 种子生成秘钥
     */
    static MnemonicToSeed(mnemonic: string, password?: string): Promise<Buffer> {

        return bip39.mnemonicToSeed(mnemonic, password);
    }

    /**
    * 编码助记词
    * @param mnemonic 助记词 
    * @param worldLanguage 助记词使用的语言
    */
    static MnemonicToEntropy(mnemonic: string, worldLanguage: WordLanguageEnum = WordLanguageEnum.english): string {
        return bip39.mnemonicToEntropy(mnemonic, bip39.wordlists[worldLanguage.toString()])
    }

    /**
     * 解码助记词
     * @param encrytMnemonic 加密的助记词
     */
    static EntropyToMnemonic(encrytMnemonic: string): string {
        return bip39.entropyToMnemonic(encrytMnemonic);
    }

    /**
    * 创建一个新的钱包
    * @param mnemonic 助记词
    * @param password 种子密钥
    */
    static CreateNewHDWallet(seedBuffer: Buffer) {
        // 生成钱包私钥
        var hdWallet = hdkey.fromMasterSeed(seedBuffer); //主私钥
        BaseWallet.hdWallet = hdWallet;
        return {
            _hdwallet: hdWallet
        };
    }

    /**
     * 验证助记词
     * @param mnemonic 助记词
     */
    static ValidateMnemonic(mnemonic: any[], worldLanguage: WordLanguageEnum): boolean;
    static ValidateMnemonic(mnemonic: string, worldLanguage: WordLanguageEnum): boolean;
    static ValidateMnemonic(mnemonic: string | any[], worldLanguage: WordLanguageEnum = WordLanguageEnum.english): boolean {
        if (typeof (mnemonic) == "string") {
            return bip39.validateMnemonic(mnemonic, bip39.wordlists[worldLanguage]);
        }
        else {
            let mnemonic_str = "";
            mnemonic.forEach(v => {
                if (typeof v == "string") {
                    mnemonic_str += v + " ";
                }
                else {
                    mnemonic_str += v.name + " ";
                }
            });
            mnemonic_str = mnemonic_str.substring(0, mnemonic_str.length - 1);
            return bip39.validateMnemonic(mnemonic_str, bip39.wordlists[worldLanguage]);
        }
    }

    /**
     * 获取语言列表
     * @param worldLanguage 语言
     */
    static GetWorldLanguageList(worldLanguage: WordLanguageEnum = WordLanguageEnum.english): string[] {
        return bip39.wordlists[worldLanguage.toString()];
    }

    /**
     * 创建引导路径
     * @param coin_type 一个主节点（种子）可用于无限数量的独立加密币，如比特币，Litecoin或Namecoin。 但是，为各种加密币共享相同的空间有一些缺点。
                        此级别为每个加密币创建一个单独的子树，避免重用加密链中的地址并改善隐私问题。
                        硬币类型是一个常量，为每个加密币设置。 Cryptocoin开发人员可能会要求为他们的项目注册未使用的号码。
                        已分配硬币类型的列表在下面的“已注册硬币类型”一章中。
                        在此级别使用强化派生。

     * @param account   此级别将密钥空间拆分为独立的用户身份，因此钱包永远不会将硬币混合在不同的帐户中。
                        用户可以使用这些账户以与银行账户相同的方式组织资金; 用于捐赠目的（所有地址都被视为公共地址），用于保存目的，共同费用等。
                        帐户按顺序递增的方式从索引0开始编号。 此数字在BIP32派生中用作子索引。
                        在此级别使用强化派生。
                        如果先前的帐户没有交易历史记录（意味着之前没有使用过任何地址），则软件应该阻止创建帐户。
                        从外部源导入种子后，软件需要发现所有使用过的帐户。 “帐户发现”一章中描述了这种算法。

     * @param change    常量0用于外部链，常量1用于内部链（也称为找零地址）。 外部链用于在钱包外部可见的地址（例如，用于接收付款）。 内部链用于地址，这些地址并不意味着在钱包外可见，并用于返回交易更改。
     
     * @param address_index 地址按顺序递增的方式从索引0开始编号。 此数字在BIP32派生中用作子索引。
     */
    static CreateDervePath(coin_type: CoinTypeEnum, account: number = 0, change: ChangeEnum = 0, address_index: number = 0): string {
        return `m/44'/${coin_type.valueOf()}'/${account}'/${change.valueOf()}/${address_index}`;
    }

    /**
     * 根据钱包主私钥生成钱包私钥+钱包地址
     * @param hdWallet 钱包对象
     * @param derivePath 钱包生成引导路径
     */
    public static GetWalletAddressAndPrivateKey(hdWallet: any, derivePath: string) {

        // 生成第一个账户的私钥
        var key: any = hdWallet.derivePath(derivePath) //生成子私钥
        // 导出私钥
        var privateKey: string = util.bufferToHex(key._hdkey._privateKey);
        //公共扩展密钥
        var publicExtendedKey: string = key._hdkey.publicExtendedKey;

        // 生成地址
        var address: string = util.pubToAddress(key._hdkey._publicKey, true).toString("hex");
        if (address.indexOf("0x") == -1)
            address = "0x" + address;
        address = util.toChecksumAddress(address);
        return {
            key,
            address,
            publicKey: key._hdkey._publicKey.toString("hex"),
            privateKey,
            /**
             * 公共扩展密钥
             */
            publicExtendedKey
        };
    }

    /**
 * keystore 解锁返回私钥
 * @param keystore 
 * @param pwd 
 */
    public static async GetPrivateFromKeystoreAsync(keystore: string, pwd: string): Promise<string> {
        try {
            //反向
            let v = await Wallet.default.fromV3(keystore, pwd);
            if (v == null)
                throw "解锁失败!";
            let private_key: string = v.privateKey.toString("hex");
            if (private_key.indexOf("0x") == -1)
                private_key = `0x${private_key}`;
            return private_key;
        }
        catch (ex) {
            throw "解码失败!";
        }
    }
}