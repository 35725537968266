<template>
  <div class="super_tab_box">
    <div class="tab_name_box" v-if="!hide_tab">
      <template :key="i" v-for="(v, i) in tab_list">
        <premission :premission_name="v?.premission_name ?? ''">
          <span
            @click="select_index = i"
            :class="['tab_title', select_index == i ? 'select_tab_title' : '']"
          >
            <span>{{ typeof v == "string" ? v : v.tab_name }}</span>
          </span>
        </premission>
      </template>
    </div>
    <template :key="i" v-for="(v, i) in tab_list">
      <premission :premission_name="v?.premission_name ?? ''">
        <div
          :class="
            no_overflow_y ? 'my_tab_content' : 'my_tab_content_overflow_y'
          "
          v-show="select_index == i"
        >
          <slot :name="`${i}`"></slot>
        </div>
      </premission>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      select_index: 0,
    };
  },
  watch: {
    select_index(_new, _old) {
      this.OnTabSelected && this.OnTabSelected(_new);
    },
  },
  props: {
    tab_list: Array,
    no_overflow_y: Boolean, //是否没有滚动 默认有
    hide_tab: Boolean, //隐藏标签头
    OnLoad: function (index) {},
    OnTabSelected: function (index) {},
  },
  methods: {},
  mounted() {
    this.OnLoad && this.OnLoad(this.select_index);
    this.OnTabSelected && this.OnTabSelected(this.select_index);
  },
};
</script>
<style scoped lang="less">
.super_tab_box {
  height: 100%;
  width: 100%;
  background-color: @c292929_ffffff;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.tab_name_box {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;
}

.tab_name_box::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.tab_title {
  background-color: tab_title;
  font-size: 12px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  color: @FFFFFF_c040506;
}

.select_tab_title {
  font-size: 18px;
  font-weight: bold;
  background-color: @c292929_ffffff;
}

.tab_title:first-child {
  border-top-left-radius: 10px;
  margin-left: 2px;
}

.tab_title:last-child {
  border-top-right-radius: 10px;
}

.my_tab_content_overflow_y {
  background-color: @home_bgc_class;
  height: 100%;
  overflow-y: auto;
  padding-top: 5px;
  /* overflow: hidden; */
  flex-grow: 1;
}

.my_tab_content {
  background-color: @home_bgc_class;
  height: 100%;
  /* overflow-y: auto; */
  padding-top: 5px;
  overflow: hidden;
  flex-grow: 1;
}
.my_tab_content_overflow_y::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>