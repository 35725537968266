import { CurrencySystemIdEnum } from "@/ts/SuperEnum";
import AccountManagement from "../AccountManagement";
import { CoinTypeEnum } from "../BaseWallet";
import CoinTypeManager from "../CoinTypeManager";

/**
 * 地址结构体
 */
export default class AddressVO {
    /**
     * 对应账户UID
     */
    public uid: string = "";
    /**
     * 所属币种
     */
    public coin_type: CoinTypeEnum = CoinTypeEnum.Default;
    /**
     * 货币地址(主地址或合约地址)
     */
    public address: string = "";
    /**
     * 资产
     */
    public amount: number = 0;

    /**
     * 体系ID
     */
    public currency_system_id: CurrencySystemIdEnum = CurrencySystemIdEnum.LookBao;

    /**
     * 
     * @param currency_system_id 体系ID
     * @param address 
     * @param amount 
     * @param coin_type 
     */
    constructor(uid: string, currency_system_id: CurrencySystemIdEnum, address: string, amount: number, coin_type: CoinTypeEnum) {
        this.uid = uid;
        this.currency_system_id = currency_system_id;
        this.address = address;
        this.amount = amount;
        this.coin_type = coin_type;
    }

    /**
     * 更新资产
     */
    public async UpdateAmountAsync(): Promise<number> {
        try {
            
            //获取钱包对象
            let wallet_vo = AccountManagement.GetNowSelectAccountWalletVO(this.uid);
            if (wallet_vo) {
                let wallet_server = wallet_vo.GetWalletServer(this.currency_system_id);
                let amount: number = 0;
                if (this.coin_type == CoinTypeEnum.IM) {
                    //如果地址是空说明是主地址资产
                    wallet_server.GetBalanceAsync(this.address);
                }
                else { //否则为合约地址资产
                    let coin_type_vo = CoinTypeManager.singleton.GetCoinTypeVOFromCoinType(this.currency_system_id, this.coin_type);
                    
                    if (coin_type_vo != null) {
                        if (coin_type_vo.contract_server != null) {
                            amount = await coin_type_vo.contract_server.BalanceOf(this.address);
                        }
                    }
                }
                amount = amount / 10 ** 18;
                this.amount = amount;
                return this.amount;
            }
            else {
                return 0;
            }
        }
        catch (e) {
            console.log(e);
            return 0;
        }
    }
}