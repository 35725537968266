<template>
  <div class="body">
    <div class="head">
      <span>"{{ show_user_vo.nick_name }}"的信息</span>
      <img @click="CloseMe" class="close_btn" src="image/back.png" />
    </div>
    <div class="content">
      <img class="head_icon" :src="GetResourceIMG(show_user_vo.head_pic)" />
      <div class="content_item">
        <div class="item">
          <span> 昵称 </span>
          <span> {{ show_user_vo.nick_name }} </span>
        </div>
        <div class="item">
          <span> UID </span>
          <span> {{ show_user_vo.uid }} </span>
          <div class="blocked_account">
            <div>封锁账号</div>
        <LBSwitch v-model="user_vo.is_disable" @update="blockedAccount"></LBSwitch></div>
        </div>
        <div class="item">
          <span> 权限 </span>
          <span> {{ ShowPermission(show_user_vo.permission) }} </span>
        </div>
        <div class="item">
          <span> NFT星级 </span>
          <span><Star :star_num="show_user_vo.nft_star"></Star></span>
        </div>
        <div class="item">
          <span> 状态 </span>
          <span> {{ show_user_vo.status == 1 ? "可用" : "不可用" }} </span>
        </div>
        <div class="item">
          <span> 创建时间 </span>
          <span> {{ show_user_vo.create_time }} </span>
        </div>
        <div class="item">
          <span> 手机号 </span>
          <span>
            {{ show_user_vo.phone == "" ? "无" : show_user_vo.phone }}
          </span>
        </div>
        <div class="item">
          <span> 是否为主号 </span>
          <span> {{ show_user_vo.is_master == 1 ? "是" : "否" }} </span>
        </div>
        <div class="item">
          <span> 钱包地址 </span>
          <span> {{ show_user_vo.wallet_address }} </span>
        </div>
        <!-- <div class="item">
          <span> 设备驱动号 </span>
          <span>
            {{
              show_user_vo.device_tokens == ""
                ? "无"
                : show_user_vo.device_tokens
            }}
          </span>
        </div> -->
        <div class="item">
          <span> 生日 </span>
          <span> {{ GetDateTime(show_user_vo.birthady) }} </span>
        </div>
        <div class="item">
          <span> 个人标签 </span>
          <span> {{ show_user_vo.personal_label }} </span>
        </div>
        <div class="item">
          <span> 个人签名 </span>
          <span> {{ show_user_vo.personal_signature }} </span>
        </div>
        <div class="item">
          <span> 购买小程序数 </span>
          <span> {{ show_user_vo.max_applet_count }} </span>
        </div>
        <div class="item">
          <span> 账户余额 </span>
          <span> {{ show_user_vo.balance }} </span>
        </div>
        <div class="item">
          <span> 可创币种数 </span>
          <span> {{ show_user_vo.max_coin_count }} </span>
        </div>
        <div class="item">
          <span> 是否在线 </span>
          <span> {{ show_user_vo.is_online == 1 ? "在线" : "离线" }} </span>
        </div>
        <div class="item">
          <span> 冻结金额 </span>
          <span> {{ show_user_vo.frozen_balance }} </span>
        </div>
        <div class="item">
          <span> 作者介绍 </span>
          <span>
            {{
              show_user_vo.author_introduction == ""
                ? "无"
                : show_user_vo.author_introduction
            }}
          </span>
        </div>
        <div class="item">
          <span> 首页授权 </span>
          <span>
            {{ show_user_vo.home_page_mint_power == 1 ? "已授权" : "未授权" }}
          </span>
        </div>
        <div class="item">
          <span> 是否已实名 </span>
          <span>
            {{ show_user_vo.is_real_name == 1 ? "已实名" : "未实名" }}
          </span>
        </div>
        <div class="item">
          <span> 签到时间 </span>
          <span> {{ show_user_vo.check_in_time }} </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
import Star from "@/components/StarComponent.vue";
import Tools from "@/ts/Tools";
import LBSwitch from "@/components/lookbao_components/LookBaoSwitch.vue";
import { inject } from "vue";
export default {
  data() {
    return {
    };
  },
  components: {
    Star,
    LBSwitch
  },
  props: {
    show_user_vo: Object,
  },

  methods: {
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    ShowPermission(p) {
      switch (p) {
        case 0:
          return "普通权限";
        case 1:
          return "管理员权限";
        case 2:
          return "平台权限";
        default:
          return "未知";
      }
    },
    GetDateTime(v) {
      return Tools.FormatDateTimeToDate(v);
    },
    CloseMe() {
      this.$parent.show_user_infromation_viewing = false;
    },
  async blockedAccount() {
      //推送显示零钱v
      let uidlist=[this.show_user_vo.uid]
      let res = await Tools.GetHtmlAsync("SetUserDisableState", {
        uids:JSON.stringify(uidlist),
        statu: this.user_vo.is_disable ?1:0,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        Tools.AlertSuccess("设置成功!");
      } else {
        _this.Set(!v.change_payment);
      }
    },
  },
      setup() {
    const user_vo = inject("user_vo");
    return {
      user_vo,
    };
  },
  mounted() {
  },
};
</script>
<style scoped lang="less">
.body {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: @c000000_F0F1F5;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
/* 新增样式 start */
.blocked_account{
  display: flex;
  flex-direction: row;
  margin-left: 100px;
}
/* end */
.head {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px;
}
.head_icon {
  height: 100px;
  width: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.content_item {
  display: flex;
  height: 100%;
  padding: 10px;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.content_item::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.item {
  background-color: @c303339_ffffff;
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  text-align: left;
  font-size: 12px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 20px;
}
.item > span:nth-child(1) {
  display: inline-block;
  width: 100px;
  min-width: 100px;
}

.item > span:nth-child(2) {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.close_btn {
  width: 20px;
  position: absolute;
  left: 10px;
}
</style>