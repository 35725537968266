import BackDataVO from "./BackDataVO";
import BaseEventVO from "./BaseEventVO";

/**
 * 普通回调事件
 */
export default class ServerCallBackEvent extends BaseEventVO {

    public constructor(bind_this: any, key: string, call_back_fun: ((_this: any, data_vo: BackDataVO) => void), group_id: number = 0, max_use_count: number = 0) {
        super(bind_this, key, group_id, max_use_count);
        super.call_back_fun = call_back_fun;
    }
}