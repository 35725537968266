import Global from "@/ts/Global";
import { CurrencySystemIdEnum } from "@/ts/SuperEnum";
import Tools from "@/ts/Tools";
import AccountManagement from "../AccountManagement";
import { CoinTypeEnum } from "../BaseWallet";
import LookBaoContract from "../LookBaoWallet/LookBaoContract";
import I_WalletContractServer from "../WalletInterface/I_WalletContractServer";
import I_WalletServer from "../WalletInterface/I_WalletServer";

/**
 * 币种结构体
 */
export default class CoinTypeVO {
    /**
     * 别名
     */
    public coin_alias: string = "";
    /**
     * 是否可用(true代表正在使用)
     */
    public is_use: boolean = false;
    /**
     * 是否为主币种
     */
    public is_main: boolean = false;
    /**
     * 币种全名
     */
    public coin_full_name: string = "";

    /**
     * 币种单位如：ETH EOS 等
     */
    public coin_symbol: string = "";

    /**
     * 币种配图(可能是图片路径，或者base64数据)
     */
    public coin_img: string = "";
    /**
     * 币类型
     */
    public coin_type: CoinTypeEnum = CoinTypeEnum.Default;
    /**
     * 合约地址
     */
    public contract_address: string = "";

    /**
     * 合约描述代码
     */
    public contract_abi_json: string = "";

    /**
     * 币种单位(默认18位)
     */
    public reserved_decimal: number = 18;
    /**
     * 目前货币价格
     */
    private amount: number = 0;
    /**
     * 币种体系ID
     */
    public currency_system_id: CurrencySystemIdEnum = CurrencySystemIdEnum.LookBao;

    /**
     * 合约类型 目前1积分卡ERC20 2藏品卡 ERC721
     */
    public contract_type_id: number = 1;

    /**
     * 是否为系统积分币种
     */
    public is_system_integral: boolean = false;

    /**
     * 合约服务
     */
    public contract_server: I_WalletContractServer | null = null;
    private wallet_server: I_WalletServer | null = null;


    /**
     * 
     * @param wallet_server 
     * @param currency_system_id 
     * @param coin_alias 
     * @param is_use 
     * @param is_main 
     * @param coin_full_name 
     * @param coin_symbol 
     * @param coin_img 
     * @param coin_type 
     * @param contract_address 
     * @param contract_abi_json 
     * @param amount 
     * @param is_system_integral 
     * @param contract_type_id 合约类型id 1(erc20) 2(erc721)
     * @param reserved_decimal 
     */
    constructor(wallet_server: I_WalletServer, currency_system_id: CurrencySystemIdEnum, coin_alias: string, is_use: boolean, is_main: boolean, coin_full_name: string, coin_symbol: string, coin_img: string, coin_type: CoinTypeEnum, contract_address: string, contract_abi_json: string, amount: number, is_system_integral: boolean, contract_type_id: number, reserved_decimal: number = 18) {
        this.wallet_server = wallet_server;
        this.is_use = is_use;
        this.is_main = is_main;
        this.coin_full_name = coin_full_name;
        this.coin_symbol = coin_symbol;
        this.coin_img = coin_img;
        this.coin_type = coin_type;
        this.contract_address = contract_address;
        this.amount = amount;
        this.contract_abi_json = contract_abi_json;
        this.reserved_decimal = reserved_decimal;
        this.coin_alias = coin_alias;
        this.currency_system_id = currency_system_id;
        this.is_system_integral = is_system_integral;
        this.contract_type_id = contract_type_id;
    }

    public toJSON() {
        return {
            is_use: this.is_use,
            is_main: this.is_main,
            coin_full_name: this.coin_full_name,
            coin_symbol: this.coin_symbol,
            coin_img: this.coin_img,
            coin_type: this.coin_type,
            contract_address: this.contract_address,
            amount: this.amount,
            contract_abi_json: this.contract_abi_json,
            reserved_decimal: this.reserved_decimal,
            coin_alias: this.coin_alias,
            currency_system_id: this.currency_system_id,
            is_system_integral: this.is_system_integral
        }
    }

    /**
     * 初始化合约
     */
    public InitContract() {

        if (this.contract_server == null && this.wallet_server != null) {
            if (Tools.StrIsNotNull(this.contract_abi_json) && Tools.StrIsNotNull(this.contract_address)) {
                switch (this.currency_system_id) {
                    case CurrencySystemIdEnum.LookBao:
                        this.contract_server = new LookBaoContract(this.wallet_server, this.contract_abi_json, this.contract_address);
                        break;
                }

            }
        }
    }


    /**
     * 更新货币余额
     */
    public async UpdateAmountAsync(): Promise<number> {

        /**
         * 更新余额
         */
        let address_vo = await AccountManagement.GetAddressVOFromCoinTypeAsync(Global.login_user_vo?.uid as string, this.coin_type);

        if (address_vo != null) {
            this.amount = await address_vo.UpdateAmountAsync();
        }
        else {

        }
        return this.amount;
    }
}