<template>
  <div ref="message_notification_box" class="message_notification_box">
    <div class="message_notification_body" @click="Open">
      <div class="message_notification_head">
        <my-image :src="host_platform_vo.icon" alt="" />
        <span>{{ APP_NAME }}</span>
      </div>
      <div class="message_notification_item">
        <div class="item_left">
          <div class="message_notification_title">
            <span>{{ title }}</span>
          </div>
          <div class="message_notification_content" v-if="type == 16">
            {{ nick_name }}:
            <my-image
              class="face_icon msg_face"
              src="image/transparent.png"
              :style="
                'background-image: url(' +
                GetResource(content[0].path) +
                ');animation-timing-function:steps(' +
                content[0].config.frameCount +
                '); background-size:' +
                content[0].config.allWidth +
                '%;'
              "
            />
          </div>
          <div class="message_notification_content" v-else>
            {{ nick_name }}:{{ content }}
          </div>
        </div>
        <div class="item_right">
          <my-image class="user_icon" :src="GetResource(icon)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from "@/ts/Config";
import SuperTimer from "@/ts/SuperTimer";
import Global from "@/ts/Global";
import { ChatTypeEnum } from "@/ts/SuperEnum";
import Tools from "@/ts/Tools";
import IM_User from "@/ts/vo/IM_User";
import { inject } from '@vue/runtime-core';
export default {
  setup(){
    const host_platform_vo = inject("host_platform_vo");
    return {
      host_platform_vo
    };
  },
  data() {
    return {
      APP_NAME: Config.APP_NAME,
    };
  },
  props: {
    type: ChatTypeEnum,
    title: String,
    nick_name: String,
    icon: String,
    content: String,
    user_vo: IM_User,
    group_id: Number,
  },
  methods: {
    GetResource(url) {
      return Global.GetCoinIconURL(url);
    },
    Open() {
      if (this.group_id > 0) {
        Tools.GoTo(
          "/reload",
          {
            url: "chat_window",
            group_id: this.group_id,
          },
          {},
          true
        );
      } else {
        Tools.GoTo(
          "/reload",
          {
            url: "chat_window",
          },
          {
            user_vo: this.user_vo,
          },
          true
        );
      }
      this.Hide();
    },
    Show() {
      let message_notification_box = this.$refs["message_notification_box"];
      message_notification_box.style = "top:30px";
      SuperTimer.Start(
        "message_notification_box",
        () => {
          message_notification_box.style = "top:-150px";
          return false;
        },
        3000
      );
    },
    Hide() {
      SuperTimer.Stop("message_notification_box");
      let message_notification_box = this.$refs["message_notification_box"];
      message_notification_box.style = "top:-150px";
    },
  },
  mounted() {},
};
</script>
<style scoped>
.msg_face {
  width: 30px;
  height: 30px;
  display: inline-block;
  box-sizing: content-box;
  vertical-align: middle;
}
.message_notification_box {
  position: fixed;
  top: -150px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  z-index: 30000;
  overflow: hidden;
  flex: none;
  transition: all 0.5s;
}

.message_notification_body {
  width: 95%;
  background-color: #191F23;
  padding: 10px;
  border-radius: 10px;
}

.message_notification_head {
  display: flex;
  align-items: center;
}

.message_notification_head > img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
}
.message_notification_item {
  display: flex;
  width: 100%;
  flex: none;
}

.user_icon {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.item_right {
  margin-left: auto;
  display: flex;
  height: 60px;
}

.message_notification_title {
  display: flex;
  margin-top: 10px;
}
.message_notification_content {
  display: flex;
  color: rgba(255, 255, 255, 0.5);
  align-items: center;
}
</style>