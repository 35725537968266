<template>
  <img :class="is_loading ? 'load_class' : 'load_ok_class'" ref="load_img" />
</template>
<script>
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
export default {
  data() {
    return {
      is_loading: false,
    };
  },
  props: {
    data_src: String,
  },
  watch: {
    data_src(_new, _src) {
      if (Tools.IsNotNull(_new)) {
        this.LoadImage();
      }
    },
  },
  methods: {
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    LoadImage() {
      const imgDiv = new Image();
      const img_element = this.$refs["load_img"];
      let src = this.data_src;
      if (Tools.IsNull(src)) {
        src = img_element.src;
      }
      if (Tools.IsNotNull(src)) {
        try {
          if (src.startsWith("file:")) {
            this.is_loading = false;
            return;
          }
          if (src.endsWith("/") || src.endsWith("\\")) {
            this.is_loading = false;
            return;
          }
          img_element.setAttribute("_src", src);

          imgDiv.src = this.GetResourceIMG(src); //img 为清晰图
          if (imgDiv.complete) {
            console.log(`存在缓存:${src}`);
            img_element.src = src;
          } else {
            console.log(`[图片加载]${src}`);
            img_element.src = "image/image_loading_img.png";
            this.is_loading = true;
            imgDiv.onload = () => {
              img_element.onload = () => {
                this.is_loading = false;
              };
              img_element.style.filter = "none";
              img_element.style.webkitFilter = "none";
              img_element.src = this.GetResourceIMG(src);
            };
            imgDiv.onerror = () => {
              imgDiv.onload = null;
              this.is_loading = false;
              img_element.src = this.GetResourceIMG(src);
            };
          }
        } catch (ex) {
          this.is_loading = false;
          imgDiv.onload = null;
          img_element.src = this.GetResourceIMG(src);
        }
      } else {
        // this.is_loading = true;
      }
    },
  },
  mounted() {
    console.log("dsrc====" + this.dsrc);
    this.LoadImage();
  },
};
</script>
<style scoped>
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.load_class {
  transform: rotate (360deg);
  -webkit-transform: rotate (360deg);
  animation: rotation 1s linear infinite;
  -moz-animation: rotation 1s linear infinite;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  opacity: 0.3 !important;
  /* width: 30px !important;
  height: 30px !important; */
}

.load_ok_class {
  transition: opacity 0.3s ease;
  opacity: 1;
}
</style>