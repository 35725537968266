<template>
  <div class="message_box_body">
    <div class="message_box">
      <span class="message_box_title">{{ message_box_title }}</span>
      <span class="message_box_content" v-html="message_box_content"></span>
      <div class="message_box_bzts_box" v-if="open_no_ti_shi">
        <input type="checkbox" v-model="no_ti_shi" />
        <span>不在提示</span>
      </div>
      <div class="button_box">
        <button class="warning_btn" @click="QuXiao">取消</button>
        <button class="success_btn" @click="QueDing">确定</button>
      </div>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
export default {
  data() {
    return {
      no_ti_shi: false, //不在提示
    };
  },
  props: {
    open_no_ti_shi: Boolean,
    message_box_title: String,
    message_box_content: String,
    other_tishi_data: String,
    message_box_success_call: function(){},
    message_box_cancel_call: function(){},
  },
  methods: {
    QueDing() {
      this.message_box_success_call && this.message_box_success_call();
      this.CloseMe();
    },
    QuXiao() {
      this.message_box_cancel_call && this.message_box_cancel_call();
      this.CloseMe();
    },
    CloseMe() {
      if (this.no_ti_shi) {
        let md5 = Tools.ToMd5(this.message_box_content + this.other_tishi_data);
        //进行存储
        localStorage.setItem(md5, true);
      }
      this.no_ti_shi = false;
      this.$parent.show_message_box = false;
    },
  },
  mounted() {
    let md5 = Tools.ToMd5(this.message_box_content + this.other_tishi_data);
    if (localStorage.getItem(md5)) {
      this.QueDing();
    }
  },
};
</script>
<style scoped lang="less">
.message_box_body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  color: @FFFFFF_c040506;
  z-index: 5000;
}

.message_box {
  padding: 15px;
  background-color: @tishikuang_bgc;

  display: flex;
  flex-direction: column;
  width: 80%;
  border-radius: 10px;
}

.message_box_title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
.message_box_content {
  font-size: 16px;
  margin-bottom: 10px;
}

.button_box {
  display: flex;
  justify-content: space-between;
}
.button_box > button {
  height: 30px;
  width: 40%;
  border-radius: 20px;
}
.message_box_bzts_box {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 10px 10px 10px;
}
.message_box_bzts_box > span {
  margin-left: 5px;
}
</style>