import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { createApp, provide, reactive } from 'vue';
import App from './App.vue';
import router from './router';
import Global from './ts/Global';
import SuperEvents from './ts/SuperEvents';
import MyImage from "@/custom_components/image_component.vue";
import VueClipboard from 'vue-clipboard2'
import LookBaoSDK from '@/ts/sdk/LookBaoSDK';
import LookBaoAppletSDK from './ts/sdk/LookBaoAppletSDK';
import LookBaoAPI from "@/ts/sdk/LookBaoAPI";
import LookBaoPremission from "@/components/lookbao_components/LookBaoPremissionComponent.vue";
import store from './store';
import Tools from './ts/Tools';

(window as any).LookBaoSDK = LookBaoSDK;
(window as any).LookBaoAppletSDK = LookBaoAppletSDK;
(window as any).LookBaoAPI = LookBaoAPI;

/**
 * 回调事件
 */
(window as any).SubmitEventCall = (key: string, data: any) => {
    SuperEvents.SubmitEvent(key, data);
}
export class VueTouch {
    obj: any;
    binding: any;
    touchType: any;
    vueTouches: { x: number; y: number; };
    vueMoves: boolean;
    vueLeave: boolean;
    longTouch: boolean;
    vueCallBack: any;
    time: NodeJS.Timeout | undefined;
    constructor(el: any, binding: any, type: any, vnode: any) {
        this.obj = el;
        this.binding = binding;
        this.touchType = type;
        this.vueTouches = { x: 0, y: 0 };
        this.vueMoves = true;
        this.vueLeave = true;
        this.longTouch = true;
        this.vueCallBack = binding.value.fn;
        this.obj.addEventListener("touchstart", (e: any) => {
            this.start(e);
        }, false);
        this.obj.addEventListener("touchend", (e: any) => {
            this.end(e);
        }, false);
        this.obj.addEventListener("touchmove", (e: any) => {
            this.move(e);
        }, false);
    }

    start(e: any) {
        Global.LongTouch = false;
        this.vueMoves = true;
        this.vueLeave = true;
        this.longTouch = true;
        this.vueTouches = { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY };
        this.time = setTimeout(() => {
            if (this.vueLeave && this.vueMoves) {
                this.touchType == "longtap" && this.vueCallBack(e, this.binding.value);
                this.longTouch = false;
                Global.LongTouch = true;
            };
        }, 300);
    }

    end(e: any) {
        console.log("抬起");
        if (this.longTouch == false) {
            console.log("长按!");
            return;
        }

        var disX = e.changedTouches[0].pageX - this.vueTouches.x;
        var disY = e.changedTouches[0].pageY - this.vueTouches.y;
        if (this.time)
            clearTimeout(this.time);
        if (Math.abs(disX) > 10 || Math.abs(disY) > 100) {
            this.touchType == "swipe" && this.vueCallBack(e, this.binding.value);
            if (Math.abs(disX) > Math.abs(disY)) {
                if (disX > 10) {
                    this.touchType == "swipeleft" && this.vueCallBack(e, this.binding.value);
                };
                if (disX < -10) {
                    this.touchType == "swiperight" && this.vueCallBack(e, this.binding.value);
                };
            } else {
                if (disY > 10) {
                    this.touchType == "swipedown" && this.vueCallBack(e, this.binding.value);
                };
                if (disY < -10) {
                    this.touchType == "swipeup" && this.vueCallBack(e, this.binding.value);
                };
            };
        } else {
            if (this.longTouch && this.vueMoves) {
                this.touchType == "tap" && this.vueCallBack(e, this.binding.value);
                this.vueLeave = false
            };
        };
    }
    public move(e: any) {
        this.vueMoves = false;
    }
    randomString() {
        var len = 10;
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        var maxPos = $chars.length;
        var pwd = '';
        for (var i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    }
}

/**
 * 初始化数据库
 */
async function InitAsync() {
    /**
     * 初始化平台分站列表
     */
    await Global.GetPlatformFromHostAsync();

    console.log("初始化窗口!");

    window.addEventListener('message', function (e) {
        // console.log(e.data);
        let w = window as any;
        switch (e.data) {
            case "MenuCloseApplet": //关闭小程序
                w?.MenuCloseApplet && w.MenuCloseApplet();
                break;
            case "AppletCloseNow":
                w?.AppletCloseNow && w.AppletCloseNow();
                break;
        }
        
    }, false);

    let vue = createApp(App);

    vue.use(router);
    vue.use(Antd);
    vue.use(store)
    vue.use(VueClipboard);
    // vue.config.globalProperties.$ImgC = imageConversion; //图片压缩

    vue.component('my-image', MyImage);
    vue.component("premission", LookBaoPremission);

    vue.directive("tap", {
        beforeMount: function (el, binding, vnode) {
            new VueTouch(el, binding, "tap", vnode);
        }
    });
    vue.directive("swipe", {
        beforeMount: function (el, binding, vnode) {
            new VueTouch(el, binding, "swipe", vnode);
        }
    });
    vue.directive("swipeleft", {
        beforeMount: function (el, binding, vnode) {
            new VueTouch(el, binding, "swipeleft", vnode);
        }
    });
    vue.directive("swiperight", {
        beforeMount: function (el, binding, vnode) {
            new VueTouch(el, binding, "swiperight", vnode);
        }
    });
    vue.directive("swipedown", {
        beforeMount: function (el, binding, vnode) {
            new VueTouch(el, binding, "swipedown", vnode);
        }
    });
    vue.directive("swipeup", {
        beforeMount: function (el, binding, vnode) {
            new VueTouch(el, binding, "swipeup", vnode);
        }
    });
    vue.directive("longtap", {
        beforeMount: function (el, binding, vnode) {
            new VueTouch(el, binding, "longtap", vnode);
        }
    });
    vue.mount('#app');
}

async function OnDeviceReady() {
    console.log("设备可用!");
    await InitAsync();
}

if (Global.GetNODE_ENV == "development") {
    InitAsync();
}
else {
    if (Global.GetVueAppTitle == "webbuild") {
        InitAsync();
    }
    else {
        document.addEventListener("deviceready", OnDeviceReady, false);
    }
}