<template>
  <div class="withdrawal_body">
    <div v-show="show_withd_rawal_record" class="withd_rawal_record_body">
      <div>
        <span>提现记录</span>
        <img    :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" @click="HideWithdrawalRecord()" />
      </div>
      <Page
        action="GetRefundApplyList"
        ref="GetRefundApplyList"
        :pageNum="30"
        :CompleteDataFunAsync="GetRefundApplyList"
        class="withd_rawal_record_content"
      >
        <div
          :key="i"
          :class="[
            'withd_rawal_record_item',
            v.refund_apply_vo.state == 1
              ? 'success_active'
              : v.refund_apply_vo.state == 2
              ? 'error_active'
              : '',
          ]"
          v-for="(v, i) in refund_apply_list"
        >
          <div>
            <div>
              <span>提现金额:</span>
              <span>￥{{ v.refund_apply_vo.amount }}</span>
            </div>

            <div>
              <span>申请时间:</span>
              <span>{{ v.refund_apply_vo.application_time }}</span>
            </div>

            <div>
              <span>提现方式:</span>
              <span>{{
                v.refund_apply_vo.method == 1 ? "支付宝" : "银行卡"
              }}</span>
            </div>

            <div>
              <span>审核时间:</span>
              <span>{{ v.refund_apply_vo.processing_time ?? "无" }}</span>
            </div>

            <div>
              <span>审核状态:</span>
              <span>{{ GetState(v.refund_apply_vo.state) }}</span>
            </div>
          </div>
          <div>
            <span>备注:</span>
            <span>{{
              v.refund_apply_vo.remarks ? v.refund_apply_vo.remarks : "无"
            }}</span>
          </div>
        </div>
      </Page>
    </div>

    <div class="withdrawal_content_0" v-show="select_model == 0">
      <div>
        <span>提现方式</span>
        <img v-if="$store.state.colorbtn"         :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')"  @click="CloseMe" />
        <img class="cont_btn" v-else src="@/assets/image/xiaoxi/blackx.png" @click="CloseMe" />
      </div>

      <div @click="ShowWithdrawalRecord">
        <span>查看提现记录</span>
      </div>

      <div @click="ls_select_model = 1">
        <span>支付宝</span>
        <img v-if="ls_select_model == 1" src="image/select_xuan_ze.png" />
      </div>
      <div @click="ls_select_model = 2">
        <span>银行卡</span>
        <img v-if="ls_select_model == 2" src="image/select_xuan_ze.png" />
      </div>
      <div>
        <button class="que_ding_btn" @click="QueRenSelectModel">确定</button>
      </div>
    </div>
    <div class="withdrawal_content" v-show="select_model > 0">
      <div class="withdrawal_content_title">
        <img src="image/back.png" @click="CloseMe" />
        <span>提现</span>
        <span class="balance">￥{{ GetBalance(user_vo.balance) }}</span>
      </div>
      <div class="item">
        <span
          >提现金额(提现金额不能低于{{
            refund_deduction_props_vo?.refund_config_vo?.minimum_amount
          }})</span
        >
        <input v-model="amount" type="text" placeholder="请填写提现金额" />
        <span
          >手续费:{{
            use_deduction
              ? 0
              : refund_deduction_props_vo?.refund_config_vo
                  ?.service_charge_percentage * 100
          }}%</span
        >
      </div>

      <div
        class="item daoju_dikou"
        v-if="refund_deduction_props_vo.prop_goods_vo"
      >
        <div>
          <span>是否使用抵扣道具(可免手续费)</span>
          <Switch v-model="use_deduction" Title="使用抵扣"></Switch>
          <span
            >您目前有 "{{ refund_deduction_props_vo?.prop_back_pack_vo?.num }}"
            个 "{{ refund_deduction_props_vo?.prop_goods_vo?.prop_name }}"</span
          >
        </div>
        <div v-if="use_deduction">
          <img
            :src="
              GetResourceIMG(refund_deduction_props_vo?.prop_goods_vo?.prop_img)
            "
          />
          <span
            >需要
            {{ refund_deduction_props_vo?.prop_goods_vo?.prop_name }}
            {{
              refund_deduction_props_vo?.refund_config_vo
                ?.prop_goods_deduction_num
            }}
            个</span
          >
        </div>
      </div>

      <div class="item">
        <span>提现方式</span>
        <select disabled class="select" v-model="ls_select_model">
          <option value="1">支付宝</option>
          <option value="2">银行卡</option>
        </select>
      </div>
      <template v-if="select_model == 1">
        <div class="item">
          <span>支付宝账号</span>
          <input
            v-model="zfb_account"
            type="text"
            placeholder="请输入支付宝账号"
          />
        </div>

        <div class="item">
          <span>支付宝姓名</span>
          <input
            v-model="real_name"
            type="text"
            placeholder="请输入支付宝姓名"
          />
        </div>
      </template>
      <template v-if="select_model == 2">
        <div class="item">
          <span>银行卡号</span>
          <input
            v-model="bank_card_number"
            type="text"
            placeholder="请输入银行卡号"
          />
        </div>

        <div class="item">
          <span>持卡人姓名</span>
          <input
            v-model="real_name"
            type="text"
            placeholder="请输入持卡人姓名"
          />
        </div>

        <div class="item">
          <span>开户行</span>
          <input
            v-model="deposit_bank"
            type="text"
            placeholder="请输入开户行"
          />
        </div>
      </template>

      <div class="button_box">
        <button
          :class="CanToRefund ? '' : 'to_gray'"
          @click="ToRefundApplyAsync"
        >
          提交可提现({{ ShowAmount }})
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { inject } from "@vue/runtime-core";
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import Page from "@/components/PageComponent.vue";
import Switch from "@/components/lookbao_components/LookBaoSwitch.vue";
export default {
  setup() {
    const user_vo = inject("user_vo");
    return {
      user_vo,
    };
  },
  watch: {
    amount(_new, _old) {
      if (_new > this.user_vo.balance) this.amount = this.user_vo.balance;
      if (_new <= 0) this.amount = "";
    },
  },
  computed: {
    CanToRefund() {
      if (isNaN(this.amount)) return false;
      if (Tools.IsNull(this.amount)) return false;
      if (
        this.amount >=
        this.refund_deduction_props_vo?.refund_config_vo?.minimum_amount
      )
        return true;
      return false;
    },
    /**
     * 显示可提现金额
     */
    ShowAmount() {
      let v = 0;
      if (this.use_deduction) {
        if (typeof this.amount == "number") {
          v = this.amount.toFixed(2);
        } else {
          v = parseFloat(this.amount);
          if (isNaN(v)) {
            v = 0;
          }
          v = v.toFixed(2);
        }
      } else {
        let service_charge_percentage =
          this.refund_deduction_props_vo?.refund_config_vo
            ?.service_charge_percentage;
        if (service_charge_percentage <= 0) service_charge_percentage = 1;

        v = (this.amount - this.amount * service_charge_percentage).toFixed(2);
      }
      if (isNaN(v)) {
        return "0.00";
      }
      return v;
    },
  },
  components: {
    Page,
    Switch,
  },
  data() {
    return {
      use_deduction: false, //是否使用抵扣
      refund_deduction_props_vo: {},
      ls_select_model: 1, //临时选项
      select_model: 0, //0选择提现方式 1支付宝 2银行卡
      zfb_account: "", //支付宝账号
      real_name: "", //真实姓名
      amount: "", //提现金额
      bank_card_number: "", //银行卡号
      deposit_bank: "", //开户行
      show_withd_rawal_record: false, //显示提现记录
      refund_apply_list: [], //提现记录列表
    };
  },
  methods: {
    GetBalance(v) {
      if (typeof v == "number") return v.toFixed(2);
      return 0;
    },
    /**
     * 获取资源图
     */
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    GetState(state) {
      switch (state) {
        case 0:
          return "未审核";
        case 1:
          return "已通过";
        case 2:
          return "已拒绝";
        default:
          return "未知";
      }
    },
    GetRefundApplyList(d, page) {
      if (page == 1) {
        this.refund_apply_list = d.data;
      } else {
        this.refund_apply_list.PushAll(d.data);
      }
    },
    async GetRefundApplyListAsync() {
      // let res = await Tools.GetHtmlAsync("GetRefundApplyList", {
      //   state: -1,
      // });
      // if (Tools.CheckHtmlIsOK(res)) {
      //   this.refund_apply_list = res.data.data;
      // }
      this.$refs["GetRefundApplyList"]?.Start({
        state: -1,
      });
    },
    QueRenSelectModel() {
      this.select_model = this.ls_select_model;
    },
    ShowWithdrawalRecord() {
      this.GetRefundApplyListAsync();
      this.show_withd_rawal_record = true;
    },
    HideWithdrawalRecord() {
      this.refund_apply_list = [];
      this.show_withd_rawal_record = false;
    },
    /**
     *
     */
    async GetMyRefundDeductionPropsAsync() {
      let res = await Tools.GetHtmlAsync("GetMyRefundDeductionProps", {});
      if (Tools.CheckHtmlIsOK(res)) {
        this.refund_deduction_props_vo = res.data;
      }
    },
    CloseMe() {
      this.ls_select_model = 1;
      this.select_model = 0;
      this.$parent.show_withdrawal = false;
    },
    async ToRefundApplyAsync() {
      if (!this.CanToRefund) return;
      if (Tools.IsNull(this.amount)) {
        Tools.AlertError("提现金额不能为空!");
        return;
      }
      let amount = parseFloat(this.amount);
      if (amount <= 0) {
        Tools.AlertError("提现金额必须大于0!");
        return;
      }
      Global.MessageBox("系统提示!", "您确定要提现么?", async () => {
        switch (parseInt(this.select_model)) {
          case 1:
            if (Tools.IsNull(this.zfb_account)) {
              Tools.AlertError("支付宝账号不能为空!");
              return;
            }
            if (Tools.IsNull(this.real_name)) {
              Tools.AlertError("支付宝姓名不能为空!");
              return;
            }
            break;
          case 2:
            if (Tools.IsNull(this.bank_card_number)) {
              Tools.AlertError("银行卡号不能为空!");
              return;
            }
            if (Tools.IsNull(this.real_name)) {
              Tools.AlertError("银行用户名不能为空!");
              return;
            }
            if (Tools.IsNull(this.deposit_bank)) {
              Tools.AlertError("开户行不能为空!");
              return;
            }
            break;
          default:
            Tools.AlertError("提现方式错误!!");
            return;
        }
        //开始提交数据
        console.log(1111,this.ls_select_model)
        console.log(11222211,this.select_model)

        // if(this.select_model=1){
        //   this.bank_card_number='',
        //   this.deposit_bank=''
        // }else{
        //   this.zfb_account=''
        // }
        let res = await Tools.GetHtmlAsync("RefundApply", {
          zfb_account: this.zfb_account,
          real_name: this.real_name,
          amount: this.amount,
          bank_card_number: this.bank_card_number,
          deposit_bank: this.deposit_bank,
          method: this.select_model,
          use_deduction: this.use_deduction ? 1 : 0,
        });
        if (Tools.CheckHtmlIsOK(res)) {
          Tools.AlertSuccess("操作成功!");
          Global.UpdateMyLoginUserAsync();
          this.CloseMe();
        }
      });
    },
  },
  mounted() {
    this.amount = this.GetBalance(this.user_vo.balance);
    this.GetMyRefundDeductionPropsAsync();
  },
};
</script>
<style scoped lang="less">
.withdrawal_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.withdrawal_body > div {
  background-color: @bottom_class;
  width: 100%;
}
.cont_btn{
    width: 16px !important;
    height: auto !important;
}
.withdrawal_content_0 {
  position: absolute;
  bottom: 0px;
  font-size: 16px;
  
  img {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
  }

  div {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 35px 10px;
    border-bottom: 1px solid @tixian_border;
  }
  div:nth-of-type(1) {
    justify-content: center !important;
    padding: 0px 10px;
  }
  div:last-of-type {
    padding: 0px !important;
  }
}

.withdrawal_content {
  height: 100%;
  width: 100%;
  .withdrawal_content_title {
    display: flex;
    justify-content: center;
    position: relative;
    height: 60px;
    align-items: center;
    font-size: 16px;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 10px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 20px;
    font-size: 14px;
    margin-bottom: 20px;
    span:nth-of-type(1),
    span:nth-of-type(2) {
      color: @FFFFFF_c040506;
      margin-bottom: 5px;
      font-size: 14px;
    }
    span:nth-of-type(2) {
      text-align: right;
      display: block;
      width: 100%;
    }

    input,
    select {
      height: 40px;
      border-radius: 6px;
      width: 100%;
      padding: 0px 10px;
    }
  }
}

.que_ding_btn {
  background-color: #02cdb0;
  border-radius: 0px;
  border: 0px;
  width: 100%;
  height: 45px;
  color:white
}

.button_box {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  button {
    border: 0px;
    background-color: #02cdb0;
    width: 100%;
    height: 45px;
    border-radius: 20px;
  }
}

.balance {
  position: absolute;
  right: 10px;
  font-size: 12px;
}

.withd_rawal_record_body {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: @c000000_F0F1F5;
  & > div:nth-of-type(1) {
    height: 40px !important;
    background-color: @c000000_F0F1F5;
    display: flex;
    align-items: center;
  }
}

// 提现记录
.withd_rawal_record_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  & > div:nth-of-type(1) {
    height: 24px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      position: absolute;
      // top: 10px;
      right: 10px;
    }
  }
}

.withd_rawal_record_item {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  font-size: 12px;
  background-color: @c303339_ffffff;
  margin-bottom: 5px;
  & > div:nth-of-type(1) {
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 50%;
      text-align: left;
      margin-bottom: 5px;
    }
    & > div > span:nth-of-type(1) {
      color: @ffffff_324460;
    }
  }
}

.success_active {
  background-color: #42b98366;
}
.error_active {
  background-color: #ff572266;
  margin:  10px;
  border-radius: 5px;
}
// 提现记录

.daoju_dikou {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  & > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    text-align: left !important;
    span {
      display: inline-block;
      width: 100%;
      text-align: left !important;
    }
  }
  & > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 10px;
    }
  }
}
.select{
  background-color: @input_bgc;
}
</style>