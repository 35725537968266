<template>
  <div class="keyboard_bg" @click="CloseMe">
    <div class="keyboard_body" @click.stop="ClickNull">
      <div class="show_title" v-show="show_keyboard_title">
        <span>{{ number_str }}</span>
      </div>
      <div class="keyboard_content">
        <div class="left">
          <div class="item">
            <button @click="ClickNumber('1')">1</button>
            <button @click="ClickNumber('2')">2</button>
            <button @click="ClickNumber('3')">3</button>
          </div>
          <div class="item">
            <button @click="ClickNumber('4')">4</button>
            <button @click="ClickNumber('5')">5</button>
            <button @click="ClickNumber('6')">6</button>
          </div>
          <div class="item">
            <button @click="ClickNumber('7')">7</button>
            <button @click="ClickNumber('8')">8</button>
            <button @click="ClickNumber('9')">9</button>
          </div>
          <div class="item">
            <button @click="ClickNumber('0')">0</button>
            <button @click="ClickNumber('.')">.</button>
          </div>
        </div>
        <div class="right">
          <button @click="ClickNumber('shanchu')" class="warning_btn del_btn">
            删除
          </button>
          <button @click="ClickNumber('queding')" class="succ_btn">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
export default {
  data() {
    return {
      number_str: "",
    };
  },
  props: {
    default_keyboard_number: String,
    show_keyboard_title: Boolean,
    keyboard_finish_call: function(number) {},
  },
  watch: {
    number_str(_new, _old) {
      if (_new == ".") this.number_str = "";
      if (_new == "00") this.number_str = "0";
    },
  },
  methods: {
    CloseMe() {
      this.$parent.show_lookbao_keyboard = false;
    },
    ClickNull() {},
    ClickNumber(v) {
      switch (v) {
        case "queding":
          this.keyboard_finish_call &&
            this.keyboard_finish_call(parseFloat(this.number_str));
          this.CloseMe();
          break;
        case "shanchu":
          if (this.number_str.length > 0) {
            this.number_str = this.number_str.substring(
              0,
              this.number_str.length - 1
            );
            if (this.number_str.length > 0) {
              if (this.number_str[this.number_str.length - 1] == ".")
                this.number_str = this.number_str.substring(
                  0,
                  this.number_str.length - 1
                );
            }
          }
          break;
        case ".":
          if (this.number_str.length == 1) {
            this.number_str += v;
          } else {
            if (this.number_str.indexOf(".") == -1) this.number_str += v;
          }
          break;
        default:
          this.number_str += v;
          break;
      }
    },
  },
  created() {
    if (typeof this.default_keyboard_number == "number") {
      this.number_str = this.default_keyboard_number.toString();
    } else {
      this.number_str = this.default_keyboard_number; //默认数字
    }
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.keyboard_bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
}
.keyboard_body {
  display: flex;
  flex-direction: column;
  background-color: rgb(31, 31, 31);
  margin-top: auto;
  width: 100%;
}
.show_title {
  height: 40px;
  overflow: hidden;
  width: 100%;
}
.show_title > span {
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
}
.keyboard_content {
  display: flex;
  flex-direction: row;
}
.left {
  flex-grow: 1;
}
.right {
  width: 25%;
  display: flex;
  flex-direction: column;
}
.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.right > button,
.item > button {
  width: 98%;
  height: 50px;
  background-color: rgb(51, 51, 51);
  border: solid rgb(31, 31, 31);
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
}
.right > button:nth-child(2) {
  flex-grow: 1;
}

.del_btn {
  background-color: #ffb800 !important;
  font-weight: 500 !important;
}
.succ_btn {
  background-color: #42b983 !important;
  font-weight: 500 !important;
}
</style>