<template>
  <div class="account_system_upgrade_box">
    <div class="account_system_upgrade_content">
      <div class="head">
        <span>绑定手机</span>
        <img
        :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')"
          @click="AccountSystemClose"
          class="account_system_close_btn"
        />
      </div>
      <div class="content">
        <div class="item">
          <div class="phone_box">
            <input
              type="text"
              v-model="phone"
              maxlength="11"
              placeholder="请填写您的手机号码"
            />
            <button
              :class="[
                'send_code_btn success_btn',
                send_code_ing ? 'to_gray' : '',
              ]"
              @click="SendCodeAsync"
            >
              {{ send_code_ing ? `${wait_time}s后再试` : "发送验证码" }}
            </button>
          </div>
        </div>
        <div class="item">
          <input
            type="text"
            maxlength="4"
            v-model="code"
            placeholder="验证码"
          />
        </div>
        <div class="item">
          <input
            type="password"
            maxlength="8"
            v-model="pwd"
            placeholder="请输入新密码"
          />
        </div>
        <div class="item">
          <input
            :disabled="pwd.length != 8"
            type="password"
            maxlength="8"
            v-model="repwd"
            :placeholder="
              pwd.length != 8 ? '密码长度必须为8位' : '请输入确认密码'
            "
          />
        </div>
      </div>
      <span class="tab">备注:请完善您的账户信息!</span>

      <div class="console_box">
        <button
          :class="['success_btn', AllIsOk ? '' : 'to_gray']"
          @click="QueDingAsync"
        >
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import SuperTimer from "@/ts/SuperTimer";
import AccountManagement from "@/ts/blockchain/AccountManagement";
import { inject } from "vue";
import { SlideVerificationTypeEnum } from "@/ts/SuperEnum";
export default {
  data() {
    return {
      wait_time: 120,
      send_code_ing: false,
      phone: "",
      code: "",
      pwd: "",
      repwd: "",
    };
  },
  watch: {
    code(_new, _old) {
      let new_number = "";
      for (let i = 0; i < _new.length; i++) {
        const v = _new[i];
        if (!isNaN(parseInt(v))) {
          new_number += v;
        }
      }
      if (_new != new_number) this.code = new_number;
    },
  },
  computed: {
    AllIsOk() {
      if (Tools.IsNull(this.phone)) return false;
      if (this.phone.length != 11) return false;
      if (Tools.IsNull(this.code)) return false;
      if (this.code.length != 4) return false;
      if (Tools.IsNull(this.pwd)) return false;
      if (this.pwd.length != 8) return false;
      if (this.repwd.length != 8) return false;
      if (this.pwd != this.repwd) return false;

      return true;
    },
  },
  methods: {
    AccountSystemClose() {
      Global.MessageBox(
        "系统提示!",
        "取消绑定将会退出本账号，您确定要操作么?",
        () => {
          this.$parent.show_to_account_system_upgrade = false;
          Global.Send("UnLogin", {});
        }
      );
    },
    async SendCodeAsync() {
      if (this.send_code_ing) {
        return;
      }
      this.send_code_ing = true;
      this.wait_time = 120;
      let flag = await this.SendMobilePhoneVerificationAsync(this.wait_time);
      if (flag) {
        SuperTimer.Start(
          "send_code_ing",
          () => {
            this.wait_time--;
            if (this.wait_time <= 0) {
              this.wait_time = 0;
              this.send_code_ing = false;
              return false;
            }
            return true;
          },
          1000
        );
      } else {
        this.send_code_ing = false;
      }
    },
    /**
     * 发送手机验证请求
     */
    async SendMobilePhoneVerificationAsync(wait_time) {
      // let res = await Tools.GetHtmlAsync("SendMobilePhoneVerification", {
      //   phone: this.phone,
      //   wait_time: wait_time,
      // });
      // if (Tools.CheckHtmlIsOK(res)) {
      //   return true;
      // }
      // return false;
      return await new Promise((r) => {
        try {
          Global.OpenSlideVerification(
            this.phone,
            SlideVerificationTypeEnum.VerificationPhone,
            (f) => {
              r(true);
            },
            (msg) => {
              Tools.AlertError(msg);
            },
            () => {
              // Tools.AlertError("已取消");
              r(false);
            }
          );
        } catch (ex) {
          r(false);
        }
      });
    },
    async QueDingAsync() {
      if (!this.AllIsOk) return;
      //获取助记词
      let v = AccountManagement.GetNowSelectAccountWalletVO(this.user_vo.uid);
      let mnemonic = v.mnemonic;
      if (Tools.IsNull(mnemonic)) {
        Tools.AlertError("您的助记词无效,无法提交资料!");
        return;
      }

      let mi_mnemonic = Tools.EncryptDes(mnemonic, this.pwd);

      let res = await Tools.GetHtmlAsync("SetAccountSystemUpgrade", {
        phone: this.phone,
        code: this.code,
        mnemonic: mi_mnemonic,
        pwd: this.pwd,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        this.$parent.show_to_account_system_upgrade = false;
        Tools.AlertSuccess("资料已提交!");
      } else {
        this.code = "";
        this.pwd = "";
        this.repwd = "";
      }
    },
  },
  setup() {
    const user_vo = inject("user_vo");
    return {
      user_vo,
    };
  },
  mounted() {
    this.phone = this.user_vo.phone;
  },
};
</script>
<style scoped>
.account_system_upgrade_box {
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 200;
}

.account_system_upgrade_content {
  background-color: #191f23;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  border: 1px rgb(61, 61, 61) solid;
  position: relative;
}

.head {
  font-size: 25px;
  margin-bottom: 10px;
}

.item {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 10px;
}

.item input {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  height: 30px;
}

.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.console_box {
  display: flex;
  justify-content: center;
}

.console_box > button {
  width: 80%;
  display: block;
  border-radius: 10px;
  height: 30px;
}

.close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.phone_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.send_code_btn {
  background-color: #ff5722;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 12px;
}

.tab {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin: 5px 0px;
}

.account_system_close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>