<template>
  <div class="uplink_status_box">
    <div class="ulink_head_box">
      <my-image
        class="close_me"
        :src="
          !$store.state.colorbtn
            ? require('@/assets/image/xiaoxi/blackx.png')
            : require('@/assets/image/xiaoxi/x.png')
        "
        @click="CloseMe"
      />
      <span>上链记录</span>
    </div>

    <div class="uplink_item" :key="i" v-for="(item, i) in status_list">
      <div class="head_body">
        <div class="head_box">
          <my-image
            class="user_pic"
            :src="GetResourceIMG(item.from_user_vo.head_pic)"
            alt=""
          />
          <span class="nick_name">{{ item.from_user_vo.nick_name }}</span>
        </div>
        <span>{{ item.task_title }}</span>
        <div class="head_box">
          <my-image
            class="user_pic"
            :src="GetResourceIMG(item.to_user_vo.head_pic)"
            alt=""
          />
          <span class="nick_name">{{ item.to_user_vo.nick_name }}</span>
        </div>
      </div>

      <div class="item">
        <div
          :class="[
            'steps_node',
            'none_state',
            v.task_state == 2
              ? 'success_state'
              : v.task_state == 3
              ? 'error_state'
              : '',
          ]"
          :key="index"
          v-for="(v, index) in item.steps_list"
        >
          <div
            :class="
              index + 1 == item.steps_list.length ? '' : 'steps_node_content'
            "
          >
            <div>
              <my-image
                v-if="v.task_state == 0"
                class="icon"
                src="image/wait_icon.png"
                alt=""
              />
              <svg
                v-else-if="v.task_state == 1"
                viewBox="0 0 1024 1024"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
                focusable="false"
                class="anticon-spin"
              >
                <path
                  d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"
                ></path>
              </svg>
              <my-image
                v-else-if="v.task_state == 2"
                class="icon"
                src="image/gou_xuan.png"
                alt=""
              />
              <my-image
                v-else-if="v.task_state == 3"
                class="icon"
                src="image/error_icon.png"
                alt=""
              />
            </div>
            <div class="lie">
              <span class="title"
                >{{ v.title }}({{ v.current_count }}/{{ v.max_count }})</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
import SuperEvents from "@/ts/SuperEvents";
export default {
  data() {
    return {
      status_list: [],
    };
  },
  methods: {
    CloseMe() {
      this.$parent.show_uplink_status_window = false;
    },
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    /**
     * 获取我的任务列表
     */
    async GetMyBlockChainTaskListAsync() {
      let res = await Tools.GetHtmlAsync("GetMyBlockChainTaskList", {});
      if (Tools.CheckHtmlIsOK(res)) {
        this.status_list = res.data;
      }
    },
  },
  async mounted() {
    await this.GetMyBlockChainTaskListAsync();

    /**
     * 添加区块链状态变更事件
     */
    SuperEvents.AddGroupEvent(
      this,
      "OnBlockChainTaskEvent",
      async (_this, d) => {
        let v = d.DATA;
        let bct_vo = v.bct_vo;
        let count = v.count;
        let task_guid = bct_vo.task_guid;
        let steps_list = bct_vo.steps_list;

        let find_list = this.status_list.Where((x) => x.task_guid == task_guid);
        if (find_list.length > 0) {
          find_list[0].steps_list = steps_list;
        } else {
          await this.GetMyBlockChainTaskListAsync();
        }
      },
      20
    );
  },
};
</script>

<style scoped>
.uplink_status_box {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100%;
  z-index: 500;
  background-color: #070d0d;
  width: 100%;
  color: white;
  padding: 10px;
  overflow-y: auto;
  white-space: nowrap;
}

.uplink_status_box::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.uplink_item {
  display: flex;
  flex-direction: column;
  background-color: #191f23;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.steps_node {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  flex-grow: 1;
  overflow: hidden;
  padding: 0px 10px;
}
.steps_node:last-child {
  flex-grow: 0 !important;
}

.steps_node_content {
  position: relative;
}

.steps_node_content::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #1890ff;
  border: 0px;
  content: "";
}

.tab {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: 30%;
}

.title {
  font-size: 12px;
}

.icon {
  width: 20px;
  height: 20px;
}
.lie {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.none_state {
  color: white;
}

.success_state {
  color: #42b983;
}

.error_state {
  color: #ff5722;
}

.head_body {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
  align-items: center;
}

.head_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user_pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.nick_name {
  font-size: 12px;
  margin-top: 5px;
}

.ulink_head_box {
  position: relative;
  height: 40px;
  font-size: 18px;
}

.close_me {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>