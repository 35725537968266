import Config from '@/ts/Config';
import CordovaPlugin from '@/ts/CordovaPlugin';
import Global from '@/ts/Global';
import Tools from '@/ts/Tools';
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue';

export const main_menu_list: any[] =
  Global.GetVueAppTitle == "webbuild" ? [
    {
      name: "发起群聊",
      icon: "image/fasongxiaoxi_menu.png",
      click: () => {
        Tools.GoTo("/create_group");
      }
    },
    {
      name: "添加朋友",
      icon: "image/tianjiapengyou_menu.png",
      click: () => {
        Tools.GoTo("/search_friend");
      }
    }
  ] :
    [
      {
        name: "发起群聊",
        icon: "image/fasongxiaoxi_menu.png",
        click: () => {
          Tools.GoTo("/create_group");
        }
      },
      {
        name: "添加朋友",
        icon: "image/tianjiapengyou_menu.png",
        click: () => {
          Tools.GoTo("/search_friend");
        }
      },
      {
        name: "扫一扫",
        icon: "image/saoyisao.png",
        click: async () => {
          await CordovaPlugin.OpenQRAsync();
        }
      }
    ];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Main",
    meta: {
      hide_head: true, //隐藏头
      hide_bottom: true //隐藏底部
    },
    // component: () => import("../views/Main.vue")
    component: () => import("../views/LoginManager.vue")
  },
  {
    path: "/home_page",
    name: "HomePage",
    meta: {
      // title:'我的',
      // hide_head: true, //隐藏头
      hide_bottom: true, //隐藏底部
      keepAlive: true  // 需要缓存
    },
    // component: () => import("../views/Main.vue")
    component: () => import("../views/HomePage.vue")
  },
  {
    path: "/my_world",
    name: "MyWorld",
    meta: {
      hide_head: true, //隐藏头部
      hide_bottom: false, //隐藏底部
    },
    component: () => import("../views/MyWorld.vue")
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: "聊天",
      keepAlive: true,  // 需要缓存
      title_css: "font-size:20px; width: 100%; text-align: center;",
      menu_list: main_menu_list,
      buttons: [
        {
          icon: "image/head_jiahao.png",
          css: "width:20px;height:20px;",
          click: () => {
            Global.ShowMenu("46px", "");
          }
        }
      ]
    },
    component: Home
  },
  {
    path: "/address_book",
    name: "AddressBook",
    meta: {
      title: "通讯录",
      title_css: "font-size:20px; width: 100%; text-align: center; ",
      menu_list: main_menu_list,
      buttons: [
        {
          icon: "image/head_jiahao.png",
          css: "width:20px;height:20px;",
          click: () => {
            Global.ShowMenu("46px", "");
          }
        }
      ]
    },
    component: () => import("../views/AddressBook.vue")
  },
  {
    path: "/shop",
    name: "Shop",
    meta: {
      title: "市场",
      title_css: "font-size:20px; width: 100%; text-align: center; ",
      menu_list: main_menu_list,
      buttons: []
    },
    component: () => import("../views/Shop.vue")
  },
  {
    path: "/my",
    name: "My",
    meta: {
      // title: "我的",
      title_css: "font-size:20px; width: 100%; text-align: center; ",
      // hide_head: true, //隐藏头部
      menu_list: main_menu_list,

    },
    component: () => import("../views/My.vue")
  },
  {
    path: "/chat_window",
    name: "ChatWindow",
    meta: {
      title_css: "font-size:20px; width: 100%; text-align: center;",
      hide_bottom: true, //隐藏底部
      title: "聊天窗口",
      goback: "-1"
    },
    component: () => import("../views/ChatWindow.vue")
  },
  {
    path: "/search_friend",
    name: "SearchFriend",
    meta: {
      title: "新朋友",
      goback: "-1",
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/SearchFriend.vue")
  },
  {
    path: "/friend_inforamation",
    name: "FriendInformation",
    meta: {
      title: "朋友昵称",
      goback: "-1",
      hide_bottom: true
    },
    component: () => import("../views/FriendInformation.vue")
  },
  {
    path: "/group_inforamation",
    name: "GroupInformation",
    meta: {
      title: "群详情",
      goback: "-1",
      hide_bottom: true
    },
    component: () => import("../views/GroupInformation.vue")
  },
  {
    path: "/user_information",
    name: "UserInformation",
    meta: {
      title: "用户信息",
      goback: "-1",
      // menu_list: [
      //   {
      //     name: "修改ID",
      //     // icon: "image/fasongxiaoxi_menu.png",
      //     click: () => {

      //     }
      //   },
      //   {
      //     name: "设为星标好友",
      //     // icon: "image/tianjiapengyou_menu.png",
      //     click: () => {

      //     }
      //   },
      //   {
      //     name: "取消星标好友",
      //     // icon: "image/saoyisao.png",
      //     click: () => {

      //     }
      //   }
      // ],
      // buttons: [
      //   {
      //     icon: "image/diandiandian.png",
      //     click: () => {
      //       Global.ShowMenu("48px", "calc(100% - 50%)");
      //     }
      //   }
      // ],
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/UserInformation.vue")
  },
  {
    path: "/session_details",
    name: "SessioonoDetails",
    meta: {
      title: "会话详情",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("../views/SessionDetails.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    meta: {
      goback: "-1",
      title: "设置",
      hide_bottom: true
    },
    component: () => import("../views/SettingsWindow.vue")
  },
  {
    path: "/group_list",
    name: "GroupList",
    meta: {
      goback: "-1",
      title: "群列表",
      hide_bottom: true
    },
    component: () => import("../views/GroupList.vue")
  },
  {
    path: "/invite_friends",
    name: "InviteFriends",
    meta: {
      title: "邀请好友",
      goback: "/home_page",
      hide_bottom: true,
      buttons: [
        {
          name: "确定",
          click: () => {
            let v = Global.app;

          }
        }
      ]
    },
    component: () => import("../views/InviteFriends.vue")
  },
  {
    path: "/create_group",
    name: "CreateGroup",
    meta: {
      title: "创建会话",
      goback: "-1",
      hide_bottom: true,
      buttons: [
        {
          name: "完成",
          css: "success_btn FinishBTN",
          click: () => {
            // alert("完成创建!");
            Tools.AlertSuccess("完成创建!");
          }
        }
      ]
    },
    component: () => import("@/views/CreateGroup.vue")
  },
  {
    path: "/message_notification",
    name: "MessageNotification",
    meta: {
      title: "新消息通知",
      goback: "-1",
      hide_bottom: true
    },
    component: () => import("@/views/MessageNotification.vue")
  },
  {
    path: "/collection_window",
    name: "CollectionWindow",
    meta: {
      title: "收藏",
      goback: "-1",
      hide_bottom: true
    },
    component: () => import("@/views/CollectionWindow.vue")
  },
  {
    path: "/wallet",
    name: "Wallet",
    meta: {
      title: "卡包",
      goback: "-1",
      hide_bottom: true,
      buttons: [
        {
          name: "",
          icon: "image/head_jiahao.png",
          css: "width:20px;height:20px;",
          click: () => {
            // alert("完成创建!");
            Tools.GoTo("/management_coin");
          }
        }
      ]
    },
    component: () => import("@/views/Wallet.vue")
  },
  {
    path: "/management_coin",
    name: "ManagementCoin",
    meta: {
      title: "钱包管理",
      goback: "-1",
      hide_bottom: true
    },
    component: () => import("@/views/ManagementCoin.vue")
  },
  {
    path: "/bill",
    name: "Bill",
    meta: {
      title: "账单",
      goback: "-1",
      hide_bottom: true
    },
    component: () => import("@/views/Bill.vue")
  },
  {
    path: "/test_cordova",
    name: "TestCordova",
    meta: {

    },
    component: () => import("@/views/TestCordova.vue")
  },
  {
    path: "/add_user_to_group",
    name: "AddUserToGroup",
    meta: {
      title: "添加成员",
      goback: "-1",
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/AddUserToGroup.vue")
  },
  {
    path: "/business_card_select",
    name: "BusinessCardSelect",
    meta: {
      title: "选择好友",
      goback: "-1",
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/BusinessCardSelect.vue")
  },
  {
    path: "/group_chat_information",
    name: "GroupChatInformation",
    meta: {
      title: "群聊资料",
      goback: "-1",
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/GroupChatInformation.vue")
  },
  {
    path: "/notice",
    name: "Notice",
    meta: {
      title: "通知",
      goback: "-1",
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/Notice.vue")
  },
  {
    path: "/qr",
    name: "QR",
    meta: {
      title: "二维码",
      goback: "-1",
      hide_head: true, //隐藏头部
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/QR.vue")
  },
  {
    path: "/repeat",
    name: "Repeat",
    meta: {
      title: "名片转发",
      goback: "-1",
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/Repeat.vue")
  },
  {
    path: "/forward_friend_selection",
    name: "ForwardFriendSelection",
    meta: {
      title: "转发",
      goback: "-1",
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/ForwardFriendSelection.vue")
  },
  {
    path: "/share_window",
    name: "ShareWindow",
    meta: {
      title: "小程序分享",
      goback: async () => {
        Tools.GoTo("/home_page");
        await CordovaPlugin.RecoveryAppletAsync(); //执行恢复操作
      },
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/ShareWindow.vue")
  },
  {
    path: "/open_url",
    name: "OpenURL",
    meta: {
      title: "网页",
      goback: "-1",
      // hide_head:true, //隐藏头部
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/OpenURL.vue")
  },
  {
    path: "/open_platform_authorization",
    name: "OpenPlatformAuthorization",
    meta: {
      title: `${Config.APP_NAME}授权`,
      goback: "-1",
      // hide_head:true, //隐藏头部
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/OpenPlatformAuthorization.vue")
  },
  {
    path: "/group_live",
    name: "GroupLive",
    meta: {
      title: "群直播",
      background_color: "transparent !important",
      // goback: "-1",
      hide_head: true, //隐藏头部
      hide_bottom: true //隐藏底部
    },
    component: () => import("../views/GroupLive.vue")
  },
  {
    path: "/push_live",
    name: "PushLive",
    meta: {
      title: `直播推送`,
      background_color: "transparent !important",
      // goback: "-1",
      hide_head: true, //隐藏头部
      hide_bottom: true //隐藏底部
    },
    component: () => import("@/views/PushLive.vue")
  },
  {
    path: "/group_information_user_data",
    name: "GroupInformationUserData",
    meta: {
      title: "用户信息",
      goback: "-1",
      hide_bottom: true //隐藏底部
    },
    component: () => import("@/views/GroupInformationUserData.vue")
  },
  {
    path: "/reload",
    name: "ReLoad",
    meta: {
      title: "域名中转",
      goback: "-1",
      hide_head: true,
      hide_bottom: true //隐藏底部
    },
    component: () => import("@/views/Reload.vue")
  },
  {
    path: "/capital_window",
    name: "CapitalWindow",
    meta: {
      title: "钱包",
      goback: "-1",
      hide_bottom: true, //隐藏底部
      buttons: [
        {
          name: "提现",
          css: "width:20px;height:20px;",
          click: () => {
            //检查是否已开通分账服务
            Tools.GetHtmlAsync("CheckIsOpenLedgerService", {
            }).then((res) => {
              if (Tools.CheckHtmlIsOK(res)) {
                if (res.data.is_open) {
                  Global.ShowWithdrawalfenzhang();
                }else{
                  Global.ShowWithdrawal();
                  // Global.ShowWithdrawalfenzhang();
                }
              }
            });
          }
        }
      ]
    },
    component: () => import("@/views/CapitalWindow.vue")
  },
  {
    path: "/push_nft",
    name: "PushNFT",
    meta: {
      title: "发布藏品",
      goback: "-1",
      hide_bottom: true, //隐藏底部
      buttons: []
    },
    component: () => import("@/views/PushNFT.vue")
  },
  {
    path: "/nft_deploy",
    name: "NFTDeploy",
    meta: {
      title: "部署NFT",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/NFTDeploy.vue")
  },
  {
    path: "/nft_collection_or_release",
    name: "NFTCollectionOrRelease",
    meta: {
      title: "藏品与发布",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/NFTCollectionOrRelease.vue")
  },
  {
    path: "/nft_asset_details_list",
    name: "NFTAssetDetailsList",
    meta: {
      title: "资源详情",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/NFTAssetDetailsList.vue")
  },
  {
    path: "/nft_asset_details",
    name: "NFTAssetDetails",
    meta: {
      title: "",
      goback: "-1",
      hide_head: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/NFTAssetDetails.vue")
  },
  {
    path: "/nft_transaction_record",
    name: "NFTTransactionRecord",
    meta: {
      title: "NFT交易记录",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/NFTTransactionRecord.vue")
  },
  {
    path: "/wait_mint_verify_list",
    name: "WaitMintVerifyList",
    meta: {
      title: "NFT资产待审核列表",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/WaitMintVerifyList.vue")
  },
  {
    path: "/invest_money",
    name: "InvestMoney",
    meta: {
      title: "充值",
      goback: "-1",
      hide_bottom: true, //隐藏底部
      buttons: [
        {
          name: "提现",
          css: "width:20px;height:20px;",
          style: "color:rgba(255,255,255,0.6);",
          click: () => {
            // Global.ShowWithdrawal();
             //检查是否已开通分账服务
             Tools.GetHtmlAsync("CheckIsOpenLedgerService", {
            }).then((res) => {
              if (Tools.CheckHtmlIsOK(res)) {
                if (res.data.is_open) {
                  Global.ShowWithdrawalfenzhang();
                }else{
                  Global.ShowWithdrawal();
                  // Global.ShowWithdrawalfenzhang();
                }
              }
            });
          }
        }
      ]
    },
    component: () => import("@/views/InvestMoney.vue")
  },
  {
    path: "/user_demolition_change_record",
    name: "MyUserDemolitionChangeRecord",
    meta: {
      title: "资金变更记录",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/MyUserDemolitionChangeRecord.vue")
  },
  {
    path: "/auction_record",
    name: "AuctionRecord",
    meta: {
      title: "出价记录",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/AuctionRecord.vue")
  },
  {
    path: "/audit_management",
    name: "AuditManagement",
    meta: {
      title: "审核管理",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/AuditManagement.vue")
  },
  {
    path: "/customer",
    name: "customer",
    meta: {
      title: "客服",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/customer.vue")
  },
  {
    path: "/blind_box_management",
    name: "BlindBoxManagement",
    meta: {
      title: "盲盒管理",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/BlindBoxManagement.vue")
  },
  {
    path: "/blind_box_details",
    name: "BlindBoxDetails",
    meta: {
      title: "盲盒详情",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/BlindBoxDetails.vue")
  },
  {
    path: "/luck_draw_record",
    name: "LuckDrawRecord",
    meta: {
      title: "抽取记录",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/LuckDrawRecord.vue")
  }
  ,
  {
    path: "/super_management",
    name: "SuperManagement",
    meta: {
      title: "超级管理",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/SuperManagement.vue")
  }
  ,
  {
    path: "/digital_collection_management",
    name: "DigitalCollectionManagement",
    meta: {
      title: "数字藏品",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/DigitalCollectionManagement.vue")
  },
  {
    path: "/new_digital_collection_management",
    name: "NewDigitalCollectionManagement",
    meta: {
      title: "数字藏品",
      goback: "-1",
      // hide_head: true, //隐藏头
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/NewDigitalCollectionManagement.vue")
  },

  {
    path: "/wallet_address_alert",
    name: "WalletAddressAlert",
    meta: {
      title: "区块链地址",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/WalletAddressAlert.vue")
  },
  {
    path: "/about_us",
    name: "AboutUS",
    meta: {
      title: "关于我们",
      goback: "-1",
      replice: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/AboutUS.vue")
  },
  {
    path: "/webview",
    name: "WebView",
    meta: {
      title: "浏览",
      goback: "/about_us",
      replice: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/WebView.vue")
  },
  {
    path: "/nft_invite_friends",
    name: "NFTInviteFriends",
    meta: {
      title: "邀请好友",
      goback: "-1",
      replice: true,
      hide_head: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/NFTInviteFriends.vue")
  },
  {
    path: "/my_nft_invite_friends_list",
    name: "MyNftInviteFriendsList",
    meta: {
      title: "好友明细",
      goback: "-1",
      replice: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/MyNftInviteFriendsList.vue")
  }, {
    path: "/daily_check_in",
    name: "DailyCheckIN",
    meta: {
      title: "每日签到",
      goback: "-1",
      replice: false,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/DailyCheckIN.vue")
  },
  {
    path: "/report",
    name: "Report",
    meta: {
      title: "举报",
      goback: "-1",
      replice: false,
      hide_head: true, //隐藏头部菜单
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/Report.vue")
  },
  {
    path: "/asset_transfer",
    name: "AssetTransfer",
    meta: {
      title: "藏品转赠",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/AssetTransfer.vue")
  },
  {
    path: "/asset_chain_details",
    name: "AssetChainDetails",
    meta: {
      title: "藏品详情",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/AssetChainDetails.vue")
  },
  {
    path: "/moticelist",
    name: "moticelist",
    meta: {
      title: "公告信息",
      goback: "-1",
      back_color: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/notice/moticelist.vue")
  },
  {
    path: "/three_test",
    name: "ThreeTest",
    meta: {
      title: "3D测试",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/ThreeTest.vue")
  },
  {
    path: "/my_white_name",
    name: "MyWhiteName", //我的白名单
    meta: {
      title: "白名单",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/MyWhiteName.vue")
  },
  {
    path: "/prop_manager",
    name: "PropManager", //道具管理
    meta: {
      title: "道具管理",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/PropManager.vue")
  },
  {
    path: "/prop_goods_list",
    name: "PropGoodsList", //道具列表
    meta: {
      title: "道具列表",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/PropGoodsList.vue")
  },
  {
    path: "/lachine_ranking",
    name: "LachineRanking",
    meta: {
      title: "排行榜",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/LachineRanking.vue")
  },
  {
    path: "/asset_compose",
    name: "AssetCompose",
    meta: {
      title: "合成",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/AssetCompose.vue")
  },

  //#region 玲珑塔相关
  {
    path: "/linglongta",
    name: "LingLongTa",
    meta: {
      title: "合成",
      goback: "/home_page",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/LingLongTa/index.vue")
  },
  //#region 数字魔方
  {
    path: "/digitalrubikcube",
    name: "digitalrubikcube",
    meta: {
      title: "数字魔方",
      goback: "/home_page",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/digitalrubikcube.vue")
  },
  {

    path: "/llt_compose",
    name: "LLTCompose",
    meta: {
      title: "合成",
      goback: "/linglongta",
      hide_head: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/LingLongTa/compose.vue")
  },
  {
    path: "/game_map",
    name: "GameMap",
    meta: {
      title: "地图",
      goback: "-1",
      // hide_head: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/LingLongTa/map.vue")
  },
  {
    path: "/game_over",
    name: "GameOver",
    meta: {
      title: "游戏结束",
      goback: "/linglongta",
      hide_head: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/LingLongTa/gameover.vue")
  },
  {
    path: "/message_miu",
    name: "message_miu",
    meta: {
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/user/miu.vue")
  },
  {
    path: "/miudetails",
    name: "miudetails",
    meta: {
      goback: "-1",
      back_color: true,
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/user/miudetails.vue")
  },
  {
    path: "/my_records",
    name: "myRecords",
    meta: {
      title: "我的记录",
      hide_bottom: true, //隐藏底部
      goback: "-1",
    },
    component: () => import("@/views/myRecords.vue")
  },
  {
    path: "/asset_list",
    name: "assetList",
    meta: {
      title: "资产记录",
      hide_bottom: true, //隐藏底部
      goback: "-1",
    },
    component: () => import("@/views/assetList.vue")
  },
  {
    path: "/furnace",
    name: "furnace",
    meta: {
      title: "测试H5扫一扫",
      hide_bottom: true, //隐藏底部
      goback: "-1",
    },
    component: () => import("@/views/FurnaceInlet.vue")
  },
  {
    path: "/slide",
    name: "slide",
    meta: {
      title: "测试验证码",
      hide_bottom: true, //隐藏底部
      goback: "-1",
    },
    component: () => import("@/views/TestSlide.vue")
  },
  {
    path: "/substitution",
    name: "substitution",
    meta: {
      title: "我的置换",
      hide_bottom: true, //隐藏底部
      goback: "-1",
    },
    component: () => import("@/views/Substitution.vue")
  },
  {
    path: "/replace",
    name: "replace",
    meta: {
      title: "置换",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/Replace.vue")
  },
  {
    path: "/replace_show",
    name: "replace_show",
    meta: {
      title: "置换详情",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/ReplaceShow.vue")
  },
  {
    path: "/replacementRecord",
    name: "replacementRecord",
    meta: {
      title: "置换记录",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/replacementRecord.vue")
  },
  {
    path: "/replacementDetails",
    name: "replacementDetails",
    meta: {
      title: "详情信息",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/replacementDetails.vue")
  },
  {
    path: "/BuyerReplacementdetails",
    name: "BuyerReplacementdetails",
    meta: {
      title: "置换",
      goback: "-1",
      hide_bottom: true, //隐藏底部
    },
    component: () => import("@/views/BuyerReplacementdetails.vue")
  },
  // {
  //   path: "/chatgroup",
  //   name: "chatgroup",
  //   meta: {
  //     // title: "置换",
  //     goback: "-1",
  //     hide_head: true, //隐藏头部
  //     hide_bottom: false, //隐藏底部
  //   },
  //   component: () => import("@/components/chat_group.vue")
  // },


  //#endregion
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
const router = createRouter({
  history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL),
  routes
})
export default router
