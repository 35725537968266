<template>
  <!-- 最小化小程序 -->
  <div class="lookbao_minimize_box" @click="OpenApp">
    <img class="lookbao_minimize_icon" :src="app_vo?.icon" alt="" />
    <img
      class="lookbao_minimize_icon lookbao_bowen_animation"
      :src="app_vo?.icon"
      alt=""
    />
  </div>
</template>
<script>
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
export default {
  data() {
    return {
      app_vo: null,
    };
  },
  props: {
    app_id: Number,
  },
  methods: {
    OpenApp() {
      //   Global.OpenAppletAsync(this, this.app_id, {});
      debugger;
      Global.app && Global.app?.RecoveryAppletWindow();
    },
    async GetAppDataFromIDAsync() {
      let res = await Tools.GetHTMLDAppAsync("GetDAppVOFromAppID", {
        app_id: this.app_id,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        let d = res.data;
        this.app_vo = d;
      }
    },
  },
  async mounted() {
    await this.GetAppDataFromIDAsync();
  },
  created() {},
};
</script>
<style lang="less" scoped>
.lookbao_bowen_animation {
  position: absolute !important;
  z-index: 100;
  opacity: 1;
  animation: ripple 1000ms infinite;

  @keyframes ripple {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(2);
      opacity: 0;
    }
  }
}

.lookbao_minimize_box {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 15%;
  right: -10px;
  border: 2px rgba(255, 255, 255, 0.3) solid;
  background-color: white;
  border-radius: 50%;
  //   overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  .lookbao_minimize_icon {
    display: inline-block;
    width: 80%;
    height: 80%;
  }
}
</style>
