import Global from "./Global";

/**
 * 调试模式下 并不启用远程数据库节点配置表
 */
export const IS_DEBUG: boolean = false;

/**
 * 是否为铸造服
 */

export const IS_MINT_Server: boolean = false;
/**
 * 是否为测试环境
 */
export const IS_TEST: boolean = false; //是否为测试环境
/**
 * 使用SSL
 */
export const UseSSL: boolean = true;
export const Local_URL: string = "pay.run.lookbao.net";
export const Local_Node_URL: string = "pay.run.lookbao.net";

/**
 * 配置表
 */
export default class Config {
    // sqlserver相关的正式服测试服配置
    public static WEB_API_HOST: string = IS_DEBUG ? `http://${Local_URL}:1001` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'webapi.81002200.com:1001' : `${IS_MINT_Server ? "mint.lookbao.net:1001" : "webapi.lookbao.net"}`}`;
    public static DAPP_HOST: string = IS_DEBUG ? `http://${Local_URL}:2001` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'dapp.81002200.com:2001' : 'dapp.lookbao.net'}`;
    public static OPEN_API_HOST: string = IS_DEBUG ? `http://${Local_URL}:3001` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'openapi.81002200.com:3001' : 'openapi.lookbao.net'}`;
    public static WEB_SOCKET_HOST: string = IS_DEBUG ? `ws://${Local_URL}:4001` : `${UseSSL ? 'wss' : 'ws'}://${IS_TEST ? 'ws.81002200.com:4001' : 'ws.lookbao.net'}`;
    public static RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'res.81002200.com:11111' : 'res.lookbao.net'}`; //原资源地址
    public static CDN_RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `http://${IS_TEST ? 'cdn.res.81002200.com:11111' : 'cdn.res.lookbao.net'}`;
    // sqlserver相关的正式服测试服配置
    // 测试服MYSQL
    // public static WEB_API_HOST: string = IS_DEBUG ? `http://${Local_URL}:1001` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'webapi.81002200.com:1002' : 'webapi.lookbao.net'}`;
    // public static DAPP_HOST: string = IS_DEBUG ? `http://${Local_URL}:2001` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'dapp.81002200.com:2002' : 'dapp.lookbao.net'}`;
    // public static OPEN_API_HOST: string = IS_DEBUG ? `http://${Local_URL}:3001` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'openapi.81002200.com:3002' : 'openapi.lookbao.net'}`;
    // public static WEB_SOCKET_HOST: string = IS_DEBUG ? `ws://${Local_URL}:4001` : `${UseSSL ? 'wss' : 'ws'}://${IS_TEST ? 'ws.81002200.com:4002' : 'ws.lookbao.net'}`;
    // public static RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'res.81002200.com:11111' : 'res.lookbao.net'}`; //原资源地址
    // public static CDN_RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `http://${IS_TEST ? 'cdn.res.81002200.com:11111' : 'cdn.res.lookbao.net'}`;
    // 测试服MYSQL






    // 独立铸造用地址
    // public static WEB_API_HOST: string = IS_DEBUG ? `http://${Local_URL}:1001` : `http://${IS_TEST ? 'test.' : ''}mint.lookbao.net:1001`;
    // public static DAPP_HOST: string = IS_DEBUG ? `http://${Local_URL}:2001` : `http://${IS_TEST ? 'test.' : ''}dapp.lookbao.net`;
    // public static OPEN_API_HOST: string = IS_DEBUG ? `http://${Local_URL}:3001` : `http://openapi.lookbao.net`;
    // public static WEB_SOCKET_HOST: string = IS_DEBUG ? `ws://${Local_URL}:4001` : `ws://${IS_TEST ? 'test.' : ''}ws.lookbao.net`;
    // public static RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `http://${IS_TEST ? 'test.' : ''}res.lookbao.net`; //原资源地址
    // public static CDN_RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `http://${IS_TEST ? 'test.' : ''}cdn.res.lookbao.net`;


    // public static WEB_API_HOST: string = IS_DEBUG ? `http://${Local_URL}:1001` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'webapi.81002200.com:1001' : 'mint.lookbao.net:1001'}`;
    // public static DAPP_HOST: string = IS_DEBUG ? `http://${Local_URL}:2001` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'dapp.81002200.com:2001' : 'dapp.lookbao.net'}`;
    // public static OPEN_API_HOST: string = IS_DEBUG ? `http://${Local_URL}:3001` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'openapi.81002200.com:3001' : 'openapi.lookbao.net'}`;
    // public static WEB_SOCKET_HOST: string = IS_DEBUG ? `ws://${Local_URL}:4001` : `${UseSSL ? 'wss' : 'ws'}://${IS_TEST ? 'ws.81002200.com:4001' : 'ws.lookbao.net'}`;
    // public static RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'res.81002200.com:11111' : 'res.lookbao.net'}`; //原资源地址
    // public static CDN_RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `http://${IS_TEST ? 'cdn.res.81002200.com:11111' : 'cdn.res.lookbao.net'}`;
    // 独立铸造用地址


    private static _APP_NAME = "LookBao";

    public static get APP_NAME(): string {
        if (Global.host_platform_vo) {
            this.SetIconOrTitleName();
            return Global.host_platform_vo?.platform_name ?? this._APP_NAME;
        }
        return this._APP_NAME;
    }

    public static Version: string = "V1.2.2.0";
    /**
     * 商务合作邮箱
     */
    public static CooperationEmail: string = ""
    /**
     * 客服邮箱
     */
    public static CustomerServiceEmail: string = "";


    /**
     * WebSocket服务地址
     */
    public static WEBSOCKET_URL = `${Config.WEB_SOCKET_HOST}`;

    /**
     * 网站API接口地址
     */
    public static API_URL = `${Config.WEB_API_HOST}`;

    /**
     * DAPP API WEB接口地址
     */
    public static DAPP_URL = `${Config.DAPP_HOST}`;

    /**
     * 资源URL地址
     */
    public static CDN_RES_URL = `${Config.CDN_RES_HOST}`;

    /**
     * 主币名称
     */
    public static MAIN_CODE_NAME: string = "IM";

    private static SetIconOrTitleName() {
        try {
            if (Global.host_platform_vo) {
                let $favicon: any = document.querySelector('link[rel="icon"]');
                let title_element: any = document.querySelector("title");
                title_element.text = Global.host_platform_vo?.platform_name??"Loading";
                let link = Global.host_platform_vo.icon;
                if ($favicon !== null) {
                    $favicon.href = link;
                } else {
                    $favicon = document.createElement("link");
                    $favicon.rel = "icon";
                    $favicon.href = link;
                    document.head.appendChild($favicon);
                }
            }
        }
        catch (e) {

        }
    }
}