import { CurrencySystemIdEnum } from "@/ts/SuperEnum";
import Tools from "@/ts/Tools";
import AccountManagement from "../AccountManagement";
import { CoinTypeEnum } from "../BaseWallet";
import LookBaoWalletServer from "../LookBaoWallet/LookBaoWalletServer";
import I_WalletServer from "../WalletInterface/I_WalletServer";
import AddressVO from "./AddressVO";
import CurrencySystemAddressVO from "./CurrencySystemAddressVO";
/**
 * 账号结构类
 */
export default class AccountWalletVO {
    /**
     * 用户UID
     */
    public uid: string = "";
    /**
     * 是否已激活
     */
    public activated: boolean = false;
    /**
     * 钱包名称
     */
    public wallet_name: string = "";
    /**
     * 账号的唯一标识
     */
    public guid: string = "";
    /**
     * 钱包私钥
     */
    public private_key: string = "";
    /**
     * 钱包公钥
     */
    public public_key: string = "";
    /**
     * 钱包助记词
     */
    public mnemonic: string = "";
    /**
     * 钱包的KeyStore
     */
    public keystore: string = "";

    /**
     * 币种体系地址列表
     */
    public currency_system_address_list: { [key: number]: CurrencySystemAddressVO } = {};

    /**
     * 钱包密码
     */
    public pwd: string = "";


    /**
     * 主AddressVO
     */
    public get address_vo(): AddressVO {
        let currency_system_vo = this.currency_system_address_list[CurrencySystemIdEnum.LookBao];
        return currency_system_vo.address;
    }


    /**
     * @param uid 账户UID
     * @param wallet_name 钱包名称
     * @param address 
     * @param private_key 
     * @param public_key 
     * @param mnemonic 
     * @param pwd 
     * @param coin_type 
     */
    constructor(uid: string, wallet_name: string, private_key: string, public_key: string, mnemonic: string, pwd: string) {
        this.guid = Tools.GUID;
        this.uid = uid;
        this.wallet_name = wallet_name;
        this.private_key = private_key;
        this.public_key = public_key;
        this.mnemonic = mnemonic;
        this.pwd = pwd;
        this.MakeKeystoreAsync(pwd);
    }

    /**
     * 初始化
     */
    public async InitAsync() {

        /**
         * 存储时清空未激活的账号
         */
        let keys: number[] = [];
        for (let key in CurrencySystemIdEnum) {
            let v = parseInt(key);
            // console.log("KEY1======" + Tools.GetNowTime);
            if (!isNaN(v)) {
                console.log(`找到KEY=======${key}`);
                keys.push(v);
            }
        }
        /**
         * 生成地址
         */
        for (let i = 0; i < keys.length; i++) {
            let v = keys[i];
            console.log(`KEY======${v}`);
            let ref_vo: {
                flag: boolean,
                address: string,
                privateKey: string,
                publicKey: string,
                coin_type: CoinTypeEnum
            } = {
                flag: false,
                address: "",
                privateKey: "",
                publicKey: "",
                coin_type: CoinTypeEnum.IM
            };
            console.log("KEY1======" + Tools.GetNowTime);
            if (!isNaN(v)) {
                switch (v) {
                    case CurrencySystemIdEnum.BTC:
                        ref_vo = await AccountManagement.GetWalletDataFromMnemonicAsync(this.mnemonic, CoinTypeEnum.BTC);
                        console.log(`TT1:${Tools.GetNowTime}`);

                        break;
                    case CurrencySystemIdEnum.SOL: //SOL体系
                    case CurrencySystemIdEnum.USDT_ERC20: //ERC20体系
                    case CurrencySystemIdEnum.BSC: //BSC体系
                    case CurrencySystemIdEnum.LookBao: //LookBao体系
                    case CurrencySystemIdEnum.ETH: //ETH体系
                    case CurrencySystemIdEnum.HECO: //火币体系
                        ref_vo = await AccountManagement.GetWalletDataFromMnemonicAsync(this.mnemonic, CoinTypeEnum.IM);
                        console.log(`TT2:${Tools.GetNowTime}`);
                        break;
                    case CurrencySystemIdEnum.TRX: //TRX体系
                    case CurrencySystemIdEnum.USDT_TRC20: //USDT_TRC20
                        ref_vo = await AccountManagement.GetWalletDataFromMnemonicAsync(this.mnemonic, CoinTypeEnum.TRX);
                        console.log(`TT3:${Tools.GetNowTime}`);
                        break;
                    case CurrencySystemIdEnum.DOGE:
                        ref_vo = await AccountManagement.GetWalletDataFromMnemonicAsync(this.mnemonic, CoinTypeEnum.DOGE);
                        console.log(`TT4:${Tools.GetNowTime}`);
                        break;
                    case CurrencySystemIdEnum.EOS:
                        ref_vo = await AccountManagement.GetWalletDataFromMnemonicAsync(this.mnemonic, CoinTypeEnum.EOS);
                        console.log(`TT5:${Tools.GetNowTime}`);
                        break;
                    case CurrencySystemIdEnum.USDT_OMNI: //
                        ref_vo = await AccountManagement.GetWalletDataFromMnemonicAsync(this.mnemonic, CoinTypeEnum.OMNI);
                        console.log(`TT6:${Tools.GetNowTime}`);
                        break;
                    default:

                        break;
                }
                console.log("KEY2======" + Tools.GetNowTime);
                if (ref_vo.flag) {

                    console.log("KEY3======" + Tools.GetNowTime);
                    let csa_vo = new CurrencySystemAddressVO(v, new AddressVO(this.uid, v, ref_vo.address, 0, ref_vo.coin_type), ref_vo.privateKey, ref_vo.publicKey);
                    console.log("KEY4======" + Tools.GetNowTime);
                    await csa_vo.initAsync();
                    console.log("KEY5======" + Tools.GetNowTime);
                    if (this.currency_system_address_list == null)
                        this.currency_system_address_list = {};
                    this.currency_system_address_list[v] = csa_vo;
                }
            }
        }
        console.log("Over======" + Tools.GetNowTime);
        await this.InitConnectServerAsync();
        console.log("Over1======" + Tools.GetNowTime);
        return this;
    }

    /**
     * 初始化并连接到所有服务
     */
    private async InitConnectServerAsync() {

        for (const key in this.currency_system_address_list) {
            if (Object.prototype.hasOwnProperty.call(this.currency_system_address_list, key)) {
                const v = this.currency_system_address_list[key];

                await v.wallet_server?.ConnectServiceAsync();
            }
        }
    }

    private async MakeKeystoreAsync(pwd: string) {
        //获取Keystore
        let keystore = await LookBaoWalletServer.MakeKeystoreAsync(this.private_key, pwd);
        //进行存储
        console.log(`keystore:${keystore}`);
        this.keystore = keystore;
    }

    /**
     * 激活账号(激活说明已备份)
     */
    public ToActivated() {
        this.activated = true;
    }

    /**
     * 获取Wallet
     * @param currency_system_id 
     * @returns 
     */
    public GetWalletServer(currency_system_id: CurrencySystemIdEnum): I_WalletServer {
        let list = this.currency_system_address_list;
        let csa_vo = list[currency_system_id];
        return csa_vo.wallet_server as I_WalletServer;
    }
}