<template>
  <div class="invite_friends_body">
    <div class="invite_friends_content">
      <div class="invite_friends_head">
        <span>邀请记录</span>
        <img src="image/block_x.png" @click="CloseMe" alt="" />
      </div>

      <div class="invite_friends_tongji_head">
        <div>
          <span>{{ all_count }}</span>
          <span>累计邀请</span>
          <span></span>
        </div>
        <div>
          <span>{{ all_is_real_name_count }}</span>
          <span>已实名</span>
          <span></span>
        </div>
      </div>

      <Page
        ref="GetMyNftInviteFriendsList"
        action="GetMyNftInviteFriendsList"
        :pageNum="20"
        :CompleteDataFunAsync="CompleteDataFunAsync"
        class="invite_friends_list"
      >
        <div class="invite_friends_item">
          <span>账号</span>
          <span>UID</span>
          <span>状态</span>
          <!-- <span></span> -->
        </div>
        <div class="invite_friends_item" :key="i" v-for="(v, i) in list">
          <span>
            <img
              :src="
                GetResourceIMG(
                  v.head_pic == '' ? host_platform_vo?.icon : v.head_pic
                )
              "
              alt=""
            />
            <span>{{ v?.phone == "" ? "无" : v.phone }}</span>
          </span>
          <span>{{ v.uid }}</span>
          <span></span>
          <span v-if="!v.is_friend" @click="FriendInvitation(v)">
            <img src="image/plus.png" alt="" />
          </span>
          <span v-else></span>
        </div>
      </Page>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
import Page from "@/components/PageComponent.vue";
import { inject } from '@vue/runtime-core';
export default {
  setup() {
    const host_platform_vo = inject("host_platform_vo");
    return {
      host_platform_vo,
    };
  },
  data() {
    return {
      all_is_real_name_count: 0, //实名人数
      all_count: 0, //总人数
      list: [],
    };
  },
  components: {
    Page,
  },
  methods: {
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    /**
     * 发送好友邀请
     */
    async FriendInvitation(v) {
      let user_name = Global.login_user_vo.nick_name;
      let res = await Global.server.SendAsync(this, "FriendInvitation", {
        friend_uid: v.uid, //this.user_vo.uid,
        message: `您好，我是 ${user_name}`,
      });
      if (Tools.CheckDataIsOK(res)) {
        Tools.AlertSuccess("已发出申请!");
      }
    },
    CompleteDataFunAsync(d, page) {
      this.all_is_real_name_count = d.all_is_real_name_count;
      this.all_count = d.allcount;
      if (page == 1) {
        this.list = d.data;
      } else {
        this.list.PushAll(d.data);
      }
    },

    async GetMyNftInviteFriendsListAsync() {
      this.$refs["GetMyNftInviteFriendsList"]?.Start({});
    },
    CloseMe() {
      Global.HideMyNftInviteFriendList();
    },
  },
  async mounted() {
    await this.GetMyNftInviteFriendsListAsync();
  },
};
</script>
<style scoped lang="less">
.invite_friends_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  .invite_friends_content {
    color: #4e586e;
    background-color: white;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 10px);
    height: 90%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .invite_friends_head {
      display: flex;
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      align-items: center;
      border-bottom: 1px solid #f3f3f3;
      img {
        position: absolute;
        right: 15px;
        width: 12px;
      }
    }

    .invite_friends_tongji_head {
      display: flex;
      justify-content: space-around;
      margin-bottom: 25px;
      background-color: #f3f3f3;
      border-radius: 8px;
      padding: 19px 0px;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        & > span:nth-child(1) {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        & > span:nth-child(2) {
          font-size: 16px;
          margin-bottom: 9px;
        }
        & > span:nth-child(3) {
          width: 35px;
          display: inline-block;
          height: 3px;
          background-color: #06ccaf;
          border: 0px;
        }
      }
    }

    .invite_friends_list {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-y: auto;
      height: 100%;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      .invite_friends_item:first-child {
        border: 0px;
        display: flex;
        justify-content: space-between;
      }
      .invite_friends_item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f3f3f3;
        span {
          width: 20%;
          display: inline-block;
          text-align: center;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 0px;
            margin-right: 15px;
          }
        }
        & > span:first-child {
          width: 50% !important;
          text-align: left;
        }
        & > span:last-child {
          width: 10% !important;
          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
</style>