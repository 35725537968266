import Tools from "../Tools";
import BackDataVO from "./BackDataVO";
import ServerCallBackEvent from "./ServerCallBackEvent";
import ServerWaitEvent from "./ServerWaitEvent";
/**
 * 基础事件
 */
export default class BaseEventVO {

    /**
     * 事件注册时间
     */
    private start_time: number = 0;

    /**
     * 是否还可用
     */
    public can_use: boolean = true;
    /**
     * 键值
     */
    public key: string | undefined;
    /**
     * 绑定的类页面指针
     */
    public bind_this: any;
    /**
     * 最大可调用次数 0为不限制 1为一次 以此类推
     */
    public max_use_count: number = 0;

    /**
     * 剩余使用次数
     */
    public surplus_count: number = 0;

    /**
     * 消息事件组(默认组号为0,其他组自编 相同组会覆盖)
     */
    public group_id: number = 0;

    /**
     * 回调函数
     */
    public call_back_fun: ((_this: any, data_vo: BackDataVO) => void) | undefined;

    /**
     * 获取生命周期时间
     */
    public get LifeCycleTime(): string {
        return Tools.DifftimeToString(Tools.GetNowTime - this.start_time);
    }


    /**
     * 获取事件类型
     */
    public get GetEventTypeName(): string {
        return this.constructor.name;
    }


    constructor(bind_this: any, key: string, group_id: number = 0, max_use_count: number = 0) {
        this.start_time = Tools.GetNowTime;
        this.bind_this = bind_this;
        this.key = key;
        this.group_id = group_id;
        this.max_use_count = max_use_count;
        this.surplus_count = this.max_use_count;
    }
}