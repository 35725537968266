<template>
  <div class="input_box_body">
    <div class="input_box_content">
      <my-image class="close_btn" :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')" @click="CloseMe" />
      <span v-if="title" class="title">{{ title }}</span>
      <textarea
        v-if="input_box_type == 0"
        class="input_content"
        v-model="content"
        :placeholder="title == null ? '请输入内容' : title"
        :maxlength="input_max_length > 0 ? input_max_length : ''"
      ></textarea>

      <input
        type="text"
        class="input_content"
        v-if="input_box_type == 1"
        v-model="content"
        :maxlength="input_max_length > 0 ? input_max_length : ''"
        :placeholder="title == null ? '请输入内容' : title"
      />

      <input
        type="number"
        class="input_content"
        v-if="input_box_type == 6"
        v-model="content"
        :placeholder="title == null ? '请输入数字' : title"
      />

      <div class="upload_file_box" v-if="input_box_type == 2">
        <img
          ref="image_input"
          src="image/upload_image_icon.png"
          @click="UploadImageAsync()"
        />
      </div>

      <input
        type="password"
        class="input_content"
        v-if="input_box_type == 3"
        v-model="content"
        :maxlength="input_max_length > 0 ? input_max_length : ''"
        :placeholder="title == null ? '请输入密码' : title"
      />

      <!-- <input
        type="datetime"
        class="input_content"
        v-if="input_box_type == 4"
        v-model="content"
        :placeholder="title == null ? '请输入时间+日期' : title"
      /> -->

      <a-date-picker
        inputReadOnly
        class="bottom_10 date_time_input"
        :default-value="content"
        v-if="input_box_type == 4"
        show-time
        :placeholder="title == null ? '请输入时间+日期' : title"
        @change="onDateTimeChange"
      />

      <a-date-picker
        inputReadOnly
        class="bottom_10 date_time_input"
        :default-value="content"
        format="YYYY/MM/DD"
        v-if="input_box_type == 5"
        :placeholder="title == null ? '请输入时间+日期' : title"
        @change="onDateChange"
      />

      <button class="success_btn" @click="ToSave">保存</button>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
export default {
  props: {
    input_box_type: 0,
    title: String,
    content: String,
    input_max_length: Number, //输入框最大长度
    call_fun: function (txt) {},
  },
  data() {
    return {
      date_content: "",
    };
  },
  mounted() {
    this.date_content = "";
    if (this.input_box_type == 2) {
      if (Tools.IsNotNull(this.content)) {
        let img = new Image();
        img.onload = (e) => {
          this.$refs["image_input"].src = this.content;
        };
        img.onerror = () => {};
        img.src = this.content;
      }
    }
  },
  methods: {
    onDateChange(date, dateString) {
      console.log(date, dateString);
      this.date_content = dateString;
    },
    onDateTimeChange(date, dateString) {
      // this.content = dateString;
      this.date_content = dateString;
    },
    async UploadFileAsync(event) {
      var files = event.target.files;
      if (files.length > 0) {
        let res = await Tools.UploadFileAsync("UploadFile", {}, files);
        if (Tools.CheckHtmlIsOK(res)) {
          let v = res.data[0];
          this.call_fun && this.call_fun(v.FileURL);
          this.CloseMe();
        }
      }
    },
    CloseMe() {
      this.$parent.show_input_box = false;
    },
    async UploadImageAsync() {
      let open_image_item = await Global.SelectPhotosAsync();
      if (!open_image_item.flag) {
        Tools.AlertError(open_image_item.error_message);
      } else {
        this.call_fun && this.call_fun(open_image_item.file_url);
      }
      this.CloseMe();
    },
    ToSave() {
      if (this.call_fun) {
        switch (this.input_box_type) {
          case 4:
          case 5:
            this.call_fun(this.date_content);
            break;
          default:
            this.call_fun(this.content);
            break;
        }
      }
      this.CloseMe();
    },
  },
};
</script>
<style scoped lang="less">
.input_box_body {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: center;
  background-color: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  z-index: 5000;
}

.input_box_content {
  position: relative;
    width: 80%;
    background-color: @home_bgc_class;
    padding: 12px 20px 5px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 200px;
}

.input_content {
  border: 0px;
  border-radius: 0px;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  line-height: 36px;
  font-size: 16px;
  box-sizing: border-box;
  color: @FFFFFF_c040506;
}

.close_btn {
  // width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.success_btn {
  width: 50%;
  height: 30px;
  font-size: 16px;
  /* font-weight: bold; */
  border: 0px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #02cdb0 !important;


}

.title {
  color: @FFFFFF_c040506;
  margin-bottom: 10px;
  font-size: 15px;
  /* font-weight: bold; */
}
.textarea {
  height: 250px;
}
</style>

<style>
/* .input_content {
  color: black !important;
} */
.ant-calendar-picker-container {
  z-index: 1000000 !important;
}
.bottom_10 {
  margin-bottom: 10px;
}
.date_time_input {
  width: 100%;
}

.upload_file_box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_file_box > img {
  margin-bottom: 10px;
  width: 100%;
  display: inline-block;
  height: 80px;
  width: 80px;
}
</style>