import Global from "../Global";
import { CurrencySystemIdEnum } from "../SuperEnum";
import Tools from "../Tools";
import AccountManagement from "./AccountManagement";
import { CoinTypeEnum } from "./BaseWallet";
import AccountWalletVO from "./vo/AccountWalletVO";
import AddressVO from "./vo/AddressVO";
import CoinTypeVO from "./vo/CoinTypeVO";
import I_WalletServer from "./WalletInterface/I_WalletServer";

/**
 * 币种管理器
 */
export default class CoinTypeManager {
    private coin_type_vo_list: CoinTypeVO[] = [];
    private account_wallet_vo: AccountWalletVO | null = null;

    private static coin_type_manager: CoinTypeManager | null = null;
    constructor() {
    }
    /**
     * 单例
     */
    public static get singleton(): CoinTypeManager {
        if (CoinTypeManager.coin_type_manager == null) {
            CoinTypeManager.coin_type_manager = new CoinTypeManager();
        }
        return CoinTypeManager.coin_type_manager;
    }
    /**
     * 初始化合约币增加到我的钱包中
     */
    public async InitAsync(uid: string) {

        /**
         * 初始化币种类型加入合约队列
         */
        this.account_wallet_vo = AccountManagement.GetNowSelectAccountWalletVO(uid) as AccountWalletVO;
        if (this.account_wallet_vo != null) {
            /**
             * 更新币种数据
             */
            await this.UpdateCoinTypeListAsync();

            for (let k = 0; k < this.coin_type_vo_list.length; k++) {
                const v = this.coin_type_vo_list[k];
                let currency_system_id = v.currency_system_id;
                let currency_system_address_vo = this.account_wallet_vo.currency_system_address_list[currency_system_id];
                if (v.is_main) { //如果是主币
                    continue;
                }
                else {
                    currency_system_address_vo.contract_address_list.push(new AddressVO(uid, currency_system_id, currency_system_address_vo.address.address, 0, v.coin_type));
                }
            }
        }
    }
    /**
     * 更新货币类型列表
     */
    public async UpdateCoinTypeListAsync() {
        let res = await Tools.GetHtmlAsync("GetMyUserCoinList", {});

        if (Tools.CheckHtmlIsOK(res, false)) {
            let list = res.data;
            this.coin_type_vo_list.splice(0, this.coin_type_vo_list.length);
            for (let i = 0; i < list.length; i++) {
                const v = list[i];
                let wallet_server = this.account_wallet_vo?.GetWalletServer(v.currency_system_id);

                //生成几个币种
                let coin_type_vo = new CoinTypeVO(
                    wallet_server as I_WalletServer,
                    v.currency_system_id,
                    v.coin_alias,
                    v.default_is_use == 1,
                    /**
                     * 是否为主币种
                     */
                    v.is_main == 1,
                    /**
                     * 币种全名
                     */
                    v.coin_full_name,
                    /**
                     * 币种单位如：ETH EOS 等
                     */
                    v.coin_symbol,
                    /**
                     * 币种配图
                     */
                    v.coin_img,
                    /**
                     * 币类型
                     */
                    v.coin_type,
                    /**
                     * 合约地址
                     */
                    v.contract_address,
                    /**
                     * 合约代码
                     */
                    v.contract_abi_json,
                    /**
                     * 主地址资产
                     */
                    0, v.is_system_integral == 1 ? true : false,
                    v.contract_type_id
                );
                coin_type_vo.InitContract();
                this.coin_type_vo_list.push(coin_type_vo);
            }
            this.Read(); //进行初始化覆盖选项
            this.Save();
        }
    }

    private Read() {
        let read_list_json = localStorage.getItem("COIN_TYPE_VO_LIST");
        if (Tools.StrIsNotNull(read_list_json)) {
            if (read_list_json) {
                let read_list = JSON.parse(read_list_json);
                for (let i = 0; i < read_list.length; i++) {
                    const v = read_list[i];
                    if (v.is_main)
                        continue;
                    //查找当前配置
                    for (let y = 0; y < this.coin_type_vo_list.length; y++) {
                        const now_v = this.coin_type_vo_list[y];
                        if (now_v.contract_address == v.contract_address) {
                            now_v.is_use = v.is_use;
                            break;
                        }
                    }
                }
            }
        }
    }

    /**
     * 进行存储
     */
    public Save() {
        localStorage.setItem("COIN_TYPE_VO_LIST", JSON.stringify(this.coin_type_vo_list, (key, v) => {
            if (key == "contract")
                return;
            return v;
        }));
    }

    /**
     * 获取已被使用的币种
     */
    public async GetCanUseCoinTypeListAsync() {
        let list: CoinTypeVO[] = [];
        this.coin_type_vo_list.forEach(v => {
            if (v.is_use) {
                list.push(v);
            }
        });
        for (let i = 0; i < list.length; i++) {
            const v = list[i];
            await v.UpdateAmountAsync();
        }
        return list;
    }

    /**
     * 获取没有被使用的币种列表
     */
    public GetNotUseCoinTypeList(): CoinTypeVO[] {
        let list: CoinTypeVO[] = [];
        this.coin_type_vo_list.forEach(v => {
            if (!v.is_use)
                list.push(v);
        });
        return list;
    }

    /**
     * 获取所有币种
     * @param show_main_coin 是否显示主币 默认不显示
     */
    public GetAllCoinTypeList(show_main_coin: boolean = false): CoinTypeVO[] {
        let list: CoinTypeVO[] = [];
        for (let i = 0; i < this.coin_type_vo_list.length; i++) {
            const v = this.coin_type_vo_list[i];
            if (v.coin_type == CoinTypeEnum.IM && show_main_coin == false)
                continue;
            if (v.is_system_integral)
                continue;

            list.push(v);
        }
        return list;
    }

    /**
     * 根据合约地址获取币种类型结构体
     * @param contract_address 
     * @returns 
     */
    public GetCoinTypeFromContractAddress(contract_address: string): {
        flag: boolean,
        coin_type_vo: CoinTypeVO | null
    } {
        for (let i = 0; i < this.coin_type_vo_list.length; i++) {
            const v = this.coin_type_vo_list[i];
            if (v.contract_address == contract_address) {
                return {
                    flag: true,
                    coin_type_vo: v
                };
            }
        }
        return {
            flag: false,
            coin_type_vo: null
        };
    }

    /**
     * 获取当前选择的币种数据
     */
    public GetNowSelectCoinTypeVO(): CoinTypeVO | null {
        let coin_type = Global.GetSelectCoinType();
        let currency_system_id = Global.GetSelectCurrencySystemID();
        return this.GetCoinTypeVOFromCoinType(currency_system_id, coin_type);
    }

    /**
     * 根据币种类型获取钱包币种对象
     * @param coin_type 
     * @param currency_system_id 体系
     * @returns 
     */
    public GetCoinTypeVOFromCoinType(currency_system_id: CurrencySystemIdEnum, coin_type: CoinTypeEnum): CoinTypeVO | null {
        for (let i = 0; i < this.coin_type_vo_list.length; i++) {
            const v = this.coin_type_vo_list[i];
            if (v.coin_type == coin_type && currency_system_id == v.currency_system_id) {
                return v;
            }
        }
        return null;
    }
}