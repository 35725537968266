<template>
  <div class="head_body">
    <template v-if="$route.meta.goback">
      <my-image
        class="back"
        :src="theme_obj?.allback?.back_img"
        @click="GoBack"
        v-if="!BackIsFun"
      />
      <my-image
        class="back"
        :src="theme_obj?.allback?.back_img"
        @click="$route.meta.goback"
        v-else
      />
    </template>

    <div class="title title_smail">
      <span
        class="title_smail"
        :style="$route.meta.title_css"
        v-if="$route.meta.title || title"
        >{{ title == null || title == "" ? $route.meta.title : title }}</span
      >
      <span class="online_box" v-if="online">
        <!-- <span class="online_tab">在线</span> -->
      </span>
    </div>

    <div class="right_content">
      <span
        :key="index"
        v-for="(btn, index) in ButtonList"
        class="jia"
        @click.stop="btn.click"
      >
        <my-image :style="btn.css" v-if="btn.icon" :src="btn.icon" />
        <span
          v-if="btn.name"
          :style="btn.style"
          :class="btn.css ? btn.css : ''"
          >{{ btn.name }}</span
        >
      </span>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
import PageDataCache from "@/ts/PageDataCache";
import { inject } from "@vue/runtime-core";
export default {
  props: {
    title: String,
    online: Boolean,
  },
  data() {
    return {
      show_menu: false,
      button_list: [],
    };
  },
  setup() {
    let theme_obj = inject("theme_obj");
    return {
      theme_obj,
    };
  },
  computed: {
    BackIsFun() {
      if (this.$route) {
        if (this.$route.meta) {
          if (this.$route.meta.goback) {
            switch (typeof this.$route.meta.goback) {
              case "string":
              case "number":
                break;
              case "function":
                return true;
            }
          }
        }
      }
      return false;
    },
    ButtonList() {
      if (this.button_list.length > 0) return this.button_list;
      return this.$route.meta.buttons;
    },
  },
  components: {},
  mounted() {
    Global.head = this;
  },
  methods: {
    IsNumber(v) {
      let num = parseInt(v);
      if (isNaN(num)) {
        return false;
      }
      return true;
    },
    SetButtonList(button_list) {
      
      this.button_list = button_list;
    },
    /**
     * 当前头部按钮列表
     */
    GetButtonList() {
      return this.button_list;
    },
    GoBack() {
      let path = Global?.app?.$route?.path;
      this.OnGoBack && this.OnGoBack[path] && this.OnGoBack[path]();

      let v = this.$route.meta.goback;
      let replice = this.$route.meta.replice;

      if (replice == null) replice = false;
      if ((typeof v == "string" ? parseInt(v) : v) == -1) {
        this.$router.back();
      } else if (this.IsNumber(v)) {
        this.$router.go(v);
      } else {
        Tools.GoTo(v, {}, {}, replice);
      }
    },
    CloseHeader() {
      this.$parent.close_header = true;
    },
    ShowHeader() {
      this.$parent.close_header = false;
    },
  },
};
</script>
<style lang="less" scoped>
.head_body {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 10px 0px;
  border: 0px;
  box-sizing: border-box;
  color: @ffffff_324460;
  background-color: @home_bgc_class;
}

.title {
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  /* padding-left: 20px; */
  /* font-size: 35px; */

  /* font-weight: bold; */
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
}

.title_smail {
  font-size: 16px !important;
  width: 100%;
}

.right_content {
  display: inline-block;
  margin-right: 10px;
  position: absolute;
  right: 0px;
  /* margin-top: 10px; */
  /* min-width: 100px; */
}

.jia {
  margin-left: 20px;
  display: inline-block;
  white-space: nowrap;
}

.jia > img {
  width: 30px;
  height: 30px;
}

.back {
  margin-left: 10px;
  width: 10px;
}

.back_btn {
  color: rgb(204, 204, 204);
  font-weight: bold;
  margin-left: 10px;
  display: inline-block;
  width: 20px;
}

.sousuo {
  display: flex;
}

.sousuo > img {
  width: 20px;
  height: 20px;
}

.FinishBTN {
  padding: 5px;
  margin-top: -5px;
}

/**
账单
*/
.zhang_dan_btn {
}

.online_box {
  font-size: 12px;
  margin-left: 3px;
  color: rgb(124, 186, 89);
}

.head_online {
  width: 10px;
  height: 10px;
}

.online_tab {
  color: aqua;
  font-size: 12px !important;
  border: 1px solid aqua;
  padding: 0px 5px;
  border-radius: 10px;
  display: inline-block;
  max-height: 16px;
  line-height: 16px;
}

.uplink_transaction {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
  height: 30px;
  min-height: 30px;
  animation: color_move 2s infinite linear;
  color: white;
}
</style>