<template>
  <div class="import_mnemonic_box" v-if="show_import_mnemonic_box">
    <div class="import_mnemonic_content">
      <my-image
        class="icon close_icon"
        :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')"
        @click="show_import_mnemonic_box = false"
      />
      <span class="import_mnemonic_title">导入助记词</span>
      <a-select
        default-value=""
        style="width: 120px"
        @change="OnSelectMnemonicAsync"
        v-if="mnemonic_file_list.length > 0"
      >
        <a-select-option value="">请选择备份文件</a-select-option>
        <a-select-option
          :value="item.file_path"
          :key="index"
          v-for="(item, index) in mnemonic_file_list"
        >
          [{{ item.nick_name }}]:{{ item.file_name }}
        </a-select-option>
      </a-select>

      <div class="import_mnemonic_input_box">
        <input
          type="text"
          v-model="item.value"
          :key="index"
          @input="InputMnemonicChange"
          v-for="(item, index) in input_mnemonic_arr"
        />
      </div>
      <button class="success_btn" @click="ImportMnemonicAsync">确认导入</button>
    </div>
  </div>

  <div class="details_body">
    <div class="details_content">
      <my-image
        v-if="login_vue"
        class="icon close_icon"
        :src="!$store.state.colorbtn
              ? require('@/assets/image/xiaoxi/blackx.png')
              : require('@/assets/image/xiaoxi/x.png')"
        @click="CloseWindow"
      />
      <my-image
        v-else
        class="icon close_icon back_icon"
        src="image/back.png"
        @click="CloseWindow"
      />
      <div class="row" :style="login_vue ? '' : 'margin-top: 15px;'">
        <span>地址</span>
        <div class="row_content_tab">
          <span>{{ address }}</span>
          <div class="copy_box">
            <button class="copy_btn" @click="ToCopy('address')">复制</button>
          </div>
        </div>
      </div>
      <div class="row">
        <span>私钥</span>
        <div class="row_content_tab">
          <span>{{ private_key }}</span>
          <div class="copy_box">
            <button class="copy_btn" @click="ToCopy('private_key')">
              复制
            </button>
          </div>
        </div>
      </div>
      <div class="row" v-if="mnemonic_arr.length >= 12">
        <span>助记词</span>
        <div class="mnemonic_content">
          <div
            class="mnemonic_item"
            :key="index"
            v-for="(item, index) in mnemonic_arr"
          >
            {{ item }}
          </div>
          <!-- <div class="copy_box beifen_box">
              <button class="beifen_btn infomation_btn" @click="SaveToFile">
                备份
              </button>
              <button class="copy_btn" @click="ToCopy('mnemonic')">复制</button>
            </div> -->
          <div class="copy_box">
            <button class="copy_btn" @click="ToCopy('mnemonic')">复制</button>
          </div>
        </div>
      </div>

      <div class="row" v-if="show_import_btns">
        <button class="success_btn" @click="ShowImportMnemonicBoxAsync">
          导入助记词
        </button>
        <button class="success_btn warning_btn" @click="ImportPrivateAsync">
          导入私钥
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import AccountManagement from "@/ts/blockchain/AccountManagement";
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import { reactive } from "vue";
import BaseWallet, {
  CoinTypeEnum,
  WordLanguageEnum,
} from "@/ts/blockchain/BaseWallet";
import CordovaPlugin from "@/ts/CordovaPlugin";

export default {
  setup() {
    let arr = [];
    for (let i = 0; i < 12; i++) {
      arr.push({
        value: "",
      });
    }
    return {
      input_mnemonic_arr: reactive(arr),
    };
  },
  props: {
    login_vue: Object,
  },
  data() {
    return {
      show_import_mnemonic_box: false,
      address: "",
      uid: "",
      private_key: "",
      mnemonic_arr: [],
      /**
       * 可选择的助记词文件列表
       */
      mnemonic_file_list: [],
      show_import_btns: false,
    };
  },
  methods: {
    /**
     * 关闭此窗口
     */
    CloseWindow() {
      this.login_vue && this.login_vue.RefWallet && this.login_vue.RefWallet();
      Global.CloseMnemonicWindow();
    },
    /**
     * 刷新钱包
     */
    RefWallet() {
      //初始化成功
      //获取钱包数据
      
      let wallet_vo = AccountManagement.GetNowSelectAccountWalletVO(Global.SelectUID);
      if (wallet_vo != null) {
        this.uid = Global.SelectUID;
        this.address = wallet_vo.address_vo.address;
        this.private_key = wallet_vo.private_key;
        this.mnemonic_arr = wallet_vo.mnemonic.split(" ");
        
        Global.InitWalletAsync();
      }
    },
    async ToCopy(key) {
      let b = false;
      switch (key) {
        case "address":
          b = await CordovaPlugin.CopyContentToClipboardAsync(this.address);
          break;
        case "private_key":
          b = await CordovaPlugin.CopyContentToClipboardAsync(this.private_key);
          break;
        case "mnemonic":
          let copy_mnemonic_str = "";
          this.mnemonic_arr.forEach((v) => {
            copy_mnemonic_str += `${v} `;
          });
          if (Tools.StrIsNotNull(copy_mnemonic_str)) {
            copy_mnemonic_str = copy_mnemonic_str.substring(
              0,
              copy_mnemonic_str.length - 1
            );
          }
          b = await CordovaPlugin.CopyContentToClipboardAsync(
            copy_mnemonic_str
          );
          break;
        default:
          return;
      }
      if (b) {
        Tools.AlertSuccess("已复制到剪切板");
      } else {
        Tools.AlertError("复制失败");
      }
    },
    async OnSelectMnemonicAsync(e) {
      let file_path = e;
      if (Tools.IsNotNull(file_path)) {
        //获取数据
        let content = await CordovaPlugin.ReadTxtFileAsync(file_path);
        if (Tools.IsNotNull(content)) {
          //进行恢复
          this.MakeSetMnemonic(content);
        } else {
          //清空操作
          for (let i = 0; i < this.input_mnemonic_arr.length; i++) {
            this.input_mnemonic_arr[i] = "";
          }
        }
      } else {
        //清空操作
        for (let i = 0; i < this.input_mnemonic_arr.length; i++) {
          this.input_mnemonic_arr[i] = "";
        }
      }
    },
    InputMnemonicChange(e) {
      let mnemonic = e.target.value;
      this.MakeSetMnemonic(mnemonic);
    },
    MakeSetMnemonic(mnemonic) {
      if (Tools.IsNotNull(mnemonic)) {
        let mne_arr = mnemonic.split(" ");
        if (mne_arr.length == 12) {
          //初始化所有属性值
          for (let i = 0; i < mne_arr.length; i++) {
            const d = mne_arr[i];
            this.input_mnemonic_arr[i] = {
              value: d,
            };
          }
        }
      }
    },
    /**
     * 备份助记词到文件
     */
    async SaveToFile() {
      let copy_mnemonic_str = "";
      this.mnemonic_arr.forEach((v) => {
        copy_mnemonic_str += `${v} `;
      });
      if (Tools.StrIsNotNull(copy_mnemonic_str)) {
        copy_mnemonic_str = copy_mnemonic_str.substring(
          0,
          copy_mnemonic_str.length - 1
        );
      }

      if (Tools.IsNotNull(copy_mnemonic_str)) {
        if (
          await CordovaPlugin.WriteTxtFileAsync(
            copy_mnemonic_str,
            "lookbao_mnemonic",
            `mnemonic_${this.address}`
          )
        ) {
          Tools.AlertSuccess("助记词已存储");
        } else {
          Tools.AlertError("备份失败!");
        }
      } else {
        Tools.AlertError("备份失败!");
      }
    },
    /**
     * 导入助记词
     */
    async ImportMnemonicAsync() {
      let mne_arr = this.input_mnemonic_arr.Select((x) => x.value);
      if (mne_arr.Where((x) => Tools.IsNull(x)).length > 0) {
        Tools.AlertError("助记词格式不正确!");
        return;
      }
      let mnemonic = "";
      mne_arr.forEach((v) => {
        mnemonic += `${v} `;
      });
      mnemonic = mnemonic.substring(0, mnemonic.length - 1);

      //检测助记词是否正确
      if (!BaseWallet.ValidateMnemonic(mne_arr, WordLanguageEnum.english)) {
        Tools.AlertError("助记词错误!");
        return;
      }

      let f = false;
      let wallet_item = await AccountManagement.GetWalletDataFromMnemonicAsync(
        mnemonic
      );
      if (wallet_item.flag) {
        let wallet_address = wallet_item.address;
        /**
         * 获取当前地址用户UID
         */
        let user_uid_item = await Tools.GetHtmlAsync(
          "GetUserUIDFromWalletAddress",
          {
            wallet_address: wallet_address,
          }
        );
        if (Tools.CheckHtmlIsOK(user_uid_item)) {
          
          let is_new = user_uid_item.data.is_new;
          console.log(is_new ? "新号" : "旧号");
          let uid = user_uid_item.data.uid;
          f = await AccountManagement.AddNewWalletAsync(
            uid,
            "未命名",
            "",
            CoinTypeEnum.IM,
            mnemonic,
            "",
            ""
          );
          if (f) Global.SelectUID = uid;
        } else {
          return;
        }
      }

      if (!f) {
        Tools.AlertError("恢复账号失败!");
      } else {
        Tools.AlertSuccess("导入成功!");
        //刷新当前账号
        this.RefWallet();
        this.show_details = false;
        this.show_import_mnemonic_box = false;
        /**
         * 清空数据
         */
        for (let i = 0; i < this.input_mnemonic_arr.length; i++) {
          this.input_mnemonic_arr[i] = "";
        }
      }
    },
    /**
     * 导入私钥
     */
    async ImportPrivateAsync() {
      let private_key = await Global.ShowInputBoxAsync("导入您的私钥", "");
      if (Tools.IsNull(private_key)) {
        Tools.AlertError("私钥不能为空!");
        return;
      }
      if (!private_key.startsWith("0x")) {
        Tools.AlertError("私钥必须已0x开头!");
        return;
      }

      let f = false;
      let wallet_item =
        await AccountManagement.GetWalletDataFromPrivateKeyAsync(private_key);
      if (wallet_item.flag) {
        let wallet_address = wallet_item.address;
        /**
         * 获取当前地址用户UID
         */
        let user_uid_item = await Tools.GetHtmlAsync(
          "GetUserUIDFromWalletAddress",
          {
            wallet_address: wallet_address,
          }
        );
        if (Tools.CheckHtmlIsOK(user_uid_item)) {
          let is_new = user_uid_item.data.is_new;
          console.log(is_new ? "新号" : "旧号");
          let uid = user_uid_item.data.uid;
          f = await AccountManagement.AddNewWalletAsync(
            uid,
            "未命名",
            "",
            CoinTypeEnum.IM,
            "",
            private_key,
            ""
          );
          if (f) Global.SelectUID = uid;
        }
      }

      if (!f) {
        Tools.AlertError("恢复账号失败!");
      } else {
        Tools.AlertSuccess("导入成功!");
        //刷新当前账号
        this.RefWallet();
        this.show_details = false;
      }
    },
    async ShowImportMnemonicBoxAsync() {
      /**
       * 获取目录存储的文件列表
       */
      let list = await CordovaPlugin.GetFileListAsync("lookbao_mnemonic");
      let mnemonic_file_list = [];
      list.forEach((v) => {
        let arr = v.split("/");
        if (arr.length > 0) {
          let file_name = arr[arr.length - 1];
          if (file_name.startsWith("mnemonic_")) {
            let arr2 = file_name.split("_");
            let address_str = arr2[arr2.length - 1];
            mnemonic_file_list.push({
              file_name: address_str,
              file_path: v,
            });
          }
        }
      });

      //获取玩家名称
      let res = await Tools.GetHtmlAsync("GetUserVOFromWalletAddressList", {
        wallet_address_list: JSON.stringify(
          mnemonic_file_list.Select((x) => x.file_name)
        ),
      });
      if (Tools.CheckHtmlIsOK(res, false)) {
        let find_user_list = res.data;
        mnemonic_file_list.forEach((t_d) => {
          let u_find_list = find_user_list.Where(
            (x) => x.wallet_address == t_d.file_name
          );
          if (u_find_list.length > 0) {
            t_d.nick_name = u_find_list[0].nick_name;
            t_d.head_pic = u_find_list[0].head_pic;
          }
        });
      }
      this.mnemonic_file_list = mnemonic_file_list;
      this.show_import_mnemonic_box = true;
    },
  },
  mounted() {
    if (this.login_vue != null) {
      this.show_import_btns = true;
    }
    this.RefWallet();
  },
};
</script>
<style scoped lang='less'>
.body {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #2c3e50;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 80%;
}

.logo {
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
  display: block;
  border-radius: 10px;
}

.head_box {
  margin-bottom: 30px;
}

.icon_rotate {
  animation: icon_rotate 10s linear infinite;
  border-radius: 0px;
}

.wallet_address {
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  line-height: 30px;
}

.pwd {
  height: 50px;
  font-size: 20px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}

.success_btn {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
}

/* 初始化窗口 */
.init_body {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  align-items: center;
  justify-content: center;
  background-size: 100% 100%;
  background-origin: border-box;
  background-position: center center;
  background-repeat: no-repeat;
}

.init_content {
  display: flex;
  flex-direction: column;
}

.init_content > img {
  margin-bottom: 15px;
}

.init_content > span {
  color: white;
  font-weight: bold;
}

.wallet_address_box {
  display: flex;
  width: 100%;
  justify-content: center;
}

.icon {
  width: 30px;
  height: 30px;
  display: inline-block;
}
/* 初始化窗口结束 */

/* 详情样式 */

.details_body {
  position: fixed;
  top: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #2c3e50;
  z-index: 100;
  justify-content: center;
  flex-shrink: 0;
}

.details_content {
  background-color: @c000000_F0F1F5;
  color: white;
  padding: 30px;
  width: 100%;
  position: relative;
  /* border-radius: 10px; */
  overflow-y: auto;
  /* height: 90%; */
}

.details_content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.close_icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.back_icon {
  right: auto !important;
  left: 10px !important;
  width: 25px;
  height: 25px;
}

.row {
  text-align: left;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  /* font-weight: bold; */
  margin-bottom: 10px;
  box-sizing: border-box;
}

.row > span:nth-child(1) {
  font-size: 16px;
  /* font-weight: bold; */
  color:  @FFFFFF_c040506;
}

.row_content_tab_title {
  padding: 20px 10px !important;
}

.row_content_tab {
  flex-wrap: wrap;
  background-color: #2c3e50;
  color: white;
  padding: 20px 10px 10px 10px;
  border-radius: 10px;
  margin-top: 10px;
  white-space: normal;
  word-wrap: break-word;
  display: block;
  letter-spacing: 2px;
  word-spacing: 20px;
}

.mnemonic_content {
  background-color: #2c3e50;
  color: white;
  padding: 10px 10px 5px 10px;
  border-radius: 10px;
  margin-top: 10px;
  display: block;
  position: relative;
}

.mnemonic_item {
  flex-wrap: wrap;
  white-space: normal;
  word-wrap: break-word;
  display: inline-block;
  width: 33%;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 0px;
}

/* 详情样式结束 */

.head_icon {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  /* border: 1px white solid; */
}

.nick_name {
  color: white;
  font-weight: bold;
}

.copy_box {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin: 0px 0px 0px 0px;
}

.beifen_box {
  justify-content: space-between;
}

.copy_btn {
  /* position: absolute; */
  display: inline-block;
  width: 50px;
  top: 10px;
  right: 10px;
  background-color: @c000000_F0F1F5;
  padding: 5px;
  border: 0px;
  border-radius: 0px;
  padding: 5px;
  margin: 0px;
  color: @FFFFFF_c040506;
}

.beifen_btn {
  display: inline-block;
  width: 50px;
  top: 10px;
  right: 10px;
  padding: 5px;
  border: 0px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 0px;
  padding: 5px;
  margin: 0px;
  color: white;
}

/* 导入助记词 */
.import_mnemonic_box {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(21, 21, 21, 1);
}

.import_mnemonic_content {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 20px 20px 20px 20px;
  background-color: #070D0D;
  position: relative;
  width: 100%;
}
.import_mnemonic_title {
  font-size: 18px;
  font-weight: bold;
  color:@ffffff_324460;
}
.import_mnemonic_select {
  height: 50px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.import_mnemonic_input_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
  width: 100%;
}
.import_mnemonic_input_box > input {
  flex: 0 0 30%;
  margin-right: 10px;
  width: 20%;
  margin: 0px;
  height: 50px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: center;
  /* padding: 0px; */
  // background-color:@ffffff_324460 ;
}

/* 导入助记词完毕 */

@keyframes icon_rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
