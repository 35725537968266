<template>
  <div class="connect_server_state" v-if="servre_connect_state != 'connected'">
    <a-spin tip="服务器连接中..."></a-spin>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    servre_connect_state: String,
  },
};
</script>
<style scoped>
.connect_server_state {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>