<template>
  <div class="body">
    <div class="head">
      <img class="close_btn" @click="CloseMe" src="image/back.png" alt="" />
      <span>实名认证</span>
    </div>

    <div class="main_box" v-show="real_vo == null || real_vo?.state == 3">
      <div class="content">
        <div class="content_span">
          <span>根据国家网络实名制规定，请填写您本人的实名认证信</span>
          <span
            >息，此认证作为您在本平台的账户所有权以及提现申请的唯一凭据，一旦提交审核成功后不可修改，此信息只作为用户</span
          >
          <span>实名登记，不会被另作它用。</span>
        </div>
        <div class="tab_box">
          <div class="input_box">
            <span :class="focus_index == 1 ? 'select_input_name' : ''"
              >真实姓名<span class="xingxing">*</span></span
            >
            <input
              v-model="real_name"
              :class="['input', focus_index == 1 ? 'select_input' : '']"
              @focus="SelectIt(1)"
              @blur="NoSelect"
              type="text"
              maxlength="4"
              placeholder="请输入真实姓名"
            />
          </div>

          <div class="input_box">
            <span :class="focus_index == 2 ? 'select_input_name' : ''"
              >身份证号码<span class="xingxing">*</span></span
            >
            <input
              v-model="card_id"
              :class="['input', focus_index == 2 ? 'select_input' : '']"
              @focus="SelectIt(2)"
              @blur="NoSelect"
              type="text"
              maxlength="18"
              placeholder="请输入18位身份证号码"
            />
          </div>
        </div>
        <div class="up_card_id_box tab_box">
          <div>上传身份证照片</div>
          <div>
            <label>
              <input
                type="file"
                accept="image/*"
                @change="UploadZhengMian($event)"
                style="left: -9999px; position: absolute"
              />
              <img
               class="inputcardimg"
                :src="
                  card_id_positive != ''
                    ? GetResourceIMG(card_id_positive)
                    : theme_obj?.real_name?.positive
                "
                alt=""
              />
            </label>

            <label>
              <input
                type="file"
                accept="image/*"
                @change="UploadBeiMian($event)"
                style="left: -9999px; position: absolute"
              />
              <img
              class="inputcardimg"
                :src="
                
                  card_id_back != ''
                    ? GetResourceIMG(card_id_back)
                    : theme_obj?.real_name?.other
                "
                alt=""
              />
            </label>
          </div>
        </div>
      </div>
      <div class="bottom_box">
        <button class="tijiao_btn" @click="ToAuthAsync">提交</button>
        <!-- <div>
          <img src="image/an_quan_icon.png" alt="" />
          <span> 信息安全保障中,信息仅用于身份确认</span>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import { inject } from "vue";
export default {
  data() {
    return {
      focus_index: 0,
      real_name: "",
      card_id: "",
      real_vo: null,
      card_id_positive: "", //正面
      card_id_back: "", //背面
    };
  },
  methods: {
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    SelectIt(index) {
      this.focus_index = index;
    },
    NoSelect() {
      this.focus_index = 0;
    },
    CloseMe() {
      this.$parent.show_real_name_authentication = false;
    },
    /**
     * 上传正面
     */
    async UploadZhengMian($event) {
      Global.ShowGlobalLoadingAsync("正在上传", async (m) => {
        let files = $event.target.files;
        if (files.length > 0) {
          let res = await Tools.UploadFileAsync("UploadFile", {}, files);
          if (Tools.CheckHtmlIsOK(res)) {
            let v = res.data[0];
            let file_url = v.FileURL;
            //   let file_hash = v.FileHash;
            this.card_id_positive = Global.GetCoinIconURL(file_url);
          }
        }
      });
    },
    /**
     * 上传背面
     */
    async UploadBeiMian($event) {
      Global.ShowGlobalLoadingAsync("正在上传", async (m) => {
        let files = $event.target.files;
        if (files.length > 0) {
          let res = await Tools.UploadFileAsync("UploadFile", {}, files);
          if (Tools.CheckHtmlIsOK(res)) {
            let v = res.data[0];
            let file_url = v.FileURL;
            //   let file_hash = v.FileHash;
            this.card_id_back = Global.GetCoinIconURL(file_url);
          }
        }
      });
    },

    async GetMyRealNameAuthenticationVOAsync() {
      let res = await Tools.GetHtmlAsync("GetMyRealNameAuthenticationVO", {});
      if (Tools.CheckHtmlIsOK(res)) {
        if (res.data.have) {
          this.real_vo = res.data.real_vo;
        } else {
          this.real_vo = null;
        }
      }
    },
    async ToAuthAsync() {
      let res = await Tools.GetHtmlAsync("RealNameAuthenticationApplication", {
        real_name: this.real_name,
        card_id: this.card_id,
        card_id_positive: this.card_id_positive, //身份证正面
        card_id_back: this.card_id_back, //身份证背面
      });
      if (Tools.CheckHtmlIsOK(res)) {
        Tools.AlertSuccess("已提交!");
        this.CloseMe();
      }
    },
  },
  setup() {
    const user_vo = inject("user_vo");
    const theme_obj = inject("theme_obj");
    return {
      user_vo,
      theme_obj
    };
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: @c000000_F0F1F5;
  z-index: 1000;
}

.head {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 14px 0px;
  width: 100%;
  position: relative;
  background-color: @c1B1C1E_FFFFFF;

}

.content {
  margin-top: 20px;
}

.input_box {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  margin-bottom: 10px;
  width: 100%;
  // padding: 0px 5%;
}
.input_box > span:nth-child(1) {
  color: @FFFFFF_c040506;
}

.input {
  display: inline-block;
  width: 100%;
  height: 40px;
  border: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  background-color: transparent;
}

.select_input_name {
  color: rgb(240, 186, 82) !important;
}

.select_input {
  border-bottom: 2px solid rgb(240, 186, 82) !important;
}

.bottom_box {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
}

.bottom_box > div {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.bottom_box > div > img {
  width: 25px;
  height: 25px;
}

.close_btn {
  position: absolute;
  left: 10px;
  height: 20px;
}

.main_box {
  height: 100%;
  width: 100%;
}

.content_span {
  text-align: left;
  font-size: 14px;
  color: @FFFFFF_c040506;;
  margin-bottom: 20px;
  background-color: @c1B1C1E_FFFFFF;
  padding: 20px 15px;
}

.tijiao_btn {
  background-color: rgb(12, 208, 178);
  width: 80%;
  height: 40px;
  border-radius: 20px;
  border: 1px ;
}

.up_card_id_box {
  display: flex;
  flex-direction: column;
  & > div:nth-of-type(1) {
    margin-bottom: 9px;
    text-align: left;
    font-size: 12px;
    color: @FFFFFF_c040506;
  }

  & > div:nth-of-type(2) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    label {
      display: inline-block;
      width: 59%;
      height: 27vw;
    }
    img {
      border-radius: 6px;
    }
  }
}

.tab_box {
  background-color: @c1B1C1E_FFFFFF;
  padding: 15px 9px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.xingxing {
  color: red;
}
.inputcardimg{
  width: 160px  !important;
  height: 88px  !important;
}
</style>