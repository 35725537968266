<template>
  <div class="platform_switch_box">
    <div class="back">
      <img class="close_btn" @click="CloseMe" src="image/back.png" />
    </div>
    <span class="title">切换站点</span>
    <div class="input_row">
      <input
        placeholder="请输入搜索内容"
        class="switch_sites_input"
        v-model="inputvalue"
      />
      <button @click="GetCanSwitchPlatformListAsync">搜索</button>
    </div>
    <template v-if="substationList.length > 0">
      <div class="box" v-for="(v, index) in substationList" :key="index">
        <img :src="v.icon" />
        <div>{{ v.platform_name }}</div>
        <div v-if="host_platform_vo?.guid == v.guid">本站</div>
        <div v-else class="qie_huan_btn" @click="TryToPlatform(v)">切换</div>
      </div>
    </template>
    <template v-else>
      <div class="no_data">
        <span>暂无数据</span>
      </div>
    </template>
  </div>
</template>
<script>
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import Page from "@/components/PageComponent.vue";
import { inject } from "vue";
import SuperEvents from "@/ts/SuperEvents";
import BackDataVO from "@/ts/vo/BackDataVO";
import SuperTimer from "@/ts/SuperTimer";
export default {
  data() {
    return {
      select_platform_vo: {},
      substationList: [],
      inputvalue: "",
    };
  },
  setup() {
    let host_platform_vo = inject("host_platform_vo");
    return {
      host_platform_vo,
    };
  },
  components: {
    Page,
  },
  methods: {
    /**
     * 尝试跳转分站
     * @param {*} pf_vo
     */
    TryToPlatform(pf_vo) {
      if (pf_vo.have_account) {
        this.ToPlatform(pf_vo);
      } else {
        debugger;
        Global.ShowSuperMessageBox({
          title: "系统提示!",
          content: "目标站点并未开通账号,是否授权？",
          ok_text: "授权并跳转",
          cancel_text: "取消",
          show_ok_btn: true,
          show_cancel_btn: true,
          ok_fun: async () => {
            let res = await Tools.GetHtmlAsync(
              "PlatformAccountSynchronization",
              {
                target_guid: pf_vo.guid,
              }
            );
            if (Tools.CheckHtmlIsOK(res)) {
              this.ToPlatform(pf_vo);
            }
          },
        });
      }
    },
    /**
     * 切换平台
     * @param {} pf_vo
     */
    ToPlatform(pf_vo) {
      Global.SetUsePlatformGuid = pf_vo.guid;
      Global.host_platform_vo = pf_vo;
      SuperEvents.SubmitEvent("OnChangePlatform", {});
      SuperEvents.SubmitEvent("OnCloseApplet", {});
      Global.ShowGlobalLoading(
        "站点切换中!",
        async () => {
          SuperTimer.Start(
            "loading_time",
            () => {
              this.CloseMe();
              Global.HideGlobalLoading();
              return false;
            },
            2000
          );
        },
        false
      );
    },
    CloseMe() {
      Global.ClosePlatformSwitch();
    },
    async GetCanSwitchPlatformListAsync() {
      let res = await Tools.GetHtmlAsync("GetCanSwitchPlatformList", {
        page: "",
        num: "",
        search: this.inputvalue,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        console.log("分站列表", res);
        this.substationList = res.data.data;
      }
    },
    //分页
    async GetMySubstitutionRecordListAsync() {
      this.$refs["GetCanSwitchPlatformList"]?.Start({
        filter_y: this.timeobj.year,
        filter_m: this.timeobj.month,
      });
    },
    GetCanSwitchPlatformList(d, page) {
      if (page == 1) {
        this.list = d.data;
      } else {
        this.list.PushAll(d.data);
      }
    },
  },
  mounted() {
    this.GetCanSwitchPlatformListAsync();
  },
};
</script>
<style scoped lang="less">
.platform_switch_box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  font-size: 12px;
  background-color: @home_bgc_class;
  color: @ffffff_324460;

  .back {
    margin: 10px;
    text-align: left;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 30px;
    .qie_huan_btn {
      color: #01EECC !important;
      font-weight: bold;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 10px;
    }
  }

  .no_data {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: @FFFFFF_c040506;
    opacity: 0.2 !important;
    font-size: 30px;
    font-weight: bold;
  }
}
.title {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
}
.input_row {
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  height: 30px;
  .switch_sites_input {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: rgb(48, 53, 57);
    background-color: @box_bg;
    text-align: center;
  }
  button {
    // background: rgb(64, 158, 255);
    background: #01EECC !important;
    // border: 1px rgb(64, 158, 255) solid;
    border: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #ffffff;
    width: 60px;
  }
  ::-webkit-input-placeholder {
    text-align: center;
  }
}
</style>