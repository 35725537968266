export default class LookBao_RPC {
    public web3: any;
    constructor() {

    }
    /**
     * 获取交易的nonce
     * @param {string} address 交易地址
     * @param {function(err,result)} callfun 交易回调
     */
    GetTransactionCount(
        address: string,
        callfun: (arg0: any, arg1: any) => void
    ) {
        this.web3.eth.getTransactionCount(address, callfun);
    }

    /**
     * 返回当前的客户端版本。
     */
    public web3_clientVersion(): string {
        return this.web3.eth.getNodeInfo();
    }
    /**
     * 返回当前以太坊协议的版本。 
     */
    public eth_protocolVersion(): string {
        return this.web3.eth.getProtocolVersion();
    }

    /**
     * 返回客户端的coinbase地址。
     */
    public eth_coinbase(): any {
        return this.web3.eth.getCoinbase();
    }

    /**
     * 如果客户端在积极挖矿则返回true。
     */
    public eth_mining(): boolean {
        return this.web3.eth.isMining();
    }

    /**
     * 返回节点挖矿时每秒可算出的哈希数量。
     */
    public eth_hashrate(): number {
        return this.web3.eth.getHashrate();
    }

    /**
     * 对于已经同步的客户端，该调用返回一个描述同步状态的对象；对于未同步客户端，返回false。
     */
    public eth_syncing(): any | boolean {
        return this.web3.eth.isSyncing();
    }

    /**
     * 返回当前的gas价格，单位：wei。
     */
    public async eth_gasPriceAsync(): Promise<number> {
        let num: number = parseInt(await this.web3.eth.getGasPrice());
        if (num > 0)
            num = num / 10 ** 9;
        return num;
    }

    /**
     * 返回客户端持有的地址列表
     */
    public eth_accounts(): string[] {
        return this.web3.eth.getAccounts();
    }

    /**
     * 返回最新块的编号。
     */
    public async eth_blockNumberAsync(): Promise<number> {
        return await this.web3.eth.getBlockNumber();
    }

    /**
     * 获取账户余额
     * @param address  20字节，要检查余额的地址
     * @param str 整数块编号，或者字符串"latest", "earliest" 或 "pending"
     * @returns  当前余额，单位：wei
     */
    public eth_getBalance(address: string, str: "latest" | "earliest" | "pending" = "latest"): number {
        return this.web3.eth.getBalance(address, str);
    }

    /**
     * 返回指定地址存储位置的值
     * @param address  20字节，存储地址
     * @param num 存储中的位置号
     * @param str2 整数块号，或字符串"latest"、"earliest" 或"pending"
     */
    public eth_getStorageAt(address: string, num: number, str2: "latest" | "earliest" | "pending" = "latest"): any {
        return this.web3.eth.getStorageAt(address, num, str2);
    }

    /**
     * 返回指定地址的代码。
     * @param address 20字节，地址
     * @param str 整数块编号，或字符串"latest"、"earliest" 或"pending"
     */
    public eth_getCode(address: string, str: "latest" | "earliest" | "pending" = "latest") {
        return this.web3.eth.getCode(address, str);
    }

    /**
     * 返回具有指定哈希的块。
     * @param hash  32字节 - 块哈希
     * @param b 为true时返回完整的交易对象，否则仅返回交易哈希
     */
    public eth_getBlockByHash(hash: string, b: boolean): any {
        return this.web3.eth.getBlock(hash, b);
    }

    /**
     * 返回指定编号的块。
     * @param str   整数块编号，或字符串"earliest"、"latest" 或"pending"
     * @param b 为true时返回完整的交易对象，否则仅返回交易哈希
     */
    public async eth_getBlockByNumber(arg: number | "latest" | "earliest" | "pending" = "latest", b: boolean = true): Promise<any> {
        return await this.web3.eth.getBlock(arg, b);
    }

    /**
     * 返回具有指定哈希的块具有指定索引位置的叔伯。
     * @param arg 2字节 - 块哈希
     * @param arg1 叔伯索引位置
     */
    public eth_getUncleByBlockHashAndIndex(arg: any, arg1: any): any {
        return this.web3.eth.getUncle(arg, arg1);
    }

    /**
     * 返回具有指定编号的块内具有指定索引序号的叔伯。
     * @param arg 整数块编号，或字符串"earliest"、"latest" 或"pending"
     * @param arg1 叔伯在块内的索引序号
     */
    public eth_getUncleByBlockNumberAndIndex(arg: number | "latest" | "earliest" | "pending" = "latest", arg1: any): any {
        return this.web3.eth.getUncle(arg, arg1);
    }

    /**
     * 返回指定块内的交易数量，使用哈希来指定块。
     * @param str 32字节，块哈希
     */
    public eth_getBlockTransactionCountByHash(str: any): any {
        return this.web3.eth.getBlockTransactionCountCall(str);
    }
    /**
     * 返回指定块内的交易数量，使用块编号指定块。
     * @param str 整数块编号，或字符串"earliest"、"latest"或"pending"
     */
    public eth_getBlockTransactionCountByNumber(arg: any | "latest" | "earliest" | "pending" = "latest"): any {
        return this.web3.eth.getBlockTransactionCountCall(arg);
    }
    /**
     * 返回指定交易的收据，使用哈希指定交易。需要指出的是，挂起的交易其收据无效。
     * @param hash  32字节 - 交易哈希
     * 
     */
    public eth_getTransactionReceipt(hash: string): Promise<{
        blockHash: string,
        blockNumber: number,
        contractAddress: string, //合约地址,
        cumulativeGasUsed: number,
        from: string,
        gasUsed: number,
        logs: any[],
        logsBloom: string,
        status: boolean,
        to: string,
        transactionHash: string,
        transactionIndex: number
    } | null> {
        return this.web3.eth.getTransactionReceipt(hash);
    }
    /**
     * 返回指定地址发生的交易数量。
     * @param str 20字节，地址
     * @param str1  整数块编号，或字符串"latest"、"earliest"或"pending"
     */
    public eth_getTransactionCount(str: string, str1: "latest" | "earliest" | "pending" = "latest"): any {
        return this.web3.eth.eth_getTransactionCount(str, str1);
    }

    /**
     * 返回指定块的叔伯数量，使用哈希指定块
     *@param 32字节，块哈希
     */
    public eth_getUncleCountByBlockHash(hash: string): string {
        return this.web3.eth.getBlockUncleCount(hash);
    }
    /**
     * 返回指定块的叔伯数量，使用块编号指定块。
     *@param  整数块编号，或字符串"latest"、"earliest"或"pending"
     */
    public eth_getUncleCountByBlockNumber(str1: "latest" | "earliest" | "pending" = "latest"): any {
        return this.web3.eth.getBlockUncleCount(str1);
    }
    /**
     * 返回指定哈希对应的交易。
     * @param  32 字节 - 交易哈希
     */
    public eth_getTransactionByHash(txHash: string): Promise<{
        blockHash: string,
        blockNumber: number,
        from: string,
        gas: number,
        gasPrice: number,
        hash: string,
        input: string,
        nonce: number,
        r: string,
        s: string,
        to: string,
        transactionIndex: string,
        v: string,
        value: string
    } | null> {
        return this.web3.eth.getTransaction(txHash);
    }
    /**
     * 返回指定块内具有指定索引序号的交易。
     * @param 32字节 - 块哈希
     * @param   交易在块内的索引序号
     */
    public eth_getTransactionByBlockHashAndIndex(str: string, num: any): any {
        return this.web3.eth.getTransactionFromBlock(str, num);
    }
    /**
     * 返回指定编号的块内具有指定索引序号的交易。
     * @param 整数块编号，或字符串"earliest"、"latest" 或"pending"
     * @param   交易索引序号
     */
    public eth_getTransactionByBlockNumberAndIndex(str: "latest" | "earliest" | "pending" = "latest", num: any): any {
        return this.web3.eth.getTransactionFromBlock(str, num);
    }
    /**
     * 为签名交易创建一个新的消息调用交易或合约。
     * @param  签名的交易数据
     */
    public eth_sendRawTransaction(str: any): any {
        return this.web3.eth.sendSignedTransaction(str);
    }
    /**
     * 没有找到文档示例
     */
    public eth_signTransaction() {
        return this.web3.eth.signTransaction();
    }
    /**
     * 创建一个新的消息调用交易，如果数据字段中包含代码，则创建一个合约。
     * @param 交易对象，结果如下：

    from: DATA, 20字节 - 发送交易的源地址
    to: DATA, 20字节 - 交易的目标地址，当创建新合约时可选
    gas: QUANTITY - 交易执行可用gas量，可选整数，默认值90000，未用gas将返还。
    gasPrice: QUANTITY - gas价格，可选，默认值：待定(To-Be-Determined)
    value: QUANTITY - 交易发送的金额，可选整数
    data: DATA - 合约的编译带啊或被调用方法的签名及编码参数
    nonce: QUANTITY - nonce，可选。可以使用同一个nonce来实现挂起的交易的重写
     */
    public eth_sendTransaction(obj: any): any {
        return this.web3.eth.sendTransaction(obj);
    }
    /**
     * 使用如下公式计算以太坊签名：sign(keccak256("\x19Ethereum Signed Message:\n" + len(message) + message)))。

通过给消息添加一个前缀，可以让结果签名被识别为以太坊签名。这可以组织恶意DApp签名任意数据（例如交易）并使用 签名冒充受害者。

需要指出的是，进行签名的地址必须是解锁的。
     * @param 20字节，地址
     * @param 要签名的消息
     */
    public eth_sign(str: any, str1: any): any {
        return this.web3.eth.sign(str, str1);
    }
    /**
     * 立刻执行一个新的消息调用，无需在区块链上创建交易。
     * @param 交易调用对象

    from: DATA, 20 Bytes - 发送交易的原地址，可选
    to: DATA, 20 Bytes - 交易目标地址
    gas: QUANTITY - 交易可用gas量，可选。eth_call不消耗gas，但是某些执行环节需要这个参数
    gasPrice: QUANTITY - gas价格，可选
    value: QUANTITY - 交易发送的以太数量，可选
    data: DATA - 方法签名和编码参数的哈希，可选
    QUANTITY|TAG - 整数块编号，或字符串"latest"、"earliest"或"pending
     */
    public eth_call(obj: any): any {
        return this.web3.eth.call(obj);
    }
    /**
     * 执行并估算一个交易需要的gas用量。该次交易不会写入区块链。注意，由于多种原因，例如EVM的机制 及节点旳性能，估算的数值可能比实际用量大的多。
     * @param 参考eth_call调用的参数，所有的属性都是可选的。如果没有指定gas用量上限，geth将使用挂起块的gas上限。 在这种情况下，返回的gas估算量可能不足以执行实际的交易。
     */
    public eth_estimateGas(obj: any): any {
        return this.web3.eth.estimateGas(obj);
    }
    /**
     * 用于提交POW解决方案
     * 
     * @param  8字节 - nonce，64位
     * @param  32字节 - 头部的pow哈希，256位
     * @param  32字节 - 混合摘要，256位
     */
    public eth_submitWork(str: any, str1: any, str2: any): any {
        return this.web3.eth.submitWork(str, str1, str2);
    }
    /**
     * 返回当前块的哈希、种子哈希、以及要满足的边界条件，即目标。
     */
    public eth_getWork() {
        return this.web3.eth.getWork();
    }
    /**
     * 返回指定过滤器中的所有日志。
     * @param 过滤器对象，参考eth_newFilter调用的参数
     */
    public async eth_getLogsAsync(fromBlock: string, toBlock: number | "latest", address: string | string[], topics: string[]): Promise<any> {

        return await this.web3.eth.getPastLogs({
            fromBlock: fromBlock,
            toBlock: toBlock,
            address: address,
            topics: topics
        });
    }

}