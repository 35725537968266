<template>
  <div class="transfer_icon_box" @click="OpenTransferState">
    <my-image
      :class="upper_chain_data && upper_chain_data.run_count > 0 ? 'transfer_icon_rotate' : ''"
      src="image/transfer_icon.png"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    upper_chain_data: Object,
  },
  methods: {
    OpenTransferState() {
      this.$parent.show_transfer_state_window = true;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.transfer_icon_box {
  position: fixed;
  bottom: 60px;
  right: 10px;
  z-index: 1000;
}

.transfer_icon_box > img {
  width: 50px;
  height: 50px;
}
.transfer_icon_rotate {
  animation: transfer_icon_rotate 2s linear infinite;
}

@keyframes transfer_icon_rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>