<template>
  <TransferPassword
    v-if="show_transfer_password"
    custom_title="红包打包解锁"
    :call_back="PasswordCallBack"
    :close_callback="ClosePasswordCallBack"
  ></TransferPassword>
  <div class="redpacket_tools_body" v-show="is_load">
    <div class="redpacket_tools_contnet">
      <img
        v-if="$store.state.colorbtn"
        @click="CloseMe"
        class="redpacket_tools_close"
        :src="
          !$store.state.colorbtn
            ? require('@/assets/image/xiaoxi/blackx.png')
            : require('@/assets/image/xiaoxi/x.png')
        "
      />
      <img
        v-else
        @click="CloseMe"
        class="redpacket_tools_close btn2"
        src="@/assets/image/xiaoxi/blackx.png"
      />
      <span class="redpacket_tools_title">红包</span>
      <div class="redpacket_tools_item">
        <InputBox
          Css="SuperTextCenter"
          InputType="number"
          class="inputwidth"
          v-model="amount"
          PlaceHolder="请输入金额"
        ></InputBox>
      </div>

      <div class="redpacket_tools_item radio_content">
        <div class="middel">
          <input
            type="radio"
            name="temp_red_pack_type"
            v-model="temp_red_pack_type"
            :disabled="!open_change_package"
            value="1"
          />
          <span>零钱</span>
        </div>
        <div class="middel">
          <input
            type="radio"
            name="temp_red_pack_type"
            v-model="temp_red_pack_type"
            :disabled="!open_miu_packget"
            value="2"
          />
          <span>{{ host_platform_vo?.main_integral_alias }}</span>
        </div>
      </div>

      <div class="redpacket_tools_item button_box">
        <button
          :class="['success_btn', CanRedPacket ? '' : 'to_gray']"
          @click="ToCreateAsync()"
        >
          确认
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import InputBox from "@/components/lookbao_components/LookBaoInput.vue";
import { RedPacketToolsModelEnum } from "@/ts/SuperEnum";
import { IM_PropGoods } from "@/ts/vo/IM_PropGoods";
import Tools from "@/ts/Tools";
import TransferPassword from "@/components/TransferPasswordComponent.vue";
import { inject } from "@vue/runtime-core";
import Global from "@/ts/Global";
export default {
  components: {
    InputBox,
    TransferPassword,
  },
  setup() {
    const user_vo = inject("user_vo");
    const host_platform_vo = inject("host_platform_vo");
    return {
      user_vo,
      host_platform_vo,
    };
  },
  props: {
    /**
     * 是否允许老资产
     */
    allow_use_old_prop: Boolean, //是否允许老资产
    allow_use_old_now_count: Number, //当前已使用数量(一般用于老资产时检测可以避免打包不出来的尴尬局面)
    red_packet_model: RedPacketToolsModelEnum, //红包工具模式
    red_packet_callback: function (red_packet_model, d) {}, //回调函数(根据不同模式返回不同的数据)

    // 特殊需求
    use_temp_id: Number, //模板id
  },
  data() {
    return {
      nick_name: "",
      is_load: false,
      show_transfer_password: false, //解锁窗口
      temp_red_pack_type: 1,
      amount: "",
      packet_id: 0, //打包ID
      substation_config_vo: null, //平台配置
    };
  },
  computed: {
    /**
     * 检查是否可以打包红包
     */
    CanRedPacket() {
      if (this.substation_config_vo) {
        if (
          this.substation_config_vo.open_change_package == 1 ||
          this.substation_config_vo.open_miu_packget == 1
        )
          return true;
      }
      return false;
    },
    open_change_package() {
      return this.substation_config_vo?.open_change_package == 1;
    },
    open_miu_packget() {
      return this.substation_config_vo?.open_miu_packget == 1;
    },
  },
  methods: {
    /**
     * 用户获取分站配置数据
     */
    async UserGetSubstationConfigurationAsync() {
      let res = await Tools.GetHtmlAsync("UserGetSubstationConfiguration", {});
      if (Tools.CheckHtmlIsOK(res)) {
        this.substation_config_vo = res.data;
      }
    },
    CloseMe() {
      this.$parent.show_redpack_tools = false;
    },
    ClosePasswordCallBack() {
      this.CloseMe();
    },
    /**
     * 支付回调
     */
    async PasswordCallBack(pwd) {
      let res = await Tools.GetHtmlAsync("PayUnlock", { pwd: pwd });
      if (Tools.CheckHtmlIsOK(res)) {
        this.RedPacketToPackAsync();
      } else {
        this.TryRedPacketToPackAsync();
      }
    },
    /**
     * 打包尝试解锁
     */
    async TryRedPacketToPackAsync() {
      let res = await Tools.GetHtmlAsync("CheckHavePayPassword", {});
      if (Tools.CheckHtmlIsOK(res)) {
        let d = res.data;
        let must_to_unlock = d.must_to_unlock;
        let have_pay_pwd = d.have_pay_pwd;
        if (have_pay_pwd) {
          //如果存在密码,并且必须解锁
          //进行解锁
          if (must_to_unlock) {
            this.$nextTick(() => {
              this.show_transfer_password = true;
            });
          } else {
            //不需要解锁直接转移
            await this.RedPacketToPackAsync();
          }
        } else {
          //跳转到密码设置界面
          Global.ShowSetTransferPassword(async () => {
            await this.RedPacketToPackAsync();
          });
        }
      }
    },
    /**
     * 红包打包
     * @param id 模板ID
     */
    async RedPacketToPackAsync() {
      let res = await Tools.GetHtmlAsync("RedPacketToPack", {
        red_pack_template_id: this.packet_id,
        allow_use_old_prop: this.allow_use_old_prop,
        allow_use_old_now_count: this.allow_use_old_now_count,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        let d = res.data;
        this.red_packet_callback &&
          this.red_packet_callback(this.red_packet_model, d);
        this.CloseMe();
      } else {
        if (
          this.red_packet_model ==
          RedPacketToolsModelEnum.UseTemplateIDToBuildRedPack
        ) {
          this.ClosePasswordCallBack();
        }
      }
    },
    /**
     * 执行创建
     */
    async ToCreateAsync() {
      if (!this.CanRedPacket) return;

      let res = null;
      switch (this.red_packet_model) {
        case RedPacketToolsModelEnum.OnlyCreateRedPackTemplate:
          if (Tools.IsNull(this.amount)) {
            Tools.AlertError("请输入金额!");
            return;
          }
          res = await Tools.GetHtmlAsync("CreateRedPackTemplate", {
            temp_red_pack_type: this.temp_red_pack_type,
            amount: this.amount,
          });
          if (Tools.CheckHtmlIsOK(res)) {
            this.red_packet_callback &&
              this.red_packet_callback(this.red_packet_model, res.data);
            this.CloseMe();
          }
          break;
        case RedPacketToolsModelEnum.CreateTemplateAndBuildRedPack:
          if (Tools.IsNull(this.amount)) {
            Tools.AlertError("请输入金额!");
            return;
          }
          res = await Tools.GetHtmlAsync("CreateRedPackTemplate", {
            temp_red_pack_type: this.temp_red_pack_type,
            amount: this.amount,
          });
          if (Tools.CheckHtmlIsOK(res)) {
            let prop_goods_vo = res.data;
            this.packet_id = prop_goods_vo.id;
            await this.TryRedPacketToPackAsync();
          }
          break;

        default:
          break;
      }
    },
  },
  async created() {
    await this.UserGetSubstationConfigurationAsync();

    if (this.open_change_package) {
      this.temp_red_pack_type = 1;
    } else if (this.open_miu_packget) {
      this.temp_red_pack_type = 2;
    }

    this.packet_id = 0;
    this.nick_name = this.user_vo.nick_name;
    switch (this.red_packet_model) {
      case RedPacketToolsModelEnum.OnlyCreateRedPackTemplate:
        break;
      case RedPacketToolsModelEnum.UseTemplateIDToBuildRedPack:
        if (this.use_temp_id <= 0) {
          Tools.AlertError("模板ID错误!");
          this.CloseMe();
          return;
        }
        this.packet_id = this.use_temp_id;
        await this.TryRedPacketToPackAsync();
        // this.CloseMe();
        return;
      case RedPacketToolsModelEnum.CreateTemplateAndBuildRedPack:
        break;
    }
    this.is_load = true;
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.redpacket_tools_body {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 800;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  .redpacket_tools_contnet {
    background-color: @c303339_ffffff;
    padding: 20px 20px 10px 20px;
    border: 1px solid @c303339_ffffff;
    border-radius: 10px;
    width: 100%;
    margin: 0 30px;
    position: relative;
    .redpacket_tools_title {
      font-size: 16px;
    }

    .redpacket_tools_close {
      position: absolute;
      top: 10px;
      right: 10px;
      // width: 25px;
      // height: 25px;
    }
    .btn2 {
      width: 16px;
      height: auto;
    }
    .redpacket_tools_item {
      display: flex;
      margin-bottom: 10px;
      width: 100%;
      justify-content: center;
      margin-top: 10px;
      .middel {
        font-size: 16px;
        margin: 20px 0 15px;
      }
    }

    .radio_content {
      display: flex;
      justify-content: space-around;
      padding: 0 50px;
      input {
        margin-right: 5px;
      }
    }

    .button_box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      button {
        width: 80%;
        margin: 0 10px;
        padding: 7px 0;
        border-radius: 10px;
      }
    }
  }
}
.inputwidth {
  width: 70%;
}
</style>
<style>
.SuperTextCenter {
  text-align: center !important;
}
</style>