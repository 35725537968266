import CordovaPlugin from "./CordovaPlugin";
import Global from "./Global";
import { I_Server } from "./I_Server";
import SuperEvents from "./SuperEvents";
import Tools from "./Tools";
import BackDataVO from "./vo/BackDataVO";
import ServerWaitEvent from "./vo/ServerWaitEvent";

export default class AppServer implements I_Server {
    private is_connect: boolean = false;
    private interval?: NodeJS.Timer | number;
    public success_connect_event: (() => void) | null = null;

    public async Start(success_connect_event: (() => void) | null): Promise<void> {
        Global.app && Global.app.SetConnectState('connecting');

        success_connect_event && (this.success_connect_event = success_connect_event);
        (window as any).LookBaoSocketEvent = {
            OnOpen: async () => {
                console.log("socket连接成功!");

                Global.app && Global.app.SetConnectState('connected');
                this.is_connect = true;
                
                Global.home && Global.home.GetOffineMessageAsync();

                this.success_connect_event && this.success_connect_event();
            },
            OnMessage: (v: string) => {
                let back_data = new BackDataVO(v);
                SuperEvents.CallEvent(back_data);
            },
            OnClose: async () => {
                console.log("连接已关闭!");
                this.is_connect = false;
                // await this.ReConnectAsync();
                Global.app && Global.app.SetConnectState('connecting');
            },
            OnError: () => {
                console.log("连接出错!");
            }
        };
        let connected = await CordovaPlugin.ConnectIMWebSocketAsync();
        console.log("APP连接WEBSOCKET 返回值:" + connected);
    }

    async ToCloseAsync(): Promise<boolean> {
        try {
            //强制关闭连接
            return await CordovaPlugin.IMWebSocketCloseAsync();
        }
        catch(ex) {

        }
        finally {
        }
        return false;
    }
    async ReConnectAsync(): Promise<boolean> {
        console.log("正在重连.....");
        Global.app && Global.app.SetConnectState('connecting');
        console.log("正在关闭连接....");
        await this.ToCloseAsync();
        console.log("连接关闭完成!");
        //准备重连
        setTimeout(async () => {
            console.log("正在访问插件重连!");
            await CordovaPlugin.IMWebSocketReConnectAsync();
            console.log("插件访问完毕!");
        }, 1500);
        return true;
    }

    /**
     * 发送数据
     * @param action 
     * @param data 
     */
    public Send(action: string, data: any): void {
        let json: string = JSON.stringify({
            action: action,
            data: data,
            rand_number: Tools.GUID,
            token: Global.GetLoginToken,
            use_platform_guid : Global.GetUsePlatformGuid
        });
        CordovaPlugin.IMWebSocketSendAsync(json);
    }

    /**
     * 同步请求接口
     * @param action 请求键
     * @param data 请求数据
     * @param wait_time 默认时间3秒
     */
    public SendAsync(bind_this: any, action: string, data: any, wait_time: number = 60000): Promise<{ flag: boolean; _this: any; err_msg: string; data_vo: BackDataVO | null; } | null> {
        let rand_number: string = Tools.GUID;
        let wait_event = new ServerWaitEvent(bind_this, action, rand_number, wait_time);

        return new Promise(async (r) => {
            try {
                wait_event.call_back_fun = (_this: any, data_vo: BackDataVO): void => {
                    clearTimeout(wait_event.timeout_Interval as number);
                    r({
                        flag: true,
                        _this: _this,
                        err_msg: "",
                        data_vo: data_vo
                    });
                }

                SuperEvents.AddEvents(wait_event);

                let json: string = JSON.stringify({
                    action: action,
                    data: data,
                    rand_number: rand_number,
                    token: Global.GetLoginToken,
                    use_platform_guid : Global.GetUsePlatformGuid
                });
                await CordovaPlugin.IMWebSocketSendAsync(json);

                wait_event.timeout_Interval = setTimeout(() => {

                    clearTimeout(wait_event.timeout_Interval as number);
                    r({
                        flag: false,
                        _this: bind_this,
                        err_msg: "执行超时!",
                        data_vo: null
                    });
                }, wait_time);
                // console.log(`启动定时器:${wait_event.timeout_Interval}/ms!`);
            }
            catch (e) {
                r({
                    flag: false,
                    _this: bind_this,
                    data_vo: null,
                    err_msg: e as string
                });
            }
        })
    }
}