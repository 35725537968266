<template>
  <div
    ref="uplink_status_alert_box"
    @click="OpenUplinkWindow"
    class="uplink_status_alert_box"
  >
    <div class="uplink_item">
      <div class="uplink_item_content">
        <div class="tab_count">
          {{ count }}
        </div>

        <div class="head_body">
          <div class="head_box">
            <my-image
              class="user_pic"
              :src="GetResourceIMG(status_data.from_user_vo?.head_pic)"
              alt=""
            />
            <span class="nick_name">{{
              status_data.from_user_vo?.nick_name
            }}</span>
          </div>
          <span>{{ status_data.task_title }}</span>
          <div class="head_box">
            <my-image
              class="user_pic"
              :src="GetResourceIMG(status_data.to_user_vo?.head_pic)"
              alt=""
            />
            <span class="nick_name">{{
              status_data.to_user_vo?.nick_name
            }}</span>
          </div>
        </div>

        <div class="item">
          <div
            :class="[
              'steps_node',
              'none_state',
              v.task_state == 2
                ? 'success_state'
                : v.task_state == 3
                ? 'error_state'
                : '',
            ]"
            :key="index"
            v-for="(v, index) in status_data.steps_list"
          >
            <div
              :class="
                index + 1 == status_data.steps_list?.length
                  ? ''
                  : 'steps_node_content'
              "
            >
              <div>
                <my-image
                  v-if="v.task_state == 0"
                  class="icon"
                  src="image/wait_icon.png"
                  alt=""
                />
                <svg
                  v-else-if="v.task_state == 1"
                  viewBox="0 0 1024 1024"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class="anticon-spin"
                >
                  <path
                    d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"
                  ></path>
                </svg>
                <my-image
                  v-else-if="v.task_state == 2"
                  class="icon"
                  src="image/gou_xuan.png"
                  alt=""
                />
                <my-image
                  v-else-if="v.task_state == 3"
                  class="icon"
                  src="image/error_icon.png"
                  alt=""
                />
              </div>
              <div class="lie">
                <span class="title"
                  >{{ v.title }}({{ v.current_count }}/{{ v.max_count }})</span
                >
                <!-- <span class="tab">{{ v.tab }}</span> -->
              </div>
            </div>
          </div>
        </div>
        <div class="transaction_hash">{{ status_data.transaction_hash }}</div>
      </div>

      <!-- <div class="tab_content" v-if="count > 1">
        <span>{{ `点击打开更多记录` }}</span>
      </div> -->
      <div class="tab_content">
        <span>{{ `点击关闭此窗口` }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router";
import Global from "@/ts/Global";
import SuperTimer from "@/ts/SuperTimer";
export default {
  data() {
    return {
      status_data: {},
      count: 0,
    };
  },
  props: {
    default_uplink_status_data: Object,
  },
  watch: {
    default_uplink_status_data(_new, _old) {
      let bct_vo = _new.bct_vo;
      if (
        bct_vo.task_guid == this.status_data.task_guid ||
        this.status_data.task_guid == null ||
        (this.status_data.no_show == true &&
          bct_vo.task_guid != this.status_data.task_guid)
      ) {
        this.status_data = bct_vo;
        this.count = _new.count;
        if (
          this.status_data.work_state == 3 ||
          this.status_data.work_state == 2
        ) {
          SuperTimer.Start(
            "hide_uplink_status_alert",
            () => {
              this.$refs["uplink_status_alert_box"].addEventListener(
                "webkitTransitionEnd",
                this.call_fun
              );
              this.Hide();
              return false;
            },
            1000
          );
        }
      }
    },
  },
  methods: {
    call_fun() {
      this.$refs["uplink_status_alert_box"].removeEventListener(
        "webkitTransitionEnd",
        this.call_fun
      );
      this.count = 0;
    },
    OpenUplinkWindow() {
      this.Hide();
      this.$parent.show_pp_icon = true;
      // if (this.count > 1) Global.OpenUplinkStatusWindow();
    },
    GetResourceIMG(img) {
      return Global.GetCoinIconURL(img);
    },
    GetNowURL() {
      let v = router;
      let path = v.currentRoute.value.path;
      return path;
    },
    Show() {
      if (this.status_data.no_show) return;
      if (this.GetNowURL() == "/chat_window") return;
      if (!Global.app.show_uplink_status_window)
        this.$refs["uplink_status_alert_box"].style = "top:30px";
    },
    Hide() {
      this.status_data.no_show = true;
      this.$refs["uplink_status_alert_box"].style = "top:-200px";
    },
  },
  mounted() {},
};
</script>
<style scoped>
.uplink_status_alert_box {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: -200px;
  left: 0px;
  z-index: 500;
  width: 100%;
  color: white;
  padding: 10px;
  transition: all 0.5s ease;
}

.uplink_status_box::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.uplink_item {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #191F23;
  padding: 30px 0px 0px 0px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.uplink_item_content {
  padding: 0px 10px;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.steps_node {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  flex-grow: 1;
  overflow: hidden;
  padding: 0px 10px;
}
.steps_node:last-child {
  flex-grow: 0 !important;
}

.steps_node_content {
  position: relative;
}

.steps_node_content::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #1890ff;
  border: 0px;
  content: "";
}

.tab {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.title {
  font-size: 12px;
}

.icon {
  width: 20px;
  height: 20px;
}
.lie {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.none_state {
  color: white;
}

.success_state {
  color: #42b983;
}

.error_state {
  color: #ff5722;
}

.head_body {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
  align-items: center;
}

.head_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user_pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.nick_name {
  font-size: 12px;
  margin-top: 5px;
}

.ulink_head_box {
  position: relative;
  height: 40px;
  font-size: 18px;
}

.close_me {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.transaction_hash {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  white-space: nowrap;
  margin-top: 5px;
}

.tab_count {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #ff5722;
  min-width: 50px;
  width: 50px;
  font-size: 12px;
  border-radius: 10px;
}

.tab_content {
  /* position: absolute; */
  /* left: 0px;
  bottom: 0px; */
  background-color: rgba(0, 0, 0, 0.2);
  /* min-width: 50px; */
  font-size: 12px;
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
  /* margin-top: 10px; */
  display: flex;
  justify-content: center;
  width: 100%;
}

.la_gan_box {
  position: absolute;
  bottom: -38px;
  right: 0px;
  z-index: 600;
  width: 100%;
  pointer-events: none;
}

.la_gan_btn {
  width: 50px;
  height: 50px;
}
</style>